import './avocationQuestionnaire.css';
import CommonFormHeader from '../../commonFormHeader';
import PlanQuestion from './planQuestion';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import { useEffect, useState } from 'react';
import AerialSports from './aerialSportsSection';
import AutoSection from './autoSection';
import MountaineeringSection from './mountaineeringSection';
import UnderwaterSection from './underwaterSection';
import OthersSection from './othersSection';
import { useDispatch, useSelector } from 'react-redux';
import { avocationAction } from '../../../actions/avocationAction';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import axios from '../../../axios/axios';
import ToastMessage from '../../ui/toastMessage';
import Loader from '../../loader';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const AvocationQuestionnaire = () => {
  const [nextPath, setNextPath] = useState('');
  const [aerialSportsCheckBoxes, setAerialSportsCheckBoxes] = useState([]);
  const [aerialSportsOtherValue, setAerialSportsOtherValue] = useState('');
  const [ballooningFieldStatus, setBallooningFieldStatus] = useState({});
  const [ballooningFieldOptions, setBallooningFieldOptions] = useState({});
  const [
    ballooningSkyDivingFieldMinimumHeight,
    setSkyDivingFieldMinimumHeight,
  ] = useState();
  const [skyDivingField, setSkyDivingField] = useState({});
  const [heliSkiingField, setHeliSkiingField] = useState({});
  const [heliSkiingStatus, setHeliSkiingStatus] = useState({});
  const [skyDivingStatus, setSkyDivingStatus] = useState({});
  const [ballooningPSAffiliationRadio, setPSAffiliationRadio] = useState({});
  const [BallooningParachuteSportsReserve, setParachuteSportsReserve] =
    useState();
  const [ballooningPSAAffiliatedTextArea, setPSAAffiliatedTextArea] = useState(
    {}
  );
  const [autoCheckBoxes, setAutoCheckBoxes] = useState([]);
  const [autoOtherValue, setAutoOtherValue] = useState('');
  const [racingFieldOptions, setRacingFieldOptions] = useState({});
  const [racePlan, setRacePlan] = useState({});
  const [racePlanTextArea, setRacePlanTextArea] = useState({});
  const [purposeOfRacing, setPurposeOfRacing] = useState({});
  const [racingCheckBoxes, setRacingCheckBoxes] = useState([]);
  const [racingOtherValue, setRacingOtherValue] = useState('');
  const [mountaineeringCheckBoxes, setMountaineeringCheckBoxes] = useState([]);
  const [mountaineeringOtherValue, setMountaineeringOtherValue] = useState('');
  const [underwaterDivingCheckBoxes, setUnderwaterDivingCheckBoxes] = useState(
    []
  );
  const [heliSkiingPSAffiliationRadio, setHeliSkiingPSAffiliationRadio] =
    useState({});
  const [underwaterDivingOtherValue, setUnderwaterDivingOtherValue] =
    useState('');
  const [underwaterDivingLocations, setUnderwaterDivingLocations] = useState(
    []
  );
  const [heliSkiingPSAAffiliatedTextArea, setHeliSkiingPSAAffiliatedTextArea] =
    useState('');
  const [
    heliSkiingLocationPerformedFields,
    setHeliSkiingLocationPerformedFields,
  ] = useState({});
  const [
    underwaterDivingLocationsOtherValue,
    setUnderwaterDivingLocationsOtherValue,
  ] = useState('');
  const [UWDTRadioOptions, setUWDTRadioOptions] = useState({});
  const [UWDTDetail, setUWDTDetail] = useState('');
  const [UADRadioOptions, setUADRadioOptions] = useState({});
  const [UADDetail, setUADDetail] = useState('');
  const [underwaterDepthTable, setUnderwaterDepthTable] = useState({});
  const [othersCheckBoxes, setOthersCheckBoxes] = useState([]);
  const [othersOtherValue, setOthersOtherValue] = useState('');
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [ballooningLocationPerformedFields, setLocationPerformedFields] =
    useState({});
  const [
    heliSkiingSkyDivingFieldMinimumHeight,
    setHeliSkiingSkyDivingFieldMinimumHeight,
  ] = useState({});
  const [
    heliSkiingParachuteSportsReserve,
    setHeliSkiingParachuteSportsReserve,
  ] = useState({});
  const [SkyDivingFieldMinimumHeight, setSkyDivingFieldMinimumHeightField] =
    useState({});
  const [skyDivingParachuteSportsReserve, setSkyDivingParachuteSportsReserve] =
    useState({});
  const [skyDivingPSAffiliationRadio, setSkyDivingPSAffiliationRadio] =
    useState({});
  const [skyDivingPSAAffiliatedTextArea, setSkyDivingPSAAffiliatedTextArea] =
    useState('');
  const [
    skyDivingLocationPerformedFields,
    setSkyDivingLocationPerformedFields,
  ] = useState({});

  const sideNavData = useSelector((state) => state.pageNav);

  useEffect(() => {
    sideNavData?.formData?.map((item) => {
      if (item.sectionId === 'f1v1s18')
        if (item.show_section) {
          setNextPath('/form/aviationquestionnaire');
        } else {
          setNextPath('/form/existinginsurance');
        }
    });
  }, [sideNavData]);

  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(avocationAction());
  }, [dispatch]);

  useEffect(() => {
    setAutoOtherValue(formData?.data?.autoOtherValue?.value);
    let autoCheckboxDefaltValue = [];
    formData?.data &&
      formData?.data?.autoCheckBoxes.option?.map((item) => {
        if (item.defaultChecked) {
          autoCheckboxDefaltValue.push(item.value);
        }
      });
    setAutoCheckBoxes(autoCheckboxDefaltValue);

    setAerialSportsOtherValue(formData?.data?.aerialSportsOtherValue?.value);
    let aerialCheckboxDefaltValue = [];
    formData?.data &&
      formData?.data?.aerialSportsCheckBoxes.option?.map((item) => {
        if (item.defaultChecked) {
          aerialCheckboxDefaltValue.push(item.value);
        }
      });
    setAerialSportsCheckBoxes(aerialCheckboxDefaltValue);

    let raceCheckboxDefaltValue = [];
    formData?.data &&
      formData?.data?.racingCheckBoxes.option?.map((item) => {
        if (item.defaultChecked) {
          raceCheckboxDefaltValue.push(item.value);
        }
      });
    setRacingCheckBoxes(raceCheckboxDefaltValue);

    setMountaineeringOtherValue(
      formData?.data?.mountaineeringOtherValue?.value
    );
    let mountaineeringCheckboxDefaltValue = [];
    formData?.data &&
      formData?.data?.mountaineeringCheckBoxes?.option?.map((item) => {
        if (item.defaultChecked) {
          mountaineeringCheckboxDefaltValue.push(item.value);
        }
      });
    setMountaineeringCheckBoxes(mountaineeringCheckboxDefaltValue);

    setUnderwaterDivingOtherValue(
      formData?.data?.underwaterDivingOtherValue?.value
    );
    let underwaterDivingCheckboxDefaltValue = [];
    formData?.data &&
      formData?.data?.underwaterDivingCheckBoxes?.option?.map((item) => {
        if (item.defaultChecked) {
          underwaterDivingCheckboxDefaltValue.push(item.value);
        }
      });
    setUnderwaterDivingCheckBoxes(underwaterDivingCheckboxDefaltValue);

    setOthersOtherValue(formData?.data?.othersOtherValue?.value);
    let othersCheckboxDefaltValue = [];
    formData?.data &&
      formData?.data?.othersCheckBoxes.option?.map((item) => {
        if (item.defaultChecked) {
          othersCheckboxDefaltValue.push(item.value);
        }
      });
    setOthersCheckBoxes(othersCheckboxDefaltValue);

    let underwaterDivingLocationsDefalt = [];
    formData?.data &&
      formData?.data?.underwaterDivingLocations.option?.map((item) => {
        if (item.defaultChecked) {
          underwaterDivingLocationsDefalt.push(item.value);
        }
      });
    setUnderwaterDivingLocations(underwaterDivingLocationsDefalt);
    const initialRacePlan = formData?.data?.racePlan?.options?.find(
      (item) => item.defaultChecked
    );
    if (initialRacePlan) {
      const key = initialRacePlan['id'];
      const value = initialRacePlan['value'];
      setRacePlan({ [key]: value });
    }
    const initialPurposeOfRacing =
      formData?.data?.purposeOfRacing?.radioOptions.find(
        (item) => item.defaultChecked
      );
    if (initialPurposeOfRacing) {
      const key = initialPurposeOfRacing['id'];
      const value = initialPurposeOfRacing['value'];
      setPurposeOfRacing({ [key]: value });
    }
    setSkyDivingPSAAffiliatedTextArea(
      formData?.data?.skyDivingPSAAffiliatedTextArea?.value
    );
    setRacingOtherValue(formData?.data?.racingOtherValue?.value);
    setUnderwaterDivingLocationsOtherValue(
      formData?.data?.underwaterDivingLocationsOtherValue?.value
    );

    const initialUWDT = formData?.data?.UWDTRadioOptions?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (initialUWDT) {
      const key = initialUWDT['id'];
      const value = initialUWDT['value'];
      setUWDTRadioOptions({ [key]: value });
    }
    setUWDTDetail(formData?.data?.UWDTDetail?.value);
    const initialUAD = formData?.data?.UADRadioOptions?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (initialUAD) {
      const key = initialUAD['id'];
      const value = initialUAD['value'];
      setUADRadioOptions({ [key]: value });
    }
    setUADDetail(formData?.data?.UADDetail?.value);

    const initialParachuteSports =
      formData?.data?.BallooningParachuteSportsReserve?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialParachuteSports) {
      const key = initialParachuteSports['id'];
      const value = initialParachuteSports['value'];
      setParachuteSportsReserve({ [key]: value });
    }
    const initialHeliSkiingPSAffiliationRadio =
      formData?.data?.heliSkiingPSAffiliationRadio?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialHeliSkiingPSAffiliationRadio) {
      const key = initialHeliSkiingPSAffiliationRadio['id'];
      const value = initialHeliSkiingPSAffiliationRadio['value'];
      setHeliSkiingPSAffiliationRadio({ [key]: value });
    }
    const initialSkyDivingParachuteSportsReserve =
      formData?.data?.heliSkiingPSAffiliationRadio?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialSkyDivingParachuteSportsReserve) {
      const key = initialSkyDivingParachuteSportsReserve['id'];
      const value = initialSkyDivingParachuteSportsReserve['value'];
      setSkyDivingParachuteSportsReserve({ [key]: value });
    }
    const initialSkyDivingPSAffiliation =
      formData?.data?.skyDivingPSAffiliationRadio?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialSkyDivingPSAffiliation) {
      const key = initialSkyDivingPSAffiliation['id'];
      const value = initialSkyDivingPSAffiliation['value'];
      setSkyDivingPSAffiliationRadio({ [key]: value });
    }

    const initialPSAffiliationRadio =
      formData?.data?.ballooningPSAffiliationRadio?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialPSAffiliationRadio) {
      const key = initialPSAffiliationRadio['id'];
      const value = initialPSAffiliationRadio['value'];
      setPSAffiliationRadio({ [key]: value });
    }

    const initialBallooningStatus =
      formData?.data?.ballooningFieldStatus?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialBallooningStatus) {
      const key = initialBallooningStatus['id'];
      const value = initialBallooningStatus['value'];
      setBallooningFieldStatus({ [key]: value });
    }
    const initialHeliSkiingParachute =
      formData?.data?.heliSkiingParachuteSportsReserve?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialHeliSkiingParachute) {
      const key = initialHeliSkiingParachute['id'];
      const value = initialHeliSkiingParachute['value'];
      setHeliSkiingParachuteSportsReserve({ [key]: value });
    }

    const initialSkyDivingStatus =
      formData?.data?.skyDivingStatus?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialSkyDivingStatus) {
      const key = initialSkyDivingStatus['id'];
      const value = initialSkyDivingStatus['value'];
      setSkyDivingStatus({ [key]: value });
    }
    const initialHeliSkiingStatus =
      formData?.data?.heliSkiingStatus?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialHeliSkiingStatus) {
      const key = initialHeliSkiingStatus['id'];
      const value = initialHeliSkiingStatus['value'];
      setHeliSkiingStatus({ [key]: value });
    }
  }, [formData?.data]);
  useEffect(() => {
    if (aerialSportsCheckBoxes.includes('flying')) {
      dispatch(pageNavigationAction({ f1v1s18: true }));
    } else {
      dispatch(pageNavigationAction({ f1v1s18: false }));
    }
  }, [aerialSportsCheckBoxes]);
  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);
  const sidenavData = useSelector((state) => state.pageNav);
  const payload = {
    aerialSportsCheckBoxes,
    aerialSportsOtherValue: { 'as-otherValue': aerialSportsOtherValue },
    ballooningFieldStatus,
    ballooningFieldOptions,
    skyDivingField,
    skyDivingStatus,
    ballooningSkyDivingFieldMinimumHeight,
    ballooningPSAffiliationRadio,
    ballooningLocationPerformedFields,
    BallooningParachuteSportsReserve,
    ballooningPSAAffiliatedTextArea,
    heliSkiingSkyDivingFieldMinimumHeight,
    heliSkiingParachuteSportsReserve,
    heliSkiingField,
    heliSkiingStatus,
    autoCheckBoxes,
    heliSkiingPSAAffiliatedTextArea,
    heliSkiingPSAffiliationRadio,
    heliSkiingLocationPerformedFields,
    SkyDivingFieldMinimumHeight,
    skyDivingParachuteSportsReserve,
    skyDivingPSAffiliationRadio,
    skyDivingPSAAffiliatedTextArea,
    skyDivingLocationPerformedFields,
    autoOtherValue: { 'auto-otherValue': autoOtherValue },
    racingFieldOptions,
    racePlanTextArea,
    racePlan,
    purposeOfRacing,
    racingCheckBoxes,
    racingOtherValue: { 'rac-otherValue': racingOtherValue },
    mountaineeringCheckBoxes,
    mountaineeringOtherValue: { 'mo-otherValue': mountaineeringOtherValue },
    underwaterDivingCheckBoxes,
    underwaterDivingOtherValue: { 'ud-otherValue': underwaterDivingOtherValue },
    underwaterDivingLocations,
    underwaterDivingLocationsOtherValue: {
      'udl-otherValue': underwaterDivingLocationsOtherValue,
    },
    UWDTRadioOptions,
    UWDTDetail: { underwaterDivingTrainingDetail: UWDTDetail },
    UADRadioOptions,
    UADDetail: { underwaterAloneDivingTrainingDetail: UADDetail },
    underwaterDepthTable,
    othersCheckBoxes,
    othersOtherValue: { 'oth-otherValue': othersOtherValue },
  };
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s16`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  const onCloseToastMessage = () => {
    showToastMessage(false);
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s16',
      },
    });
  }, [payload]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CommonFormHeader
        backPath="/form/foreigntravelinformation"
        nextPath={nextPath}
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s16"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      {sidenavData.loading && <Loader />}
      {loading ? (
        <Loader />
      ) : (
        <>
          {formData?.data && (
            <FormFieldsWrapper>
              <PlanQuestion
                aerialSportsCheckBoxes={aerialSportsCheckBoxes}
                autoCheckBoxes={autoCheckBoxes}
                mountaineeringCheckBoxes={mountaineeringCheckBoxes}
                underwaterDivingCheckBoxes={underwaterDivingCheckBoxes}
                othersCheckBoxes={othersCheckBoxes}
              />
              <div className="line-separation "></div>
              <AerialSports
                aerialSportsCheckBoxes={aerialSportsCheckBoxes}
                setAerialSportsCheckBoxes={setAerialSportsCheckBoxes}
                setAerialSportsOtherValue={setAerialSportsOtherValue}
                setSkyDivingField={setSkyDivingField}
                setSkyDivingFieldMinimumHeight={setSkyDivingFieldMinimumHeight}
                setParachuteSportsReserve={setParachuteSportsReserve}
                ballooningPSAffiliationRadio={ballooningPSAffiliationRadio}
                setLocationPerformedFields={setLocationPerformedFields}
                setPSAffiliationRadio={setPSAffiliationRadio}
                setPSAAffiliatedTextArea={setPSAAffiliatedTextArea}
                setHeliSkiingPSAAffiliatedTextArea={
                  setHeliSkiingPSAAffiliatedTextArea
                }
                setSkyDivingFieldMinimumHeightField={
                  setSkyDivingFieldMinimumHeightField
                }
                setSkyDivingParachuteSportsReserve={
                  setSkyDivingParachuteSportsReserve
                }
                setSkyDivingPSAffiliationRadio={setSkyDivingPSAffiliationRadio}
                setSkyDivingPSAAffiliatedTextArea={
                  setSkyDivingPSAAffiliatedTextArea
                }
                setSkyDivingLocationPerformedFields={
                  setSkyDivingLocationPerformedFields
                }
                skyDivingPSAffiliationRadio={skyDivingPSAffiliationRadio}
                setHeliSkiingField={setHeliSkiingField}
                setBallooningFieldStatus={setBallooningFieldStatus}
                setBallooningFieldOptions={setBallooningFieldOptions}
                setSkyDivingStatus={setSkyDivingStatus}
                setHeliSkiingStatus={setHeliSkiingStatus}
                setHeliSkiingSkyDivingFieldMinimumHeight={
                  setHeliSkiingSkyDivingFieldMinimumHeight
                }
                setHeliSkiingParachuteSportsReserve={
                  setHeliSkiingParachuteSportsReserve
                }
                setHeliSkiingPSAffiliationRadio={
                  setHeliSkiingPSAffiliationRadio
                }
                heliSkiingPSAffiliationRadio={heliSkiingPSAffiliationRadio}
                setHeliSkiingLocationPerformedFields={
                  setHeliSkiingLocationPerformedFields
                }
              />
              <div className="line-separation "></div>
              <AutoSection
                autoCheckBoxes={autoCheckBoxes}
                setAutoCheckBoxes={setAutoCheckBoxes}
                setAutoOtherValue={setAutoOtherValue}
                setRacingFieldOptions={setRacingFieldOptions}
                setRacePlan={setRacePlan}
                racePlan={racePlan}
                setRacePlanTextArea={setRacePlanTextArea}
                setPurposeOfRacing={setPurposeOfRacing}
                setRacingCheckBoxes={setRacingCheckBoxes}
                racingCheckBoxes={racingCheckBoxes}
                setRacingOtherValue={setRacingOtherValue}
              />
              <div className="line-separation "></div>
              <MountaineeringSection
                mountaineeringCheckBoxes={mountaineeringCheckBoxes}
                setMountaineeringCheckBoxes={setMountaineeringCheckBoxes}
                setMountaineeringOtherValue={setMountaineeringOtherValue}
              />
              <div className="line-separation "></div>
              <UnderwaterSection
                underwaterDivingCheckBoxes={underwaterDivingCheckBoxes}
                setUnderwaterDivingCheckBoxes={setUnderwaterDivingCheckBoxes}
                setUnderwaterDivingOtherValue={setUnderwaterDivingOtherValue}
                underwaterDivingLocations={underwaterDivingLocations}
                setUnderwaterDivingLocations={setUnderwaterDivingLocations}
                setUnderwaterDivingLocationsOtherValue={
                  setUnderwaterDivingLocationsOtherValue
                }
                setUWDTRadioOptions={setUWDTRadioOptions}
                UWDTRadioOptions={UWDTRadioOptions}
                setUWDTDetail={setUWDTDetail}
                UADRadioOptions={UADRadioOptions}
                setUADRadioOptions={setUADRadioOptions}
                setUADDetail={setUADDetail}
                setUnderwaterDepthTable={setUnderwaterDepthTable}
              />
              <div className="line-separation "></div>
              <OthersSection
                othersCheckBoxes={othersCheckBoxes}
                setOthersCheckBoxes={setOthersCheckBoxes}
                setOthersOtherValue={setOthersOtherValue}
              />
            </FormFieldsWrapper>
          )}
        </>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default AvocationQuestionnaire;
