// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.existingPolicie-radio-container {
  display: flex;
  align-items: center;
  height: 44px;
}
.existingPolicie-radio-label {
  font-size: 13px;
  font-weight: 600;
  margin: 0px 10px 0px 5px;
}
.existing-radio {
  display: flex;
  align-items: center;
}
.existing-radio input[type="radio"]:checked ~ label {
  color: #0177b5;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/existingInsurance/existingInsurance.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB","sourcesContent":[".existingPolicie-radio-container {\n  display: flex;\n  align-items: center;\n  height: 44px;\n}\n.existingPolicie-radio-label {\n  font-size: 13px;\n  font-weight: 600;\n  margin: 0px 10px 0px 5px;\n}\n.existing-radio {\n  display: flex;\n  align-items: center;\n}\n.existing-radio input[type=\"radio\"]:checked ~ label {\n  color: #0177b5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
