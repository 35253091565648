import {
  POLICY_ONWER_DATA_REQUEST,
  POLICY_ONWER_DATA_SUCCESS,
  POLICY_ONWER_DATA_FAIL,
} from '../constants/policyOwnerConstants';

export const policyOwnerReducer = (state = { poData: [] }, action) => {
  switch (action.type) {
    case POLICY_ONWER_DATA_REQUEST:
      return { loading: true, poData: [] };
    case POLICY_ONWER_DATA_SUCCESS:
      return { loading: false, poData: action.payload };
    case POLICY_ONWER_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
