import { useState } from 'react';
import { IoCloseSharp, IoSearchSharp } from 'react-icons/io5';
const ApplicationFilloutFiltersSearch = ({
  heading,
  searchedString,
  setSearchedString,
}) => {
  const [showSearch, setShowSearch] = useState(false);
  return (
    <div className="applicationFillout--headingSearchWrapper">
      <div className="applicationFillout--headingSearch">
        <h3>{heading}</h3>
        <IoSearchSharp
          className="applicationFillout--iconSearch"
          size={20}
          onClick={() => {
            setShowSearch(!showSearch);
            setSearchedString('');
          }}
        />
      </div>

      {showSearch && (
        <div className="applicationFillout--filterSearchWrapper">
          <input
            className="applicationFillout--filterSearchBar"
            type="text"
            value={searchedString}
            onChange={(e) => {
              setSearchedString(e.target.value);
            }}
          />
          <IoCloseSharp
            className="applicationFillout--iconSearchClose"
            size={20}
            onClick={() => {
              setShowSearch(!showSearch);
              setSearchedString('');
            }}
          />
        </div>
      )}
    </div>
  );
};
export default ApplicationFilloutFiltersSearch;
