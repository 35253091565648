import axios from '../axios/axios';
import {
  VERIFY_MAPPING_FIELDS_DATA_REQUEST,
  VERIFY_MAPPING_FIELDS_DATA_SUCCESS,
  VERIFY_MAPPING_FIELDS_DATA_FAIL,
} from '../constants/verifymappingConstants';

export const verifyMappingAction = (uploadedId) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_MAPPING_FIELDS_DATA_REQUEST });
    const { data } = await axios.post('/list-mapping', {
      upload_id: parseInt(uploadedId),
    });
    dispatch({
      type: VERIFY_MAPPING_FIELDS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: VERIFY_MAPPING_FIELDS_DATA_FAIL,
      payload: error,
    });
  }
};
