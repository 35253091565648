import './FormField.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  SET_FORM_FIELD_ERROR,
  REMOVE_FORM_FIELD_ERROR,
} from '../../../constants/formValidationConstants';
export const EMAIL_REGEX = /^\S+@\S+$/;

const InputEmail = (props) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeHandler = (e) => {
    props.fieldData.onChangeHandler(e);

    if (e.target.value) {
      const regexMatch = EMAIL_REGEX.test(e.target.value);
      if (regexMatch) {
        setErrorMessage('');
        dispatch({
          type: REMOVE_FORM_FIELD_ERROR,
          payload: props.fieldData.id,
        });
      } else {
        setErrorMessage('Please enter a valid email address.');
        dispatch({
          type: SET_FORM_FIELD_ERROR,
          payload: props.fieldData.id,
        });
      }
    } else {
      setErrorMessage('');
      dispatch({
        type: REMOVE_FORM_FIELD_ERROR,
        payload: props.fieldData.id,
      });
    }
  };
  const emailFieldDefaultValue = (
    <input
      type={props.fieldData.type}
      id={props.fieldData.id}
      name={props.fieldData.name}
      defaultValue={props.fieldData.value}
      placeholder={props.fieldData.placeholder || 'Enter'}
      onChange={onChangeHandler}
      className={errorMessage.trim().length > 0 ? 'error-border' : ''}
    />
  );
  const emailFieldAddMore = (
    <input
      type={props.fieldData.type}
      id={props.fieldData.id}
      name={props.fieldData.name}
      value={props.fieldData.value}
      placeholder={props.fieldData.placeholder || 'Enter'}
      onChange={onChangeHandler}
      className={errorMessage.trim().length > 0 ? 'error-border' : ''}
    />
  );
  return (
    <div
      className={`label-value-wrapper  ${
        props.fieldData.layoutType ? props.fieldData.layoutType : ''
      } ${!props.fieldData.value ? 'value--empty' : ''}`}
      key={props.fieldData.id}
    >
      {props.fieldData.label && (
        <label className="field-label" htmlFor={props.fieldData.id}>
          {props.fieldData.label}
        </label>
      )}
      <div>
        {props.fieldData.addMoreField
          ? emailFieldAddMore
          : emailFieldDefaultValue}
      </div>
      {errorMessage.trim().length > 0 && (
        <div className="errorMessage">{errorMessage}</div>
      )}
    </div>
  );
};
export default InputEmail;
InputEmail.propTypes = {
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
