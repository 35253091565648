import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { businessInsuranceAction } from '../../../actions/businessInsuranceAction';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import './businessInsurance.css';
import BusinessType from './businessType';
import BusinessInfoAndCoverage from './businessInfoAndCoverage';
import FinancialFigureAndInfo from './financialFigureAndInfo';
import FairMarketValue from './fairMarketValue';
import BusinessOwners from './businessOwners';
import BusinessFinance from './businessFinance';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import axios from '../../../axios/axios';
import ToastMessage from '../../ui/toastMessage';
import Loader from '../../loader';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
const BusinessInsurance = (props) => {
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const pageData = useSelector((state) => state.businessInsuranceData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(businessInsuranceAction());
  }, [dispatch]);
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };

  const [businessType, setBusinessType] = useState({});
  const [businessInfo, setBusinessInfo] = useState({});
  const [pocChecked, setPocChecked] = useState([]);
  const [loanFields, setLoanFields] = useState({});
  const [collaterallyRadio, setCollaterallyRadio] = useState({});
  const [otherCoverageValue, setOtherCoverageValue] = useState({});
  const [financialInfoCheck, setFinancialInfoCheck] = useState([]);
  const [accFields, setAccFields] = useState({});
  const [compFinanFields, setCompFinanFields] = useState({});
  const [sourceFinanFields, setSourceFinanFields] = useState({});
  const [fairMarketValue, setFairMarketValue] = useState({});
  const [fairMarketCheck, setFairMarketCheck] = useState([]);
  const [capatilizedEarningValue, setCapatilizedEarningValue] = useState({});
  const [fairMarketOtherValue, setFairMarketOtherValue] = useState({});
  const [businessOwnerDetails, setBusinessOwnerDetails] = useState([]);
  useEffect(() => {
    if (formData?.data?.businessOwnerFields !== undefined) {
      setBusinessOwnerDetails(formData?.data?.businessOwnerFields);
    }
  }, [formData?.data]);

  const [financialFigure, setFinancialFigure] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    businessType: businessType,
    businessInfo: businessInfo,
    purposeOfCoverage: pocChecked,
    collaterallyAssigned: collaterallyRadio,
    loanCoverageFields: loanFields,
    otherCoverageInfo: otherCoverageValue,
    businessOwnerFields: businessOwnerDetails,
    businessFinanceInfo: financialFigure,
    sourceFinancialInfo: financialInfoCheck,
    accountantFields: accFields,
    companyFinancialFields: compFinanFields,
    sourceFinancialInfoOther: sourceFinanFields,
    fairMarketValue: fairMarketValue,
    fairMarketValueOptions: fairMarketCheck,
    capitalizedEarningsFields: capatilizedEarningValue,
    fairMarketOtherFields: fairMarketOtherValue,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s22',
      },
    });
  }, [payload]);

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s22`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };

  return (
    <>
      <CommonFormHeader
        backPath="/form/financialinformation"
        nextPath="/form/longtermcare"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s22"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {formData?.data && (
            <>
              <div className="businessInsurance">
                <BusinessType setBusinessType={setBusinessType} />
                <BusinessInfoAndCoverage
                  setBusinessInfo={setBusinessInfo}
                  pocChecked={pocChecked}
                  setPocChecked={setPocChecked}
                  setLoanFields={setLoanFields}
                  setCollaterallyRadio={setCollaterallyRadio}
                  setOtherCoverageValue={setOtherCoverageValue}
                />
                <BusinessOwners
                  businessOwnerDetails={businessOwnerDetails}
                  setBusinessOwnerDetails={setBusinessOwnerDetails}
                />
                <BusinessFinance setFinancialFigure={setFinancialFigure} />
                <FinancialFigureAndInfo
                  financialInfoCheck={financialInfoCheck}
                  setFinancialInfoCheck={setFinancialInfoCheck}
                  setAccFields={setAccFields}
                  setCompFinanFields={setCompFinanFields}
                  setSourceFinanFields={setSourceFinanFields}
                />
                <FairMarketValue
                  setFairMarketValue={setFairMarketValue}
                  fairMarketCheck={fairMarketCheck}
                  setFairMarketCheck={setFairMarketCheck}
                  setCapatilizedEarningValue={setCapatilizedEarningValue}
                  setFairMarketOtherValue={setFairMarketOtherValue}
                />
              </div>
            </>
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default BusinessInsurance;
