import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
import PhoneDetailsForm from '../../../ui/phoneDetailsForm';

const CompanySection = (props) => {
  const fieldData = useSelector((state) => state.policyOwnerData);
  const { loading, error, poData } = fieldData;
  const onCompanyNameFieldChange = (event) => {
    props.setCompanyNameSection((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCompanyAuthorizedFieldChange = (event) => {
    props.setCompanyAuthorizedFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCompanyAddressFieldChange = (event) => {
    props.setCompanyAddressFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCompanyContactDetailsFieldsChange = (event) => {
    props.setCompanyContactDetailsFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      {poData?.data && (
        <>
          <div className="flex">
            {poData?.data?.companyNameSection?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onCompanyNameFieldChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  dropdownType={item?.dropdownType}
                  validationType={item?.validationType}
                />
              );
            })}
          </div>
          <div className="line-separation layout-1"></div>

          <h3 id={poData?.data?.companyAuthorizedLabel?.id}>
            {poData?.data?.companyAuthorizedLabel?.label}
          </h3>
          <div className="flex">
            {poData?.data?.companyAuthorizedFields?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onCompanyAuthorizedFieldChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  dropdownType={item.dropdownType}
                  validationType={item?.validationType}
                />
              );
            })}
          </div>
          <div className="line-separation layout-1"></div>

          <div className="flex">
            {poData?.data?.companyAddressFields?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onCompanyAddressFieldChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  dropdownType={item.dropdownType}
                />
              );
            })}
          </div>
          <div className="line-separation layout-1"></div>

          <h3 id={poData?.data?.companyContactDetailsLabel?.id}>
            {poData?.data?.companyContactDetailsLabel?.label}
          </h3>
          <div className="flex">
            {poData?.data?.companyContactDetailsFields?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onCompanyContactDetailsFieldsChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  dropdownType={item.dropdownType}
                />
              );
            })}
          </div>
          <PhoneDetailsForm
            setAddPhoneList={props.setCompanyPhoneFields}
            addPhoneList={props.companyPhoneFields}
            hideDelete
          />
        </>
      )}
    </>
  );
};

export default CompanySection;
