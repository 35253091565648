import axios from '../axios/axios';
import {
  APPLICATION_FILLOUT_DATA_REQUEST,
  APPLICATION_FILLOUT_DATA_SUCCESS,
  APPLICATION_FILLOUT_DATA_FAIL,
} from '../constants/applicationFilloutConstants';

export const applicationFilloutAction = () => async (dispatch) => {
  try {
    dispatch({ type: APPLICATION_FILLOUT_DATA_REQUEST });
    const { data } = await axios.post('/generate-pdf-filter', {
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: APPLICATION_FILLOUT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: APPLICATION_FILLOUT_DATA_FAIL,
      payload: error,
    });
  }
};
