import axios from 'axios';
import {
  AVIATION_QUESTIONS_DATA_REQUEST,
  AVIATION_QUESTIONS_DATA_SUCCESS,
  AVIATION_QUESTIONS_DATA_FAIL,
} from '../constants/aviationConstants';

export const aviationAction = () => async (dispatch) => {
  try {
    dispatch({ type: AVIATION_QUESTIONS_DATA_REQUEST });
    const { data } = await axios.post('/rpiq-form-fetch', {
      section: 'f1v1s18',
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: AVIATION_QUESTIONS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AVIATION_QUESTIONS_DATA_FAIL,
      payload: error,
    });
  }
};
