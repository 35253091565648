import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ppaApplicationAction } from '../../actions/ppaApplicationAction';
import { purposeOfCoverageAction } from '../../actions/purposeOfCoverageAction';
import { pageNavigationAction } from '../../actions/pageNavigationAction';
import Footer from '../footer';
import Header from '../header';
import NotesSection from '../notesSection';
import CaseDataSection from '../caseDataSection';
import SideNav from '../sideNav';
const FormUi = (props) => {
  const [showEmptyFields, setShowEmptyFields] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const productType = localStorage.getItem('productType');
    if (productType === 'annuity') {
      dispatch(pageNavigationAction());
      dispatch(ppaApplicationAction());
    } else {
      dispatch(pageNavigationAction());
      dispatch(purposeOfCoverageAction());
    }
  }, [dispatch]);
  return (
    <>
      <Header />
      <div id="contents">
        <div
          className={`page-navigation ${
            showEmptyFields ? 'highlightUnfilledSection' : ''
          }`}
        >
          <SideNav setShowEmptyFields={setShowEmptyFields} />
        </div>
        <div
          className={`page-contents ${
            showEmptyFields ? 'highlightUnfilledFields' : ''
          }`}
        >
          <Outlet />
        </div>
        <div className="notes-section-wrapper stickySection">
          <CaseDataSection />
          <NotesSection />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FormUi;
