import { Link } from 'react-router-dom';
import BreadCrumbImage from './../../../assets/images/breadcrumb-home.svg';
import './BreadCrumb.css';
const BreadCrumb = (props) => {
  return (
    <>
      <div className="breadCrumb__wrapper">
        <Link to={'/caseListing'}>
          <img src={BreadCrumbImage} alt="img" className="breadCrumb__img" />
        </Link>
        <Link to={'/caseListing'}>
          <span className="breadCrumb__link">Case Listing</span>
        </Link>
        {props.showCaseDetails && (
          <>
            <span className="breadCrumb__arrow"></span>

            {props.pageTitle ? (
              <Link
                to={
                  props?.productType === 'annuity'
                    ? '/form/ppaapplication'
                    : '/form/products&coverage'
                }
                className="arrowIcon"
              >
                <span className="breadCrumb__link">Case Details</span>
              </Link>
            ) : (
              <span className="breadCrumb__link--static">Case Details</span>
            )}
          </>
        )}
        {props.pageTitle && (
          <>
            <span className="breadCrumb__arrow"></span>
            <span className="breadCrumb__pageTitle">{props.pageTitle}</span>
          </>
        )}
      </div>
    </>
  );
};
export default BreadCrumb;
