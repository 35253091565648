import Checkboxes from '../../../ui/formField/Checkboxes';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const MountaineeringSection = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  const mountaineeringOther = formData?.data?.mountaineeringOtherValue;
  const [mountaineeringOtherValue, setMountaineeringOtherValue] = useState(
    formData?.data?.mountaineeringOtherValue?.value
  );
  const handleCheckBox = (event) => {
    let updatedList = [...props.mountaineeringCheckBoxes];
    if (event.target.checked) {
      updatedList = [...props.mountaineeringCheckBoxes, event.target.value];
    } else {
      updatedList.splice(
        props.mountaineeringCheckBoxes.indexOf(event.target.value),
        1
      );
    }
    props.setMountaineeringCheckBoxes(updatedList);
  };
  return (
    <>
      <h3>Mountaineering</h3>
      <p
        className="plan-question-label"
        id={formData?.data?.mountaineeringLabel?.id}
      >
        {formData?.data?.mountaineeringLabel?.label}
      </p>
      {formData?.data &&
        formData?.data?.mountaineeringCheckBoxes?.option?.map(
          (option, index) => {
            return (
              <Checkboxes
                key={index}
                name={option.name}
                value={option.value}
                type={option.type}
                label={option.label}
                defaultChecked={option.defaultChecked}
                id={option.id}
                onChangeHandler={handleCheckBox}
                disabled={option.disable}
              />
            );
          }
        )}
      {props.mountaineeringCheckBoxes.includes('other') && (
        <div className="flex">
          <div
            className={`label-value-wrapper layout-2 ${
              !mountaineeringOtherValue ? 'value--empty' : ''
            }`}
          >
            <label className="field-label" htmlFor={mountaineeringOther?.id}>
              {mountaineeringOther?.label}
            </label>
            <div>
              <input
                type={mountaineeringOther?.type}
                id={mountaineeringOther?.id}
                name={mountaineeringOther?.name}
                placeholder={mountaineeringOther?.placeholder}
                onChange={(e) => {
                  setMountaineeringOtherValue(e.target.value);
                  props.setMountaineeringOtherValue(e.target.value);
                }}
                defaultValue={mountaineeringOther?.value}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MountaineeringSection;
