import { useSelector } from 'react-redux';
import FormField from '../../../../ui/formField';

const PrimaryLLCSection = (props) => {
  const fieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = fieldData;

  const onllcJurisdictionChangeHandler = (event) => {
    props.setPBLLCJurisdicationData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onFieldChangeHandler = (event) => {
    if (event.target.id === 'llcPercentage') {
      props.setPBLLCPercentage(event.target.value ? event.target.value : 0);
    }
    props.setPBLLCFieldsData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      {bnData?.data && (
        <>
          {bnData?.data?.pbLLCJurisdiction?.map((item, index) => {
            return (
              <FormField
                key={index}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                dropdownType={item.dropdownType}
                layoutType={item.layoutType}
                onChangeHandler={onllcJurisdictionChangeHandler}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
              />
            );
          })}
          <div className="flex ">
            {bnData?.data?.pbLLCFields?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  dropdownType={item.dropdownType}
                  layoutType={item.layoutType}
                  onChangeHandler={onFieldChangeHandler}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default PrimaryLLCSection;
