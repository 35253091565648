import Checkboxes from '../../../ui/formField/Checkboxes';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const OthersSection = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  const Otherfields = formData?.data?.othersOtherValue;
  const [otherfieldValue, setOtherFieldValue] = useState(
    formData?.data?.othersOtherValue?.valiie
  );
  const handleCheckBox = (event) => {
    let updatedList = [...props.othersCheckBoxes];
    if (event.target.checked) {
      updatedList = [...props.othersCheckBoxes, event.target.value];
    } else {
      updatedList.splice(props.othersCheckBoxes.indexOf(event.target.value), 1);
    }
    props.setOthersCheckBoxes(updatedList);
  };
  return (
    <>
      <h3>Others</h3>
      <p className="plan-question-label" id={formData?.data?.othersLabel?.id}>
        {formData?.data?.othersLabel?.label}
      </p>
      {formData?.data &&
        formData?.data?.othersCheckBoxes?.option?.map((option, index) => {
          return (
            <Checkboxes
              key={index}
              name={option.name}
              value={option.value}
              type={option.type}
              label={option.label}
              defaultChecked={option.defaultChecked}
              id={option.id}
              onChangeHandler={handleCheckBox}
              disabled={option.disable}
            />
          );
        })}
      {props.othersCheckBoxes.includes('other') && (
        <div className="flex">
          <div
            className={`label-value-wrapper layout-2 ${
              !otherfieldValue ? 'value--empty' : ''
            }`}
          >
            <label className="field-label" htmlFor={Otherfields?.id}>
              {Otherfields?.label}
            </label>
            <div>
              <input
                type={Otherfields?.type}
                id={Otherfields?.id}
                name={Otherfields?.name}
                placeholder={Otherfields?.placeholder}
                onChange={(e) => {
                  setOtherFieldValue(e.target.value);
                  props.setOthersOtherValue(e.target.value);
                }}
                defaultValue={Otherfields?.value}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OthersSection;
