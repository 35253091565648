import { useEffect, useState } from 'react';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import FormField from '../../ui/formField';
import NameField from './NameField';
import LegalName from './LegalName';
import BasicInfoSection from './BasicInfoSection';
import USCitizenSection from './USCitizenSection';
import EmailDetailsForm from '../../ui/emailDetailsForm';
import PhoneDetailsForm from '../../ui/phoneDetailsForm';
import MailingAddressSection from './MailingAddressSection';
import './ProposedInsured.css';
import axios from '../../../axios/axios';
import { proposedInsuredAction } from '../../../actions/proposedInsuredAction';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../loader';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import CommonLabelRadioButtons from '../../ui/commonLabelRadioButton';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import AddressDetailsForm from '../../ui/addressDetailsForm';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import HowLongLiving from './howLongLiving';

const ProposedInsured = (props) => {
  const [nameSection, setNameSection] = useState({});
  const [showLegalNameFields, setshowLegalNameFields] = useState(false);
  const [showLegalNameValue, setShowLegalNameValue] = useState({});
  const [legalSectionData, setLegalSectionData] = useState({});
  const [basicInfoSectionData, setBasicInfoSectionData] = useState({});
  const [showUSCitizenFields, setShowUSCitizenFields] = useState('yes');
  const [showUSCitizenValue, setShowUSCitizenValue] = useState({});
  const [countryofCitizenship, setCountryofCitizenship] = useState('USA');
  const [showUSVisaInfoFields, setShowUSVizaInfoFields] = useState('yes');
  const [usVisaCheck, setUSVisaCheck] = useState({ usVizaYes: 'yes' });
  const [usaVisaDetails, setUsaVisaDetails] = useState({});
  const [greenCardInfoFields, showGreenCardInfoFields] = useState('yes');
  const [greenCardCheck, setGreenCardCheck] = useState({ greenCardNo: 'no' });
  const [greenCardFieldsDetails, setGreenCardFieldsDetails] = useState({});
  const [applyUSCitizen, setApplyUSCitizen] = useState('no');
  const [applyUSCitizenCheck, setApplyUSCitizenCheck] = useState({});
  const [spouseData, setSpouseData] = useState({});
  const [showMailingAddressFields, setShowMailingAddressFields] = useState({});
  const [mailingAddressData, setMailingAddressData] = useState({});
  const [driverLicenseData, setDriverLicenseData] = useState({});
  const [primaryOccupation, setPrimaryOccupation] = useState({});
  const [primaryOccupationValue, setPrimaryOccupationValue] = useState('');
  const [showEmployerDetails, setShowEmployerDetails] = useState('employed');
  const [employerDetailsData, setEmployerDetailsData] = useState({});
  const [selfEmployedData, setSelfEmployedData] = useState({});
  const [longHaveLivedFields, setLongHaveLivedFields] = useState({});
  const [addPhoneList, setAddPhoneList] = useState([]);
  const [addAddressList, setAddAddressList] = useState([]);
  const [addEmailList, setAddEmailList] = useState([]);
  const dispatch = useDispatch();
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [greenCardDate, setGreenCardDate] = useState('');
  const [usVisaDate, setUsVisaDateField] = useState('');
  const [employmentDetail, setEmploymentDetail] = useState({});
  const [employmentDetailValue, setEmploymentDetailValue] = useState('');
  const [driverLicenseFields, setDriverLicenseFields] = useState({});
  const [permanentResidencyCardData, setPermanentResidencyCardData] = useState(
    {}
  );
  const [passportData, setPassportData] = useState({});
  const [governmentIssuedIdData, setGovernmentIssuedIdData] = useState({});
  const [typeOfId, setTypeOfId] = useState({});
  const [licenseState, setLicenseState] = useState({});
  const [mailingPreferredRadio, setMailingPreferredRadio] = useState(false);

  const piFormData = useSelector((state) => state.piData);
  const { loading, error, formData } = piFormData;

  useEffect(() => {
    if (formData?.data?.addMorePhoneFields !== undefined) {
      setAddPhoneList(formData?.data?.addMorePhoneFields);
    }
    if (formData?.data?.addressFields !== undefined) {
      setAddAddressList(formData?.data?.addressFields);
    }
    if (formData?.data?.addMoreEmailFields) {
      setAddEmailList(formData?.data?.addMoreEmailFields);
    }
    setMailingPreferredRadio(
      formData?.data?.mailingPreferredRadio?.radioOptions?.defaultChecked
    );
    setshowLegalNameFields(
      formData?.data?.legalNameLabel?.radioOptions[0]?.defaultChecked
    );

    if (formData?.data?.citizenshipCountry?.value) {
      setCountryofCitizenship(formData?.data?.citizenshipCountry?.value);
    }
    setShowUSCitizenFields(
      formData?.data?.usCitizenLabel?.radioOptions?.find(
        (item) => item.defaultChecked
      )?.value
    );
    const initialUS = formData?.data?.usCitizenLabel?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (initialUS) {
      const key = initialUS['id'];
      const value = initialUS['value'];
      setShowUSCitizenValue({ [key]: value });
    }

    const initialUSVisa = formData?.data?.usVizaLabel?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (initialUSVisa) {
      const key = initialUSVisa['id'];
      const value = initialUSVisa['value'];
      setUSVisaCheck({ [key]: value });
    }
    const initialGreenCard = formData?.data?.greenCardLabel?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (initialGreenCard) {
      const key = initialGreenCard['id'];
      const value = initialGreenCard['value'];
      setGreenCardCheck({ [key]: value });
    }
    const initialUsaCitizen =
      formData?.data?.applyUSCitizenshipLabel?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialUsaCitizen) {
      const key = initialUsaCitizen['id'];
      const value = initialUsaCitizen['value'];
      setApplyUSCitizenCheck({ [key]: value });
    }
    const initialLegal = formData?.data?.legalNameLabel?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (initialLegal) {
      const key = initialLegal['id'];
      const value = initialLegal['value'];
      setShowLegalNameValue({ [key]: value });
    }
    const initialEmployeType =
      formData?.data?.employmentSection?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialEmployeType) {
      const key = initialEmployeType['id'];
      const value = initialEmployeType['value'];
      setEmploymentDetail({ [key]: value });
    }
    const initialMailingAddress =
      formData?.data?.mailingAddressLabel?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialMailingAddress) {
      const key = initialMailingAddress['id'];
      const value = initialMailingAddress['value'];
      setShowMailingAddressFields({ [key]: value });
    }
    setTypeOfId({ typeOfId: formData?.data?.typeOfId[0]?.value });
    setPrimaryOccupationValue(formData?.data?.primaryOccupation?.value);
  }, [formData?.data]);
  useEffect(() => {
    dispatch(proposedInsuredAction());
  }, [dispatch]);

  const employerDetails = (e) => {
    setShowEmployerDetails(e.target.value);
  };
  const onSpouseFieldsChangeHandler = (event) => {
    setSpouseData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const driverLicenseChangeHandler = (event) => {
    setDriverLicenseData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const primaryOccupationChangeHandler = (event) => {
    setPrimaryOccupationValue(event.target.value.toLowerCase());

    if (event.target.value.toLowerCase() === 'unemployed') {
      setEmploymentDetailValue(event.target.value.toLowerCase());
      document.getElementById('employmentSection-unemployed').checked = true;
      setEmploymentDetail(() => ({
        'employmentSection-selfEmployed': 'unemployed',
      }));
    }
    setPrimaryOccupation((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onEmployerDetailsChange = (event) => {
    setEmployerDetailsData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onSelfEmployedDetailsChange = (event) => {
    setSelfEmployedData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const dlChangeHandler = (event) => {
    setDriverLicenseFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPRCFieldChangeHandler = (event) => {
    setPermanentResidencyCardData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onPassportFieldChangeHandler = (event) => {
    setPassportData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onGovIdFieldChangeHandler = (event) => {
    setGovernmentIssuedIdData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onTypeOfIdFieldChangeHandler = (event) => {
    setTypeOfId((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onLicenseStateChangeHandler = (event) => {
    setLicenseState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const proposedInsuredSpouseDetailsLabel =
    piFormData?.formData?.data?.spouseDetailsLabel?.label;

  const proposedInsuredSpouseDeatilsFields =
    piFormData?.formData?.data?.spouseDetailsFields?.map((item, index) => {
      return (
        <FormField
          key={item.id}
          id={item.id}
          value={item.value}
          type={item.type}
          layoutType={item.layoutType}
          name={item.name}
          label={item.label}
          regex={item?.regex}
          errorMessage={item?.errorMessage}
          onChangeHandler={onSpouseFieldsChangeHandler}
          validationType={item.validationType}
        />
      );
    });

  const proposedInsuredDriverLicenseLabel =
    formData?.data?.driverLicenseLabel?.label;
  const proposedInsuredDriverLicenseJson = formData?.data?.driverLicenseFields;
  const proposedInsuredDriverLicenseFields =
    formData?.data?.driverLicenseFields?.map((item, index) => {
      return (
        <FormField
          key={item.id}
          id={item.id}
          value={item.value}
          type={item.type}
          layoutType={item.layoutType}
          name={item.name}
          label={item.label}
          dropdownType={item.dropdownType}
          onChangeHandler={driverLicenseChangeHandler}
        />
      );
    });

  const proposedInsuredPrimaryOccupation = formData?.data?.primaryOccupation;
  const proposedInsuredPrimaryOccupationField = (
    <div
      className={`label-value-wrapper ${
        formData?.data?.primaryOccupation?.layoutType
          ? formData?.data?.primaryOccupation?.layoutType
          : ''
      }`}
      key={formData?.data?.primaryOccupation?.id}
    >
      {formData?.data?.primaryOccupation?.label && (
        <label className="field-label">
          {formData?.data?.primaryOccupation?.label}
        </label>
      )}

      <div>
        <select
          key={formData?.data?.primaryOccupation?.id}
          name={formData?.data?.primaryOccupation?.name}
          id={formData?.data?.primaryOccupation?.id}
          defaultValue={formData?.data?.primaryOccupation?.value}
          onChange={primaryOccupationChangeHandler}
          disabled={formData?.data?.primaryOccupation?.disabled}
        >
          {formData?.data?.primaryOccupation?.selectOptions &&
            formData?.data?.primaryOccupation?.selectOptions?.length > 0 &&
            formData?.data?.primaryOccupation?.selectOptions?.map(
              (option, index) => {
                return (
                  <option value={option?.value} key={index}>
                    {option.label}
                  </option>
                );
              }
            )}
        </select>
      </div>
    </div>
  );
  const employmentChangeHandler = (e) => {
    setEmploymentDetailValue(e.target.value.toLowerCase());
    if (e.target.value.toLowerCase() === 'unemployed') {
      const $select = document.querySelector('#primaryOccuation');
      $select.value = 'Unemployed';
      setPrimaryOccupationValue(e.target.value.toLowerCase());
    }
    setEmploymentDetail(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const proposedInsuredEmployment = formData?.data?.employmentSection;
  const proposedInsuredEmploymentFields = (
    <>
      {formData?.data?.employmentSection && (
        <div className="label-value-wrapper layout-2 employment-details">
          <label className="field-label">Employment</label>
          <div className="common-label-radioButtons-wrapper">
            {proposedInsuredEmployment?.radioOptions && (
              <div
                className="common-radio-buttons-wrapper"
                onChange={employmentChangeHandler}
              >
                {proposedInsuredEmployment?.radioOptions?.map((item) => {
                  return (
                    <div className="common-radio-button" key={item.id}>
                      <input
                        type={item.type}
                        value={item.value}
                        name={item.name}
                        id={item.id}
                        className="radio"
                        defaultChecked={item.defaultChecked}
                      />
                      <label htmlFor={item.id} className="common-radio-label">
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
  const proposedInsuredEmployerFields =
    formData?.data?.employmentDetailsFields?.map((item, index) => {
      return (
        <FormField
          key={item.id}
          id={item.id}
          value={item.value}
          type={item.type}
          layoutType={item.layoutType}
          name={item.name}
          label={item.label}
          dropdownType={item.dropdownType}
          onChangeHandler={onEmployerDetailsChange}
          regex={item?.regex}
          errorMessage={item?.errorMessage}
        />
      );
    });

  const selfEmployedFields = formData?.data?.selfEmployedFields?.map(
    (item, index) => {
      return (
        <FormField
          key={item.id}
          id={item.id}
          value={item.value}
          type={item.type}
          layoutType={item.layoutType}
          name={item.name}
          label={item.label}
          dropdownType={item.dropdownType}
          onChangeHandler={onSelfEmployedDetailsChange}
          regex={item?.regex}
          errorMessage={item?.errorMessage}
        />
      );
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    nameSection,
    legalNameLabel: showLegalNameValue,
    legalNameFields: legalSectionData,
    basicInfoSection: basicInfoSectionData,
    usCitizenLabel: showUSCitizenValue,
    citizenshipCountry: countryofCitizenship,
    usVizaLabel: usVisaCheck,

    usVizaFields: usaVisaDetails,
    greenCardLabel: greenCardCheck,
    greenCardFields: greenCardFieldsDetails,
    applyUSCitizenshipLabel: applyUSCitizenCheck,
    longHaveLivedFields: longHaveLivedFields,
    spouseDetailsFields: spouseData,

    mailingAddressLabel: showMailingAddressFields,
    mailingAddressFields: mailingAddressData,
    mailingPreferredRadio,
    driverLicenseFields,
    permanentResidencyCardData,
    passportData,
    governmentIssuedIdData,
    primaryOccupation:
      employmentDetailValue &&
      employmentDetailValue.toLowerCase() === 'unemployed'
        ? { primaryOccupation: 'Unemployed' }
        : primaryOccupation,
    employmentSection:
      primaryOccupationValue &&
      primaryOccupationValue.toLowerCase() === 'unemployed'
        ? { 'employmentSection-unemployed': 'unemployed' }
        : employmentDetail,
    employmentDetailsFields: employerDetailsData,
    selfEmployedFields: selfEmployedData,
    addMorePhoneFields: addPhoneList,
    addressFields: addAddressList,
    addMoreEmailFields: addEmailList,
    licenseState,
    typeOfId: typeOfId,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s3',
      },
    });
  }, [payload]);
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(`/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s3`, payload)
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };

  return (
    <>
      <CommonFormHeader
        backPath="/form/policydetails"
        nextPath="/form/physicianinformation"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        sectionId="f1v1s3"
        isCompleted={formData?.metadata?.isCompleted}
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      <FormFieldsWrapper>
        {loading ? (
          <Loader />
        ) : (
          <div className="proposedInsured-fields">
            {formData?.data && (
              <div className="proposedInsured-nameFields flex line-separation">
                <NameField setNameSection={setNameSection} />
              </div>
            )}
            {formData?.data && (
              <div className="proposedInsured-legalName flex line-separation">
                <LegalName
                  showLegalNameFields={showLegalNameFields}
                  setshowLegalNameFields={setshowLegalNameFields}
                  setLegalSectionData={setLegalSectionData}
                  setShowLegalNameValue={setShowLegalNameValue}
                />
              </div>
            )}
            {formData?.data && (
              <div className="proposedInsured-basicInfo line-separation flex">
                <BasicInfoSection
                  setBasicInfoSectionData={setBasicInfoSectionData}
                />
              </div>
            )}
            {formData?.data && (
              <div className="proposedInsured-USCitizen line-separation flex">
                <USCitizenSection
                  usVisaCheck={usVisaCheck}
                  setShowUSCitizenFields={setShowUSCitizenFields}
                  setShowUSCitizenValue={setShowUSCitizenValue}
                  showUSCitizenFields={showUSCitizenFields}
                  setCountryofCitizenship={setCountryofCitizenship}
                  countryofCitizenship={countryofCitizenship}
                  showUSVisaInfoFields={showUSVisaInfoFields}
                  setShowUSVizaInfoFields={setShowUSVizaInfoFields}
                  setUSVisaCheck={setUSVisaCheck}
                  setUsaVisaDetails={setUsaVisaDetails}
                  showGreenCardInfoFields={showGreenCardInfoFields}
                  setGreenCardCheck={setGreenCardCheck}
                  greenCardCheck={greenCardCheck}
                  greenCardInfoFields={greenCardInfoFields}
                  setGreenCardFieldsDetails={setGreenCardFieldsDetails}
                  setApplyUSCitizen={setApplyUSCitizen}
                  setApplyUSCitizenCheck={setApplyUSCitizenCheck}
                  setGreenCardDate={setGreenCardDate}
                  setUsVisaDateField={setUsVisaDateField}
                />
              </div>
            )}
            <div className="spouse-details line-separation">
              <h3>{proposedInsuredSpouseDetailsLabel}</h3>
              {piFormData?.formData?.data?.spouseDetailsFields?.length > 0 && (
                <div className="flex">{proposedInsuredSpouseDeatilsFields}</div>
              )}
            </div>
            <div className=" line-separation">
              {formData?.data && (
                <div className="flex">
                  <p className=" proposedInsured__emailForm">
                    {formData?.data?.emailDetailsLabel?.label}
                  </p>
                </div>
              )}
              <EmailDetailsForm
                setAddEmailList={setAddEmailList}
                addEmailList={addEmailList}
                hideDelete
              />
            </div>
            <div className="proposedInsured__phoneDetails line-separation">
              <PhoneDetailsForm
                setAddPhoneList={setAddPhoneList}
                addPhoneList={addPhoneList}
                hideDelete
              />
            </div>
            <div className="proposedInsured__residence flex line-separation">
              <AddressDetailsForm
                setAddAddressList={setAddAddressList}
                addAddressList={addAddressList}
                setMailingPreferredRadio={setMailingPreferredRadio}
                checkPreferredMailingRadio
                hideMailingOption
                hideDelete
              />
            </div>
            <div className="line-separation">
              <MailingAddressSection
                addAddressList={addAddressList}
                showMailingAddressFields={showMailingAddressFields}
                setShowMailingAddressFields={setShowMailingAddressFields}
                setMailingAddressData={setMailingAddressData}
                setMailingPreferredRadio={setMailingPreferredRadio}
                mailingPreferredRadio={mailingPreferredRadio}
                setAddAddressList={setAddAddressList}
              />
            </div>
            <HowLongLiving setLongHaveLivedFields={setLongHaveLivedFields} />
            <div className="line-separation"></div>
            <h3>{formData?.data?.driverLicenseLabel?.label}</h3>
            <div className="flex">
              {formData?.data?.typeOfId?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    dropdownType={item.dropdownType}
                    layoutType={item.layoutType}
                    onChangeHandler={onTypeOfIdFieldChangeHandler}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item?.validationType}
                  />
                );
              })}

              {typeOfId.typeOfId === 'driverLicense' && (
                <>
                  {formData?.data?.driverLicenseFields?.map((item, index) => {
                    return (
                      <FormField
                        key={index}
                        label={item.label}
                        id={item.id}
                        value={item.value}
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        min={item.min}
                        max={item.max}
                        dropdownType={item.dropdownType}
                        layoutType={item.layoutType}
                        onChangeHandler={dlChangeHandler}
                        selectOptions={item.selectOptions}
                        regex={item?.regex}
                        errorMessage={item?.errorMessage}
                        validationType={item?.validationType}
                      />
                    );
                  })}
                </>
              )}
              {typeOfId.typeOfId === 'permanentResidencyCard' && (
                <>
                  {formData?.data?.permanentResidencyCardData?.map(
                    (item, index) => {
                      return (
                        <FormField
                          key={index}
                          label={item.label}
                          id={item.id}
                          value={item.value}
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          min={item.min}
                          max={item.max}
                          dropdownType={item.dropdownType}
                          layoutType={item.layoutType}
                          onChangeHandler={onPRCFieldChangeHandler}
                          selectOptions={item.selectOptions}
                          regex={item?.regex}
                          errorMessage={item?.errorMessage}
                          validationType={item?.validationType}
                        />
                      );
                    }
                  )}
                </>
              )}
              {typeOfId.typeOfId === 'passport' && (
                <>
                  {formData?.data?.passportData?.map((item, index) => {
                    return (
                      <FormField
                        key={index}
                        label={item.label}
                        id={item.id}
                        value={item.value}
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        min={item.min}
                        max={item.max}
                        dropdownType={item.dropdownType}
                        layoutType={item.layoutType}
                        onChangeHandler={onPassportFieldChangeHandler}
                        selectOptions={item.selectOptions}
                        regex={item?.regex}
                        errorMessage={item?.errorMessage}
                        validationType={item?.validationType}
                      />
                    );
                  })}
                </>
              )}
              {typeOfId.typeOfId === 'governmentIssuedId' && (
                <>
                  {formData?.data?.governmentIssuedIdData?.map(
                    (item, index) => {
                      return (
                        <FormField
                          key={index}
                          label={item.label}
                          id={item.id}
                          value={item.value}
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          min={item.min}
                          max={item.max}
                          dropdownType={item.dropdownType}
                          layoutType={item.layoutType}
                          onChangeHandler={onGovIdFieldChangeHandler}
                          selectOptions={item.selectOptions}
                          regex={item?.regex}
                          errorMessage={item?.errorMessage}
                          validationType={item?.validationType}
                        />
                      );
                    }
                  )}
                </>
              )}
            </div>
            <div className="flex ">
              {formData?.data?.licenseState?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    dropdownType={item.dropdownType}
                    layoutType={item.layoutType}
                    onChangeHandler={onLicenseStateChangeHandler}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item?.validationType}
                  />
                );
              })}
            </div>
            <div className="flex line-separation"></div>

            {formData?.data && (
              <div className="employment-details">
                <h3>{formData?.data?.occupationLabel?.label}</h3>
                <div className="flex">
                  {formData?.data?.primaryOccupation &&
                    proposedInsuredPrimaryOccupationField}
                  {proposedInsuredEmploymentFields}
                </div>

                {employmentDetail['employmentSection-employed'] ===
                  'employed' && (
                  <h3>{formData?.data?.employmentDetailsLabel.label}</h3>
                )}
                <div className="flex">
                  {employmentDetail['employmentSection-employed'] ===
                    'employed' && proposedInsuredEmployerFields}
                </div>
                {employmentDetail['employmentSection-selfEmployed'] ===
                  'selfEmployed' && (
                  <h3>{formData?.data?.selfEmployedlabel.label}</h3>
                )}
                <div className="flex">
                  {employmentDetail['employmentSection-selfEmployed'] ===
                    'selfEmployed' && selfEmployedFields}
                </div>
              </div>
            )}
          </div>
        )}
      </FormFieldsWrapper>
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default ProposedInsured;
