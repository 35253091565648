import axios from '../../../axios/axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import ToastMessage from '../../ui/toastMessage';
import PlanSection from './planSection';
import TraveledSection from './traveledSection';
import { foreignTravelAction } from '../../../actions/foreignTravelAction';
import Loader from '../../loader';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const ForeignTravel = () => {
  const [nextPath, setNextPath] = useState('');
  const dispatch = useDispatch();
  const foreignTravelFieldData = useSelector((state) => state.ftFieldData);

  const { loading, error, ftData } = foreignTravelFieldData;
  const sideNavData = useSelector((state) => state.pageNav);

  useEffect(() => {
    sideNavData?.formData?.map((item) => {
      if (item.sectionId === 'f1v1s16')
        if (item.show_section) {
          setNextPath('/form/avocationquestionnaire');
        } else {
          setNextPath('/form/existinginsurance');
        }
    });
  }, [sideNavData]);

  useEffect(() => {
    dispatch(foreignTravelAction());
  }, [dispatch]);

  const traveledData = ftData?.data?.traveledQuestion;
  const planData = ftData?.data?.planQuestion;
  const showExtraFieldsCheck = 'yes';

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [traveledFormData, setTraveledFormData] = useState([]);
  const [traveledQuestionValue, setTraveledQuestionValue] = useState({});

  const [planFormData, setPlanFormData] = useState(ftData?.data?.planFields);
  const [planQuestionValue, setPlanQuestionValue] = useState({});

  useEffect(() => {
    if (ftData?.data?.traveledFields !== undefined) {
      setTraveledFormData(ftData?.data?.traveledFields);
    }
    if (ftData?.data?.planFields !== undefined) {
      setPlanFormData(ftData?.data?.planFields);
    }
    const oldTravel = ftData?.data?.traveledQuestion?.options?.find(
      (item) => item.defaultChecked
    );
    if (oldTravel) {
      const key = oldTravel['id'];
      const value = oldTravel['value'];
      setTraveledQuestionValue({ [key]: value });
    }
    const oldPlan = ftData?.data?.planQuestion?.options?.find(
      (item) => item.defaultChecked
    );
    if (oldPlan) {
      const key = oldPlan['id'];
      const value = oldPlan['value'];
      setPlanQuestionValue({ [key]: value });
    }
  }, [ftData?.data]);

  const traveledChangeHandler = (event) => {
    setTraveledQuestionValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const planChangeHandler = (event) => {
    setPlanQuestionValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const onCloseToastMessage = () => {
    showToastMessage(false);
  };
  let traveledResult;
  let planResult;
  if (traveledQuestionValue['traveled-yes'] === showExtraFieldsCheck) {
    traveledResult = {
      traveledQuestion: traveledQuestionValue,
      traveledFields: traveledFormData,
    };
  } else {
    traveledResult = { traveledQuestion: traveledQuestionValue };
  }
  if (planQuestionValue['plan-yes'] === showExtraFieldsCheck) {
    planResult = {
      planFields: planFormData,
      planQuestion: planQuestionValue,
    };
  } else {
    planResult = {
      planQuestion: planQuestionValue,
    };
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  let payload = {
    ...planResult,
    ...traveledResult,
  };
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s15`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s15',
      },
    });
  }, [payload]);
  return (
    <>
      <CommonFormHeader
        backPath="/form/lifestyle&othergeneralquestions"
        nextPath={nextPath}
        pageHeading={ftData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={ftData?.common_details?.case_id}
        isCompleted={ftData?.metadata?.isCompleted}
        sectionId="f1v1s15"
        userName={ftData?.common_details?.name}
        productSubType={ftData?.common_details?.product_subtype}
        formId={ftData?.common_details?.form_id}
        otherCasesInfo={ftData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {ftData?.data && (
            <>
              <LabelRadioButtons
                label={traveledData.label}
                options={traveledData.options}
                id={traveledData.id}
                onChangeHandler={traveledChangeHandler}
              />
              {traveledQuestionValue['traveled-yes'] === showExtraFieldsCheck &&
                ftData?.data?.traveledFields && (
                  <TraveledSection
                    setTraveledFormData={setTraveledFormData}
                    traveledFormData={traveledFormData}
                  />
                )}

              <div className="line-separation"></div>
              <LabelRadioButtons
                label={planData.label}
                options={planData.options}
                id={planData.id}
                onChangeHandler={planChangeHandler}
              />
              {planQuestionValue['plan-yes'] === showExtraFieldsCheck &&
                ftData?.data?.planFields && (
                  <PlanSection
                    setPlanFormData={setPlanFormData}
                    planFormData={planFormData}
                  />
                )}
            </>
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default ForeignTravel;
