// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-contents{
    padding:30px 20px;
    display:flex;
    justify-content: space-between;
    background:#fff;
}
.footer-contents p{
    font-size:14px;
    color:#333;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,8BAA8B;IAC9B,eAAe;AACnB;AACA;IACI,cAAc;IACd,UAAU;AACd","sourcesContent":[".footer-contents{\n    padding:30px 20px;\n    display:flex;\n    justify-content: space-between;\n    background:#fff;\n}\n.footer-contents p{\n    font-size:14px;\n    color:#333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
