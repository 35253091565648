import axios from '../axios/axios';
import {
  CARRIER_UPLOAD_DATA_REQUEST,
  CARRIER_UPLOAD_DATA_SUCCESS,
  CARRIER_UPLOAD_DATA_FAIL,
} from '../constants/carrierUploadConstants';

export const carrierUploadAction = () => async (dispatch) => {
  try {
    dispatch({ type: CARRIER_UPLOAD_DATA_REQUEST });
    const { data } = await axios.post('/carrier-upload/input');
    dispatch({
      type: CARRIER_UPLOAD_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: CARRIER_UPLOAD_DATA_FAIL,
      payload: error,
    });
  }
};
