import { useEffect, useState } from 'react';
import axios from '../../../axios/axios';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import { foreignResidencyAction } from '../../../actions/foreignResidencyAction';
import ToastMessage from '../../ui/toastMessage';
import './ForeignResidencyAndFinancial.css';
import OtherCitizen from './otherCitizen';
import USAddress from './usAddress';
import OwnerOfPolicy from './ownerOfPolicy';
import ImmediateMemberInUS from './immediateMemberInUS';
import ForeignEmployment from './foreignEmployment';
import ForeignAddress from './foreignAddress';
import USAccountInfo from './usAccountInfo';
import FinancialBackground from './financialBackground';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../loader';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import BasicInfoSection from './basicInfoSection';
const ForeignResidencyAndFinancial = (props) => {
  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  const [otherCitizen, setOtherCitizen] = useState({});
  const [usAddress, setUSAddress] = useState({});
  const [foreignEmployment, setForeignEmployment] = useState({});
  const [primaryCountryFields, setPrimaryCountryFields] = useState({});
  const [usResidenceFields, setUSResidenceFields] = useState({});
  const [otherResidenceFields, setOtherResidenceFields] = useState({});
  const [foreignFields, setForeignFields] = useState({});
  const [usTotalFields, setUSTotalFields] = useState({});
  const [financial_foreignTotalValue, setFinancial_foreignTotalValue] =
    useState(0);
  const [financial_usTotalValue, setFinancial_usTotalValue] = useState(0);

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const [immediateMember, setImmediateMember] = useState([]);
  useEffect(() => {
    if (formData?.data?.immediateMemberInUS !== undefined) {
      setImmediateMember(formData?.data?.immediateMemberInUS);
    }
  }, [formData?.data]);

  const [foreignAddress, setForeignAddress] = useState([]);
  useEffect(() => {
    if (formData?.data?.foreignAddress !== undefined) {
      setForeignAddress(formData?.data?.foreignAddress);
    }
  }, [formData?.data]);

  const [usAccountInfo, setUsAccountInfo] = useState([]);
  useEffect(() => {
    if (formData?.data?.usAccountInformation !== undefined) {
      setUsAccountInfo(formData?.data?.usAccountInformation);
    }
  }, [formData?.data]);

  useEffect(() => {
    dispatch(foreignResidencyAction());
  }, [dispatch]);

  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    otherCitizenInfo: otherCitizen,
    usAddress: usAddress,
    po_primaryResidence: primaryCountryFields,
    po_usResidence: usResidenceFields,
    po_otherCountryResidence: otherResidenceFields,
    immediateMemberInUS: immediateMember,
    foreignEmployment: foreignEmployment,
    foreignAddress: foreignAddress,
    financial_foreignFields: foreignFields,
    financial_usTotalFields: usTotalFields,
    financial_usForeignTotalValue: financial_foreignTotalValue,
    financial_usTotalValue: financial_usTotalValue,
    financialBackgroundNewWorth:
      financial_foreignTotalValue + financial_usTotalValue,
    usAccountInformation: usAccountInfo,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s17',
      },
    });
  }, [payload]);
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s17`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/policyowner"
        nextPath="/form/beneficiaryinformation"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s17"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          <BasicInfoSection />
          <div className="line-separation "></div>
          <OtherCitizen setOtherCitizen={setOtherCitizen} />
          <div className="line-separation "></div>
          <USAddress setUSAddress={setUSAddress} />
          <div className="line-separation "></div>
          <OwnerOfPolicy
            setPrimaryCountryFields={setPrimaryCountryFields}
            setUSResidenceFields={setUSResidenceFields}
            setOtherResidenceFields={setOtherResidenceFields}
          />
          <div className="line-separation "></div>
          <ImmediateMemberInUS
            immediateMember={immediateMember}
            setImmediateMember={setImmediateMember}
          />
          <div className="line-separation "></div>
          <ForeignEmployment setForeignEmployment={setForeignEmployment} />
          <div className="line-separation "></div>
          <ForeignAddress
            foreignAddress={foreignAddress}
            setForeignAddress={setForeignAddress}
          />
          <div className="line-separation "></div>
          <FinancialBackground
            setForeignFields={setForeignFields}
            setUSTotalFields={setUSTotalFields}
            setFinancial_usTotalValue={setFinancial_usTotalValue}
            setFinancial_foreignTotalValue={setFinancial_foreignTotalValue}
          />
          <div className="line-separation "></div>
          <USAccountInfo
            usAccountInfo={usAccountInfo}
            setUsAccountInfo={setUsAccountInfo}
          />
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default ForeignResidencyAndFinancial;
