import Checkboxes from '../../../ui/formField/Checkboxes';
import './QuestionSection.css';
const QuestionSection = (props) => {
  const handleCheckBox = (event) => {
    let updatedList = [...props.checkboxValue];
    if (event.target.checked) {
      if (event.target.value === 'all') {
        const allInputs = props.checkboxFieldData.checkOptions.map(
          (ele) => ele.value
        );
        updatedList = allInputs;
        if (props?.setCheckboxData) {
          const newCheckOptions = props.checkboxFieldData?.checkOptions?.map(
            (ele) => {
              return { ...ele, checked: true };
            }
          );
          const data = {
            ...props.checkboxFieldData,
            checkOptions: newCheckOptions,
          };
          props.setCheckboxData(data);
        }
      } else {
        updatedList = [...props.checkboxValue, event.target.value];
        const checkOptions = [...props.checkboxFieldData?.checkOptions];
        const newIndex = checkOptions.find(
          (ele) => ele.value === event.target.value
        );
        if (props?.setCheckboxData) {
          if (newIndex) {
            newIndex.checked = true;
          }
          const ifAllChecked =
            checkOptions.filter((ele) => ele.checked).length ===
            props.checkboxFieldData?.checkOptions?.length - 1;
          if (ifAllChecked) {
            const allIndex = checkOptions.find((ele) => ele.value === 'all');
            if (allIndex) {
              allIndex.checked = true;
              updatedList.push('all');
            }
          }
          const data = {
            ...props.checkboxFieldData,
            checkOptions: checkOptions,
          };
          props.setCheckboxData(data);
        }
      }
    } else {
      if (event.target.value === 'all') {
        updatedList = [];
        if (props?.setCheckboxData) {
          const newCheckOptions = props.checkboxFieldData?.checkOptions?.map(
            (ele) => {
              return { ...ele, checked: false };
            }
          );
          const data = {
            ...props.checkboxFieldData,
            checkOptions: newCheckOptions,
          };
          props.setCheckboxData(data);
        }
      } else {
        const checkOptions = [...props.checkboxFieldData?.checkOptions];
        const allIndex = checkOptions.find((ele) => ele.value === 'all');
        const newIndex = checkOptions.find(
          (ele) => ele.value === event.target.value
        );
        if (props?.setCheckboxData) {
          if (allIndex) {
            allIndex.checked = false;
          }
          if (newIndex) {
            newIndex.checked = false;
          }
          const data = {
            ...props.checkboxFieldData,
            checkOptions: checkOptions,
          };
          props.setCheckboxData(data);
        }
        const checkboxIndex = props.checkboxValue.indexOf('all');
        if (checkboxIndex > -1) {
          updatedList.splice(checkboxIndex, 1);
        }
        updatedList.splice(props.checkboxValue.indexOf(event.target.value), 1);
      }
    }
    props.setCheckBoxValue(updatedList);
  };
  return (
    <>
      {props?.checkboxFieldData?.label && (
        <p className="professionalAdvisors-heading">
          {props?.checkboxFieldData && props?.checkboxFieldData?.label}
        </p>
      )}
      {props?.checkboxFieldData?.checkOptions?.map((option, index) => {
        return (
          <Checkboxes
            key={index}
            name={option.name}
            value={option.value}
            type={option.type}
            label={option.label}
            defaultChecked={option.defaultChecked}
            id={option.id}
            onChangeHandler={handleCheckBox}
            checked={option?.checked}
          />
        );
      })}
    </>
  );
};

export default QuestionSection;
