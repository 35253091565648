import './../Modal.css';

const ApplicationFilloutModal = (props) => {
  return (
    <>
      <div className="modal-component">
        <div className="modal-overlay"></div>
        <div className="modal-wrapper">
          <h2 className="modal-heading">Are you Sure?</h2>
          <p className="modal-contents">
            There are few fields which are left blank or may have errors.
          </p>
          <div className="modal-buttons">
            <button
              onClick={props.onCancelhandler}
              className="button cancel-btn"
            >
              Cancel
            </button>
            <button
              onClick={props.onContinueHandler}
              className="button continue-btn"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ApplicationFilloutModal;
