// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelRadioOptions__label {
  color: #222222;
  font-size: 14px;
  line-height: 1.5;
  padding-right: 20px;
  margin-bottom: 10px;
}
.labelRadioOptions__radioTabsWrapper {
  justify-content: left;
}
.labelRadioOptions__radioTabs {
  margin-bottom: 10px;
  margin-right: 25px;
}
.labelRadioOptions__radioTabs .field-label {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/labelRadioOptions/LabelRadioOptions.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".labelRadioOptions__label {\n  color: #222222;\n  font-size: 14px;\n  line-height: 1.5;\n  padding-right: 20px;\n  margin-bottom: 10px;\n}\n.labelRadioOptions__radioTabsWrapper {\n  justify-content: left;\n}\n.labelRadioOptions__radioTabs {\n  margin-bottom: 10px;\n  margin-right: 25px;\n}\n.labelRadioOptions__radioTabs .field-label {\n  display: flex;\n  display: -webkit-flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
