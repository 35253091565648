import Checkboxes from '../../../ui/formField/Checkboxes';
import { useSelector } from 'react-redux';
import DisabledCheckBoxes from './disabledCheckBoxes';

const PlanQuestion = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  return (
    <>
      <p
        className="plan-question-label"
        id={formData?.data?.PlanQuestionLabel.id}
      >
        {formData?.data?.PlanQuestionLabel.label}
      </p>

      <div className="disabled-checkbox-container">
        <DisabledCheckBoxes
          label={'Aerial Sports'}
          dependCheckBox={props.aerialSportsCheckBoxes}
        />
        <DisabledCheckBoxes
          label={'Auto / Motorboat / Motorcycle Racing'}
          dependCheckBox={props.autoCheckBoxes}
        />
        <DisabledCheckBoxes
          label={'Mountaineering'}
          dependCheckBox={props.mountaineeringCheckBoxes}
        />
        <DisabledCheckBoxes
          label={'Underwater Diving'}
          dependCheckBox={props.underwaterDivingCheckBoxes}
        />
        <DisabledCheckBoxes
          label={'Other'}
          dependCheckBox={props.othersCheckBoxes}
        />
      </div>
    </>
  );
};

export default PlanQuestion;
