import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonLabelRadioButtons from '../../../../ui/commonLabelRadioButton';
import FormField from '../../../../ui/formField';
import LabelRadioButtons from '../../../../ui/labelRadioButtons';
import CbTrusteeSection from './cbTrustee';

const CbTrustSection = (props) => {
  const [cbTrustTypeOptions, setCbTrustTypeOptions] = useState({});
  const [cbTrustAgreementSection, setCbTrustAgreementSection] = useState({});
  const [cbTrustAgreementYesOptions, setCbTrustAgreementYesOptions] =
    useState('');
  const [cbTrusteeSection, setCbTrusteeSection] = useState([]);
  const [cbBeneficiarySection, setCbBeneficiarySection] = useState([]);
  const beneficiaryFieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = beneficiaryFieldData;

  const cbTrustTypeFieldData = bnData?.data?.cbTrustTypeOptions?.options;
  const cbTrustAgreementSectionData = bnData?.data?.cbTrustAgreementSection;
  const cbTrustAgreementYesOptionsData =
    bnData?.data?.cbTrustAgreementYesOptions;
  const cbTrustAgreementChangeHandler = (event) => {
    setCbTrustAgreementSection(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const cbTrustTypeChangeHandler = (event) => {
    setCbTrustTypeOptions(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onTrustNameFieldsChange = (event) => {
    props.setCBTrustName((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  useEffect(() => {
    const initialcbTrustAgreementValue =
      bnData?.data?.cbTrustAgreementSection?.options?.find(
        (item) => item.defaultChecked
      );
    if (initialcbTrustAgreementValue) {
      const key = initialcbTrustAgreementValue['id'];
      const value = initialcbTrustAgreementValue['value'];
      setCbTrustAgreementSection({ [key]: value });
    }
    const initialcbTrustTypeValue =
      bnData?.data?.cbTrustTypeOptions?.options?.find(
        (item) => item.defaultChecked
      );
    if (initialcbTrustTypeValue) {
      const key = initialcbTrustTypeValue['id'];
      const value = initialcbTrustTypeValue['value'];
      setCbTrustTypeOptions({ [key]: value });
    }

    if (bnData?.data?.cbTrusteeSection !== undefined) {
      setCbTrusteeSection(bnData?.data?.cbTrusteeSection);
    }
    if (bnData?.data?.cbBeneficiarySection !== undefined) {
      setCbBeneficiarySection(bnData?.data?.cbBeneficiarySection);
    }
  }, [bnData?.data]);
  useEffect(() => {
    props.setCbTrustData({
      cbTrustTypeOptions,
      cbTrusteeSection,
      cbBeneficiarySection,
      cbTrustAgreementYesOptions,
      cbTrustAgreementSection,
    });
  }, [
    cbTrustTypeOptions,
    cbTrusteeSection,
    cbBeneficiarySection,
    cbTrustAgreementYesOptions,
    cbTrustAgreementSection,
  ]);
  return (
    <>
      {bnData?.data && (
        <>
          <div className="flex">
            <div className="line-separation layout-1"></div>
            <div className="label-value-wrapper layout-2">
              <h3>{bnData?.data?.cbTrustTypeOptions?.label}</h3>
              <CommonLabelRadioButtons
                options={cbTrustTypeFieldData}
                onChangeHandler={cbTrustTypeChangeHandler}
              />
            </div>
            <div className="label-value-wrapper layout-2 trust-per">
              <label className="field-label">
                {bnData?.data?.cbTrustTotalPer?.label}
              </label>
              <div>
                <input
                  type={bnData?.data?.cbTrustTotalPer?.type}
                  name={bnData?.data?.cbTrustTotalPer?.name}
                  defaultValue={bnData?.data?.cbTrustTotalPer?.value}
                  placeholder="Enter"
                  onChange={(e) => props.setCbTrustTotalPer(e.target.value)}
                />
              </div>
            </div>
            {bnData?.data?.cbTrustName?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  layoutType={item.layoutType}
                  onChangeHandler={onTrustNameFieldsChange}
                  validationType={item.validationType}
                />
              );
            })}
          </div>
          <CbTrusteeSection
            trustee={cbTrusteeSection}
            setTrustee={setCbTrusteeSection}
            cbBeneficiarySection={cbBeneficiarySection}
            setCbBeneficiarySection={setCbBeneficiarySection}
            cbTrusteePercentage={props.cbTrusteePercentage}
            setCbTrusteePercentage={props.setCbTrusteePercentage}
            cbTrusteeIndividualPercentage={props.cbTrusteeIndividualPercentage}
            setCbTrusteeIndividualPercentage={
              props.setCbTrusteeIndividualPercentage
            }
            setCbBeneficiaryPercentage={props.setCbBeneficiaryPercentage}
            cbBeneficiaryPercentage={props.cbBeneficiaryPercentage}
          />
          <LabelRadioButtons
            label={cbTrustAgreementSectionData?.label}
            options={cbTrustAgreementSectionData?.options}
            id={cbTrustAgreementSectionData?.id}
            onChangeHandler={cbTrustAgreementChangeHandler}
          />
          {cbTrustAgreementSection['cbTrustAgreement-yes'] === 'yes' && (
            <div className="field-remark-wrapper">
              <label>{cbTrustAgreementYesOptionsData?.label}</label>
              <textarea
                name={cbTrustAgreementYesOptionsData?.id}
                id={cbTrustAgreementYesOptionsData?.id}
                onChange={(event) =>
                  setCbTrustAgreementYesOptions(() => ({
                    [event.target.id]: event.target.value,
                  }))
                }
                //value={cbTrustAgreementYesOptions}
                defaultValue={cbTrustAgreementYesOptionsData?.value}
                placeholder={cbTrustAgreementYesOptionsData?.placeholder}
              ></textarea>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CbTrustSection;
