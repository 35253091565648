import {
  SET_FORM_FIELD_ERROR,
  REMOVE_FORM_FIELD_ERROR,
  RESET_FORM_FIELD_ERROR,
} from '../constants/formValidationConstants';
export const formValidationReducer = (
  state = { invalidFields: [] },
  action
) => {
  const setInvalidFields = (state, field) => {
    if (!state.invalidFields.includes(field)) {
      state.invalidFields.push(field);
    }
    return state;
  };
  const removeInvalidFields = (state, field) => {
    const index = state.invalidFields.indexOf(field);
    if (index > -1) {
      state.invalidFields.splice(index, 1);
    }

    return state;
  };
  switch (action.type) {
    case SET_FORM_FIELD_ERROR:
      return setInvalidFields(state, action.payload);
    case REMOVE_FORM_FIELD_ERROR:
      return removeInvalidFields(state, action.payload);
    case RESET_FORM_FIELD_ERROR:
      return { invalidFields: [] };
    default:
      return state;
  }
};
