import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '../../../ui/formField';

const BusinessType = (props) => {
  const pageData = useSelector((state) => state.businessInsuranceData);
  const { loading, error, formData } = pageData;

  const handleChange = (e) => {
    props.setBusinessType(() => ({
      [e.target.id]: e.target.name,
    }));
  };

  const businessType = formData?.data?.businessType?.radioOptions.map(
    (item, index) => {
      return (
        <div key={index} className="businessInsurance__radioTabs">
          <label className="field-label">
            <input
              id={item.id}
              type="radio"
              name={item.name}
              value={item.value}
              defaultChecked={item.defaultChecked}
              onChange={handleChange}
            />

            <span>{item.value}</span>
          </label>
        </div>
      );
    }
  );

  return (
    <>
      {formData?.data?.businessType && (
        <div className="line-separation">
          {formData?.data?.businessType?.label && (
            <h3 className="businessInsurance__sectionHeading">
              {formData?.data?.businessType?.label}
            </h3>
          )}
          <div className="flex businessInsurance__radioTabsWrapper">
            {businessType}
          </div>
        </div>
      )}
    </>
  );
};
export default BusinessType;
