import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonLabelRadioButtons from '../../../ui/commonLabelRadioButton';
import FormField from '../../../ui/formField';
import Checkboxes from '../../../ui/formField/Checkboxes';
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import RadioWithHeading from '../../../ui/radioWithHeading';

const LifeStyleSection = (props) => {
  const [cigarettesQuestion, setCigarettesQuestion] = useState({});
  const [cigarettesQuestionYesFields, setCigarettesQuestionYesFields] =
    useState({});
  const [alcoholQuestion, setAlcoholQuestion] = useState({});
  const [alcoholQuestionYesFields, setAlcoholQuestionYesFields] = useState({});
  const [longTermCareExercise, setLongTermCareExercise] = useState({});
  const [socialActivitiesQuestion, setSocialActivitiesQuestion] = useState({});
  const [socialActivitiesCheckBoxes, setSocialActivitiesCheckBoxes] = useState(
    []
  );
  const longTermCareData = useSelector((state) => state.longTermCareData);
  const { loading, error, ltcData } = longTermCareData;

  const onCigarettesQuestionRadioChange = (e) => {
    setCigarettesQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onCigarettesQuestionYesFieldsChange = (event) => {
    setCigarettesQuestionYesFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onAlcoholQuestionRadioChange = (e) => {
    setAlcoholQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onAlcoholQuestionYesFieldsChange = (event) => {
    setAlcoholQuestionYesFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const longTermCareExerciseChange = (e) => {
    setLongTermCareExercise(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onSocialActivitiesQuestionRadioChange = (e) => {
    setSocialActivitiesQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const handleCheckBox = (event) => {
    let updatedList = [...socialActivitiesCheckBoxes];
    if (event.target.checked) {
      updatedList = [...socialActivitiesCheckBoxes, event.target.value];
    } else {
      updatedList.splice(
        socialActivitiesCheckBoxes.indexOf(event.target.value),
        1
      );
    }
    setSocialActivitiesCheckBoxes(updatedList);
  };

  useEffect(() => {
    const initialCigarettesQuestionValue =
      ltcData?.data?.cigarettesQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialCigarettesQuestionValue) {
      const key = initialCigarettesQuestionValue['id'];
      const value = initialCigarettesQuestionValue['value'];
      setCigarettesQuestion({ [key]: value });
    }
    const initialAlcoholQuestionValue =
      ltcData?.data?.alcoholQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialAlcoholQuestionValue) {
      const key = initialAlcoholQuestionValue['id'];
      const value = initialAlcoholQuestionValue['value'];
      setAlcoholQuestion({ [key]: value });
    }
    const initiallongTermCareExerciseValue =
      ltcData?.data?.longTermCareExercise?.options?.find(
        (item) => item.defaultChecked
      );
    if (initiallongTermCareExerciseValue) {
      const key = initiallongTermCareExerciseValue['id'];
      const value = initiallongTermCareExerciseValue['value'];
      setLongTermCareExercise({ [key]: value });
    }
    const initialsocialActivitiesValue =
      ltcData?.data?.socialActivitiesQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialsocialActivitiesValue) {
      const key = initialsocialActivitiesValue['id'];
      const value = initialsocialActivitiesValue['value'];
      setSocialActivitiesQuestion({ [key]: value });
    }
    let checkboxDefaltValuearr = [];
    ltcData?.data?.socialActivitiesCheckBoxes?.option?.map((item) => {
      if (item.defaultChecked) {
        checkboxDefaltValuearr.push(item.value);
      }
    });
    setSocialActivitiesCheckBoxes(checkboxDefaltValuearr);
  }, [ltcData?.data]);

  useEffect(() => {
    props.setLifeStyleData({
      cigarettesQuestion,
      cigarettesQuestionYesFields,
      alcoholQuestion,
      alcoholQuestionYesFields,
      longTermCareExercise,
      socialActivitiesQuestion,
      socialActivitiesCheckBoxes,
    });
  }, [
    cigarettesQuestion,
    cigarettesQuestionYesFields,
    alcoholQuestion,
    alcoholQuestionYesFields,
    longTermCareExercise,
    socialActivitiesQuestion,
    socialActivitiesCheckBoxes,
  ]);
  return (
    <>
      {ltcData?.data && (
        <LabelRadioButtons
          label={ltcData?.data?.cigarettesQuestion?.label}
          options={ltcData?.data?.cigarettesQuestion?.radioOptions}
          id={ltcData?.data?.cigarettesQuestion?.id}
          onChangeHandler={onCigarettesQuestionRadioChange}
        />
      )}
      {cigarettesQuestion['cigarettesQuestion-yes'] === 'yes' && (
        <div className="flex">
          {ltcData?.data &&
            ltcData?.data?.cigarettesQuestionYesFields?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  dropdownType={item.dropdownType}
                  layoutType={item.layoutType}
                  onChangeHandler={onCigarettesQuestionYesFieldsChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                />
              );
            })}
        </div>
      )}
      {ltcData?.data && (
        <LabelRadioButtons
          label={ltcData?.data?.alcoholQuestion?.label}
          options={ltcData?.data?.alcoholQuestion?.radioOptions}
          id={ltcData?.data?.alcoholQuestion?.id}
          onChangeHandler={onAlcoholQuestionRadioChange}
        />
      )}
      {alcoholQuestion['alcoholQuestion-yes'] === 'yes' && (
        <div className="flex">
          {ltcData?.data &&
            ltcData?.data?.alcoholQuestionYesFields?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  dropdownType={item.dropdownType}
                  layoutType={item.layoutType}
                  onChangeHandler={onAlcoholQuestionYesFieldsChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                />
              );
            })}
        </div>
      )}
      <p className="ltc-label">
        {ltcData?.data && ltcData?.data?.longTermCareExercise?.label}
      </p>
      {ltcData?.data && (
        <CommonLabelRadioButtons
          options={ltcData?.data?.longTermCareExercise?.options}
          onChangeHandler={longTermCareExerciseChange}
        />
      )}
      {ltcData?.data && (
        <LabelRadioButtons
          label={ltcData?.data?.socialActivitiesQuestion?.label}
          options={ltcData?.data?.socialActivitiesQuestion?.radioOptions}
          id={ltcData?.data?.socialActivitiesQuestion?.id}
          onChangeHandler={onSocialActivitiesQuestionRadioChange}
        />
      )}
      {socialActivitiesQuestion['socialActivitiesQuestion-yes'] === 'yes' && (
        <div className="ltc-checkboxes-container">
          {ltcData?.data &&
            ltcData?.data?.socialActivitiesCheckBoxes?.option?.map(
              (option, index) => {
                return (
                  <Checkboxes
                    key={index}
                    name={option.name}
                    value={option.value}
                    type={option.type}
                    label={option.label}
                    defaultChecked={option.defaultChecked}
                    id={option.id}
                    onChangeHandler={handleCheckBox}
                  />
                );
              }
            )}
        </div>
      )}
    </>
  );
};

export default LifeStyleSection;
