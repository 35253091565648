import FormField from '../../../ui/formField';
import { useDispatch, useSelector } from 'react-redux';
const MotherInformation = (props) => {
  const pageData = useSelector((state) => state.familyHealthReducer);
  const { loading, error, formData } = pageData;

  const onChangeHandler = (e) => {
    props.setMotherData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const sectionLabel = formData?.data?.motherSectionLabel;
  const sectionField = formData?.data?.motherInformation.map((item, index) => {
    return (
      <FormField
        key={item.id}
        label={item.label}
        type={item.type}
        id={item.id}
        name={item.name}
        value={item.value}
        layoutType={item.layoutType}
        min={item.minValue}
        max={item.maxValue}
        onChangeHandler={onChangeHandler}
        hideLabel
      />
    );
  });
  return (
    <>
      <div className="flex infoRow">
        {sectionLabel && <h3 className="infoRow__label">{sectionLabel}</h3>}
        <div className="flex infoRow__fields">{sectionField}</div>
      </div>
    </>
  );
};
export default MotherInformation;
