import React from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import moment from 'moment';

const RowOverlayPopup = ({ rowData, onClose }) => {
  const containerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    padding: '20px',
    width: '800px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    wordWrap: 'break-word',
  };

  const labelStyle = {
    fontWeight: 'bold',
  };

  const labelAndValueStyle = {
    fontSize: '16px',
    marginBottom: '10px',
    minWidth: '300px',
  };
  const extractionStatus = (type) => {
    let extractionType = '';
    switch (type) {
      case 'pending':
        extractionType = 'pending';
        break;
      case 'completed':
        extractionType = 'completed';
        break;
      case 'failed':
        extractionType = 'failed';
        break;

      default:
        extractionType = '';
    }
    return extractionType;
  };

  return (
    <div className="overlay">
      <div style={containerStyle}>
        <IoCloseSharp
          className="overlay-content-close"
          onClick={onClose}
          style={{
            cursor: 'pointer',
            gridColumn: 'span 2',
            justifySelf: 'end',
          }}
          size={20}
        />
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>CARRIER:</label>
          <span style={{ marginLeft: '5px' }}>
            {rowData.carrier?.join(', ')}
          </span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>STATE:</label>
          <span style={{ marginLeft: '5px' }}>
            {rowData?.state_short_form?.join(', ')}
          </span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>CATEGORY:</label>
          <span style={{ marginLeft: '5px' }}>{rowData.product_category}</span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>PRODUCT:</label>
          <span style={{ marginLeft: '5px' }}>
            {rowData.product?.join(', ')}
          </span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>FORM NAME:</label>
          <span style={{ marginLeft: '5px' }}>{rowData.form_name}</span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>FORM CATEGORY:</label>
          <span style={{ marginLeft: '5px' }}>{rowData.form_type}</span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>CATEGORY TAG:</label>
          <span style={{ marginLeft: '5px' }}>
            {rowData.category_tags?.join(', ')}
          </span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>EXTRACTION STATUS:</label>
          <span
            className={`formAdministration--${extractionStatus(
              rowData?.extraction_status?.toLowerCase()
            )} `}
            style={{ marginLeft: '5px' }}
          ></span>
          {rowData?.extraction_status ? rowData?.extraction_status : '-'}
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>LAST UPDATED DATE:</label>
          <span style={{ marginLeft: '5px' }}>
            {rowData?.uploaded_at
              ? moment(new Date(rowData?.uploaded_at)).format('MM/DD/YYYY')
              : '-'}
          </span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>LAST UPDATED BY:</label>
          <span style={{ marginLeft: '5px' }}>{rowData.uploaded_by}</span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>FORM VERSION NUMBER:</label>
          <span style={{ marginLeft: '5px' }}>{rowData.form_version}</span>
        </div>
        <div style={labelAndValueStyle}>
          <label style={labelStyle}>FORM DATE:</label>
          <span style={{ marginLeft: '5px' }}>{rowData.form_date}</span>
        </div>
      </div>
    </div>
  );
};

export default RowOverlayPopup;
