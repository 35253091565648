import { useState } from 'react';
import DeleteIcon from '../../assets/images/delete-icon.svg';
const Note = ({ note, deleteNote }) => {
  let date = Date(note.updated_at);
  return (
    <li className="notesSection__notes" key={note.id}>
      <div className="flex notesSection__timeDeleteWrapper">
        <p className="notesSection__notesTime">{note.updated_at}</p>
        <button
          className="notesSection__deleteNote"
          onClick={() => deleteNote(note.id)}
          tabIndex="-1"
        >
          <img src={DeleteIcon} alt="Delete Icon" />
        </button>
      </div>

      <p className="notesSection__text">{note.note_text}</p>
    </li>
  );
};
export default Note;
