import FormField from '../../../ui/formField';
import deleteIcon from '../../../../assets/images/delete-icon.svg';
import { useSelector } from 'react-redux';
import React from 'react';
const ImmediateMemberInUS = (props) => {
  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;
  const sectionLabel = formData?.data?.immediateMemberLabel;
  const fieldLabel = {
    name: 'Name',
    age: 'Age',
    relationship: 'Relationship',
    citizenship: 'Citizenship',
  };
  let handleChange = (i, e) => {
    let newFormValues = [...props.immediateMember];
    newFormValues[i][e.target.name] = e.target.value;
    props.setImmediateMember(newFormValues);
  };
  let addFormFields = () => {
    props.setImmediateMember([
      ...props.immediateMember,
      {
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        zipcode: '',
        cellphone: '',
        homephone: '',
        workphone: '',
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...props.immediateMember];
    newFormValues.splice(i, 1);
    props.setImmediateMember(newFormValues);
  };
  const fields = (
    <>
      {props.immediateMember.length > 0 &&
        props.immediateMember.map((element, index) => {
          return (
            <React.Fragment key={index}>
              <div className="label-value-wrapper no-margin-text-field layout-4">
                <label className="field-label">{fieldLabel.name}</label>

                <FormField
                  id={`name${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="name"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="name"
                  placeholder="Enter"
                  value={element.name || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                  addMoreField
                />
              </div>
              <div
                className={`label-value-wrapper layout-4 ${
                  !element.age ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{fieldLabel.age}</label>
                <div>
                  <input
                    type="number"
                    name="age"
                    placeholder="Enter"
                    value={element.age || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-4 ${
                  !element.relationship ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{fieldLabel.relationship}</label>
                <div>
                  <input
                    type="text"
                    name="relationship"
                    placeholder="Enter"
                    value={element.relationship || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-4 ${
                  !element.citizenship ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{fieldLabel.citizenship}</label>
                <div>
                  <input
                    type="text"
                    name="citizenship"
                    placeholder="Enter"
                    value={element.citizenship || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              {index ? (
                <div className="immediateMember__delete">
                  <span
                    onClick={() => removeFormFields(index)}
                    className="icon-delete"
                  >
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      className="deleteIcon remarks-remove"
                    />
                  </span>
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
    </>
  );
  return (
    <>
      <div className="immediateMember">
        {sectionLabel && (
          <h3 className="immediateMember__heading">{sectionLabel}</h3>
        )}

        {fields && <div className="flex">{fields}</div>}
        {props.immediateMember.length > 0 && (
          <div>
            <span className="addFieldButton" onClick={() => addFormFields()}>
              ADD MORE
            </span>
          </div>
        )}
      </div>
    </>
  );
};
export default ImmediateMemberInUS;
