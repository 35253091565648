import '../FamilyHealthHistory.css';
const TableHeader = (props) => {
  return (
    <>
      <div className="infoHeader">
        <span className="infoHeader__fields infoHeader__fields--w20">
          Member
        </span>
        <span className="infoHeader__fields infoHeader__fields--w20">
          Age(s) if Living
        </span>
        <span className="infoHeader__fields infoHeader__fields--w20">
          Health Status
        </span>
        <span className="infoHeader__fields infoHeader__fields--w20">
          Age(s) at Death
        </span>
        <span className="infoHeader__fields infoHeader__fields--w20">
          Cause of Death
        </span>
      </div>
    </>
  );
};
export default TableHeader;
