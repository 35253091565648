// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-question-label {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 12px;
}
.avo-radio-container {
  display: flex;
  height: 44px;
  align-items: center;
}
.avo-radio-label {
  font-weight: 600;
  font-size: 12px;
  color: #222222;
}
.plan-radio-btn-container {
  margin: 15px 0;
}

.status-radio-container {
  display: flex;
  align-items: center;
  height: 44px;
}
.status-radio-label {
  font-size: 13px;
  font-weight: 600;
  margin: 0px 10px 0px 5px;
}
.status-radio {
  display: flex;
  align-items: center;
}
.status-radio input[type="radio"]:checked ~ label {
  color: #0177b5;
}
.plan-check-label {
  font-size: 14px;
}
.plan-check-label-container {
  background-color: #f1f4f6;
  padding: 8px 32px;
  display: flex;
  border: 1px solid #d6d6d6;
  margin-right: 16px;
  border-radius: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
}
.plan-check-container {
  display: flex;
}
.plan-check-icon {
  margin-right: 5px;
}
.disabled-checkbox-container {
  display: flex;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/avocationQuestionnaire/avocationQuestionnaire.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".plan-question-label {\n  font-size: 14px;\n  font-weight: 600;\n  color: #333333;\n  margin-bottom: 12px;\n}\n.avo-radio-container {\n  display: flex;\n  height: 44px;\n  align-items: center;\n}\n.avo-radio-label {\n  font-weight: 600;\n  font-size: 12px;\n  color: #222222;\n}\n.plan-radio-btn-container {\n  margin: 15px 0;\n}\n\n.status-radio-container {\n  display: flex;\n  align-items: center;\n  height: 44px;\n}\n.status-radio-label {\n  font-size: 13px;\n  font-weight: 600;\n  margin: 0px 10px 0px 5px;\n}\n.status-radio {\n  display: flex;\n  align-items: center;\n}\n.status-radio input[type=\"radio\"]:checked ~ label {\n  color: #0177b5;\n}\n.plan-check-label {\n  font-size: 14px;\n}\n.plan-check-label-container {\n  background-color: #f1f4f6;\n  padding: 8px 32px;\n  display: flex;\n  border: 1px solid #d6d6d6;\n  margin-right: 16px;\n  border-radius: 20px;\n  margin-bottom: 12px;\n  cursor: pointer;\n  position: relative;\n}\n.plan-check-container {\n  display: flex;\n}\n.plan-check-icon {\n  margin-right: 5px;\n}\n.disabled-checkbox-container {\n  display: flex;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
