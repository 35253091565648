import { useState } from 'react';
import './AccordionSingleSection.css';
import IconPlus from './../../../assets/images/icon-plus.svg';
import IconMinus from './../../../assets/images/icon-minus.svg';
const AccordionSingleSection = (props) => {
  const [openAccordion, setOpenAccordion] = useState(true);

  const onAccordionClick = (e) => {
    switch (e.target.dataset.accordion) {
      case 'true':
        setOpenAccordion(false);
        break;
      case 'false':
        setOpenAccordion(true);
        break;
      default: // Do nothing
    }
  };
  return (
    <>
      <div className="accordion__wrapper">
        <div className={`accordion__header `}>
          <span
            className="accordionClickField"
            onClickCapture={onAccordionClick}
            data-accordion={openAccordion}
          ></span>
          <h3 className="accordion__accordionHeading">
            {props.accordionHeading}
          </h3>
          <div>
            <img src={openAccordion ? IconMinus : IconPlus} alt="icon" />
          </div>
        </div>
        <div className={`accordion__content ${openAccordion ? 'open' : ''}`}>
          {props.children}
        </div>
      </div>
    </>
  );
};
export default AccordionSingleSection;
