import FormField from '../../../ui/formField';
import { useDispatch, useSelector } from 'react-redux';
import PhoneDetailsForm from '../../../ui/phoneDetailsForm';
import EmailDetailsForm from '../../../ui/emailDetailsForm';
import AddressDetailsForm from '../../../ui/addressDetailsForm';
import { useEffect } from 'react';
import { proposedInsuredAction } from '../../../../actions/proposedInsuredAction';

const IndividualSection = (props) => {
  const fieldData = useSelector((state) => state.policyOwnerData);
  const { loading, error, poData } = fieldData;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(proposedInsuredAction());
  }, [dispatch]);
  const piFormData = useSelector((state) => state.piData);

  const spouseLabel = 'Spouse';
  const spouseFirstName =
    piFormData?.formData?.data?.spouseDetailsFields[0]?.value;
  const spouseMiddleName =
    piFormData?.formData?.data?.spouseDetailsFields[1]?.value;
  const spouseLastName =
    piFormData?.formData?.data?.spouseDetailsFields[2]?.value;
  const spouseDOB = piFormData?.formData?.data?.spouseDetailsFields[3]?.value;
  const spouseSSN = piFormData?.formData?.data?.spouseDetailsFields[4]?.value;
  useEffect(() => {
    props.setSpouseFirstname(spouseFirstName);
    props.setSpouseMiddleName(spouseMiddleName);
    props.setSpouseLastName(spouseLastName);
    props.setSpouseDOB(spouseDOB);
    props.setSpouseSSN(spouseSSN);
  }, [piFormData?.formData?.data]);

  const onRelationshipFieldChange = (e) => {
    props.setIndividualRelationship(e.target.value);
  };

  const onTitleChange = (e) => {
    props.setIndividualTitle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onFirstNameChange = (e) => {
    props.setIndividualFirstName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onMiddleNameChange = (e) => {
    props.setIndividualMiddleName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onLastNameChange = (e) => {
    props.setIndividualLastName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onSuffixChange = (e) => {
    props.setIndividualSuffix((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onDOBChange = (e) => {
    props.setIndividualDOB((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onSSNChange = (e) => {
    props.setIndividualSSN((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onBirthPlaceChange = (e) => {
    props.setIndividualBirthPlace((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onGenderChange = (e) => {
    props.setIndividualGender((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onDriverLicenseFieldChange = (event) => {
    props.setIndividualDriverLicenseFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const RelationshipData = poData?.data?.insuredRelationship;
  const IndividualTitle = poData?.data?.individualTitle;
  const IndividualFirstName = poData?.data?.individualFirstName;
  const IndividualMiddleName = poData?.data?.individualMiddleName;
  const IndividualLastName = poData?.data?.individualLastName;
  const IndividualSuffix = poData?.data?.individualSuffix;
  const IndividualDOB = poData?.data?.individualDOB;
  const IndividualSSN = poData?.data?.individualSSN;
  const IndividualBirthPlace = poData?.data?.individualBirthPlace;
  const IndividualGender = poData?.data?.individualGender;
  const DriverLicenseFieldData = poData?.data?.individualDriverLicenseFields;

  return (
    <>
      <div className="flex line-separation">
        <FormField
          label={RelationshipData?.label}
          value={RelationshipData?.value}
          type={RelationshipData?.type}
          name={RelationshipData?.name}
          placeholder={RelationshipData?.placeholder}
          layoutType={RelationshipData?.layoutType}
          onChangeHandler={onRelationshipFieldChange}
          selectOptions={RelationshipData?.selectOptions}
        />
      </div>

      <div className="flex">
        <FormField
          label={IndividualTitle?.label}
          value={IndividualTitle?.value}
          type={IndividualTitle?.type}
          name={IndividualTitle?.name}
          placeholder={IndividualTitle?.placeholder}
          layoutType={IndividualTitle?.layoutType}
          onChangeHandler={onTitleChange}
          selectOptions={IndividualTitle?.selectOptions}
        />
        {props?.individualRelationship === spouseLabel ? (
          <>
            <FormField
              label={IndividualFirstName?.label}
              value={spouseFirstName}
              type={IndividualFirstName?.type}
              name={IndividualFirstName?.name}
              placeholder={IndividualFirstName?.placeholder}
              layoutType={IndividualFirstName?.layoutType}
              onChangeHandler={onFirstNameChange}
              regex={IndividualFirstName?.regex}
              errorMessage={IndividualFirstName?.errorMessage}
              validationType={IndividualFirstName?.validationType}
              disabled={true}
            />
          </>
        ) : (
          <FormField
            label={IndividualFirstName?.label}
            value={IndividualFirstName?.value}
            type={IndividualFirstName?.type}
            name={IndividualFirstName?.name}
            placeholder={IndividualFirstName?.placeholder}
            layoutType={IndividualFirstName?.layoutType}
            onChangeHandler={onFirstNameChange}
            regex={IndividualFirstName?.regex}
            errorMessage={IndividualFirstName?.errorMessage}
            validationType={IndividualFirstName?.validationType}
          />
        )}
        {props?.individualRelationship === spouseLabel ? (
          <>
            <FormField
              label={IndividualMiddleName?.label}
              value={spouseMiddleName}
              type={IndividualMiddleName?.type}
              name={IndividualMiddleName?.name}
              placeholder={IndividualMiddleName?.placeholder}
              layoutType={IndividualMiddleName?.layoutType}
              onChangeHandler={onMiddleNameChange}
              regex={IndividualMiddleName?.regex}
              errorMessage={IndividualMiddleName?.errorMessage}
              validationType={IndividualMiddleName?.validationType}
              disabled={true}
            />
          </>
        ) : (
          <FormField
            label={IndividualMiddleName?.label}
            value={IndividualMiddleName?.value}
            type={IndividualMiddleName?.type}
            name={IndividualMiddleName?.name}
            placeholder={IndividualMiddleName?.placeholder}
            layoutType={IndividualMiddleName?.layoutType}
            onChangeHandler={onMiddleNameChange}
            regex={IndividualMiddleName?.regex}
            errorMessage={IndividualMiddleName?.errorMessage}
            validationType={IndividualMiddleName?.validationType}
          />
        )}
        {props?.individualRelationship === spouseLabel ? (
          <>
            <FormField
              label={IndividualLastName?.label}
              value={spouseLastName}
              type={IndividualLastName?.type}
              name={IndividualLastName?.name}
              placeholder={IndividualLastName?.placeholder}
              layoutType={IndividualLastName?.layoutType}
              onChangeHandler={onMiddleNameChange}
              regex={IndividualLastName?.regex}
              errorMessage={IndividualLastName?.errorMessage}
              validationType={IndividualLastName?.validationType}
              disabled={true}
            />
          </>
        ) : (
          <FormField
            label={IndividualLastName?.label}
            value={IndividualLastName?.value}
            type={IndividualLastName?.type}
            name={IndividualLastName?.name}
            placeholder={IndividualLastName?.placeholder}
            layoutType={IndividualLastName?.layoutType}
            onChangeHandler={onLastNameChange}
            regex={IndividualLastName?.regex}
            errorMessage={IndividualLastName?.errorMessage}
            validationType={IndividualLastName?.validationType}
          />
        )}

        <FormField
          label={IndividualSuffix?.label}
          value={IndividualSuffix?.value}
          type={IndividualSuffix?.type}
          name={IndividualSuffix?.name}
          placeholder={IndividualSuffix?.placeholder}
          layoutType={IndividualSuffix?.layoutType}
          onChangeHandler={onSuffixChange}
          regex={IndividualSuffix?.regex}
          errorMessage={IndividualSuffix?.errorMessage}
          validationType={IndividualSuffix?.validationType}
        />
        {props?.individualRelationship === spouseLabel ? (
          <>
            <FormField
              label={IndividualDOB?.label}
              value={spouseDOB}
              type={IndividualDOB?.type}
              name={IndividualDOB?.name}
              placeholder={IndividualDOB?.placeholder}
              layoutType={IndividualDOB?.layoutType}
              onChangeHandler={onDOBChange}
              disabled={true}
            />
          </>
        ) : (
          <FormField
            label={IndividualDOB?.label}
            value={IndividualDOB?.value}
            type={IndividualDOB?.type}
            name={IndividualDOB?.name}
            placeholder={IndividualDOB?.placeholder}
            layoutType={IndividualDOB?.layoutType}
            onChangeHandler={onDOBChange}
          />
        )}
        {props?.individualRelationship === spouseLabel ? (
          <>
            <FormField
              label={IndividualSSN?.label}
              value={spouseSSN}
              type={IndividualSSN?.type}
              name={IndividualSSN?.name}
              placeholder={IndividualSSN?.placeholder}
              layoutType={IndividualSSN?.layoutType}
              onChangeHandler={onSSNChange}
              disabled={true}
            />
          </>
        ) : (
          <FormField
            label={IndividualSSN?.label}
            value={IndividualSSN?.value}
            type={IndividualSSN?.type}
            name={IndividualSSN?.name}
            placeholder={IndividualSSN?.placeholder}
            layoutType={IndividualSSN?.layoutType}
            onChangeHandler={onSSNChange}
            regex={IndividualSSN?.regex}
            errorMessage={IndividualSSN?.errorMessage}
            validationType={IndividualSSN?.validationType}
          />
        )}

        <FormField
          label={IndividualBirthPlace?.label}
          value={IndividualBirthPlace?.value}
          type={IndividualBirthPlace?.type}
          name={IndividualBirthPlace?.name}
          placeholder={IndividualBirthPlace?.placeholder}
          layoutType={IndividualBirthPlace?.layoutType}
          onChangeHandler={onBirthPlaceChange}
        />
        <FormField
          label={IndividualGender?.label}
          value={IndividualGender?.value}
          type={IndividualGender?.type}
          name={IndividualGender?.name}
          placeholder={IndividualGender?.placeholder}
          layoutType={IndividualGender?.layoutType}
          onChangeHandler={onGenderChange}
          selectOptions={IndividualGender?.selectOptions}
        />
      </div>
      <div className="flex">
        {poData?.data && (
          <>
            <div className="line-separation layout-1"></div>
            <EmailDetailsForm
              addEmailList={props.individualEmailList}
              setAddEmailList={props.setIndividualEmailList}
              hideDelete
            />
            <div className="line-separation layout-1"></div>
            <PhoneDetailsForm
              setAddPhoneList={props.setIndividualPhoneList}
              addPhoneList={props.individualPhoneList}
              hideDelete
            />
            <div className="line-separation layout-1"></div>

            <AddressDetailsForm
              setAddAddressList={props.setIndividualaddressSection}
              addAddressList={props.individualaddressSection}
              hideDelete
            />

            <div className="line-separation layout-1"></div>

            <h3 id={poData?.data?.individualDriverLicenseLabel?.id}>
              {poData?.data?.individualDriverLicenseLabel?.label}
            </h3>

            <div className="flex full-width">
              {DriverLicenseFieldData.map((item, index) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onDriverLicenseFieldChange}
                    selectOptions={item.selectOptions}
                    dropdownType={item.dropdownType}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default IndividualSection;
