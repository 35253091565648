import {
  EXERCISE_DATA_REQUEST,
  EXERCISE_DATA_SUCCESS,
  EXERCISE_DATA_FAIL,
} from '../constants/exerciseConstants';

export const exerciseReducer = (state = { exData: [] }, action) => {
  switch (action.type) {
    case EXERCISE_DATA_REQUEST:
      return { loading: true, exData: [] };
    case EXERCISE_DATA_SUCCESS:
      return { loading: false, exData: action.payload };
    case EXERCISE_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
