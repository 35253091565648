import './FormField.css';
import PropTypes from 'prop-types';
import { useState } from 'react';

const InputNumber = (props) => {
  const [fieldValue, setFieldValue] = useState(props.fieldData.value);
  const handleChange = (e) => {
    props.fieldData.onChangeHandler(e);
    setFieldValue(e.target.value);
  };
  const inputNumber = (
    <input
      type={props.fieldData.type}
      id={props.fieldData.id}
      name={props.fieldData.name}
      defaultValue={props.fieldData.value}
      placeholder={props.fieldData.placeholder || 'Enter'}
      min={props.fieldData.min}
      max={props.fieldData.max}
      onChange={handleChange}
      disabled={props.fieldData.disabled}
    />
  );
  return (
    <div
      className={`label-value-wrapper ${
        props.fieldData.layoutType ? props.fieldData.layoutType : ''
      } ${!fieldValue ? 'value--empty' : ''}`}
      key={props.fieldData.id}
    >
      {props.fieldData.label && (
        <label
          className={`field-label ${
            props.fieldData.hideLabel ? 'hide-label' : ' '
          }`}
          htmlFor={props.fieldData.id}
        >
          {props.fieldData.label}
        </label>
      )}

      <div>{inputNumber}</div>
    </div>
  );
};
export default InputNumber;

InputNumber.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.number,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChangeHandler: PropTypes.func,
};
