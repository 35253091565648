import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-contents">
          <p>
            Proprietary and Confidential. © Life Policy Management, LLC. All
            rights reserved.
          </p>
          <p>Privacy Notice</p>
        </div>
      </footer>
    </>
  );
};
export default Footer;
