import CommonTextArea from '../../../ui/commonTextArea';
import FormField from '../../../ui/formField';
import Checkboxes from '../../../ui/formField/Checkboxes';
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const AerialSports = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  const aerialOther = formData?.data?.aerialSportsOtherValue;
  const [aerialOtherValue, setAerialOtherValue] = useState(
    formData?.data?.aerialSportsOtherValue?.value
  );
  const handleCheckBox = (event) => {
    let updatedList = [...props.aerialSportsCheckBoxes];
    if (event.target.checked) {
      updatedList = [...props.aerialSportsCheckBoxes, event.target.value];
    } else {
      updatedList.splice(
        props.aerialSportsCheckBoxes.indexOf(event.target.value),
        1
      );
    }
    props.setAerialSportsCheckBoxes(updatedList);
  };
  const onFieldChangeHandler = (event) => {
    props.setBallooningFieldOptions((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSkyDivingChangeHandler = (event) => {
    props.setSkyDivingField((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onBallooningSkyDivingMinHeightChangeHandler = (event) => {
    props.setSkyDivingFieldMinimumHeight((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const ballooningParachuteSportsReserveChangeHandler = (event) => {
    props.setParachuteSportsReserve(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const ballooningPSAffiliationRadioChangeHandler = (event) => {
    props.setPSAffiliationRadio(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const onballooningPSATextChange = (e) => {
    props.setPSAAffiliatedTextArea(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onBallooningLocationPerformedFieldsChangeHandler = (event) => {
    props.setLocationPerformedFields(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onHeliSkiingSkyDivingMinHeightChangeHandler = (event) => {
    props.setHeliSkiingSkyDivingFieldMinimumHeight((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const heliSkiingParachuteSportsReserveChangeHandler = (event) => {
    props.setHeliSkiingParachuteSportsReserve(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const onHeliSkiingFieldChangeHandler = (event) => {
    props.setHeliSkiingField((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const HeliSkiingPSAffiliationRadioChangeHandler = (event) => {
    props.setHeliSkiingPSAffiliationRadio(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onHeliSkiingPSATextChange = (e) => {
    props.setHeliSkiingPSAAffiliatedTextArea(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onHeliSkiingLocationPerformedFieldsChangeHandler = (event) => {
    props.setHeliSkiingLocationPerformedFields(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  //==================================

  const onSkyDivingMinHeightChangeHandler = (event) => {
    props.setSkyDivingFieldMinimumHeightField((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const SkyDivingParachuteSportsReserveChangeHandler = (event) => {
    props.setSkyDivingParachuteSportsReserve(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const SkyDivingPSAffiliationRadioChangeHandler = (event) => {
    props.setSkyDivingPSAffiliationRadio(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onSkyDivingPSATextChange = (e) => {
    props.setSkyDivingPSAAffiliatedTextArea(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onSkyDivingLocationPerformedFieldsChangeHandler = (event) => {
    props.setSkyDivingLocationPerformedFields(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const ballooningStatusHandleChange = (event) => {
    props.setBallooningFieldStatus(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const skyDivingStatusHandleChange = (event) => {
    props.setSkyDivingStatus(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const heliSkiingStatusHandleChange = (event) => {
    props.setHeliSkiingStatus(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  return (
    <>
      <h3>Aerial Sports</h3>
      <p
        className="plan-question-label"
        id={formData?.data?.aerialSportsLabel?.id}
      >
        {formData?.data?.aerialSportsLabel?.label}
      </p>
      {formData?.data &&
        formData?.data?.aerialSportsCheckBoxes?.option?.map((option, index) => {
          return (
            <Checkboxes
              key={index}
              name={option.name}
              value={option.value}
              type={option.type}
              label={option.label}
              defaultChecked={option.defaultChecked}
              id={option.id}
              onChangeHandler={handleCheckBox}
              disabled={option.disable}
            />
          );
        })}
      {props.aerialSportsCheckBoxes.includes('other') && (
        <div className="flex">
          <div
            className={`label-value-wrapper layout-2 ${
              !aerialOtherValue ? 'value--empty' : ''
            }`}
          >
            <label className="field-label" htmlFor={aerialOther?.id}>
              {aerialOther?.label}
            </label>
            <div>
              <input
                type={aerialOther?.type}
                id={aerialOther?.id}
                name={aerialOther?.name}
                placeholder={aerialOther?.placeholder}
                onChange={(e) => {
                  setAerialOtherValue(e.target.value);
                  props.setAerialSportsOtherValue(e.target.value);
                }}
                defaultValue={aerialOther?.value}
              />
            </div>
          </div>
        </div>
      )}
      {props.aerialSportsCheckBoxes.includes('ballooning') && (
        <>
          <div className="line-separation "></div>
          <p className="plan-question-label">
            {formData?.data?.ballooningLabel}
          </p>
          <h3>{formData?.data?.ballooningHeading}</h3>
          <div className="flex">
            {formData?.data &&
              formData?.data?.ballooningFieldOptions?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    dropdownType={item.dropdownType}
                    layoutType={item.layoutType}
                    onChangeHandler={onFieldChangeHandler}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item?.validationType}
                  />
                );
              })}

            <div className="label-value-wrapper layout-2">
              <div>
                <label className="field-label">
                  {formData?.data?.ballooningFieldStatus?.label}
                </label>
                <div
                  className="status-radio-container"
                  onChange={ballooningStatusHandleChange}
                >
                  {formData?.data?.ballooningFieldStatus?.radioOptions.map(
                    (item, index) => {
                      return (
                        <div className="status-radio" key={index}>
                          <input
                            type="radio"
                            id={item.id}
                            name={item.name}
                            value={item.name}
                            defaultChecked={item.defaultChecked}
                          />
                          <label
                            htmlFor={item.id}
                            className="status-radio-label"
                          >
                            {item.label}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <>
            <p className="plan-question-label">
              {formData?.data?.skyDivingMinimumHeightLabel}
            </p>
            <div className="flex">
              {formData?.data &&
                formData?.data?.ballooningSkyDivingFieldMinimumHeight?.map(
                  (item, index) => {
                    return (
                      <FormField
                        key={index}
                        label={item.label}
                        id={item.id}
                        value={item.value}
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        min={item.min}
                        max={item.max}
                        dropdownType={item.dropdownType}
                        layoutType={item.layoutType}
                        onChangeHandler={
                          onBallooningSkyDivingMinHeightChangeHandler
                        }
                        selectOptions={item.selectOptions}
                        regex={item?.regex}
                        errorMessage={item?.errorMessage}
                        validationType={item?.validationType}
                      />
                    );
                  }
                )}
            </div>
            <LabelRadioButtons
              label={formData?.data?.BallooningParachuteSportsReserve?.label}
              options={
                formData?.data?.BallooningParachuteSportsReserve?.radioOptions
              }
              onChangeHandler={ballooningParachuteSportsReserveChangeHandler}
            />

            <LabelRadioButtons
              label={formData?.data?.ballooningPSAffiliationRadio?.label}
              options={
                formData?.data?.ballooningPSAffiliationRadio?.radioOptions
              }
              onChangeHandler={ballooningPSAffiliationRadioChangeHandler}
            />
            {props.ballooningPSAffiliationRadio[
              'ballooningPSAffiliationRadio-yes'
            ] === 'yes' && (
              <CommonTextArea
                fieldData={formData?.data?.ballooningPSAAffiliatedTextArea}
                onChangeHandler={onballooningPSATextChange}
              />
            )}
            <div className="flex">
              {formData?.data &&
                formData?.data?.ballooningLocationPerformedFields?.map(
                  (item, index) => {
                    return (
                      <FormField
                        key={index}
                        label={item.label}
                        id={item.id}
                        value={item.value}
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        min={item.min}
                        max={item.max}
                        dropdownType={item.dropdownType}
                        layoutType={item.layoutType}
                        onChangeHandler={
                          onBallooningLocationPerformedFieldsChangeHandler
                        }
                        selectOptions={item.selectOptions}
                        regex={item?.regex}
                        errorMessage={item?.errorMessage}
                        validationType={item?.validationType}
                      />
                    );
                  }
                )}
            </div>
          </>
        </>
      )}
      {props.aerialSportsCheckBoxes.includes('heliSkiing') && (
        <>
          <div className="line-separation "></div>
          <p className="plan-question-label">
            {formData?.data?.heliSkiingDesLabel}
          </p>
          <h3>{formData?.data?.heliSkiingLabel}</h3>
          <div className="flex">
            {formData?.data &&
              formData?.data?.heliSkiingField?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    dropdownType={item.dropdownType}
                    layoutType={item.layoutType}
                    onChangeHandler={onHeliSkiingFieldChangeHandler}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item?.validationType}
                  />
                );
              })}
            <div className="label-value-wrapper layout-2">
              <div>
                <label className="field-label">
                  {formData?.data?.heliSkiingStatus?.label}
                </label>
                <div
                  className="status-radio-container"
                  onChange={heliSkiingStatusHandleChange}
                >
                  {formData?.data?.heliSkiingStatus?.radioOptions.map(
                    (item, index) => {
                      return (
                        <div className="status-radio" key={index}>
                          <input
                            type="radio"
                            id={item.id}
                            name={item.name}
                            value={item.name}
                            defaultChecked={item.defaultChecked}
                          />
                          <label
                            htmlFor={item.id}
                            className="status-radio-label"
                          >
                            {item.label}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <p className="plan-question-label">
            {formData?.data?.skyDivingMinimumHeightLabel}
          </p>
          <div className="flex">
            {formData?.data &&
              formData?.data?.heliSkiingSkyDivingFieldMinimumHeight?.map(
                (item, index) => {
                  return (
                    <FormField
                      key={index}
                      label={item.label}
                      id={item.id}
                      value={item.value}
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      min={item.min}
                      max={item.max}
                      dropdownType={item.dropdownType}
                      layoutType={item.layoutType}
                      onChangeHandler={
                        onHeliSkiingSkyDivingMinHeightChangeHandler
                      }
                      selectOptions={item.selectOptions}
                      regex={item?.regex}
                      errorMessage={item?.errorMessage}
                      validationType={item?.validationType}
                    />
                  );
                }
              )}
          </div>
          <LabelRadioButtons
            label={formData?.data?.heliSkiingParachuteSportsReserve?.label}
            options={
              formData?.data?.heliSkiingParachuteSportsReserve?.radioOptions
            }
            onChangeHandler={heliSkiingParachuteSportsReserveChangeHandler}
          />

          <LabelRadioButtons
            label={formData?.data?.heliSkiingPSAffiliationRadio?.label}
            options={formData?.data?.heliSkiingPSAffiliationRadio?.radioOptions}
            onChangeHandler={HeliSkiingPSAffiliationRadioChangeHandler}
          />
          {props.heliSkiingPSAffiliationRadio[
            'heliSkiingPSAffiliationRadio-yes'
          ] === 'yes' && (
            <CommonTextArea
              fieldData={formData?.data?.heliSkiingPSAAffiliatedTextArea}
              onChangeHandler={onHeliSkiingPSATextChange}
            />
          )}
          <div className="flex">
            {formData?.data &&
              formData?.data?.heliSkiingLocationPerformedFields?.map(
                (item, index) => {
                  return (
                    <FormField
                      key={index}
                      label={item.label}
                      id={item.id}
                      value={item.value}
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      min={item.min}
                      max={item.max}
                      dropdownType={item.dropdownType}
                      layoutType={item.layoutType}
                      onChangeHandler={
                        onHeliSkiingLocationPerformedFieldsChangeHandler
                      }
                      selectOptions={item.selectOptions}
                      regex={item?.regex}
                      errorMessage={item?.errorMessage}
                      validationType={item?.validationType}
                    />
                  );
                }
              )}
          </div>
        </>
      )}
      {props.aerialSportsCheckBoxes.includes('skyDiving') && (
        <>
          <div className="line-separation "></div>
          <p className="plan-question-label">
            {formData?.data?.skyDivingLabel}
          </p>
          <h3>{formData?.data?.skyDivingParachutingLabel}</h3>
          <div className="flex">
            {formData?.data &&
              formData?.data?.skyDivingField?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    dropdownType={item.dropdownType}
                    layoutType={item.layoutType}
                    onChangeHandler={onSkyDivingChangeHandler}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item?.validationType}
                  />
                );
              })}

            <div className="label-value-wrapper layout-2">
              <div>
                <label className="field-label">
                  {formData?.data?.skyDivingStatus?.label}
                </label>
                <div
                  className="status-radio-container"
                  onChange={skyDivingStatusHandleChange}
                >
                  {formData?.data?.skyDivingStatus?.radioOptions.map(
                    (item, index) => {
                      return (
                        <div className="status-radio" key={index}>
                          <input
                            type="radio"
                            id={item.id}
                            name={item.name}
                            value={item.name}
                            defaultChecked={item.defaultChecked}
                          />
                          <label
                            htmlFor={item.id}
                            className="status-radio-label"
                          >
                            {item.label}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <p className="plan-question-label">
            {formData?.data?.skyDivingMinimumHeightLabel}
          </p>
          <div className="flex">
            {formData?.data &&
              formData?.data?.SkyDivingFieldMinimumHeight?.map(
                (item, index) => {
                  return (
                    <FormField
                      key={index}
                      label={item.label}
                      id={item.id}
                      value={item.value}
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      min={item.min}
                      max={item.max}
                      dropdownType={item.dropdownType}
                      layoutType={item.layoutType}
                      onChangeHandler={onSkyDivingMinHeightChangeHandler}
                      selectOptions={item.selectOptions}
                      regex={item?.regex}
                      errorMessage={item?.errorMessage}
                      validationType={item?.validationType}
                    />
                  );
                }
              )}
          </div>
          <LabelRadioButtons
            label={formData?.data?.skyDivingParachuteSportsReserve?.label}
            options={
              formData?.data?.skyDivingParachuteSportsReserve?.radioOptions
            }
            onChangeHandler={SkyDivingParachuteSportsReserveChangeHandler}
          />

          <LabelRadioButtons
            label={formData?.data?.skyDivingPSAffiliationRadio?.label}
            options={formData?.data?.skyDivingPSAffiliationRadio?.radioOptions}
            onChangeHandler={SkyDivingPSAffiliationRadioChangeHandler}
          />
          {props.skyDivingPSAffiliationRadio[
            'skyDivingPSAffiliationRadio-yes'
          ] === 'yes' && (
            <CommonTextArea
              fieldData={formData?.data?.skyDivingPSAAffiliatedTextArea}
              onChangeHandler={onSkyDivingPSATextChange}
            />
          )}
          <div className="flex">
            {formData?.data &&
              formData?.data?.skyDivingLocationPerformedFields?.map(
                (item, index) => {
                  return (
                    <FormField
                      key={index}
                      label={item.label}
                      id={item.id}
                      value={item.value}
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      min={item.min}
                      max={item.max}
                      dropdownType={item.dropdownType}
                      layoutType={item.layoutType}
                      onChangeHandler={
                        onSkyDivingLocationPerformedFieldsChangeHandler
                      }
                      selectOptions={item.selectOptions}
                      regex={item?.regex}
                      errorMessage={item?.errorMessage}
                      validationType={item?.validationType}
                    />
                  );
                }
              )}
          </div>
        </>
      )}
    </>
  );
};

export default AerialSports;
