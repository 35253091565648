import { useSelector } from 'react-redux';
import FormField from '../../../../ui/formField';
import PhoneDetailsForm from '../../../../ui/phoneDetailsForm';

const PbCompanySection = (props) => {
  const beneficiaryFieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = beneficiaryFieldData;

  const onPbCompanyNameFieldChange = (event) => {
    props.setPbCompanyNameSection((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPbCompanyAuthorizedFieldChange = (event) => {
    props.setPbCompanyAuthorizedFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPbCompanyAddressFieldChange = (event) => {
    props.setPbCompanyAddressFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPbCompanyContactDetailsFieldsChange = (event) => {
    props.setPbCompanyContactDetailsFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <>
      <div className="flex">
        {bnData?.data &&
          bnData?.data?.pbCompanyNameSection?.map((item) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onPbCompanyNameFieldChange}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                dropdownType={item.dropdownType}
                validationType={item.validationType}
              />
            );
          })}
        <div
          className={`label-value-wrapper layout-2 ${
            !bnData?.data?.pbCompanyPercentage?.value ? 'value--empty' : ''
          }`}
        >
          <label className="field-label">
            {bnData?.data?.pbCompanyPercentage?.label}
          </label>
          <div>
            <input
              type={bnData?.data?.pbCompanyPercentage?.type}
              name={bnData?.data?.pbCompanyPercentage?.name}
              defaultValue={bnData?.data?.pbCompanyPercentage?.value}
              placeholder="Enter"
              onChange={(e) => props.setPbCompanyPercentage(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="line-separation layout-1"></div>

      <h3 id={bnData?.data && bnData?.data?.pbCompanyAuthorizedLabel?.id}>
        {bnData?.data && bnData?.data?.pbCompanyAuthorizedLabel?.label}
      </h3>
      <div className="flex">
        {bnData?.data &&
          bnData?.data?.pbCompanyAuthorizedFields?.map((item) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onPbCompanyAuthorizedFieldChange}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                dropdownType={item.dropdownType}
                validationType={item.validationType}
              />
            );
          })}
      </div>

      <div className="flex">
        {bnData?.data &&
          bnData?.data?.pbCompanyAddressFields?.map((item) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onPbCompanyAddressFieldChange}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                dropdownType={item.dropdownType}
              />
            );
          })}
      </div>
      <div className="line-separation layout-1"></div>

      <h3 id={bnData?.data && bnData?.data?.pbCompanyContactDetailsLabel?.id}>
        {bnData?.data && bnData?.data?.pbCompanyContactDetailsLabel?.label}
      </h3>
      <div className="flex">
        {bnData?.data &&
          bnData?.data?.pbCompanyContactDetailsFields?.map((item) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onPbCompanyContactDetailsFieldsChange}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                dropdownType={item.dropdownType}
              />
            );
          })}
      </div>
      <PhoneDetailsForm
        setAddPhoneList={props.setPbCompanyPhoneFields}
        addPhoneList={props.pbCompanyPhoneFields}
        hideDelete
      />
    </>
  );
};

export default PbCompanySection;
