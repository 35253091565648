import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { policyOwnerAction } from '../../../../actions/policyOwnerAction';
import FormField from '../../../ui/formField';

const BasicInfoSection = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(policyOwnerAction());
  }, [dispatch]);

  const fieldData = useSelector((state) => state.policyOwnerData);
  const { loading, error, poData } = fieldData;

  const firstName = poData?.data?.individualFirstName;
  const middleName = poData?.data?.individualMiddleName;
  const lastName = poData?.data?.individualLastName;
  const dob = poData?.data?.individualDOB;
  const ssn = poData?.data?.individualSSN;
  const basicInfoData = poData?.data?.individualNameSection?.slice(0, 7);

  return (
    <div>
      <h3>Basic Information</h3>

      <div className="flex">
        <FormField
          label={firstName?.label}
          id={firstName?.id}
          value={firstName?.value}
          type={firstName?.type}
          name={firstName?.name}
          placeholder={firstName?.placeholder}
          layoutType="layout-3"
          disabled
        />
        <FormField
          label={middleName?.label}
          id={middleName?.id}
          value={middleName?.value}
          type={middleName?.type}
          name={middleName?.name}
          placeholder={middleName?.placeholder}
          layoutType="layout-3"
          disabled
        />
        <FormField
          label={lastName?.label}
          id={lastName?.id}
          value={lastName?.value}
          type={lastName?.type}
          name={lastName?.name}
          placeholder={lastName?.placeholder}
          layoutType="layout-3"
          disabled
        />
        <FormField
          label={dob?.label}
          id={dob?.id}
          value={dob?.value}
          type={dob?.type}
          name={dob?.name}
          placeholder={dob?.placeholder}
          layoutType="layout-2"
          disabled
        />
        <FormField
          label={ssn?.label}
          id={ssn?.id}
          value={ssn?.value}
          type={ssn?.type}
          name={ssn?.name}
          placeholder={ssn?.placeholder}
          layoutType="layout-2"
          disabled
        />
      </div>
      {basicInfoData?.map((item, index) => {
        return (
          <FormField
            key={item.id}
            label={item.label}
            id={item.id}
            value={item.value}
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            min={item.min}
            max={item.max}
            layoutType={item.layoutType}
            selectOptions={item.selectOptions}
            regex={item?.regex}
            errorMessage={item?.errorMessage}
            validationType={item?.validationType}
            disabled
          />
        );
      })}
    </div>
  );
};

export default BasicInfoSection;
