import axios from '../../../axios/axios';
import { useEffect, useState } from 'react';
import logInImg from '../../../assets/images/logo-vertical.svg';
import './landingPage.css';

const LandingPage = (props) => {
  const [redirectUrl, setRedirectUrl] = useState('');
  const onLoginClick = () => {
    axios
      .get(`/getLoginUrl`)
      .then((response) => {
        setRedirectUrl(response?.data?.login_url);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };
  useEffect(() => {
    redirectUrl.length > 0 && window.location.replace(redirectUrl);
  }, [redirectUrl]);
  return (
    <div className="landing-container">
      <div className="login-container">
        <div className="login-image-container">
          <img src={logInImg} alt="login Image" />
        </div>
        <p className="welcome-heading">Welcome!</p>
        <p className="welcome-sub-heading">
          Login with your credentials to access your account.
        </p>
        <button onClick={onLoginClick} className="login-btn">
          Login
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
