import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormField from '../../../../ui/formField';
import CbParterDetails from './cbPartnerDetails';

const CbPartnershipSection = (props) => {
  const [cbPartnershipSectionFields, setCbPartnershipSectionFields] = useState(
    {}
  );
  const [cbPartnershipAddressFields, setCbPartnershipAddressFields] = useState(
    {}
  );
  const [
    cbPartnershipContactDetailsFields,
    setCbPartnershipContactDetailsFields,
  ] = useState({});
  const beneficiaryFieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = beneficiaryFieldData;

  const onCbPartnershipSectionFieldsChange = (event) => {
    setCbPartnershipSectionFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCbPartnershipAddressFieldsChange = (event) => {
    setCbPartnershipAddressFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCbPartnershipContactDetailsFieldsChange = (event) => {
    setCbPartnershipContactDetailsFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  useEffect(() => {
    props.setCbPartnershipData({
      cbPartnershipSectionFields,
      cbPartnershipAddressFields,
      cbPartnershipContactDetailsFields,
    });
  }, [
    cbPartnershipSectionFields,
    cbPartnershipAddressFields,
    cbPartnershipContactDetailsFields,
  ]);
  return (
    <>
      {bnData?.data && (
        <>
          <div className="flex">
            <div className="line-separation layout-1"></div>

            {bnData?.data &&
              bnData?.data?.cbPartnershipSectionFields?.map((item) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onCbPartnershipSectionFieldsChange}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item.validationType}
                  />
                );
              })}
            <div className="label-value-wrapper layout-2">
              <label className="field-label">
                {bnData?.data?.cbPartnershipTotalPercentage?.label}
              </label>
              <div>
                <input
                  type={bnData?.data?.cbPartnershipTotalPercentage?.type}
                  name={bnData?.data?.cbPartnershipTotalPercentage?.name}
                  defaultValue={
                    bnData?.data?.cbPartnershipTotalPercentage?.value
                  }
                  placeholder="Enter"
                  onChange={(e) =>
                    props.setCbPartnershipTotalPercentage(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="line-separation"></div>
          <h3 id={bnData?.data?.cbPartnershipAddressLabel?.id}>
            {bnData?.data?.cbPartnershipAddressLabel?.label}
          </h3>
          <div className="flex">
            {bnData?.data &&
              bnData?.data?.cbPartnershipAddressFields?.map((item) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onCbPartnershipAddressFieldsChange}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    dropdownType={item.dropdownType}
                  />
                );
              })}
          </div>
          <div className="line-separation"></div>
          <h3 id={bnData?.data?.cbPartnershipContactDetailsLabel?.id}>
            {bnData?.data?.cbPartnershipContactDetailsLabel?.label}
          </h3>
          <div className="flex">
            {bnData?.data &&
              bnData?.data?.cbPartnershipContactDetailsFields?.map((item) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onCbPartnershipContactDetailsFieldsChange}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    dropdownType={item.dropdownType}
                  />
                );
              })}
          </div>

          <CbParterDetails
            cbParterDetails={props.cbParterDetails}
            setCbParterDetails={props.setCbParterDetails}
            setCbParterPercentage={props.setCbParterPercentage}
            cbParterPercentage={props.cbParterPercentage}
          />
        </>
      )}
    </>
  );
};

export default CbPartnershipSection;
