import FormField from '../../ui/formField';
import LabelRadioButtons from '../../ui/labelRadioButtons/index.js';
import { useSelector } from 'react-redux';
const LegalName = (props) => {
  const piFormData = useSelector((state) => state.piData);
  const { loading, error, formData } = piFormData;

  const legalNameChangeHandler = (event) => {
    props.setshowLegalNameFields(event.target.value === 'Yes');
    props.setShowLegalNameValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onFieldChangeHandler = (event) => {
    props.setLegalSectionData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const LegalNameData = formData?.data?.legalNameLabel;

  return (
    <>
      <LabelRadioButtons
        label={LegalNameData.label}
        options={LegalNameData.radioOptions}
        id={LegalNameData.id}
        onChangeHandler={legalNameChangeHandler}
      />
      {props.showLegalNameFields &&
        formData?.data?.legalNameFields?.map((item) => {
          return (
            <>
              <FormField
                key={item.id}
                label={item.label}
                type={item.type}
                id={item.id}
                name={item.name}
                value={item.value}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onFieldChangeHandler}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                validationType={item?.validationType}
              />
            </>
          );
        })}
    </>
  );
};
export default LegalName;
