import FormField from '../../ui/formField';
import { useSelector } from 'react-redux';

const HowLongLiving = (props) => {
  const piFormData = useSelector((state) => state.piData);
  const { loading, error, formData } = piFormData;

  const HowLongLivingSectionHandler = (e) => {
    props.setLongHaveLivedFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const HowLongLivingSection = formData?.data?.longHaveLivedFields?.map(
    (item, index) => {
      return (
        <FormField
          key={index}
          id={item.id}
          label={item.label}
          type={item.type}
          value={item.value}
          layoutType={item.layoutType}
          name={item.name}
          onChangeHandler={HowLongLivingSectionHandler}
          selectOptions={item.selectOptions}
          regex={item?.regex}
          errorMessage={item?.errorMessage}
          validationType={item.validationType}
        />
      );
    }
  );
  return <>{HowLongLivingSection && HowLongLivingSection}</>;
};
export default HowLongLiving;
