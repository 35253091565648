import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentAction } from '../../../actions/agentAction';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import CommonFormHeader from '../../commonFormHeader';
import Loader from '../../loader';
import FormField from '../../ui/formField';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import LabelRadioOptions from '../../ui/labelRadioOptions/index.js';
import LabelRadioWithFields from '../../ui/labelRadioWithFields';
import ToastMessage from '../../ui/toastMessage';
import './agent.css';

const AgentQuestionnaire = () => {
  const pageData = useSelector((state) => state.agentData);
  const { loading, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(agentAction());
  }, [dispatch]);

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const [applicationCompletedRadioValue, setApplicationCompletedRadioValue] =
    useState({});
  const [applicationCompletedOtherValue, setApplicationCompletedOtherValue] =
    useState({});
  const howAppCompletedChange = (e) => {
    setApplicationCompletedRadioValue(() => ({
      [e.target.id]: e.target.value,
    }));
    if (e.target.value === 'Other') {
      setApplicationCompletedOtherValue({
        'aq-applicationCompletedOther': 'Docusign',
      });
    } else {
      setApplicationCompletedOtherValue({ 'aq-applicationCompletedOther': '' });
    }
  };

  const applicationCompletedChange = (e) => {
    setApplicationCompletedOtherValue(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  const [compOfAppValue, setCompOfAppValue] = useState({});
  const compOfAppChange = (e) => {
    setCompOfAppValue(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  const [relationAnnuitantPORadio, setRelationAnnuitantPoRadio] = useState({});
  const [relationAnnuitantPOValue, setrelationAnnuitantPOValue] = useState({});

  const relationAnnuitantPOChange = (e) => {
    setrelationAnnuitantPOValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const [deliveringPolicyQuesRadio, setDeliveringPolicyQuesRadio] = useState(
    {}
  );
  const onPolicyDeliveryChange = (e) => {
    setDeliveringPolicyQuesRadio(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const [deliveringPolicyYesValue, setDeliveringPolicyYesValue] = useState({});
  const deliveringPolicyYesChange = (e) => {
    setDeliveringPolicyYesValue(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  const [deliveringPolicyNoValue, setDeliveringPolicyNoValue] = useState({});
  const deliveringPolicyNoChange = (e) => {
    setDeliveringPolicyNoValue(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  const [agentInfoValues, setAgentInfoValues] = useState({});
  const agentInfoChangeHandler = (e) => {
    setAgentInfoValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [agentApproachPlaceQuesRadio, setAgentApproachPlaceQuesRadio] =
    useState({});
  const [agentApproachPlaceQuesValue, setAgentApproachPlaceQuesValue] =
    useState({});
  const agentApproachPlaceQuesDetailChange = (e) => {
    setAgentApproachPlaceQuesValue((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const [insuranceCompValue, setInsuranceCompValue] = useState({});
  const insuranceCompChange = (e) => {
    setInsuranceCompValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const appCompOtherCheck =
      formData?.data?.howApplicationCompleted?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (appCompOtherCheck) {
      const key = appCompOtherCheck['id'];
      const value = appCompOtherCheck['value'];
      setApplicationCompletedRadioValue({ [key]: value });
    }

    const deliveringPolicyQuesCheck =
      formData?.data?.deliveringPolicyQues?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (deliveringPolicyQuesCheck) {
      const key = deliveringPolicyQuesCheck['id'];
      const value = deliveringPolicyQuesCheck['value'];
      setDeliveringPolicyQuesRadio({ [key]: value });
    }

    setApplicationCompletedOtherValue({
      'aq-applicationCompletedOther':
        formData?.data?.applicationCompletedOther?.value,
    });
  }, [formData?.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    agentInfo: agentInfoValues,
    agentApproachPlaceQues: {
      radioOptions: agentApproachPlaceQuesRadio,
      provideDetails: agentApproachPlaceQuesValue,
    },
    whereInsuranceCompleted: insuranceCompValue,
    howApplicationCompleted: applicationCompletedRadioValue,
    applicationCompletedOther: applicationCompletedOtherValue,
    completionofApplication: compOfAppValue,
    relationAnnuitantPO: {
      radioOptions: relationAnnuitantPORadio,
      provideDetails: relationAnnuitantPOValue,
    },
    deliveringPolicyQues: deliveringPolicyQuesRadio,
    deliveringPolicyYesDetails: deliveringPolicyYesValue,
    deliveringPolicyNoDetails: deliveringPolicyNoValue,
  };

  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s26',
      },
    });
  }, [payload]);
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s26`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/dispositionofnetpremium"
        nextPath="/form/existingannuity"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId={formData?.common_details?.section_id}
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
        productType={formData?.common_details?.product_type}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          <div className="flex line-separation">
            {formData?.data?.agentInfo?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  onChangeHandler={agentInfoChangeHandler}
                />
              );
            })}
          </div>
          <div>
            {formData?.data?.agentApproachPlaceQues?.map((item, index) => {
              return (
                <div className="line-separation" id="index">
                  <LabelRadioWithFields
                    id={item.id}
                    label={item.label}
                    options={item.radioOptions}
                    provideDetails={item.provideDetails}
                    setRadioChangeValue={setAgentApproachPlaceQuesRadio}
                    onDetailsChangeHandler={agentApproachPlaceQuesDetailChange}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex line-separation whereInsuranceCompletedSection">
            {formData?.data?.whereInsuranceCompleted?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  onChangeHandler={insuranceCompChange}
                />
              );
            })}
          </div>
          <div className="line-separation">
            <LabelRadioOptions
              label={formData?.data?.howApplicationCompleted?.label}
              options={formData?.data?.howApplicationCompleted?.radioOptions}
              onChangeHandler={howAppCompletedChange}
            ></LabelRadioOptions>
            {applicationCompletedRadioValue[
              'aq-howApplicationCompleted-other'
            ] === 'Other' && (
              <FormField
                id={formData?.data?.applicationCompletedOther?.id}
                label={formData?.data?.applicationCompletedOther?.label}
                type={formData?.data?.applicationCompletedOther?.type}
                value={
                  applicationCompletedOtherValue['aq-applicationCompletedOther']
                }
                layoutType={
                  formData?.data?.applicationCompletedOther?.layoutType
                }
                name={formData?.data?.applicationCompletedOther?.name}
                onChangeHandler={applicationCompletedChange}
              />
            )}
          </div>
          <div className=" line-separation">
            {formData?.data?.completionofApplication?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  onChangeHandler={compOfAppChange}
                />
              );
            })}
          </div>
          <div className="line-separation">
            {formData?.data?.relationAnnuitantPO?.map((item, index) => {
              return (
                <LabelRadioWithFields
                  id={item.id}
                  label={item.label}
                  options={item.radioOptions}
                  provideDetails={item.provideDetails}
                  setRadioChangeValue={setRelationAnnuitantPoRadio}
                  onDetailsChangeHandler={relationAnnuitantPOChange}
                />
              );
            })}
          </div>
          <div className="line-separation">
            <LabelRadioButtons
              id={formData?.data?.deliveringPolicyQues?.id}
              label={formData?.data?.deliveringPolicyQues?.label}
              options={formData?.data?.deliveringPolicyQues?.radioOptions}
              onChangeHandler={onPolicyDeliveryChange}
            />
            {deliveringPolicyQuesRadio['aq-deliveringPolicy-yes'] === 'yes' && (
              <FormField
                id={formData?.data?.deliveringPolicyYesDetails?.id}
                label={formData?.data?.deliveringPolicyYesDetails?.label}
                type={formData?.data?.deliveringPolicyYesDetails?.type}
                value={formData?.data?.deliveringPolicyYesDetails?.value}
                layoutType={
                  formData?.data?.deliveringPolicyYesDetails?.layoutType
                }
                name={formData?.data?.deliveringPolicyYesDetails?.name}
                onChangeHandler={deliveringPolicyYesChange}
              />
            )}
            {deliveringPolicyQuesRadio['aq-deliveringPolicy-no'] === 'no' && (
              <FormField
                id={formData?.data?.deliveringPolicyNoDetails?.id}
                label={formData?.data?.deliveringPolicyNoDetails?.label}
                type={formData?.data?.deliveringPolicyNoDetails?.type}
                value={formData?.data?.deliveringPolicyNoDetails?.value}
                layoutType={
                  formData?.data?.deliveringPolicyNoDetails?.layoutType
                }
                name={formData?.data?.deliveringPolicyNoDetails?.name}
                onChangeHandler={deliveringPolicyNoChange}
              />
            )}
          </div>
        </FormFieldsWrapper>
      )}

      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default AgentQuestionnaire;
