import './errorToastMessage.css';
const ErrorToastMessage = (props) => {
  return (
    <div className="errorToastMessage">
      <span className="errorToastMessage-text">
        Total Percentage should not exceed 100%
      </span>
    </div>
  );
};

export default ErrorToastMessage;
