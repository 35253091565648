import React, { useEffect, useState } from 'react';
// import { HealthAndLifestyleJson } from "./../healthAndLifestyleJson";
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import FormField from '../../../ui/formField';
import './../healthAndLifestyle.css';
import { useSelector } from 'react-redux';
import deleteIcon from '../../../../assets/images/delete-icon.svg';
import LabelRadioWithFields from '../../../ui/labelRadioWithFields';

const AlcoholUseQuestions = (props) => {
  const hlFormData = useSelector((state) => state.hlData);
  const { loading, error, formData } = hlFormData;

  const defaultAlcoholCheck = 'yes';
  const [alcoholDetails, showAlcoholDetails] = useState(defaultAlcoholCheck);
  const [alcoholRemark, setAlcoholRemark] = useState(
    formData?.data?.alcoholUseRemark?.value
  );
  const additionalUseChangeHandler = (e) => {
    showAlcoholDetails(e.target.value.toLowerCase());
    props.setAlcoholUseQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const defaultCheckedQuestions =
    formData?.data?.alcoholUseQuestion.radioOptions.find(
      (element) =>
        element.defaultChecked &&
        element.value.toLowerCase() === defaultAlcoholCheck
    );

  useEffect(() => {
    showAlcoholDetails(defaultCheckedQuestions?.value?.toLowerCase());
  }, [formData?.data]);

  const handleChange = (i, e) => {
    let newFormValues = [...props.alcoholConsumptionData];
    newFormValues[i][e.target.name] = e.target.value;
    props.setAlcoholConsumptionData(newFormValues);
  };
  let addAlcoholConsumption = () => {
    props.setAlcoholConsumptionData([
      ...props.alcoholConsumptionData,
      { beverageType: '', amount: '', frequency: '', dateLastUsed: '' },
    ]);
  };

  let removeAlcoholConsumption = (i) => {
    let newFormValues = [...props.alcoholConsumptionData];
    newFormValues.splice(i, 1);
    props.setAlcoholConsumptionData(newFormValues);
  };

  const alcoholConsumptionField = (
    <div className="flex">
      {props.alcoholConsumptionData.length > 0 &&
        props.alcoholConsumptionData.map((element, index) => {
          return (
            <React.Fragment key={index}>
              <div className="flex" key={index}>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.beverageType ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Type of Beverage</label>
                  <div>
                    <input
                      type="text"
                      name="beverageType"
                      placeholder="Enter"
                      defaultValue={element.beverageType || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.amount ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Amount(No. of Glasses)</label>
                  <div>
                    <input
                      type="text"
                      name="amount"
                      placeholder="Enter"
                      defaultValue={element.amount || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper layout-4">
                  <label className="field-label">Frequency</label>
                  <div>
                    <select
                      defaultValue={element.frequency || ''}
                      name="frequency"
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="">Select</option>
                      <option value="Per Day">Per Day</option>
                      <option value="Per Week">Per Week</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                    </select>
                  </div>
                </div>
                <div className="label-value-wrapper no-margin-text-field layout-4">
                  <label className="field-label">
                    Date Last Used(Month/Year)
                  </label>

                  <FormField
                    type="month"
                    name="dateLastUsed"
                    placeholder="Enter"
                    value={element.dateLastUsed || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              {index ? (
                <span
                  onClick={() => removeAlcoholConsumption(index)}
                  className="icon-delete icon-center"
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="deleteIcon remarks-remove"
                  />
                </span>
              ) : null}
            </React.Fragment>
          );
        })}
    </div>
  );

  const alcoholRemarkChange = (e) => {
    setAlcoholRemark(e.target.value);
    props.setAlcoholRemarkData(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const alcoholUseLabel = formData?.data?.alcoholUselabel.label;

  const alcoholUseJson = formData?.data?.alcoholUseQuestion;

  const alcoholUseFields = (
    <LabelRadioButtons
      label={alcoholUseJson.label}
      options={alcoholUseJson.radioOptions}
      onChangeHandler={additionalUseChangeHandler}
    />
  );

  const alcoholConsumptionText = formData?.data?.alcoholConsumptionLabel;

  const alcoholUseRemark = formData?.data?.alcoholUseRemark;
  const alcoholUseRemarkField = (
    <>
      {alcoholUseRemark && (
        <div
          className={`label-value-wrapper field-remark-wrapper ${
            !alcoholRemark ? 'value--empty' : ''
          }`}
        >
          <label className="field-label">{alcoholUseRemark.label}</label>
          <textarea
            name={alcoholUseRemark.name}
            id={alcoholUseRemark.id}
            onChange={alcoholRemarkChange}
            defaultValue={alcoholUseRemark.value}
            placeholder="Enter"
          ></textarea>
        </div>
      )}
    </>
  );
  const onAlcoholOtherQuestionChange = (e) => {
    props.setAlcoholOtherQuestionsDetails((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const alcoholOtherQuestions = formData?.data?.alcoholOtherQuestions?.map(
    (item, index) => {
      return (
        <div key={index} className="line-separation">
          <LabelRadioWithFields
            key={index}
            label={item.label}
            options={item.radioOptions}
            provideDetails={item.provideDetails}
            setRadioChangeValue={props.setAlcoholOtherQuestionsRadioData}
            onDetailsChangeHandler={onAlcoholOtherQuestionChange}
          />
        </div>
      );
    }
  );
  return (
    <>
      <div className="line-separation">
        {alcoholUseLabel && <h3>{alcoholUseLabel}</h3>}
        {alcoholUseFields}

        {alcoholDetails === defaultAlcoholCheck &&
          props.alcoholConsumptionData.length > 0 && (
            <>
              {alcoholConsumptionText.label && (
                <h3>{alcoholConsumptionText.label}</h3>
              )}

              <div className="flex addMoreFields">
                {alcoholConsumptionField}
              </div>
              {props.alcoholConsumptionData.length > 0 && (
                <span
                  className="addFieldButton align-center"
                  onClick={() => addAlcoholConsumption()}
                >
                  Add More
                </span>
              )}
              {alcoholUseRemarkField && alcoholUseRemarkField}
            </>
          )}
      </div>
      {alcoholOtherQuestions && (
        <div className="line-separation">{alcoholOtherQuestions}</div>
      )}
    </>
  );
};

export default AlcoholUseQuestions;
