import './ApplicationFillout.css';
import CheckboxChecked from './../../../assets/images/icon-checked.svg';
import GeneratePDF from './../../../assets/images/icon-generatePDF.svg';
import { useEffect, useState } from 'react';
import { applicationFilloutAction } from '../../../actions/applicationFilloutAction';
import { useDispatch, useSelector } from 'react-redux';
import ApplicationFilloutFiltersSearch from './applicationFilloutFiltersSearch';
import ToastMessageIcon from '../../../assets/images/icon-toastMessage.svg';
import axios from '../../../axios/axios';
import Header from '../../header';
import Footer from '../../footer';
import moment from 'moment';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import BreadCrumb from '../../ui/breadCrumb';
import Loader from '../../loader';
const ApplicationFillout = (props) => {
  const [carrierData, setCarrierData] = useState([]);
  const [carrierSelected, setCarrierSelected] = useState([]);
  const [productSelected, setProductSelected] = useState([]);
  const [productFilterNames, setProductFilterNames] = useState([]);
  const [stateSelected, setStateSelected] = useState([]);
  const [stateFilterNames, setStateFilterNames] = useState([]);
  const [formNameList, setFormNameList] = useState([]);
  const [carrierString, setCarrierString] = useState('');
  const [productString, setProductString] = useState('');
  const [stateString, setStateString] = useState('');
  const [showPDFLoader, setShowPDFLoader] = useState(false);
  const [showPDFErrMsg, setShowPDFErrMsg] = useState(false);
  const [disableGeneratePDF, setDisableGeneratePDF] = useState(true);
  const [selectedColor, setSelectedColor] = useState('');
  const pageData = useSelector((state) => state.applicationFilloutData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(applicationFilloutAction());
  }, [dispatch]);
  const colorsOptions = [
    {
      label: 'Blue',
      value: 'blue',
    },
    {
      label: 'Black',
      value: 'black',
    },
  ];
  const onCarrierChange = (e) => {
    const { name, checked } = e.target;
    let tempCarrier = carrierData?.map((item) =>
      item.name.replace(/\s/g, '').toLowerCase() === name
        ? { ...item, isChecked: checked }
        : item
    );
    setCarrierData(tempCarrier);
    if (e.target.checked) {
      setCarrierSelected((prevState) => [...prevState, e.target.value]);
    } else {
      let checkedValuesArray = [...carrierSelected];
      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      setCarrierSelected(checkedValuesArray);
    }
  };
  const onProductChange = (e) => {
    if (e.target.checked) {
      if (!productSelected.includes(e.target.value))
        setProductSelected((prevState) => [...prevState, e.target.value]);
    } else {
      let checkedValuesArray = [...productSelected];
      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      setProductSelected(checkedValuesArray);
    }
  };
  const [hideStateSelection, setHideStateSelection] = useState(false);
  useEffect(() => {
    const shouldHide =
      carrierSelected.includes('Golconda Forms') ||
      carrierSelected.includes('Broker Dealer Forms');
    setHideStateSelection(shouldHide);
  }, [carrierSelected]);

  const onStateChange = (e) => {
    if (!hideStateSelection) {
      if (e.target.checked) {
        if (!stateSelected.includes(e.target.value))
          setStateSelected((prevState) => [...prevState, e.target.value]);
      } else {
        let checkedValuesArray = [...stateSelected];
        const unCheckedValue = (element) => element === e.target.value;
        const unCheckedValueIndex =
          checkedValuesArray.findIndex(unCheckedValue);
        checkedValuesArray.splice(unCheckedValueIndex, 1);
        setStateSelected(checkedValuesArray);
      }
    }
  };
  const onClearFilters = () => {
    setCarrierSelected([]);
    setProductSelected([]);
    setStateSelected([]);

    let tempCarrier = carrierData?.map((item) => {
      return { ...item, isChecked: false };
    });
    setCarrierData(tempCarrier);
  };

  const carriers = formData?.filter_data?.map((item) => item.carrier);
  const carriersFilter = [...new Set(carriers)];
  const carrierNames = carriersFilter?.map((item) => ({ name: item }));
  useEffect(() => {
    setCarrierData(carrierNames);
  }, [formData]);

  const productData = formData?.filter_data?.filter((item) => {
    return carrierSelected?.some((carrierName) => {
      return carrierName === item.carrier;
    });
  });
  const product = productData?.map((item) => item.product);
  const productFilter = [...new Set(product)];
  useEffect(() => {
    setProductFilterNames(productFilter);
    const newProductSelected = productSelected?.filter((item) => {
      return productFilterNames?.some((productFilter) => {
        return productFilter === item;
      });
    });
    setProductSelected(newProductSelected);
  }, [carrierSelected]);

  const stateData = formData?.filter_data?.filter((item) => {
    return (
      carrierSelected?.some((carrierName) => {
        return carrierName === item.carrier;
      }) &&
      productSelected?.some((productName) => {
        return productName === item.product;
      })
    );
  });
  const state = stateData?.map((item) => item.state);
  const stateFilter = [...new Set(state)];
  useEffect(() => {
    setStateFilterNames(stateFilter);
    const newStateSelected = stateSelected?.filter((item) => {
      return stateFilterNames?.some((stateFilter) => {
        return stateFilter === item;
      });
    });
    setStateSelected(newStateSelected);
  }, [carrierSelected, productSelected]);

  useEffect(() => {
    let pdfFormList = formData?.filter_data?.filter((formItem) => {
      const isCarrierMatch = carrierSelected?.includes(formItem.carrier);
      const isProductMatch = productSelected?.includes(formItem.product);
      if (
        carrierSelected.includes('Golconda Forms') ||
        carrierSelected.includes('Broker Dealer Forms')
      ) {
        return isCarrierMatch && isProductMatch;
      } else {
        const isStateMatch = stateSelected?.includes(formItem.state);
        return isCarrierMatch && isProductMatch && isStateMatch;
      }
    });

    pdfFormList = pdfFormList
      ? pdfFormList.sort(function (a, b) {
          var labelA = a.file_label.toUpperCase(); // ignore upper and lowercase
          var labelB = b.file_label.toUpperCase(); // ignore upper and lowercase
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          // labels must be equal
          return 0;
        })
      : [];
    setFormNameList(pdfFormList);
  }, [carrierSelected, productSelected, stateSelected]);

  const onPDFSelectHandler = (e) => {
    const { name, checked } = e.target;
    if (name === 'selectAllPDF') {
      let tempFormNameList = formNameList.map((item) => {
        return { ...item, isChecked: checked };
      });
      setFormNameList(tempFormNameList);
    } else {
      let tempFormNameList = formNameList.map((item) =>
        item.pdf_id === +name ? { ...item, isChecked: checked } : item
      );
      setFormNameList(tempFormNameList);
    }
  };

  const pdfSelectedList = formNameList?.filter((item) => item?.isChecked);
  const pdfSelectedId = pdfSelectedList?.map((item) => item?.pdf_id);
  useEffect(() => {
    if (pdfSelectedList?.length > 0 && showPDFLoader) {
      setDisableGeneratePDF(true);
    } else if (pdfSelectedList?.length > 0 && !showPDFLoader) {
      setDisableGeneratePDF(false);
    } else {
      setDisableGeneratePDF(true);
    }
  }, [pdfSelectedList]);

  const payload = {
    form_id: localStorage.getItem('formId'),
    pdf_id: pdfSelectedId,
    color: selectedColor ? selectedColor : 'blue',
  };

  const onGeneratePDF = () => {
    setShowPDFLoader(true);
    setDisableGeneratePDF(true);
    axios({
      url: 'generate-carrier-pdf',
      method: 'POST',
      data: payload,
      responseType: 'blob',
    })
      .then((response) => {
        let disposition = response.headers['content-disposition'];
        const filename = disposition.split('filename=')[1].replace(/["]+/g, '');

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setShowPDFLoader(false);
        setDisableGeneratePDF(false);
      })
      .catch(function (error) {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        setShowPDFErrMsg(true);
        setShowPDFLoader(false);
        setDisableGeneratePDF(false);
      });
  };
  const onClosePDFError = () => {
    setShowPDFErrMsg(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPDFErrMsg(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [showPDFErrMsg]);
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <div className="applicationFillout__wrapper ">
            <div className="applicationFillout_filters">
              <div className="applicationFillout_filtersHeader">
                <span className="filterHeading">FILTERS</span>
                <button
                  type="button"
                  className="clearAll"
                  onClick={onClearFilters}
                >
                  CLEAR ALL
                </button>
              </div>
              <div className="applicationFillout_filtersContent">
                <div className="applicationFillout_filtersCarrier">
                  <ApplicationFilloutFiltersSearch
                    heading="Carriers"
                    searchedString={carrierString}
                    setSearchedString={setCarrierString}
                  />

                  {carrierData?.filter((item) =>
                    carrierString?.length > 1
                      ? item.name
                          .toLowerCase()
                          .includes(carrierString.toLowerCase())
                      : item.name
                  )?.length < 1 && (
                    <p className="applicationFillout__carrierLabel">
                      {' '}
                      No record found
                    </p>
                  )}

                  <ul className="applicationFillout--filterItems">
                    {carrierData
                      ?.filter((item) =>
                        carrierString?.length > 1
                          ? item.name
                              .toLowerCase()
                              .includes(carrierString.toLowerCase())
                          : item.name
                      )
                      ?.sort((a, b) =>
                        a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
                      )
                      ?.map((item) => {
                        return (
                          <li
                            className="applicationFillout__carrierName"
                            key={`carrier-${item.name
                              .replace(/\s/g, '')
                              .toLowerCase()}`}
                          >
                            <div className="applicationFillout__checkbox">
                              <input
                                type="checkbox"
                                value={item.name}
                                name={item.name
                                  .replace(/\s/g, '')
                                  .toLowerCase()}
                                id={`carrier-${item.name
                                  .replace(/\s/g, '')
                                  .toLowerCase()}`}
                                onChange={onCarrierChange}
                                checked={item?.isChecked || false}
                                disabled={
                                  ((carrierSelected.includes(
                                    'Golconda Forms'
                                  ) ||
                                    carrierSelected.includes(
                                      'Broker Dealer Forms'
                                    )) &&
                                    item.name !== 'Golconda Forms' &&
                                    item.name !== 'Broker Dealer Forms') ||
                                  (carrierSelected.length > 0 &&
                                    !(
                                      carrierSelected.includes(
                                        'Golconda Forms'
                                      ) ||
                                      carrierSelected.includes(
                                        'Broker Dealer Forms'
                                      )
                                    ) &&
                                    (item.name == 'Golconda Forms' ||
                                      item.name == 'Broker Dealer Forms'))
                                }
                              />

                              <label
                                htmlFor={`carrier-${item.name
                                  .replace(/\s/g, '')
                                  .toLowerCase()}`}
                              >
                                <span className="applicationFillout__unchecked"></span>
                                <img
                                  src={CheckboxChecked}
                                  alt="checked"
                                  className="applicationFillout__checked"
                                />
                              </label>
                            </div>
                            <div className="applicationFillout__carrierLabel">
                              {item.name}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {carrierSelected.length > 0 && (
                  <div className="applicationFillout_filtersProduct">
                    <ApplicationFilloutFiltersSearch
                      heading="Product"
                      searchedString={productString}
                      setSearchedString={setProductString}
                    />
                    {productFilter?.filter((item) =>
                      productString?.length > 1
                        ? item
                            .toLowerCase()
                            .includes(productString.toLowerCase())
                        : item
                    )?.length < 1 && (
                      <p className="applicationFillout__carrierLabel">
                        {' '}
                        No record found
                      </p>
                    )}
                    <ul className="applicationFillout--filterItems">
                      {productFilter
                        ?.filter((item) =>
                          productString?.length > 1
                            ? item
                                .toLowerCase()
                                .includes(productString.toLowerCase())
                            : item
                        )
                        ?.sort()
                        ?.map((item) => {
                          return (
                            <li
                              className="applicationFillout__carrierName"
                              key={`product-${item
                                .replace(/\s/g, '')
                                .toLowerCase()}`}
                            >
                              <div className="applicationFillout__checkbox">
                                <input
                                  type="checkbox"
                                  value={item}
                                  name={`product-${item
                                    .replace(/\s/g, '')
                                    .toLowerCase()}`}
                                  id={`product-${item
                                    .replace(/\s/g, '')
                                    .toLowerCase()}`}
                                  onChange={onProductChange}
                                />
                                <label
                                  htmlFor={`product-${item
                                    .replace(/\s/g, '')
                                    .toLowerCase()}`}
                                >
                                  <span className="applicationFillout__unchecked"></span>
                                  <img
                                    src={CheckboxChecked}
                                    alt="checked"
                                    className="applicationFillout__checked"
                                  />
                                </label>
                              </div>
                              <div className="applicationFillout__carrierLabel">
                                {item}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}

                {!hideStateSelection &&
                  carrierSelected.length > 0 &&
                  productSelected.length > 0 && (
                    <div className="applicationFillout_filtersState">
                      <ApplicationFilloutFiltersSearch
                        heading="State"
                        searchedString={stateString}
                        setSearchedString={setStateString}
                      />
                      {stateFilter?.filter((item) =>
                        stateString?.length > 1
                          ? item
                              .toLowerCase()
                              .includes(stateString.toLowerCase())
                          : item
                      )?.length < 1 && (
                        <p className="applicationFillout__carrierLabel">
                          {' '}
                          No record found
                        </p>
                      )}
                      <ul className="applicationFillout--filterItems">
                        {stateFilter
                          ?.filter((item) =>
                            stateString?.length > 1
                              ? item
                                  .toLowerCase()
                                  .includes(stateString.toLowerCase())
                              : item
                          )
                          ?.sort()
                          ?.map((item, index) => {
                            return (
                              <li
                                className="applicationFillout__carrierName"
                                key={`state-${item
                                  .replace(/\s/g, '')
                                  .toLowerCase()}`}
                              >
                                <div className="applicationFillout__checkbox">
                                  <input
                                    type="checkbox"
                                    value={item}
                                    name={`state-${item
                                      .replace(/\s/g, '')
                                      .toLowerCase()}`}
                                    id={`state-${item
                                      .replace(/\s/g, '')
                                      .toLowerCase()}`}
                                    onChange={onStateChange}
                                  />
                                  <label
                                    htmlFor={`state-${item
                                      .replace(/\s/g, '')
                                      .toLowerCase()}`}
                                  >
                                    <span className="applicationFillout__unchecked"></span>
                                    <img
                                      src={CheckboxChecked}
                                      alt="checked"
                                      className="applicationFillout__checked"
                                    />
                                  </label>
                                </div>
                                <div className="applicationFillout__carrierLabel">
                                  {item}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
            <div className="applicationFillout__content">
              <BreadCrumb
                pageTitle="Application fill out"
                showCaseDetails={true}
                productType={formData?.product_type}
              />
              <div className="applicationFillout__header flex">
                <div className="heading-icon-wrapper">
                  <h2 className="applicationFillout__heading">
                    Application fill out
                  </h2>
                </div>
                <div className="flex color-btn-container">
                  <p className="color-text">Select PDF Color</p>
                  <div>
                    <div className="color-select-container">
                      <select
                        className="color-select"
                        name="title"
                        id="title"
                        defaultValue={selectedColor}
                        onChange={(e) => setSelectedColor(e.target.value)}
                      >
                        {colorsOptions.map((item, index) => {
                          return (
                            <option value={item.value} key={index}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <button
                    className="button button--generatePDF"
                    onClick={onGeneratePDF}
                    disabled={disableGeneratePDF}
                  >
                    <img
                      src={GeneratePDF}
                      alt="icon"
                      className="icon--generatePDF"
                    />
                    Generate PDF
                  </button>
                </div>
              </div>
              <div className="applicationFillout__caseInfoData flex">
                <div className="applicationFillout__caseInfo">
                  <p className="applicationFillout__caseID">
                    <span className="caseLabel">Case Id:&nbsp;</span>
                    <span className="caseID">
                      {formData?.form_details?.smartoffice_case_id}
                    </span>
                  </p>
                </div>
                <div className="applicationFillout__caseDate">
                  <p>
                    Date Opened:&nbsp;
                    <span className="caseDate">
                      {moment(
                        new Date(formData?.form_details?.created_at)
                      ).format('MM/DD/YYYY')}
                    </span>
                  </p>
                  <p>
                    Last Updated:&nbsp;
                    <span className="caseDate">
                      {moment(
                        new Date(formData?.form_details?.updated_at)
                      ).format('MM/DD/YYYY')}
                    </span>
                  </p>
                </div>
              </div>
              {formNameList?.length < 1 && (
                <p className="applicationFillout__noFilterData">
                  Please select Carriers, Product and State from filters
                </p>
              )}

              {formNameList?.length > 0 && (
                <div className="applicationFillout__formInfo ">
                  <div className="applicationFillout__formInfoHeader">
                    <div className="applicationFillout__nameHeader">
                      <div className="applicationFillout__selectAllPDF">
                        <div className="applicationFillout__checkbox">
                          <input
                            type="checkbox"
                            name="selectAllPDF"
                            id="selectAllPDF"
                            checked={
                              formNameList?.filter(
                                (item) => item?.isChecked !== true
                              ).length < 1
                            }
                            onChange={onPDFSelectHandler}
                          />
                          <label htmlFor="selectAllPDF">
                            <span className="applicationFillout__unchecked"></span>
                            <img
                              src={CheckboxChecked}
                              alt="checked"
                              className="applicationFillout__checked"
                            />
                          </label>
                        </div>
                      </div>
                      Form Name
                    </div>
                    <div className="applicationFillout__carriersHeader">
                      Insurance Carriers
                    </div>
                    <div className="applicationFillout__stateHeader">State</div>
                    <div className="applicationFillout__productHeader">
                      Product
                    </div>
                  </div>

                  <ul className="applicationFillout__formContentWrapper">
                    {formNameList?.map((item, index) => {
                      return (
                        <li
                          className="applicationFillout__formContent"
                          key={item.pdf_id}
                        >
                          <div className="applicationFillout__formName">
                            <div className="applicationFillout__checkbox">
                              <input
                                type="checkbox"
                                value={item.pdf_id}
                                name={item.pdf_id}
                                id={item.pdf_id}
                                checked={item?.isChecked || false}
                                onChange={onPDFSelectHandler}
                              />
                              <label htmlFor={item.pdf_id}>
                                <span className="applicationFillout__unchecked"></span>
                                <img
                                  src={CheckboxChecked}
                                  alt="checked"
                                  className="applicationFillout__checked"
                                />
                              </label>
                            </div>
                            <div className="applicationFillout__tableLabel">
                              {item.file_label}
                            </div>
                          </div>
                          <div className="applicationFillout__formCarrier">
                            <span className="applicationFillout__tableLabel">
                              {' '}
                              {item.carrier}
                            </span>
                          </div>
                          <div className="applicationFillout__formState">
                            <span className="applicationFillout__tableLabel">
                              {item.state}
                            </span>
                          </div>
                          <div className="applicationFillout__formProduct">
                            <span className="applicationFillout__tableLabel">
                              {item.product}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {showPDFLoader && (
                    <div className="applicationFillout__loaderWrapper">
                      <span className="applicationFillout__loader"></span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {showPDFErrMsg && (
            <div className="applicationFillout__toastMessage toastMessage">
              <img
                className="toastmessage-icon"
                src={ToastMessageIcon}
                alt="Toast message Icon"
              />
              <span className="toastMessage-text">
                Unexpected error!! Please contact administrator
              </span>
              <span className="toastMessage-close" onClick={onClosePDFError}>
                X
              </span>
            </div>
          )}
        </>
      )}

      <Footer />
    </>
  );
};
export default ApplicationFillout;
