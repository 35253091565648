// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-label {
  color: #333333;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 12px;
  margin-bottom:18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/aviationQuestionnaire/questionSection/QuestionSection.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".question-label {\n  color: #333333;\n  margin-bottom: 5px;\n  font-weight: 600;\n  font-size: 12px;\n  margin-bottom:18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
