import './CommonLabelRadioButton.css';
import PropTypes from 'prop-types';

const CommonLabelRadioButtons = (props) => {
  const radioButtonOptions = props.options;
  const radioButtonFields =
    radioButtonOptions &&
    radioButtonOptions.map((item) => {
      return (
        <div className="common-radio-button" key={item.id}>
          <input
            type={item.type}
            value={item.value}
            name={item.name}
            id={item.id}
            className="radio"
            defaultChecked={item.defaultChecked}
          />
          <label htmlFor={item.id} className="common-radio-label">
            {item.label}
          </label>
        </div>
      );
    });
  return (
    <>
      <div className="common-label-radioButtons-wrapper">
        {props.options && (
          <div
            className="common-radio-buttons-wrapper"
            onChange={props.onChangeHandler}
          >
            {radioButtonFields}
          </div>
        )}
      </div>
    </>
  );
};
export default CommonLabelRadioButtons;

CommonLabelRadioButtons.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
};
