import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../footer';
import Header from '../../header';
import './applicationsAvailable.css';
import { applicationAvailableAction } from '../../../actions/applicationAvailableAction';
import { useEffect } from 'react';
import Loader from '../../loader';
import BreadCrumb from '../../ui/breadCrumb';
const ApplicationsAvailable = () => {
  const pageData = useSelector((state) => state.applicationAvailabeData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(applicationAvailableAction());
  }, [dispatch]);

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="applicationAvailable--wrapper">
          <BreadCrumb pageTitle="Application Available" />

          <div className="applicationAvailable--headingWrapper">
            <h2>Applications</h2>
          </div>
          <div className="applicationAvailable--contentsWrapper">
            <div className="applicationAvailable--contents applicationAvailable--separator">
              <h3 className="applicationAvailable--contentHeading">
                <span className="applicationAvailable--availableText">
                  {' '}
                  Applications Available ({formData?.available?.length})
                </span>
              </h3>
              <div className="applicationAvailable--table">
                <div className="applicationAvailable--tableHeader">
                  <div className="applicationAvailable--tableHeading">
                    CARRIER
                  </div>
                  <div className="applicationAvailable--tableHeading">
                    PRODUCT TYPE
                  </div>
                  <div className="applicationAvailable--tableHeading">
                    STATE
                  </div>
                </div>
                <div className="applicationAvailable--tableContentWrapper">
                  {formData?.available?.map((item, index) => {
                    return (
                      <div
                        className="applicationAvailable--tableRow"
                        key={index}
                      >
                        <div className="applicationAvailable--tableContent">
                          {item.carrier}
                        </div>
                        <div className="applicationAvailable--tableContent">
                          {item.product}
                        </div>
                        <div className="applicationAvailable--tableContent">
                          {item.state.join(', ')}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="applicationAvailable--contents">
              <h3 className="applicationAvailable--contentHeading">
                <span className="applicationAvailable--pipelineText">
                  {' '}
                  Applications in Pipeline ({formData?.in_pipeline?.length})
                </span>
              </h3>
              <div className="applicationAvailable--table">
                <div className="applicationAvailable--tableHeader">
                  <div className="applicationAvailable--tableHeading">
                    CARRIER
                  </div>
                  <div className="applicationAvailable--tableHeading">
                    PRODUCT TYPE
                  </div>
                  <div className="applicationAvailable--tableHeading">
                    STATE
                  </div>
                </div>

                <div className="applicationAvailable--tableContentWrapper">
                  {formData?.in_pipeline?.map((item, index) => {
                    return (
                      <div
                        className="applicationAvailable--tableRow"
                        key={index}
                      >
                        <div className="applicationAvailable--tableContent">
                          {item.carrier}
                        </div>
                        <div className="applicationAvailable--tableContent">
                          {item.product}
                        </div>
                        <div className="applicationAvailable--tableContent">
                          {item.state}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};
export default ApplicationsAvailable;
