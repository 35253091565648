// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.space-left {
  padding-left: 30px;
}
.ltc-checkboxes-container {
  margin: 15px 0px;
}
.ltc-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/longTermCare/longTermCare.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".space-left {\n  padding-left: 30px;\n}\n.ltc-checkboxes-container {\n  margin: 15px 0px;\n}\n.ltc-label {\n  font-size: 12px;\n  font-weight: 600;\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
