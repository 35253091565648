import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';
import FormField from '../../../ui/formField';
import '../foreginTravel.css';
const PlanSection = (props) => {
  const [planLabel, setPlanLabel] = useState({});

  const foreignTravelFieldData = useSelector((state) => state.ftFieldData);
  const { loading, error, ftData } = foreignTravelFieldData;

  useEffect(() => {
    setPlanLabel(ftData?.data?.planLabel);
  }, [ftData?.data]);

  let handleChange = (i, e) => {
    let newFormValues = [...props.planFormData];
    newFormValues[i][e.target.name.slice(0, -1)] = e.target.value;
    props.setPlanFormData(newFormValues);
  };

  let addFormFields = () => {
    props.setPlanFormData([
      ...props.planFormData,
      { destination: '', date: '', duration: '', planPurpose: '' },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...props.planFormData];
    newFormValues.splice(i, 1);
    props.setPlanFormData(newFormValues);
  };

  return (
    <>
      {props.planFormData.length > 0 &&
        props.planFormData.map((element, index) => {
          return (
            <AccordionAddMoreSection
              key={index}
              index={index}
              accordionHeading={`Travel Information - ${index + 1}`}
              onDeleteClick={index ? () => removeFormFields(index) : null}
            >
              <div className="flex" key={index}>
                <div
                  className={`label-value-wrapper destination-layout ${
                    !element.destination ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{planLabel.destination}</label>
                  <div>
                    <input
                      type="text"
                      id="destination"
                      placeholder="Enter"
                      name="destination."
                      value={element.destination || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper no-margin-text-field layout-4">
                  <label className="field-label">{planLabel.date}</label>

                  <FormField
                    type="month"
                    name="date."
                    min="1900-01-01"
                    placeholder="Enter"
                    value={element.date || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-5 ${
                    !element.duration ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{planLabel.duration}</label>

                  <div>
                    <input
                      type="text"
                      name="duration."
                      id="duration"
                      placeholder="Enter"
                      value={element.duration || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper purpose-layout">
                  <label className="field-label">{planLabel.purpose}</label>

                  <div>
                    <div
                      className="travel-radio-container"
                      onChange={(e) => handleChange(index, e)}
                    >
                      <div className="ft-radio-container">
                        <input
                          type="radio"
                          id={`planBusiness${index}`}
                          name={`planPurpose${index}`}
                          value="business"
                          checked={element.planPurpose === 'business'}
                        />
                        <label
                          htmlFor={`planBusiness${index}`}
                          className="travel-radio-label"
                        >
                          {planLabel.business}
                        </label>
                      </div>
                      <div className="ft-radio-container">
                        <input
                          type="radio"
                          id={`planPersonal${index}`}
                          name={`planPurpose${index}`}
                          value="personal"
                          checked={element.planPurpose === 'personal'}
                        />
                        <label
                          htmlFor={`planPersonal${index}`}
                          className="travel-radio-label"
                        >
                          {planLabel.personal}
                        </label>
                      </div>
                      <div className="ft-radio-container">
                        <input
                          type="radio"
                          id={`planBoth${index}`}
                          name={`planPurpose${index}`}
                          value="both"
                          checked={element.planPurpose === 'both'}
                        />
                        <label
                          htmlFor={`planBoth${index}`}
                          className="travel-radio-label"
                        >
                          {planLabel.both}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-1 field-remark-wrapper ${
                    !element.remarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{planLabel.remarks}</label>
                  <textarea
                    name="remarks."
                    placeholder="Enter"
                    id="remarks"
                    value={element.remarks || ''}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
                <div className="line-separation layout-1"></div>
              </div>
            </AccordionAddMoreSection>
          );
        })}
      <div>
        <span className="addFieldButton" onClick={() => addFormFields()}>
          {planLabel.addMore}
        </span>
      </div>
    </>
  );
};

export default PlanSection;
