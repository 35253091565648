import './FormField.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  SET_FORM_FIELD_ERROR,
  REMOVE_FORM_FIELD_ERROR,
} from '../../../constants/formValidationConstants';

const InputText = (props) => {
  const dispatch = useDispatch();
  const validateSSN = 'ssn';
  const addMoreText = 'addMoreText';
  const validateTaxId = 'company_taxIdNumber';
  const validateFinancialValue = 'financialValue';
  const [fieldValue, setFieldValue] = useState(props?.fieldData?.value);
  const [errorMessage, setErrorMessage] = useState('');
  const [ssnValue, setSSNValue] = useState(
    props.fieldData.validationType?.toLowerCase() === validateSSN
      ? props.fieldData.value
      : ''
  );
  const [taxIdValue, setTaxIdValue] = useState(
    props.fieldData.validationType === validateTaxId
      ? props.fieldData.value
      : ''
  );

  const [financialValue, setFinancialValue] = useState(
    props.fieldData.validationType === validateFinancialValue
      ? props.fieldData.value
      : ''
  );

  const addSSNSeparator = (e) => {
    setSSNValue(e.target.value);

    let val = e.target.value.replace(/\D/g, '');
    let newVal = '';
    if (val.length > 4) {
      e.target.value = val;
    }
    if (val.length > 3 && val.length < 6) {
      newVal += val.substr(0, 3) + '-';
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + '-';
      newVal += val.substr(3, 2) + '-';
      val = val.substr(5);
    }
    newVal += val;
    e.target.value = newVal;

    setSSNValue(e.target.value);
  };
  const taxIdSeparator = (value) => {
    if (value) {
      let val = value;
      var newVal = '';

      if (val.length === 3) {
        if (val[2] !== '-') {
          newVal = val?.substr(0, 2);
          val = val?.substr(2);
          value = newVal + '-' + val;
        } else {
          value = val?.substr(0, 2);
        }
      }
      return value;
    }
  };
  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };
  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };

  const handleChange = (e) => {
    props.fieldData.onChangeHandler(e);
    setFieldValue(e.target.value);

    switch (props.fieldData.validationType) {
      case validateSSN:
        addSSNSeparator(e);
        break;
      case validateTaxId:
        setTaxIdValue(taxIdSeparator(e.target.value));
        break;
      case validateFinancialValue:
        setFinancialValue(removeCommaSeparator(e.target.value));
        break;
      default: //do nothing
    }

    if (props.fieldData?.regex) {
      if (e.target.value) {
        const regexStr =
          props?.fieldData?.validationType === 'name'
            ? /^[a-zA-Z0-9 ,.'-]+$/
            : new RegExp(props.fieldData?.regex);
        const regexMatch = regexStr?.test(e.target.value);
        if (regexMatch) {
          setErrorMessage('');
          dispatch({
            type: REMOVE_FORM_FIELD_ERROR,
            payload: props.fieldData.id,
          });
        } else {
          setErrorMessage(props.fieldData.errorMessage);
          dispatch({
            type: SET_FORM_FIELD_ERROR,
            payload: props.fieldData.id,
          });
        }
      } else {
        setErrorMessage('');
        dispatch({
          type: REMOVE_FORM_FIELD_ERROR,
          payload: props.fieldData.id,
        });
      }
    }
  };

  let inputText = <></>;
  switch (props.fieldData.validationType) {
    case validateSSN:
      inputText = (
        <input
          type={props.fieldData.type}
          id={props.fieldData.id}
          name={props.fieldData.name}
          value={ssnValue}
          maxLength="11"
          placeholder={props.fieldData.placeholder || 'Enter'}
          onChange={handleChange}
          disabled={props.fieldData.disabled}
          className={errorMessage.trim().length > 0 ? 'error-border' : ''}
        />
      );
      break;
    case validateTaxId:
      inputText = (
        <input
          type={props.fieldData.type}
          id={props.fieldData.id}
          name={props.fieldData.name}
          value={taxIdValue}
          maxLength="10"
          placeholder={props.fieldData.placeholder || 'Enter'}
          onChange={handleChange}
          disabled={props.fieldData.disabled}
        />
      );
      break;
    case addMoreText:
      inputText = (
        <input
          type={props.fieldData.type}
          id={props.fieldData.id}
          name={props.fieldData.name}
          value={props.fieldData.value}
          placeholder={props.fieldData.placeholder || 'Enter'}
          onChange={handleChange}
          disabled={props.fieldData.disabled}
          className={errorMessage.trim().length > 0 ? 'error-border' : ''}
        />
      );
      break;
    case validateFinancialValue:
      inputText = (
        <input
          type={props.fieldData.type}
          id={props.fieldData.id}
          name={props.fieldData.name}
          value={addCommaSeparator(financialValue)}
          placeholder={props.fieldData.placeholder || 'Enter'}
          onChange={handleChange}
          disabled={props.fieldData.disabled}
          className="financialValue"
          data-validationtype={validateFinancialValue}
        />
      );
      break;
    default:
      <>
        {props.fieldData.addMoreField
          ? (inputText = (
              <input
                type={props.fieldData.type}
                id={props.fieldData.id}
                name={props.fieldData.name}
                value={props.fieldData.value}
                placeholder={props.fieldData.placeholder || 'Enter'}
                onChange={handleChange}
                disabled={props.fieldData.disabled}
                className={errorMessage.trim().length > 0 ? 'error-border' : ''}
              />
            ))
          : (inputText = (
              <input
                type={props.fieldData.type}
                id={props.fieldData.id}
                name={props.fieldData.name}
                defaultValue={props.fieldData.value}
                placeholder={props.fieldData.placeholder || 'Enter'}
                onChange={handleChange}
                disabled={props.fieldData.disabled}
                className={errorMessage.trim().length > 0 ? 'error-border' : ''}
              />
            ))}
      </>;
  }

  return (
    <div
      className={`label-value-wrapper ${
        props.fieldData.layoutType ? props.fieldData.layoutType : ''
      } ${!fieldValue ? 'value--empty' : ''}`}
      key={props.fieldData.id}
    >
      {props.fieldData.label && (
        <label
          className={`field-label ${
            props.fieldData.hideLabel ? 'hide-label' : ' '
          }`}
          htmlFor={props.fieldData.id}
        >
          {props.fieldData.label}
        </label>
      )}
      <div>{inputText}</div>
      {errorMessage.trim().length > 0 && (
        <div className="errorMessage">{errorMessage}</div>
      )}
    </div>
  );
};
export default InputText;
InputText.propTypes = {
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
