import { useState } from 'react';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import AlcoholUseQuestions from './alcoholUseQuestions';
import TobaccoNicotineQuestions from './tobaccoNicotineQuestions';
import FollowUpQuestions from './followUpQuestions';
import AdditionalQuestions from './additionalQuestions';
import { useSelector, useDispatch } from 'react-redux';
import { healthLifeStyleAction } from '../../../actions/healthLifeStyleAction';
import { useEffect } from 'react';
import Loader from '../../loader';
import axios from '../../../axios/axios';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import HeightWeightSection from '../../ui/heightWeightSection';
import BloodPressureSection from './bloodPressureSection';
import CholesterolSection from './cholesterolSection';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const HealthAndLifestyle = (props) => {
  const [heightValue, setHeightValue] = useState('');
  const [feetValue, setFeetValue] = useState('');
  const [inchesValue, setInchesValue] = useState('');
  const [wightValue, setWeightValue] = useState('');
  const [bloodPressureValue, setBloodPressureValue] = useState([]);
  const [cholesterolValue, setCholestrolValue] = useState([]);

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [alcoholUseQuestionData, setAlcoholUseQuestion] = useState({});

  const [alcoholRemarkData, setAlcoholRemarkData] = useState({});
  const [alcoholOtherQuestionsRadioData, setAlcoholOtherQuestionsRadioData] =
    useState({});
  const [alcoholOtherQuestionsDetails, setAlcoholOtherQuestionsDetails] =
    useState({});
  const [marijuanaFieldData, setMarijuanaFieldData] = useState({});

  const [tobaccoNicotineFieldData, setTobaccoNicotineFieldData] = useState({});
  const [tobaccoNicotineRemarkData, setTobaccoNicotineRemarkData] = useState(
    {}
  );

  const [otherQuestionsDetailsData, setOtherQuestionsDetailsData] = useState(
    {}
  );
  const [followUpRadioData, setFollowUpRadioData] = useState({});
  const [followUpDetailsData, setFollowUpDetailsData] = useState({});
  const [additionalQuestionRadioData, setAdditionalQuestionRadioData] =
    useState({});
  const [additionalQuestionDetailsData, setAdditionalQuestionDetailsData] =
    useState({});

  const hlFormData = useSelector((state) => state.hlData);
  const { loading, error, formData } = hlFormData;
  const dispatch = useDispatch();
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };

  const [alcoholConsumptionData, setAlcoholConsumptionData] = useState([]);
  useEffect(() => {
    if (formData?.data?.alcoholConsumptionQuestion !== undefined) {
      setAlcoholConsumptionData(formData?.data?.alcoholConsumptionQuestion);
    }
  }, [formData?.data]);

  const [tobaccoNicotineConsumptionData, setTobaccoNicotineConsumptionData] =
    useState([]);
  useEffect(() => {
    if (formData?.data?.tobaccoNicotineConsumption !== undefined) {
      setTobaccoNicotineConsumptionData(
        formData?.data?.tobaccoNicotineConsumption
      );
    }
    setWeightValue(formData?.data?.weightSectionFields?.value);
  }, [formData?.data]);

  useEffect(() => {
    dispatch(healthLifeStyleAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    alcoholUseQuestion: alcoholUseQuestionData,
    alcoholConsumptionQuestion: alcoholConsumptionData,
    alcoholUseRemark: alcoholRemarkData,
    alcoholOtherQuestions: {
      radioOptions: alcoholOtherQuestionsRadioData,
      provideDetails: alcoholOtherQuestionsDetails,
    },
    marijuanaField: marijuanaFieldData,
    tobacco_nocotine_field: tobaccoNicotineFieldData,
    tobaccoNicotineConsumption: tobaccoNicotineConsumptionData,
    tobaccoNicotineUseRemark: tobaccoNicotineRemarkData,

    followUpQuestions: {
      radioOptions: followUpRadioData,
      provideDetails: followUpDetailsData,
    },
    additionalQuestions: {
      radioOptions: additionalQuestionRadioData,
      provideDetails: additionalQuestionDetailsData,
    },
    feetSelectValue: { value: feetValue },
    inchesSelectValue: { value: inchesValue },
    cmFieldValue: { value: heightValue },
    weightSectionFields: { 'hal-wight': wightValue },
    bloodPressureField: bloodPressureValue,
    totalCholesterolField: cholesterolValue,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s7',
      },
    });
  }, [payload]);

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(`/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s7`, payload)
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };

  return (
    <>
      <CommonFormHeader
        backPath="/form/physicianinformation"
        nextPath="/form/exercise"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s7"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {formData?.data && (
            <HeightWeightSection
              heightValue={heightValue}
              setHeightValue={setHeightValue}
              wightValue={wightValue}
              setWeightValue={setWeightValue}
              setFeetValue={setFeetValue}
              feetValue={feetValue}
              setInchesValue={setInchesValue}
              inchesValue={inchesValue}
              apiData={formData}
            />
          )}
          <BloodPressureSection setBloodPressureValue={setBloodPressureValue} />
          <CholesterolSection setCholestrolValue={setCholestrolValue} />
          <div className="line-separation layout-1"></div>
          {formData?.data && (
            <>
              <div className="healthAndLifeStyle">
                <AlcoholUseQuestions
                  setAlcoholUseQuestion={setAlcoholUseQuestion}
                  setAlcoholConsumptionData={setAlcoholConsumptionData}
                  alcoholConsumptionData={alcoholConsumptionData}
                  setAlcoholRemarkData={setAlcoholRemarkData}
                  setAlcoholOtherQuestionsRadioData={
                    setAlcoholOtherQuestionsRadioData
                  }
                  setAlcoholOtherQuestionsDetails={
                    setAlcoholOtherQuestionsDetails
                  }
                />
                <TobaccoNicotineQuestions
                  setMarijuanaFieldData={setMarijuanaFieldData}
                  setTobaccoNicotineFieldData={setTobaccoNicotineFieldData}
                  tobaccoNicotineConsumptionData={
                    tobaccoNicotineConsumptionData
                  }
                  setTobaccoNicotineConsumptionData={
                    setTobaccoNicotineConsumptionData
                  }
                  setTobaccoNicotineRemarkData={setTobaccoNicotineRemarkData}
                />

                <FollowUpQuestions
                  setFollowUpRadioData={setFollowUpRadioData}
                  setFollowUpDetailsData={setFollowUpDetailsData}
                />
                <AdditionalQuestions
                  setAdditionalQuestionRadioData={
                    setAdditionalQuestionRadioData
                  }
                  setAdditionalQuestionDetailsData={
                    setAdditionalQuestionDetailsData
                  }
                />
              </div>
            </>
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default HealthAndLifestyle;
