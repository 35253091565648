import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import ExerciseCheckBoxes from './exerciseCheckBoxes';
import { exerciseAction } from '../../../actions/exerciseAction';
import axios from '../../../axios/axios';
import Loader from '../../loader';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
const Exercise = () => {
  const dispatch = useDispatch();
  const exerciseFieldData = useSelector((state) => state.exerciseData);
  const { loading, error, exData } = exerciseFieldData;

  useEffect(() => {
    dispatch(exerciseAction());
  }, [dispatch]);

  const exerciseQuestionField = exData?.data?.exerciseQuestionSection;
  const [exerciseQuestionValue, setExerciseQuestionValue] = useState({});
  const [checkboxValue, setCheckBoxValue] = useState([]);
  const [otherValue, setOtherValue] = useState('');
  const [remarksValue, setRemarksValue] = useState('');
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  useEffect(() => {
    let checkboxDefaltValuearr = [];
    exData?.data?.exerciseCheckBoxs.option?.map((item) => {
      if (item.defaultChecked) {
        checkboxDefaltValuearr.push(item.value);
      }
    });
    setCheckBoxValue(checkboxDefaltValuearr);

    const exOld = exData?.data?.exerciseQuestionSection?.options?.find(
      (item) => item.defaultChecked
    );
    if (exOld) {
      const key = exOld['id'];
      const value = exOld['value'];
      setExerciseQuestionValue({ [key]: value });
    }
    setRemarksValue(exData?.data?.exerciseRemark?.value);
    setOtherValue(exData?.data?.exerciseOtherValue?.value);
  }, [exData?.data]);

  const onCloseToastMessage = () => {
    showToastMessage(false);
  };
  const exerciseQuestionChangeHandler = (e) => {
    setExerciseQuestionValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    exerciseOtherValue: { 'ex-otherValue': otherValue },
    exerciseCheckBoxs: checkboxValue,
    exerciseRemark: { 'ex-remark': remarksValue },
    exerciseQuestionSection: exerciseQuestionValue,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s8',
      },
    });
  }, [payload]);
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(`/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s8`, payload)
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        setShowDataSaveLoader(false);
        console.error('There was an error!', error);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/health"
        nextPath="/form/familyhealthhistory"
        pageHeading={exData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={exData?.common_details?.case_id}
        isCompleted={exData?.metadata?.isCompleted}
        sectionId="f1v1s8"
        userName={exData?.common_details?.name}
        productSubType={exData?.common_details?.product_subtype}
        formId={exData?.common_details?.form_id}
        otherCasesInfo={exData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {exData?.data && (
            <LabelRadioButtons
              label={exerciseQuestionField.label}
              options={exerciseQuestionField.options}
              id={exerciseQuestionField.id}
              onChangeHandler={exerciseQuestionChangeHandler}
            />
          )}
          {exerciseQuestionValue['exercise-yes'] === 'yes' && (
            <ExerciseCheckBoxes
              setCheckBoxValue={setCheckBoxValue}
              checkboxValue={checkboxValue}
              setOtherValue={setOtherValue}
              setRemarksValue={setRemarksValue}
              remarksValue={remarksValue}
            />
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default Exercise;
