import { useSelector } from 'react-redux';
import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';

const BrotherInformation = (props) => {
  const pageData = useSelector((state) => state.familyHealthReducer);
  const { loading, error, formData } = pageData;

  let handleChange = (i, e) => {
    let newFormValues = [...props.brothersDetails];
    newFormValues[i][e.target.name] = e.target.value;
    props.setBrothersDetails(newFormValues);
  };
  let addBrotherFields = () => {
    props.setBrothersDetails([
      ...props.brothersDetails,
      {
        ageOfLiving: '',
        healthStatus: '',
        ageOfDeath: '',
        causeOfDeath: '',
        remark: '',
      },
    ]);
  };

  let removeBrotherFields = (i) => {
    let newFormValues = [...props.brothersDetails];
    newFormValues.splice(i, 1);
    props.setBrothersDetails(newFormValues);
  };

  const sectionField = (
    <div className="flex">
      {props.brothersDetails.length > 0 &&
        props.brothersDetails.map((element, index) => {
          return (
            <AccordionAddMoreSection
              key={index}
              index={index}
              accordionHeading={`Brother ${index + 1}`}
              onDeleteClick={index ? () => removeBrotherFields(index) : null}
            >
              <div className="flex line-separation" key={index}>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.ageOfLiving ? 'value--empty' : ''
                  }`}
                >
                  <input
                    type="number"
                    name="ageOfLiving"
                    placeholder="Enter"
                    value={element.ageOfLiving || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.healthStatus ? 'value--empty' : ''
                  }`}
                >
                  <input
                    type="text"
                    name="healthStatus"
                    placeholder="Enter"
                    value={element.healthStatus || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>

                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.ageOfDeath ? 'value--empty' : ''
                  }`}
                >
                  <input
                    type="number"
                    name="ageOfDeath"
                    placeholder="Enter"
                    value={element.ageOfDeath || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.causeOfDeath ? 'value--empty' : ''
                  }`}
                >
                  <input
                    type="text"
                    name="causeOfDeath"
                    placeholder="Enter"
                    value={element.causeOfDeath || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-1 ${
                    !element.remark ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Remarks</label>
                  <textarea
                    type="textarea"
                    name="remark"
                    placeholder="Enter"
                    value={element.remark || ''}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
              </div>
            </AccordionAddMoreSection>
          );
        })}
    </div>
  );

  const sectionLabel = formData?.data?.brotherSectionLabel;

  return (
    <>
      <div className="flex infoRow ">
        <div className="infoRow__label">
          {sectionLabel && <h3>{sectionLabel}</h3>}
          {props.brothersDetails.length > 0 && (
            <span className="addFieldButton" onClick={() => addBrotherFields()}>
              ADD MORE
            </span>
          )}
        </div>
        <div className="flex infoRow__fields">{sectionField}</div>
      </div>
    </>
  );
};
export default BrotherInformation;
