import { useState } from 'react';
import './NotesSection.css';
import notesOpenIcon from './../../assets/images/NotesOpenIcon.svg';
import notesCloseIcon from './../../assets/images/NotesCloseIcon.svg';
import Notes from './notes';
const NotesSection = (props) => {
  const [openNotesSection, setOpenNotesSection] = useState(false);
  const openNotesSectionHandler = (e) => {
    setOpenNotesSection(true);
  };
  const closeNotesSectionHandler = (e) => {
    setOpenNotesSection(false);
  };

  return (
    <>
      <span
        className="notesSection_openNotes"
        onClick={openNotesSectionHandler}
      >
        <img
          className="notesSection_openNotesIcon"
          src={notesOpenIcon}
          alt="openNotesSectionIcon"
        />
        <span className="notesSection_openNotesText">Notes</span>
      </span>
      <div className="notesWrapper">
        {' '}
        <div
          className={`notesSection  ${
            openNotesSection ? 'openNotesSection' : ''
          }`}
        >
          <div className="notesSection__header">
            <span
              className="notesSection_closeIcon"
              onClick={closeNotesSectionHandler}
            >
              <img src={notesCloseIcon} alt="notesCloseIcon" />
            </span>
            <h4 className="notesSection__headerHeading">Notes</h4>
          </div>
          <div className="notesSection__content">
            <Notes setOpenNotesSection={setOpenNotesSection} />
          </div>
        </div>
      </div>
    </>
  );
};
export default NotesSection;
