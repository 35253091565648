import LabelRadioWithFields from '../../../ui/labelRadioWithFields';
import { useSelector } from 'react-redux';

const GeneralQuestions = (props) => {
  const ogqFormData = useSelector((state) => state.ogqData);
  const { loading, error, formData } = ogqFormData;

  const onDetailsChangeHandler = (e) => {
    props.setGeneralQuestionsDetailsFields((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const fields = formData?.data?.questions?.map((item, index) => {
    return (
      <div className="line-separation" key={index}>
        <LabelRadioWithFields
          id={item.id}
          label={item.label}
          options={item.radioOptions}
          provideDetails={item.provideDetails}
          setRadioChangeValue={props.setGeneralQuestionsRadioOptions}
          onDetailsChangeHandler={onDetailsChangeHandler}
        />
      </div>
    );
  });
  return <>{fields}</>;
};
export default GeneralQuestions;
