import React, { useEffect, useState } from 'react';
// import { HealthAndLifestyleJson } from "./../healthAndLifestyleJson";
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import FormField from '../../../ui/formField';
import './../healthAndLifestyle.css';
import { useSelector } from 'react-redux';
import deleteIcon from '../../../../assets/images/delete-icon.svg';

const TobaccoNicotineQuestions = (props) => {
  const [marijuanaNote, showMarijuanaNote] = useState();
  const hlFormData = useSelector((state) => state.hlData);
  const { loading, error, formData } = hlFormData;

  const defaultTobaccoCheck = 'yes';
  const [tobaccoDetails, showTobaccoDetails] = useState();
  const [tobaccoRemark, setTobaccoRemark] = useState(
    formData?.data?.tobaccoNicotineUseRemark?.value
  );
  const marijuanaChangeHandler = (e) => {
    showMarijuanaNote(e.target.value.toLowerCase());
    props.setMarijuanaFieldData(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const tobaccoNicotineOnChangeHandler = (e) => {
    showTobaccoDetails(e.target.value.toLowerCase());
    props.setTobaccoNicotineFieldData(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const tobaccoNicotineRemarkChange = (e) => {
    setTobaccoRemark(e.target.value);
    props.setTobaccoNicotineRemarkData(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const defaultCheckedQuestions =
    formData?.data?.tobacco_nocotine_field.radioOptions.find(
      (element) =>
        element.defaultChecked &&
        element.value.toLowerCase() === defaultTobaccoCheck
    );
  const defaultMarijuanaCheck =
    formData?.data?.marijuanaField.radioOptions.find(
      (element) =>
        element.defaultChecked && element.value.toLowerCase() === 'yes'
    );

  useEffect(() => {
    showTobaccoDetails(defaultCheckedQuestions?.value?.toLowerCase());
    showMarijuanaNote(defaultMarijuanaCheck?.value?.toLowerCase());
  }, [formData?.data]);

  const tobaccoNicotineLabel = formData?.data?.tobacco_nicotine_label?.label;

  const marijuanaJson = formData?.data?.marijuanaField;

  const marijuanaField = (
    <LabelRadioButtons
      label={marijuanaJson.label}
      options={marijuanaJson.radioOptions}
      onChangeHandler={marijuanaChangeHandler}
    />
  );

  const tobaccoNicotineJson = formData?.data?.tobacco_nocotine_field;

  const tobaccoNicotineFields = (
    <LabelRadioButtons
      label={tobaccoNicotineJson.label}
      options={tobaccoNicotineJson.radioOptions}
      onChangeHandler={tobaccoNicotineOnChangeHandler}
    />
  );
  let handleChange = (i, e) => {
    let newFormValues = [...props.tobaccoNicotineConsumptionData];
    newFormValues[i][e.target.name] = e.target.value;
    props.setTobaccoNicotineConsumptionData(newFormValues);
  };
  let addNicotineConsumption = () => {
    props.setTobaccoNicotineConsumptionData([
      ...props.tobaccoNicotineConsumptionData,
      { useType: '', amount: '', frequency: '', dateLastUsed: '' },
    ]);
  };

  let removeNicotinConsumption = (i) => {
    let newFormValues = [...props.tobaccoNicotineConsumptionData];
    newFormValues.splice(i, 1);
    props.setTobaccoNicotineConsumptionData(newFormValues);
  };

  const tobaccoNicotineDetailsFields = (
    <div className="flex">
      {props.tobaccoNicotineConsumptionData.length > 0 &&
        props.tobaccoNicotineConsumptionData.map((element, index) => {
          return (
            <React.Fragment key={index}>
              <div className="flex" key={index}>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.useType ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Type of Use</label>
                  <div>
                    <input
                      type="text"
                      name="useType"
                      placeholder="Enter"
                      defaultValue={element.useType || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.amount ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Amount</label>
                  <div>
                    <input
                      type="text"
                      name="amount"
                      placeholder="Enter"
                      defaultValue={element.amount || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper layout-4">
                  <label className="field-label">Frequency</label>
                  <div>
                    <select
                      defaultValue={element.frequency || ''}
                      name="frequency"
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="">Select</option>
                      <option value="Per Day">Per Day</option>
                      <option value="Per Week">Per Week</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                    </select>
                  </div>
                </div>
                <div className="label-value-wrapper no-margin-text-field layout-4">
                  <label className="field-label">
                    Date Last Used(Month/Year)
                  </label>

                  <FormField
                    type="month"
                    name="dateLastUsed"
                    placeholder="Enter"
                    value={element.dateLastUsed || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              {index ? (
                <span
                  onClick={() => removeNicotinConsumption(index)}
                  className="icon-delete icon-center"
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="deleteIcon icon-remove"
                  />
                </span>
              ) : null}
            </React.Fragment>
          );
        })}
    </div>
  );

  const tobaccoNicotineRemark = formData?.data?.tobaccoNicotineUseRemark;
  const tobaccoNicotineRemarkField = (
    <>
      {tobaccoNicotineRemark && (
        <div
          className={`label-value-wrapper  ${
            !tobaccoRemark ? 'value--empty' : ''
          }`}
        >
          <label className="field-label">{tobaccoNicotineRemark.label}</label>
          <textarea
            name={tobaccoNicotineRemark.name}
            id={tobaccoNicotineRemark.id}
            onChange={tobaccoNicotineRemarkChange}
            defaultValue={tobaccoNicotineRemark.value}
            placeholder="Enter"
          ></textarea>
        </div>
      )}
    </>
  );
  return (
    <>
      {tobaccoNicotineLabel && <h3>{tobaccoNicotineLabel}</h3>}
      {marijuanaField}

      {marijuanaNote === 'yes' && (
        <p className="marijuanaNote">{formData?.data?.marijuanaNote}</p>
      )}
      <div className="line-separation "></div>
      {tobaccoNicotineFields}
      <div className="line-separation "></div>
      {tobaccoDetails === defaultTobaccoCheck &&
        props.tobaccoNicotineConsumptionData.length > 0 && (
          <>
            <div className="addMoreFields">{tobaccoNicotineDetailsFields}</div>

            {props.tobaccoNicotineConsumptionData.length > 0 && (
              <span
                className="addFieldButton align-center"
                onClick={() => addNicotineConsumption()}
              >
                Add More
              </span>
            )}
            <div className="field-remark-wrapper line-separation ">
              {tobaccoNicotineRemarkField}
            </div>
          </>
        )}
    </>
  );
};

export default TobaccoNicotineQuestions;
