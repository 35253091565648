import {
  SET_SEND_DATA,
  RESET_SEND_DATA,
  SET_SECONDRY_DATA,
  RESET_SECONDRY_DATA,
} from '../constants/sendDataConstants';

const initialState = {
  payload: {},
  sectionId: '',
  secondryPayload: {},
  secondrySectionId: '',
};
export const sendDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEND_DATA:
      return {
        ...state,
        payload: action.payload.payload,
        sectionId: action.payload.sectionId,
      };
    case RESET_SEND_DATA:
      return { payload: {}, sectionId: '' };
    case SET_SECONDRY_DATA:
      return {
        ...state,
        secondryPayload: action.payload.payload,
        secondrySectionId: action.payload.sectionId,
      };
    case RESET_SECONDRY_DATA:
      return { secondryPayload: {}, secondrySectionId: '' };
    default:
      return state;
  }
};
