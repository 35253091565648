import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
import radioCheckedImg from '../../../../assets/images/radio-checked.svg';

const FairMarketValue = (props) => {
  const pageData = useSelector((state) => state.businessInsuranceData);
  const { loading, error, formData } = pageData;
  const [estFairMarketValue, setEstFairMarketValue] = useState('');

  useEffect(() => {
    if (formData?.data?.fairMarketValueOptions) {
      const selected =
        formData?.data?.fairMarketValueOptions?.checkOptions?.filter(
          (element) => element.defaultChecked
        );
      const values = selected?.map((ele) => ele.value);
      props.setFairMarketCheck(values);
    }
    setEstFairMarketValue(formData?.data?.fairMarketValue?.value);
  }, [formData?.data]);
  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };
  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };

  const onMarketCheckChange = (e) => {
    if (e.target.checked) {
      props.setFairMarketCheck((prevState) => [...prevState, e.target.value]);
    } else {
      let checkedValuesArray = [...props.fairMarketCheck];

      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      props.setFairMarketCheck(checkedValuesArray);
    }
  };
  const fairMarketValueOptions =
    formData?.data?.fairMarketValueOptions?.checkOptions.map((item, index) => {
      return (
        <div className="checkboxTabs" key={index}>
          <input
            className="checkboxField"
            type="checkbox"
            value={item.value}
            name={item.name}
            id={item.id}
            defaultChecked={item.defaultChecked}
            onChange={onMarketCheckChange}
          />
          <label className="customCheckbox" htmlFor={item.id}>
            <img
              src={radioCheckedImg}
              alt="checked "
              className="customCheckbox__image"
            />
            <span className="customCheckbox__label">{item.value}</span>
          </label>
        </div>
      );
    });
  const marketValueChange = (e) => {
    setEstFairMarketValue(removeCommaSeparator(e.target.value));
    props.setFairMarketValue(() => ({
      [e.target.name]: removeCommaSeparator(e.target.value),
    }));
  };
  const capitalizedEarningsValueChange = (e) => {
    props.setCapatilizedEarningValue(() => ({
      [e.target.name]: e.target.value,
    }));
  };
  const fairMarketField = formData?.data?.capitalizedEarningsFields && (
    <div className="flex">
      <FormField
        type={formData?.data?.capitalizedEarningsFields?.type}
        name={formData?.data?.capitalizedEarningsFields?.name}
        value={formData?.data?.capitalizedEarningsFields?.value}
        id={formData?.data?.capitalizedEarningsFields?.id}
        label={formData?.data?.capitalizedEarningsFields?.label}
        key={formData?.data?.capitalizedEarningsFields?.id}
        layoutType={formData?.data?.capitalizedEarningsFields?.layoutType}
        onChangeHandler={capitalizedEarningsValueChange}
      ></FormField>
    </div>
  );
  const marketOtherValueChange = (e) => {
    props.setFairMarketOtherValue(() => ({
      [e.target.name]: e.target.value,
    }));
  };
  const fairMarketOtherFields = formData?.data?.fairMarketOtherFields && (
    <div className="flex">
      <FormField
        type={formData?.data?.fairMarketOtherFields?.type}
        name={formData?.data?.fairMarketOtherFields?.name}
        value={formData?.data?.fairMarketOtherFields?.value}
        id={formData?.data?.fairMarketOtherFields?.id}
        label={formData?.data?.fairMarketOtherFields?.label}
        key={formData?.data?.fairMarketOtherFields?.id}
        layoutType={formData?.data?.fairMarketOtherFields?.layoutType}
        onChangeHandler={marketOtherValueChange}
      ></FormField>
    </div>
  );

  return (
    <>
      {formData?.data?.fairMarketSectionLabel && (
        <h3>{formData?.data?.fairMarketSectionLabel}</h3>
      )}
      {formData?.data?.fairMarketValue && (
        <div className="flex">
          <div
            className={`label-value-wrapper ${
              formData?.data?.fairMarketValue.layoutType
            }  ${
              !formData?.data?.fairMarketValue?.value ? 'value--empty' : ''
            }`}
          >
            <label className="field-label">
              {formData?.data?.fairMarketValue?.label}
            </label>
            <div>
              <input
                id={formData?.data?.fairMarketValue?.id}
                type={formData?.data?.fairMarketValue?.type}
                name={formData?.data?.fairMarketValue?.name}
                placeholder="Enter"
                onChange={marketValueChange}
                value={addCommaSeparator(estFairMarketValue)}
              />
            </div>
          </div>
        </div>
      )}
      {fairMarketValueOptions && (
        <div className="line-separation">
          {formData?.data?.fairMarketLabel && (
            <h3>{formData?.data?.fairMarketLabel}</h3>
          )}
          <div>{fairMarketValueOptions}</div>
        </div>
      )}
      {props.fairMarketCheck.includes('Capitalized Earnings') && (
        <>{fairMarketField && fairMarketField}</>
      )}

      {props.fairMarketCheck.includes('Other') && (
        <>{fairMarketOtherFields && fairMarketOtherFields}</>
      )}
    </>
  );
};
export default FairMarketValue;
