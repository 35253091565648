import React, { useState, useRef, useEffect } from 'react';
import { FcEmptyFilter, FcFilledFilter } from 'react-icons/fc';
import Select from 'react-select';

const CustomMultiSelect = ({ value, options, onChange, isMulti = true }) => {
  const overlayRef = useRef(null);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = () => {
    setOverlayVisible(!overlayVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlayVisible(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let selectedOptions = value?.map((selectedValue) =>
    options.find((option) => option?.value === selectedValue?.value)
  );
  if (!selectedOptions) {
    selectedOptions = [];
  }

  let unselectedOptions = options?.filter(
    (option) =>
      !value.some((selectedValue) => selectedValue.value === option.value)
  );
  if (!unselectedOptions) {
    unselectedOptions = [];
  }

  const modifiedOptions = [...selectedOptions, ...unselectedOptions];

  return (
    <>
      <div onClick={toggleOverlay} className="carrierFilter--selectedInfo">
        {value.length > 0 ? (
          <span style={{ cursor: 'pointer', fontSize: '16px' }}>
            <FcFilledFilter />
          </span>
        ) : (
          <span style={{ cursor: 'pointer', fontSize: '16px' }}>
            <FcEmptyFilter />
          </span>
        )}
      </div>
      {overlayVisible && (
        <div className="carrierFilter--selectedValues" ref={overlayRef}>
          <div className="carrierFilter--arrow" />
          <Select
            isMulti={isMulti}
            value={value}
            options={modifiedOptions}
            onChange={onChange}
            closeMenuOnSelect={!isMulti}
            hideSelectedOptions={false}
            menuIsOpen={true}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#1984bc' : 'white',
                color: state.isSelected ? 'white' : 'black',
                cursor: 'pointer',
              }),
              multiValue: (provided) => ({
                ...provided,
                display: 'none', // Hide selected values in search box
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 2,
              }),
            }}
          />
        </div>
      )}
    </>
  );
};

export default CustomMultiSelect;
