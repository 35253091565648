import { listUsers } from '../../../../actions/caseListingAction';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

const SideFilter = (props) => {
  const filterData = props?.filterData;
  const dispatch = useDispatch();
  const [currentFilterValue, setCurrentFilterValue] = useState('');

  let userRolesArray = [];
  let userNamesArray = [];

  useEffect(() => {
    props?.filterData?.map((item) => {
      if (item.active) {
        item.innerData.map((innerItem) => {
          if (innerItem.showUsers) {
            userRolesArray.push(innerItem.innerLabel);
            innerItem?.options?.map((users) => {
              if (users.active) {
                userNamesArray.push(users.id);
              }
            });
          }
        });
      }
    });
  }, [props]);

  const onAccordionClick = (e) => {
    setCurrentFilterValue(e.target.dataset.value);
    if (e.target.dataset.active === 'false') {
      setCurrentFilterValue(e.target.dataset.value);
      dispatch(
        listUsers(
          e.target.dataset.value,
          'side_search',
          '',
          '',
          props.productType,
          props.caseType,
          ''
        )
      );
    } else {
      setCurrentFilterValue('');
      dispatch(
        listUsers(
          '',
          'side_search',
          '',
          '',
          props.productType,
          props.caseType,
          ''
        )
      );
    }
  };

  const onInnerAccordionClick = (e) => {
    if (e.target.checked) {
      userRolesArray.push(e.target.value);
      if (props.caseType === 'allCase') {
        props?.filterData?.map((item) => {
          if (item.active) {
            item.innerData.map((innerItem) => {
              if (innerItem.innerLabel === e.target.value) {
                innerItem.options.map((users) => {
                  userNamesArray.push(users.id);
                });
              }
            });
          }
        });
      }
    } else {
      let checkedValuesArray = [...userRolesArray];
      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      userRolesArray.splice(unCheckedValueIndex, 1);
      if (props.caseType === 'allCase') {
        props?.filterData?.map((item) => {
          if (item.active) {
            item.innerData.map((innerItem) => {
              if (innerItem.innerLabel === e.target.value) {
                innerItem.options.map((users) => {
                  userNamesArray.splice(users.id, 1);
                });
              }
            });
          }
        });
      }
    }

    dispatch(
      listUsers(
        currentFilterValue,
        'side_search',
        '',
        '',
        props.productType,
        props.caseType,
        '',
        userRolesArray,
        userNamesArray
      )
    );
  };

  const onUserNamesClick = (e) => {
    if (e.target.checked) {
      userNamesArray.push(e.target.id);
    } else {
      let checkedValuesArray = [...userNamesArray];
      const unCheckedValue = (element) => element === e.target.id;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      userNamesArray.splice(unCheckedValueIndex, 1);
    }

    dispatch(
      listUsers(
        currentFilterValue,
        'side_search',
        '',
        '',
        props.productType,
        props.caseType,
        '',
        userRolesArray,
        userNamesArray
      )
    );
  };

  return (
    <>
      <h3 className="search-heading">Filter Cases by Date Created:</h3>

      {filterData?.map((filterItem, index) => {
        return (
          <>
            {index === 6 && (
              <div key={index}>
                <div className="line-separation line-separation-width"></div>
                <h3 className="search-heading">
                  Filter Cases by Last Updated:
                </h3>
              </div>
            )}
            <div
              className={`side_nav_accordion__wrapper ${
                filterItem.active ? 'open-outer-accordion' : ''
              } ${filterItem.upperValue < 1 ? 'accordion-disable' : ''}`}
            >
              <div className="side_nav_accordion__header">
                <span
                  id={`outerAccordion-${index}`}
                  className="side_nav_accordionClickField"
                  onClickCapture={onAccordionClick}
                  data-value={filterItem.upperLabel}
                  data-active={filterItem.active}
                ></span>
                <div className="side_nav_heading-container">
                  <div className="flex">
                    <span className="accArrowIcon accArrowIcon-outer"></span>

                    <p className="side_nav_accordion_label side_nav_accordion_outerLabel">
                      {filterItem.upperLabel}
                    </p>
                  </div>
                  <div className=" side_nav_accordion__accordionHeading">
                    <p className="side_nav_accordion_label side_nav_accordion_outerLabel">
                      {filterItem.upperValue}
                    </p>
                  </div>
                </div>
              </div>

              <div className="side_nav_accordion__content">
                {/* =============================================== */}
                {filterItem?.innerData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`side_nav_accordion__innerWrapper ${
                        item.showUsers ? 'open-inner-accordion' : ''
                      } ${item.innerValue < 1 ? 'accordion-disable' : ''}`}
                    >
                      <div className={`side_nav_accordion__header_inner `}>
                        <label
                          id={`innerAccordion-${i}-outer-${index}`}
                          className="side_nav_accordionClickField_inner"
                          htmlFor={
                            filterItem.upperLabel.replace(/ /g, '') +
                            '-' +
                            item.innerLabel.replace(/ /g, '')
                          }
                        >
                          <div className="side_nav_heading-container_inner">
                            <div className="side_nav_sub_heading_inner">
                              <input
                                type="checkbox"
                                disabled={item.innerValue < 1 ? true : false}
                                name={item.innerLabel}
                                value={item.innerLabel}
                                id={
                                  filterItem.upperLabel.replace(/ /g, '') +
                                  '-' +
                                  item.innerLabel.replace(/ /g, '')
                                }
                                defaultChecked={item.showUsers}
                                onClick={onInnerAccordionClick}
                              />
                              <p className="side_nav_accordion_label">
                                {item.innerLabel}
                              </p>
                              {/* </label> */}
                            </div>
                            <div className="side_nav_inner_value">
                              <p className="side_nav_accordion_label">
                                {item.innerValue}
                              </p>

                              {/* {item?.options?.length > 0 && (
                                <span className="accArrowIcon accArrowIcon-inner"></span>
                              )} */}
                            </div>
                          </div>
                        </label>
                      </div>

                      <div className="side_nav_accordion__content_inner">
                        {item?.options
                          ?.sort((a, b) => {
                            let fa = a.label.toLowerCase(),
                              fb = b.label.toLowerCase();

                            if (fa < fb) {
                              return -1;
                            }
                            if (fa > fb) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((option, j) => {
                            return (
                              <div
                                className="side_nav_accordion__content_inner_container"
                                key={j}
                              >
                                <label
                                  htmlFor={option.id}
                                  className="accordion--users__wrapper"
                                >
                                  <div className="side_nav_accordion__content_inner_label">
                                    <input
                                      type="checkbox"
                                      id={option.id}
                                      name={option.label}
                                      value={option.label}
                                      defaultChecked={option.active}
                                      onClick={onUserNamesClick}
                                    />
                                    <span
                                      htmlFor={option.id}
                                      className="side_nav_accordion_label"
                                    >
                                      {option.label}
                                    </span>
                                  </div>
                                  <p className="side_nav_accordion_label">
                                    {option.value}
                                  </p>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default SideFilter;
