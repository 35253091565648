import {
  APPLICATION_AVAILABLE_REQUEST,
  APPLICATION_AVAILABLE_SUCCESS,
  APPLICATION_AVAILABLE_FAIL,
} from '../constants/applicationAvailableConstants';

export const applicationAvailableReducer = (
  state = { formData: [] },
  action
) => {
  switch (action.type) {
    case APPLICATION_AVAILABLE_REQUEST:
      return { loading: true, formData: [] };
    case APPLICATION_AVAILABLE_SUCCESS:
      return { loading: false, formData: action.payload };
    case APPLICATION_AVAILABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
