// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    background-color: white;
    padding: 20px;
    margin: 42px 10px 17px 0px;
    border-radius: 10px;
  }
  
  .static {
    font-size: 14px;
    margin-top: 12px;
    color: gray;
  }
  
  .dynamic {
    font-size: 14px;
    margin-top: 6px;
    color: #000;
    font-weight: 700;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/caseDataSection/CaseDataSection.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,0BAA0B;IAC1B,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB","sourcesContent":[".wrapper {\n    background-color: white;\n    padding: 20px;\n    margin: 42px 10px 17px 0px;\n    border-radius: 10px;\n  }\n  \n  .static {\n    font-size: 14px;\n    margin-top: 12px;\n    color: gray;\n  }\n  \n  .dynamic {\n    font-size: 14px;\n    margin-top: 6px;\n    color: #000;\n    font-weight: 700;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
