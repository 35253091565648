import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../../../axios/axios';
import Loader from '../../loader';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [authRes, setAuthRes] = useState('');
  const code = searchParams.get('code');
  const clientInfo = searchParams.get('client_info');
  const stateValue = searchParams.get('state');
  const sessionState = searchParams.get('session_state');
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        `/getAToken?code=${code}&client_info=${clientInfo}&state=${stateValue}&session_state=${sessionState}`
      )
      .then((response) => {
        if (response?.data?.status === 'success') {
          navigate('/caseListing');
        } else {
          navigate('/unauthorized');
        }
        setAuthRes(response);
        localStorage.setItem('userEmail', response?.data?.result?.email);
        localStorage.setItem('userName', response?.data?.result?.username);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  }, []);

  return <>{authRes.length === 0 && <Loader />}</>;
};

export default Auth;
