import {
  PY_DATA_REQUEST,
  PY_DATA_SUCCESS,
  PY_DATA_FAIL,
} from '../constants/physicianInformationConstants';

export const physicianInformationReducer = (state = { pyData: [] }, action) => {
  switch (action.type) {
    case PY_DATA_REQUEST:
      return { loading: true, pyData: [] };
    case PY_DATA_SUCCESS:
      return { loading: false, pyData: action.payload };
    case PY_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
