import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import FamilyHealthQuestions from './familyHealthQuestions';
import './FamilyHealthHistory.css';
import axios from '../../../axios/axios';
import { familyHealthHistoryAction } from '../../../actions/familyHealthHistoryAction';
import ToastMessage from '../../ui/toastMessage';
import Loader from '../../loader';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const FamilyHealthHistory = (props) => {
  const [familyDiseaseCheck, setFamilyDiseaseCheck] = useState({});
  const [motherData, setMotherData] = useState({});
  const [fatherData, setFatherData] = useState({});
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const pageData = useSelector((state) => state.familyHealthReducer);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(familyHealthHistoryAction());
  }, [dispatch]);

  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  const [brothersDetails, setBrothersDetails] = useState([]);
  useEffect(() => {
    if (formData?.data?.brotherInformation !== undefined) {
      setBrothersDetails(formData?.data?.brotherInformation);
    }
  }, [formData?.data]);

  const [sistersDetails, setSistersDetails] = useState([]);
  useEffect(() => {
    if (formData?.data?.sisterInformation !== undefined) {
      setSistersDetails(formData?.data?.sisterInformation);
    }
  }, [formData?.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const sendData = {
    familyHealthQuestions: familyDiseaseCheck,
    motherInformation: motherData,
    fatherInformation: fatherData,
    brotherInformation: brothersDetails,
    sisterInformation: sistersDetails,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: sendData,
        sectionId: 'f1v1s9',
      },
    });
  }, [sendData]);
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s9`,
        sendData
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };

  return (
    <>
      <CommonFormHeader
        backPath="/form/exercise"
        nextPath="/form/lifestyle&othergeneralquestions"
        onSaveFormDataHandler={onSaveFormDataHandler}
        pageHeading={formData?.metadata?.label}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s9"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {formData?.data && (
            <FamilyHealthQuestions
              setFamilyDiseaseCheck={setFamilyDiseaseCheck}
              setMotherData={setMotherData}
              setFatherData={setFatherData}
              brothersDetails={brothersDetails}
              setBrothersDetails={setBrothersDetails}
              setSistersDetails={setSistersDetails}
              sistersDetails={sistersDetails}
            />
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default FamilyHealthHistory;
