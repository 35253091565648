// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadCrumb__wrapper {
  padding-bottom: 18px;
  background: #f4f7fa;
  padding-top: 4px;
  display: flex;
  align-items: center;
}
.breadCrumb__img {
  margin-right: 8px;
}
.breadCrumb__link {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #1984bc;
  margin-right: 13px;
  display: inline-block;
  cursor: pointer;
}
.breadCrumb__link--static {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #505050;
  margin-right: 13px;
  display: inline-block;
}
.breadCrumb__pageTitle {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #505050;
  display: inline-block;
}
.breadCrumb__arrow {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-bottom: 1.5px solid #1984bc;
  border-right: 1.5px solid #1984bc;
  transform: rotate(-45deg);
  margin-right: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/breadCrumb/BreadCrumb.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,WAAW;EACX,qBAAqB;EACrB,kCAAkC;EAClC,iCAAiC;EACjC,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".breadCrumb__wrapper {\n  padding-bottom: 18px;\n  background: #f4f7fa;\n  padding-top: 4px;\n  display: flex;\n  align-items: center;\n}\n.breadCrumb__img {\n  margin-right: 8px;\n}\n.breadCrumb__link {\n  font-size: 14px;\n  line-height: 1.5;\n  font-weight: 500;\n  color: #1984bc;\n  margin-right: 13px;\n  display: inline-block;\n  cursor: pointer;\n}\n.breadCrumb__link--static {\n  font-size: 14px;\n  line-height: 1.5;\n  font-weight: 500;\n  color: #505050;\n  margin-right: 13px;\n  display: inline-block;\n}\n.breadCrumb__pageTitle {\n  font-size: 14px;\n  line-height: 1.5;\n  font-weight: 500;\n  color: #505050;\n  display: inline-block;\n}\n.breadCrumb__arrow {\n  height: 10px;\n  width: 10px;\n  display: inline-block;\n  border-bottom: 1.5px solid #1984bc;\n  border-right: 1.5px solid #1984bc;\n  transform: rotate(-45deg);\n  margin-right: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
