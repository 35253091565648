import radioCheckedImg from './../../../../assets/images/radio-checked.svg';

const DisabledCheckBoxes = (props) => {
  return (
    <>
      <div>
        <div className="plan-check-container">
          <label
            className="plan-check-label-container"
            style={{
              border:
                props.dependCheckBox.length > 0
                  ? '1px solid #0177b5'
                  : '1px solid #d6d6d6',
            }}
          >
            {props.dependCheckBox.length > 0 && (
              <img
                src={radioCheckedImg}
                alt="checked"
                className="plan-check-icon"
              />
            )}
            <span
              className="plan-check-label"
              style={{
                color: props.dependCheckBox.length > 0 ? '#0177b5' : '#222222',
              }}
            >
              {props.label}
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default DisabledCheckBoxes;
