import LabelRadioButtons from '../../../ui/labelRadioButtons';
import CommonLabelRadioButtons from '../../../ui/commonLabelRadioButton';
import TrusteeSection from './trustee';
import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';

const TrustSection = (props) => {
  const fieldData = useSelector((state) => state.policyOwnerData);
  const { loading, error, poData } = fieldData;

  const trustTypeFieldData = poData?.data?.trustTypeOptions?.options;

  const trustAgreementChangeHandler = (event) => {
    props.setTrustAgreementFields(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const entityChangeHandler = (event) => {
    props.setTrustType(() => ({
      [event.target.id]: event.target.value,
    }));
    //setEntityOptionValue(event.target.value);
  };
  const onTrustNameFieldsChange = (event) => {
    props.setTrustName((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      {poData?.data && (
        <>
          <div className="flex">
            <h3>{poData?.data?.trustTypeOptions?.label}</h3>
            <CommonLabelRadioButtons
              options={trustTypeFieldData}
              onChangeHandler={entityChangeHandler}
            />

            <div className="line-separation layout-1"></div>
            {poData?.data?.trustName?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onTrustNameFieldsChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  dropdownType={item.dropdownType}
                  validationType={item.validationType}
                />
              );
            })}
          </div>
          <TrusteeSection
            trustee={props.trustee}
            setTrustee={props.setTrustee}
            beneficiary={props.beneficiary}
            setBeneficiary={props.setBeneficiary}
          />
          <LabelRadioButtons
            label={poData?.data?.trustAgreementSection?.label}
            options={poData?.data?.trustAgreementSection?.options}
            id={poData?.data?.trustAgreementSection?.id}
            onChangeHandler={trustAgreementChangeHandler}
          />
          {props.trustAgreementFields['trustAgreement-yes'] === 'yes' && (
            <div
              className={`label-value-wrapper field-remark-wrapper ${
                !poData?.data?.trustAgreementYesOptions?.value
                  ? 'value--empty'
                  : ''
              }`}
            >
              <label>{poData?.data?.trustAgreementYesOptions?.label}</label>
              <textarea
                name={poData?.data?.trustAgreementYesOptions?.id}
                id={poData?.data?.trustAgreementYesOptions?.id}
                onChange={(e) =>
                  props.setTrustAgreementYesValue(e.target.value)
                }
                defaultValue={poData?.data?.trustAgreementYesOptions?.value}
                placeholder={
                  poData?.data?.trustAgreementYesOptions?.placeholder
                }
              ></textarea>
            </div>
          )}
          <div className="line-separation layout-1"></div>
        </>
      )}
    </>
  );
};
export default TrustSection;
