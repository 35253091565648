import { useState } from 'react';
import './../labelRadioButtons/LabelRadioButtons.css';
import PropTypes from 'prop-types';

const LabelRadioWithFields = (props) => {
  const [showExtraFields, setShowExtraFields] = useState();
  const [provideDetailsValue, setProvideDetailsValue] = useState(
    props?.provideDetails?.value
  );
  const showExtraFieldsCheck = 'yes';
  const hideExtraFieldsCheck = 'no';
  const radioButtonOptions = props.options;
  const onRadioChangeHandler = (e) => {
    setShowExtraFields(e.target.value.toLowerCase());
    props.setRadioChangeValue((prevState) => {
      const obj = { ...prevState };

      switch (e.target.value.toLowerCase()) {
        case 'no':
          delete obj[e.target.name + '-yes'];

          break;
        case 'yes':
          delete obj[e.target.name + '-no'];
          break;
        default: //do nothing
      }

      return { ...obj, [e.target.id]: e.target.value };
    });
  };
  const defaultCheckedQuestions = props?.options?.find(
    (element) =>
      element.defaultChecked &&
      element.value.toLowerCase() === showExtraFieldsCheck
  );

  const radioButtonFields = radioButtonOptions?.map((item, index) => {
    return (
      <div className="radio-button" key={index}>
        <input
          type={item.type}
          value={item.value}
          name={item.name}
          id={item.id}
          className="radio"
          defaultChecked={item.defaultChecked}
          onChange={onRadioChangeHandler}
        />
        <label htmlFor={item.id} className="radio-label">
          {item.label}
        </label>
      </div>
    );
  });
  const onProvideDetailsChangeHandler = (e) => {
    props.onDetailsChangeHandler(e);
    setProvideDetailsValue(e.target.value);
  };
  const provideDetails = props?.provideDetails;
  const extraFields = (
    <>
      <div
        className={`provide-details label-value-wrapper  ${
          !provideDetailsValue ? 'value--empty' : ''
        }`}
      >
        {provideDetails?.label && (
          <label className="provide-details-label">
            {provideDetails?.label}
          </label>
        )}
        <textarea
          id={provideDetails?.id}
          className="provide-details-text"
          onChange={onProvideDetailsChangeHandler}
          defaultValue={provideDetails?.value}
          placeholder="Enter"
        />
      </div>
    </>
  );
  return (
    <>
      <div className="label-radioButtons-wrapper" id={props.id}>
        {props.label && <p className="group-label">{props.label}</p>}
        {props.options && (
          <div
            className="radio-buttons-wrapper"
            onChange={props.onChangeHandler}
          >
            {radioButtonFields}
          </div>
        )}
      </div>

      {typeof props?.provideDetails !== 'undefined' &&
        (showExtraFields === showExtraFieldsCheck ||
          (defaultCheckedQuestions?.value.toLowerCase() ===
            showExtraFieldsCheck &&
            showExtraFields !== hideExtraFieldsCheck)) &&
        extraFields}
    </>
  );
};
export default LabelRadioWithFields;

LabelRadioWithFields.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  provideDetails: PropTypes.object,
};
