import SortIcon from './../../../../assets/images/icon-sort.svg';
import SearchIcon from './../../../../assets/images/feather-search.svg';
import { useDispatch } from 'react-redux';

import './../CaseListing.css';
import SortModal from '../sortModal';
import { useState } from 'react';
import { listUsers, navData } from '../../../../actions/caseListingAction';

const CaseListingSearch = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();

  const onSearchBtnClick = () => {
    dispatch(
      listUsers(
        searchValue,
        'search_str',
        '',
        '',
        props?.productType,
        props?.caseType
      )
    );
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      dispatch(
        listUsers(
          searchValue,
          'search_str',
          '',
          '',
          props?.productType,
          props?.caseType
        )
      );
    }
  };
  return (
    <>
      <div className="listing-search-wrapper flex">
        <div className="listing-search-bar">
          <input
            type="search"
            placeholder="Search"
            className="case-search"
            name="case-search"
            onKeyDown={handleKeyDown}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </div>
        <div className="search-btn-container" onClick={onSearchBtnClick}>
          <img src={SearchIcon} alt="Sort Icon" className="searchIcon" />
        </div>
        <div className="listing-sort" onClick={() => setShowModal(true)}>
          <span className="list-sort-button">
            Sort By
            <img src={SortIcon} alt="Sort Icon" className="list-sortIcon" />
          </span>
        </div>
      </div>
      <div className="produtType-container">
        <div
          className={`productTypeField ${
            props.activeProduct === 'insurance' ? 'productActive' : ''
          } `}
          onClick={() => {
            props.setActive('');
            props.setProductType('insurance');
            props.setActiveProduct('insurance');
            dispatch(listUsers('', '', '', '', 'insurance', props?.caseType));
            setSearchValue('');
            props.setFromDate('');
            props.setToDate('');
            props.setStartValidation(false);
            localStorage.setItem('productType', 'insurance');
          }}
        >
          <span className="productTypeValue">Insurance</span>
        </div>
        <div
          className={`productTypeField ${
            props.activeProduct === 'annuity' ? 'productActive' : ''
          } `}
          onClick={() => {
            props.setActive('');
            props.setProductType('annuity');
            props.setActiveProduct('annuity');
            dispatch(listUsers('', '', '', '', 'annuity', props?.caseType));
            setSearchValue('');
            props.setFromDate('');
            props.setToDate('');
            props.setStartValidation(false);
            localStorage.setItem('productType', 'annuity');
          }}
        >
          <span className="productTypeValue">Annuity</span>
        </div>
      </div>
      <div className="cases-container">
        <div
          className={`myCase-container ${
            props.activeCase === 'myCase' ? 'caseActive' : ''
          } case-text`}
          active={props.activeCase === 'myCase'}
          onClick={() => {
            props.setActive('');
            props.setActiveCase('myCase');
            props.setCaseType('myCase');
            dispatch(listUsers('', '', '', '', props?.productType, 'myCase'));
            setSearchValue('');
            props.setFromDate('');
            props.setToDate('');
            props.setStartValidation(false);
          }}
        >
          <div
            className={
              props.activeCase === 'myCase'
                ? 'active-my-case-icon'
                : 'my-case-icon'
            }
          ></div>
          <p>My Cases</p>
        </div>
        <div
          className={`allCase-container ${
            props.activeCase === 'allCase' ? 'caseActive' : ''
          } case-text`}
          active={props.activeCase === 'allCase'}
          onClick={() => {
            props.setActive('');
            props.setActiveCase('allCase');
            props.setCaseType('allCase');
            dispatch(listUsers('', '', '', '', props?.productType, 'allCase'));
            setSearchValue('');
            props.setFromDate('');
            props.setToDate('');
            props.setStartValidation(false);
          }}
        >
          <div
            className={
              props.activeCase === 'allCase'
                ? 'active-all-case-icon'
                : 'all-case-icon'
            }
          ></div>
          <p>All Cases</p>
        </div>
      </div>
      <div className={showModal ? 'show-component' : 'hide-component'}>
        <SortModal
          showModal={showModal}
          setShowModal={setShowModal}
          caseType={props.caseType}
          productType={props.productType}
          sortData={props.sortData}
        />
      </div>
    </>
  );
};

export default CaseListingSearch;
