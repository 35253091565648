export const SetTaxIDSeparator = (value) => {
  if (value) {
    let val = value;
    var newVal = '';

    if (val.length > 2 && val[2] !== '-') {
      newVal += val.substr(0, 2) + '-';
      val = val.substr(2);
    }
    newVal += val;
    value = newVal;
    return value;
  }
};
export const RemoveTaxIdSeparator = (value) => {
  return value.replace(/\-/g, '');
};
