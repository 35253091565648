import './unauthorized.css';
import notFoundImg from '../../../assets/images/feather-alert-triangle.svg';
import axios from '../../../axios/axios';

const Unauthorized = () => {
  const handleTryAgain = () => {
    axios
      .get('/getLogoutUrl')
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          window.location.replace(response?.data?.logout_url);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-box">
        <div className="unauthorized-image-container">
          <img src={notFoundImg} alt="not Found Image" />
          <p>ID Not Found</p>
        </div>
        <div className="unauthorized-sub-heading">
          <p>We couldn’t find your account in Smart Office.</p>
          <p className="administrator-text">Please contact Administrator.</p>
        </div>
        <div className="unauthorized-contact">
          <p>
            Support Email -
            <a href="mailto:support@rippleit.com"> support@rippleit.com </a>
            <br />
            <p className="unauthorized-contact-phone">
              {' '}
              support phone number - (404) 892-0129.
            </p>
          </p>
        </div>

        <div>
          <button className="button" onClick={handleTryAgain}>
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
