import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { formAdministrationAction } from '../../../actions/fromAdministrationAction';
import Footer from '../../footer';
import Header from '../../header';
import Loader from '../../loader';
import './carrierFormAdministration.css';
import { carrierUploadAction } from '../../../actions/carrierUploadAction';
import CarrierAdminToastMessage from './carrierAdminToastMessage';
import FormAdministrationFilters from './formAdministrationFilters';
import CarrierBreadCrumb from '../carrierOnboard/carrierBreadCrumb';
import ExistingCarriersList from './existingCarrierList';
const ITEMS_PER_PAGE_OPTIONS = [10, 15, 20, 25];
const CarrierFormAdministration = () => {
  const [showPDFLoader, setShowPDFLoader] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const pageData = useSelector((state) => state.formAdministrationData);
  const dropDownData = useSelector(
    (state) => state.carrierUploadData?.formData
  );
  const { loading, formData } = pageData;
  const [formList, setFormList] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(
    ITEMS_PER_PAGE_OPTIONS[0]
  );
  const [records, setRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  // Initialize the filter state
  const [filters, setFilters] = useState({
    searchInput: '',
    formName: '',
    categoryName: '',
    carriers: [],
    formTypes: [],
    productCategories: [],
    states: [],
    products: [],
    categoryTags: [],
  });
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onBackButtonEvent = (e) => {
    //Handling Browser Back button to move page to Case Listing
    e.preventDefault();
    navigate(`/caseListing`);
  };

  const toggleFilters = () => {
    setShowFilters((prevShowFilters) => !prevShowFilters);
  };

  // Function to handle filter changes with react-select
  const handleFilterChange = (filterType, selectedOptions) => {
    if (filterType === 'searchInput') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: selectedOptions,
      }));
    } else {
      let selectedValues;
      if (!Array.isArray(selectedOptions)) {
        selectedValues = [selectedOptions.value];
      } else {
        selectedValues = selectedOptions?.map((option) => option.value);
      }

      if (
        selectedValues.length === 0 ||
        (selectedValues.length === 1 && !selectedValues[0])
      ) {
        // If the dropdown is unselected, clear the filter
        setFilters((prevFilters) => ({
          ...prevFilters,
          [filterType]: [],
        }));
      } else {
        // Otherwise, update the filter with the selected values
        setFilters((prevFilters) => ({
          ...prevFilters,
          [filterType]: selectedValues,
        }));
      }
    }
  };

  // Function to filter the form list based on selected filters
  const filterFormList = () => {
    let filteredForms = formData?.uploaded?.concat(formData?.system || []);
    if (!filteredForms || !filteredForms.length) {
      return;
    }
    if (filters?.searchInput && filters?.searchInput?.length > 0) {
      //Search box showing result based on Form name and category Tags
      filteredForms = filteredForms.filter(
        (form) =>
          form.form_name
            .toLowerCase()
            .includes(filters.searchInput.toLowerCase()) ||
          form.category_tags
            ?.join(' ')
            ?.toLowerCase()
            .includes(filters.searchInput.toLowerCase())
      );
    }
    if (filters.formName && filters.formName.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        form.form_name.toLowerCase().includes(filters.formName.toLowerCase())
      );
    }
    if (filters?.categoryName && filters?.categoryName.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        form.category_tags
          ?.join(' ')
          ?.toLowerCase()
          .includes(filters.categoryName.toLowerCase())
      );
    }

    if (filters.productCategories.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        filters.productCategories.includes(form.product_category)
      );
    }

    if (filters.carriers.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        form.carrier.some((carrier) => filters.carriers.includes(carrier))
      );
    }

    if (filters.products.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        form.product.some((product) => filters.products.includes(product))
      );
    }

    if (filters.states.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        form.state_short_form.some((state) => filters.states.includes(state))
      );
    }

    if (filters.categoryTags.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        form.category_tags.some((tag) => filters.categoryTags.includes(tag))
      );
    }
    if (filters.formTypes.length > 0) {
      filteredForms = filteredForms.filter((form) =>
        filters.formTypes.includes(form.form_type)
      );
    }

    setFormList(filteredForms);
    setCurrentPage(1); // Reset the current page to the first page when filters change
  };

  useEffect(() => {
    dispatch(formAdministrationAction());
    dispatch(carrierUploadAction());
  }, [dispatch]);

  useEffect(() => {
    const totalFormList = formData?.uploaded?.concat(formData?.system || []);
    setFormList(totalFormList);
  }, [formData]);

  useEffect(() => {
    filterFormList(); // Call the filter function whenever filters change
  }, [filters]);

  useEffect(() => {
    //Handling Browser back Button to move page to Case Listing
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    // When formList or recordsPerPage changes, update the records displayed
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const displayedRecords = formList?.slice(firstIndex, lastIndex);
    setRecords(displayedRecords);
  }, [formList, currentPage, recordsPerPage]);

  useEffect(() => {
    // When records change, update the total pages
    setTotalPages(Math.ceil(formList?.length / recordsPerPage));
  }, [formList, recordsPerPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setToastMessage(null);
    }, 3000);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const sorting = (col) => {
    setSortColumn(col);
    if (sortOrder === 'asc') {
      if (records?.length > 0) {
        const sortedData = [...records]?.sort((a, b) => {
          return a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1;
        });
        setRecords(sortedData);
      }
      setSortOrder('dsc');
    }
    if (sortOrder === 'dsc') {
      if (records?.length > 0) {
        const sortedData = [...records]?.sort((a, b) => {
          return a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1;
        });
        setRecords(sortedData);
      }
      setSortOrder('asc');
    }
  };
  const navigateToCarrierReUploadForm = (uploadId) => {
    navigate(`/re-upload-form?upload_id=${uploadId}`);
  };
  const onDownloadClick = (uploadId) => {
    setShowPDFLoader(true);
    axios({
      url: 'carrier-upload/pdf-download',
      method: 'POST',
      data: { upload_id: uploadId },
      responseType: 'blob',
    })
      .then((response) => {
        let disposition = response.headers['content-disposition'];
        const filename = disposition.split('filename=')[1].replace(/["]+/g, '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setShowPDFLoader(false);
        const toastMessageValue = {
          messageType: 'success',
          message: 'File downloaded successfully.',
        };
        setToastMessage(toastMessageValue);
      })
      .catch(function (error) {
        setShowPDFLoader(false);
        const toastMessageValue = {
          messageType: 'fail',
          message: 'Unexpected error!! Please contact administrator',
        };
        setToastMessage(toastMessageValue);
      });
  };

  const onCloseToastMessage = () => {
    setToastMessage(null);
  };

  const extractionStatus = (type) => {
    let extractionType = '';
    switch (type) {
      case 'pending':
        extractionType = 'pending';
        break;
      case 'completed':
        extractionType = 'completed';
        break;
      case 'failed':
        extractionType = 'failed';
        break;

      default:
        extractionType = '';
    }
    return extractionType;
  };

  const onRecordSelect = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset the current page to the first page when records per page change
    /** Reoving Sorting */
    setSortOrder('asc');
    setSortColumn(null);
  };

  const onPrevPageClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      /** Reoving Sorting */
      setSortOrder('asc');
      setSortColumn(null);
    }
  };

  const onNextPageClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      /** Reoving Sorting */
      setSortOrder('asc');
      setSortColumn(null);
    }
  };

  const onPageNumberClick = (number) => {
    if (number >= 1 && number <= totalPages) {
      setCurrentPage(number);
      /** Reoving Sorting */
      setSortOrder('asc');
      setSortColumn(null);
    }
  };

  const clearFilters = () => {
    setFilters({
      searchInput: '',
      formName: '',
      categoryName: '',
      carriers: [],
      formTypes: [],
      productCategories: [],
      states: [],
      products: [],
      categoryTags: [],
    });
  };
  const clearSearch = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchInput: '',
    }));
  };

  return (
    <>
      <Header />
      {process.env.REACT_APP_ENABLE_FILTERS === 'Y' && (
        <FormAdministrationFilters
          filters={filters}
          showFilters={showFilters}
          handleFilterChange={handleFilterChange}
          toggleFilters={toggleFilters}
          clearFilters={clearFilters}
          clearSearch={clearSearch}
        />
      )}

      <div className="formAdministration--wrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            {' '}
            <CarrierBreadCrumb
              currentPageName={'Form Administration'}
              showApplicationUpload={true}
            />
            <div className="formAdministration--content">
              <h2 className="formAdministration--heading">
                Form Administration
              </h2>
              <ExistingCarriersList
                formList={formList}
                filters={filters}
                records={records}
                sortOrder={sortOrder}
                sortColumn={sortColumn}
                onDownloadClick={onDownloadClick}
                navigateToCarrierReUploadForm={navigateToCarrierReUploadForm}
                extractionStatus={extractionStatus}
                sorting={sorting}
                onPageNumberClick={onPageNumberClick}
                onPrevPageClick={onPrevPageClick}
                onNextPageClick={onNextPageClick}
                onRecordSelect={onRecordSelect}
                totalPages={totalPages}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
                ITEMS_PER_PAGE_OPTIONS={ITEMS_PER_PAGE_OPTIONS}
                dropDownData={dropDownData}
                handleFilterChange={handleFilterChange}
              />

              {showPDFLoader && (
                <div className="formAdministration__loaderWrapper">
                  <span className="formAdministration__loader"></span>
                </div>
              )}
            </div>
          </>
        )}

        {toastMessage && (
          <CarrierAdminToastMessage
            messageType={toastMessage?.messageType}
            message={toastMessage?.message}
            onCloseToastMessage={onCloseToastMessage}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default CarrierFormAdministration;
