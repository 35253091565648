import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';
import FormField from '../../../ui/formField';
import '../foreginTravel.css';
const TraveledSection = (props) => {
  const [traveledLabel, setTraveledLabel] = useState({});

  const foreignTravelFieldData = useSelector((state) => state.ftFieldData);
  const { loading, error, ftData } = foreignTravelFieldData;

  useEffect(() => {
    setTraveledLabel(ftData?.data?.traveledLabel);
  }, [ftData?.data]);

  let handleChange = (i, e) => {
    let newFormValues = [...props.traveledFormData];
    newFormValues[i][e.target.name.slice(0, -1)] = e.target.value;
    props.setTraveledFormData(newFormValues);
  };

  let addFormFields = () => {
    props.setTraveledFormData([
      ...props.traveledFormData,
      { destination: '', date: '', duration: '', purpose: '' },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...props.traveledFormData];
    newFormValues.splice(i, 1);
    props.setTraveledFormData(newFormValues);
  };

  return (
    <>
      {props.traveledFormData.length > 0 &&
        props.traveledFormData.map((element, index) => {
          return (
            <AccordionAddMoreSection
              key={index}
              index={index}
              accordionHeading={`Travel Information - ${index + 1}`}
              onDeleteClick={index ? () => removeFormFields(index) : null}
            >
              <div className="flex" key={index}>
                <div
                  className={`label-value-wrapper destination-layout ${
                    !element.destination ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {traveledLabel.destination}
                  </label>
                  <div>
                    <input
                      type="text"
                      id="destination"
                      placeholder="Enter"
                      name="destination."
                      value={element.destination || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper no-margin-text-field layout-4">
                  <label className="field-label">{traveledLabel.date}</label>

                  <FormField
                    type="month"
                    name="date."
                    min="1900-01-01"
                    placeholder="Enter"
                    value={element.date || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-5 ${
                    !element.duration ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {traveledLabel.duration}
                  </label>

                  <div>
                    <input
                      type="text"
                      name="duration."
                      placeholder="Enter"
                      id="duration"
                      value={element.duration || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper purpose-layout">
                  <div>
                    <label className="field-label">
                      {traveledLabel.purpose}
                    </label>
                    <div
                      className="travel-radio-container"
                      onChange={(e) => handleChange(index, e)}
                    >
                      <div className="ft-radio-container">
                        <input
                          type="radio"
                          id={`business${index}`}
                          name={`purpose${index}`}
                          value="business"
                          checked={element.purpose === 'business'}
                        />
                        <label
                          htmlFor={`business${index}`}
                          className="travel-radio-label"
                        >
                          {traveledLabel.business}
                        </label>
                      </div>
                      <div className="ft-radio-container">
                        <input
                          type="radio"
                          id={`personal${index}`}
                          name={`purpose${index}`}
                          value="personal"
                          checked={element.purpose === 'personal'}
                        />
                        <label
                          htmlFor={`personal${index}`}
                          className="travel-radio-label"
                        >
                          {traveledLabel.personal}
                        </label>
                      </div>
                      <div className="ft-radio-container">
                        <input
                          type="radio"
                          id={`both${index}`}
                          name={`purpose${index}`}
                          value="both"
                          checked={element.purpose === 'both'}
                        />
                        <label
                          htmlFor={`both${index}`}
                          className="travel-radio-label"
                        >
                          {traveledLabel.both}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-1 field-remark-wrapper ${
                    !element.remarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{traveledLabel.remarks}</label>
                  <textarea
                    name="remarks."
                    placeholder="Enter"
                    id="remarks"
                    value={element.remarks || ''}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
                <div className="line-separation layout-1"></div>
              </div>
            </AccordionAddMoreSection>
          );
        })}
      <div>
        <span className="addFieldButton" onClick={() => addFormFields()}>
          {traveledLabel.addMore}
        </span>
      </div>
    </>
  );
};

export default TraveledSection;
