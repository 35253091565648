import './FlightPurposeTable.css';
import FormField from '../../ui/formField';

const FlightPurpose = (props) => {
  const onFieldChangeHandler = (event, key) => {
    props.setFlightPayloadData((prevState) => ({
      ...prevState,
      flightPurposeHours: {
        ...prevState.flightPurposeHours,
        [`${key}`]: {
          ...(Object.keys(prevState?.flightPurposeHours || {}).includes(key)
            ? prevState?.flightPurposeHours[`${key}`]
            : {}),
          [event.target.name]: event.target.value,
        },
      },
    }));
  };
  return (
    <div>
      <h3 className="professionalAdvisors-heading">
        Please describe the number of annual hours flown as a pilot or crew
        member and the purpose
      </h3>
      <div id="headers" className=" header-row flex">
        <div className="header-title layout-5">Purpose</div>
        <div className="header-title layout-5">Last 12 months</div>
        <div className="header-title layout-5">Last 24 months</div>
        <div className="header-title layout-5">Next 12 monts</div>
        <div className="header-title layout-5">Date last flown</div>
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">Pleasure</div>
        {props.flightData['pleasure']?.map((item, index) => {
          return (
            <div key={`pleasure_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                onChangeHandler={(e) => onFieldChangeHandler(e, 'pleasure')}
              />
            </div>
          );
        })}
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">Business</div>
        {props.flightData['business']?.map((item, index) => {
          return (
            <div key={`business_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                onChangeHandler={(e) => onFieldChangeHandler(e, 'business')}
              />
            </div>
          );
        })}
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">Commercial</div>
        {props.flightData['commercial']?.map((item, index) => {
          return (
            <div key={`commercial_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                onChangeHandler={(e) => onFieldChangeHandler(e, 'commercial')}
              />
            </div>
          );
        })}
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">Military</div>
        {props.flightData['military']?.map((item, index) => {
          return (
            <div key={`military_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                onChangeHandler={(e) => onFieldChangeHandler(e, 'military')}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FlightPurpose;
