import { useEffect, useRef, useState } from 'react';

const MappingMultiSelectDropdown = ({
  selectedValues,
  lockFields,
  options,
  labelKey,
  dropdownType,
  onFieldsClick,
  setCPWContextArray,
  setCPWContext,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [searchedString, setSearchedString] = useState('');

  const dropdownRef = useRef(null);

  const handleChange = (e) => {
    onFieldsClick();
    const { name, checked } = e.target;

    let tempDropdownOptions = dropdownOptions?.map((option) => {
      return option[labelKey] === name
        ? { ...option, isChecked: checked }
        : option;
    });
    setDropdownOptions(tempDropdownOptions);

    if (checked) {
      selectedValues?.push(name);
    } else {
      let index = selectedValues.indexOf(name);
      if (index > -1) selectedValues.splice(index, 1);
    }
    if (selectedValues?.length < 1) {
      setCPWContext('');
    }
  };

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);
  useEffect(() => {
    if (options && selectedValues) {
      setDropdownOptions(
        options.map((option) => ({
          ...option,
          isChecked: selectedValues.includes(option[labelKey]), // Check if the selected values  matches any of the options
        }))
      );
    }
  }, [options, selectedValues]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
        setSearchedString(''); // Clearing Search of dropdown when dropdown closes
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div ref={dropdownRef} className="carrierUploadFormDropdown">
      <div
        className={`carrierUploadFormDropdown--btn ${
          lockFields ? 'button--disable' : ''
        }`}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <span className="carrierUploadFormDropdown--selectedValues">
          {selectedValues?.length > 0 ? selectedValues?.join(',') : 'Select'}
        </span>
        <span
          className={`carrierUploadFormDropdown--btnIcon ${
            isActive ? 'carrierUploadFormDropdown--btnIcon__rotate' : ''
          }`}
        ></span>
      </div>
      {isActive && (
        <div className="carrierUploadFormDropdown--content">
          <div className="label-value-wrapper carrierUploadFormDropdown--search">
            <input
              type="text"
              value={searchedString}
              onChange={(e) => {
                setSearchedString(e.target.value);
              }}
            />
          </div>

          {dropdownOptions
            ?.sort((a, b) =>
              a[labelKey]?.toLowerCase() > b[labelKey]?.toLowerCase() ? 1 : -1
            )
            ?.filter((item) =>
              searchedString?.length > 1
                ? item[labelKey]
                    .toLowerCase()
                    .includes(searchedString.toLowerCase())
                : item[labelKey]
            )
            ?.map((item, index) => {
              return (
                <label
                  className="carrierUploadFormDropdown--item"
                  key={index}
                  htmlFor={
                    dropdownType +
                    '-' +
                    index +
                    '-' +
                    item[labelKey]?.replace(/\s+/g, '')
                  }
                >
                  <input
                    type="checkbox"
                    name={item[labelKey]}
                    onChange={(e) => {
                      handleChange(e);
                      let tempArr = [];
                      dropdownOptions?.filter((item) => {
                        selectedValues?.map((value) => {
                          if (value === item.field_label) {
                            tempArr.push(...item.contexts);
                          }
                        });
                      });
                      setCPWContextArray([...new Set(tempArr)]);
                    }}
                    id={
                      dropdownType +
                      '-' +
                      index +
                      '-' +
                      item[labelKey]?.replace(/\s+/g, '')
                    }
                    checked={item?.isChecked || false}
                    className="carrierUploadFormDropdown--itemCheckbox"
                  />
                  {item[labelKey]}
                </label>
              );
            })}
        </div>
      )}
    </div>
  );
};
export default MappingMultiSelectDropdown;
