// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rwh-radio {
  display: flex;
  margin-bottom: 5px;
}
.rwh-radio input[type="radio"] {
  margin: 5px;
}
.center-align {
  display: flex;
  align-items: center;
}
.space-between-radio {
  margin-left: 15px;
}
.rwh-radio input[type="radio"]:checked ~ span {
  color: #0177b5;
}
.rwh-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/radioWithHeading/radioWithHeading.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".rwh-radio {\n  display: flex;\n  margin-bottom: 5px;\n}\n.rwh-radio input[type=\"radio\"] {\n  margin: 5px;\n}\n.center-align {\n  display: flex;\n  align-items: center;\n}\n.space-between-radio {\n  margin-left: 15px;\n}\n.rwh-radio input[type=\"radio\"]:checked ~ span {\n  color: #0177b5;\n}\n.rwh-label {\n  font-size: 12px;\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
