import './LabelRadioOptions.css';
import PropTypes from 'prop-types';

const LabelRadioOptions = (props) => {
  const radioButtonOptions = props?.options;
  const radioButtonFields = radioButtonOptions?.map((item, index) => {
    return (
      <div key={index} className="labelRadioOptions__radioTabs">
        <label className="field-label">
          <input
            id={item.id}
            type="radio"
            name={item.name}
            value={item.value}
            defaultChecked={item.defaultChecked}
          />{' '}
          <span>{item.value}</span>
        </label>
      </div>
    );
  });
  return (
    <>
      <div className="labelRadioOptions">
        {props?.label && (
          <p className="labelRadioOptions__label">{props?.label}</p>
        )}

        {props?.options && (
          <div
            className="flex labelRadioOptions__radioTabsWrapper"
            onChange={props.onChangeHandler}
          >
            {radioButtonFields}
          </div>
        )}
      </div>
    </>
  );
};
export default LabelRadioOptions;

LabelRadioOptions.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onChangeHandler: PropTypes.func,
};
