import { useState } from 'react';
import GeneralQuestions from './generalQuestions';

import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { otherGeneralQuestionsAction } from '../../../actions/otherGeneralQuestions';
import { useEffect } from 'react';
import Loader from '../../loader';
import axios from '../../../axios/axios';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import CommonTextArea from '../../ui/commonTextArea';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
const OtherGeneralQuestions = (props) => {
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [generalQuestionsRadioOptions, setGeneralQuestionsRadioOptions] =
    useState({});
  const [generalQuestionsDetailsFields, setGeneralQuestionsDetailsFields] =
    useState({});
  const [aviationQuestionnaire, setAviationQuestionnaire] = useState({});
  const [avocationQuestionnaire, setAvocationQuestionnaire] = useState({});
  const [aviationTextAreaValue, setAviationTextAreaValue] = useState('');
  const [avocationTextAreaValue, setAvocationTextAreaValue] = useState('');
  const ogqFormData = useSelector((state) => state.ogqData);
  const { loading, error, formData } = ogqFormData;
  const dispatch = useDispatch();
  const sideNavData = useSelector((state) => state.pageNav);
  const aviationQues = formData?.data?.aviationQuestionnaire;
  const avocationQues = formData?.data?.avocationQuestionnaire;

  const onAviationTextChange = (e) => {
    setAviationTextAreaValue(e.target.value);
  };

  const onAvocationTextChange = (e) => {
    setAvocationTextAreaValue(e.target.value);
  };
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  useEffect(() => {
    const aviInitial = formData?.data?.aviationQuestionnaire?.options?.find(
      (item) => item.defaultChecked
    );
    if (aviInitial) {
      const key = aviInitial['id'];
      const value = aviInitial['value'];
      setAviationQuestionnaire({ [key]: value });
    }
    setAviationTextAreaValue(formData?.data?.aviationTextAreaValue?.value);
    const avoInitial = formData?.data?.avocationQuestionnaire?.options?.find(
      (item) => item.defaultChecked
    );
    if (avoInitial) {
      const key = avoInitial['id'];
      const value = avoInitial['value'];
      setAvocationQuestionnaire({ [key]: value });
    }
    setAvocationTextAreaValue(formData?.data?.avocationTextAreaValue?.value);
  }, [formData?.data]);
  useEffect(() => {
    dispatch(otherGeneralQuestionsAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    aviationQuestionnaire,
    aviationTextAreaValue: { 'aviation-textAreaValue': aviationTextAreaValue },
    avocationQuestionnaire,
    avocationTextAreaValue: {
      'avocation-textAreaValue': avocationTextAreaValue,
    },
    questions: {
      radioOptions: generalQuestionsRadioOptions,
      provideDetails: generalQuestionsDetailsFields,
    },
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s13',
      },
    });
  }, [payload]);
  const avoPayload = {
    aerialSportsCheckBoxes: ['flying'],
  };
  const aviationQuesChangeHandler = (e) => {
    if (e.target.value === 'yes') {
      axios
        .post(
          `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s16`,
          avoPayload
        )
        .then((response) => {})
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.detail === 'Not authenticated'
          ) {
            window.location.replace('/');
          }
          console.error('There was an error!', error);
        });
    }
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s13',
      },
    });
    setAviationQuestionnaire(() => ({
      [e.target.id]: e.target.value,
    }));

    if (e.target.id === 'aviationQuestionnaire-yes') {
      dispatch(pageNavigationAction({ f1v1s16: true, f1v1s18: true }));
    } else {
      dispatch(pageNavigationAction({ f1v1s18: false }));
    }
  };
  const avocationQuesChangeHandler = (e) => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s13',
      },
    });
    if (e.target.id === 'avocationQuestionnaire-yes') {
      dispatch(pageNavigationAction({ f1v1s16: true }));
    } else {
      dispatch(pageNavigationAction({ f1v1s16: false, f1v1s18: false }));
    }
    setAvocationQuestionnaire(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s13`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/familyhealthhistory"
        nextPath="/form/foreigntravelinformation"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s13"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      {sideNavData?.loading && <Loader />}
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {formData?.data && (
            <>
              <GeneralQuestions
                setGeneralQuestionsRadioOptions={
                  setGeneralQuestionsRadioOptions
                }
                setGeneralQuestionsDetailsFields={
                  setGeneralQuestionsDetailsFields
                }
              />
              <LabelRadioButtons
                label={avocationQues?.label}
                options={avocationQues?.options}
                id={avocationQues?.id}
                onChangeHandler={avocationQuesChangeHandler}
              />
              {avocationQuestionnaire['avocationQuestionnaire-yes'] ===
                'yes' && (
                <CommonTextArea
                  fieldData={
                    formData?.data && formData?.data?.avocationTextAreaValue
                  }
                  onChangeHandler={onAvocationTextChange}
                />
              )}
              <div className="line-separation"></div>

              <LabelRadioButtons
                label={aviationQues?.label}
                options={aviationQues?.options}
                id={aviationQues?.id}
                onChangeHandler={aviationQuesChangeHandler}
              />
              {aviationQuestionnaire['aviationQuestionnaire-yes'] === 'yes' && (
                <CommonTextArea
                  fieldData={
                    formData?.data && formData?.data?.aviationTextAreaValue
                  }
                  onChangeHandler={onAviationTextChange}
                />
              )}
            </>
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default OtherGeneralQuestions;
