import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import QuestionSection from './questionSection';
import { useState, useEffect } from 'react';
import './AviationQuestionnaire.css';
import FlightPurpose from './FlightPurposeTable';
import FormField from '../../ui/formField';
import axios from '../../../axios/axios';
import Loader from '../../loader';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { useDispatch, useSelector } from 'react-redux';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import { aviationAction } from '../../../actions/aviationAction';

const AviationQuestionnaire = () => {
  const [licenses, setLicenses] = useState([]);
  const [aviationActivities, setAviationActivities] = useState([]);
  const [medicalCertificate, setMedicalCertificate] = useState([]);
  const [militaryBranch, setMilitaryBranch] = useState([]);
  const [dutyAssignments, setDutyAssignments] = useState([]);
  const [aircraftType, setAircraftType] = useState([]);
  const [noLicense, setNoLicense] = useState(false);
  const [noActivities, setNoActivities] = useState(false);
  const [noMedicalCertificate, setNoMedicalCertificate] = useState(false);
  const [flightPayloadData, setFlightPayloadData] = useState({});
  const [formData, setFormData] = useState({});

  const [
    medicalCertificateRadioFieldStatus,
    setMedicalCertificateRadioFieldStatus,
  ] = useState({});
  const [
    accidentOrViolationRadioFieldStatus,
    setAccidentOrViolationRadioFieldStatus,
  ] = useState({});
  const [
    pastMilitaryConnectionTravelRadioFieldStatus,
    setPastMilitaryConnectionTravelRadioFieldStatus,
  ] = useState({});
  const [crewMemberRadioFieldStatus, setCrewMemberRadioFieldStatus] = useState(
    {}
  );

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const onCloseToastMessage = () => {
    showToastMessage(false);
  };
  const dispatch = useDispatch();

  const pageData = useSelector((state) => state.aviationData);
  const { loading, error, apiData } = pageData;

  useEffect(() => {
    dispatch(aviationAction());
  }, [dispatch]);

  useEffect(() => {
    setLicenses(
      (
        apiData?.data?.aviationLicenseQuestions?.checkOptions?.filter(
          (item) => item.defaultChecked
        ) || []
      )?.map((item) => item.value)
    );
    setAviationActivities(
      (
        apiData?.data?.aviationActivitiesQuestions?.checkOptions?.filter(
          (item) => item.defaultChecked
        ) || []
      )?.map((item) => item.value)
    );
    setMedicalCertificate(
      (
        apiData?.data?.aviationMedicalCertificateQuestions?.checkOptions?.filter(
          (item) => item.defaultChecked
        ) || []
      )?.map((item) => item.value)
    );
    setMilitaryBranch(
      (
        apiData?.data?.militaryBranchQuestions?.checkOptions?.filter(
          (item) => item.defaultChecked
        ) || []
      )?.map((item) => item.value)
    );

    setDutyAssignments(
      (
        apiData?.data?.dutyAssignmentsQuestions?.checkOptions?.filter(
          (item) => item.defaultChecked
        ) || []
      )?.map((item) => item.value)
    );
    setAircraftType(
      (
        apiData?.data?.aircraftTypeQuestions?.checkOptions?.filter(
          (item) => item.defaultChecked
        ) || []
      )?.map((item) => item.value)
    );
    setNoLicense(apiData?.data?.noLicenseCheckbox?.value);
    setNoMedicalCertificate(apiData?.data?.noMedicalCertificateCheckbox?.value);

    const initialMedicalCertificate =
      apiData?.data?.medicalCertificateRadioFieldStatus?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialMedicalCertificate) {
      const key = initialMedicalCertificate['id'];
      const value = initialMedicalCertificate['value'];
      setMedicalCertificateRadioFieldStatus({ [key]: value });
    }

    const initialAccidentOrViolation =
      apiData?.data?.accidentOrViolationRadioFieldStatus?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialAccidentOrViolation) {
      const key = initialAccidentOrViolation['id'];
      const value = initialAccidentOrViolation['value'];
      setAccidentOrViolationRadioFieldStatus({ [key]: value });
    }
    const initialPastMilitary =
      apiData?.data?.pastMilitaryConnectionTravelRadioFieldStatus?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialPastMilitary) {
      const key = initialPastMilitary['id'];
      const value = initialPastMilitary['value'];
      setPastMilitaryConnectionTravelRadioFieldStatus({ [key]: value });
    }
    const initialCrewMember =
      apiData?.data?.crewMemberRadioFieldStatus?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialCrewMember) {
      const key = initialCrewMember['id'];
      const value = initialCrewMember['value'];
      setCrewMemberRadioFieldStatus({ [key]: value });
    }
  }, [apiData?.data]);

  const onFieldChangeHandler = (event, key) => {
    setFormData((prevState) => ({
      ...prevState,
      [`${key}`]: {
        ...prevState[`${key}`],
        [event.target.name]: noMedicalCertificate ? '' : event.target.value,
      },
    }));
  };

  const medicalCertificateRadioHandler = (e) => {
    setMedicalCertificateRadioFieldStatus(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const accidentOrViolationRadioHandler = (e) => {
    setAccidentOrViolationRadioFieldStatus(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const pastMilitaryConnectionRadioHandler = (e) => {
    setPastMilitaryConnectionTravelRadioFieldStatus(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const crewMemberRadioHandler = (e) => {
    setCrewMemberRadioFieldStatus(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    aviationLicenseQuestions: licenses,
    aviationActivitiesQuestions: aviationActivities,
    aviationMedicalCertificateQuestions: medicalCertificate,
    militaryBranchQuestions: militaryBranch,
    dutyAssignmentsQuestions: dutyAssignments,
    aircraftTypeQuestions: aircraftType,
    noLicenseCheckbox: noLicense,
    noActivitiesCheckbox: noActivities,
    noMedicalCertificateCheckbox: noMedicalCertificate,
    ...flightPayloadData,
    ...formData,
    medicalCertificateRadioFieldStatus,
    accidentOrViolationRadioFieldStatus,
    pastMilitaryConnectionTravelRadioFieldStatus,
    crewMemberRadioFieldStatus,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s18',
      },
    });
  }, [payload]);

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s18`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        setShowDataSaveLoader(false);
        console.error('There was an error!', error);
      });
  };

  return (
    <>
      <CommonFormHeader
        backPath="/form/avocationquestionnaire"
        nextPath="/form/existinginsurance"
        pageHeading={apiData?.metadata?.label}
        caseIdValue={apiData?.common_details?.case_id}
        onSaveFormDataHandler={onSaveFormDataHandler}
        isCompleted={apiData?.metadata?.isCompleted}
        sectionId="f1v1s18"
        userName={apiData?.common_details?.name}
        productSubType={apiData?.common_details?.product_subtype}
        formId={apiData?.common_details?.form_id}
        otherCasesInfo={apiData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {apiData && apiData?.data && (
            <>
              {noLicense && (
                <p className="professionalAdvisors-heading">
                  {apiData?.data?.aviationLicenseQuestions?.label}
                </p>
              )}
              {!noLicense && (
                <QuestionSection
                  setCheckBoxValue={setLicenses}
                  checkboxValue={licenses}
                  checkboxFieldData={apiData?.data?.aviationLicenseQuestions}
                />
              )}
              <div className={'noLicenseCheckbox'}>
                <label className="noLicenseLabel" htmlFor={'noLicenseCheckbox'}>
                  {apiData?.data?.noLicenseCheckbox.title}
                </label>
                <input
                  type="checkbox"
                  value={apiData?.data?.noLicenseCheckbox.value}
                  defaultChecked={apiData?.data?.noLicenseCheckbox.value}
                  id={apiData?.data?.noLicenseCheckbox.id}
                  name={apiData?.data?.noLicenseCheckbox.name}
                  onChange={(e) => {
                    setNoLicense(e.target.checked);
                    setLicenses([]);
                  }}
                />
              </div>
              <div className="line-separation "></div>
              <FlightPurpose
                flightData={apiData?.data?.flightPurposeHours}
                setFlightPayloadData={setFlightPayloadData}
              />
              <div className="totalHourseOutsideUSWrapper">
                <h3>{apiData?.data?.totalHoursOutsideUSTitle}</h3>
                <div className="flex">
                  {apiData?.data?.totalHoursOutsideUSFieldOptions.map(
                    (item, index) => {
                      return (
                        <FormField
                          key={index}
                          label={item.label}
                          id={item.id}
                          value={item.value}
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          layoutType={item.layoutType}
                          onChangeHandler={(e) =>
                            onFieldChangeHandler(
                              e,
                              'totalHoursOutsideUSFieldOptions'
                            )
                          }
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <div className="line-separation "></div>
              <div>
                <QuestionSection
                  setCheckBoxValue={setAviationActivities}
                  checkboxValue={aviationActivities}
                  checkboxFieldData={apiData?.data?.aviationActivitiesQuestions}
                />
              </div>
              <div className="line-separation "></div>
              <div>
                {noMedicalCertificate && (
                  <p className="professionalAdvisors-heading">
                    {apiData?.data?.aviationMedicalCertificateQuestions?.label}
                  </p>
                )}
                {!noMedicalCertificate && (
                  <QuestionSection
                    setCheckBoxValue={setMedicalCertificate}
                    checkboxValue={medicalCertificate}
                    checkboxFieldData={
                      apiData?.data?.aviationMedicalCertificateQuestions
                    }
                  />
                )}
                <div className={'noLicenseCheckbox'}>
                  <label
                    className="noLicenseLabel"
                    htmlFor={'noMedicalCertificateCheckbox'}
                  >
                    {apiData?.data?.noMedicalCertificateCheckbox.title}
                  </label>
                  <input
                    type="checkbox"
                    value={apiData?.data?.noMedicalCertificateCheckbox.value}
                    defaultChecked={
                      apiData?.data?.noMedicalCertificateCheckbox.value
                    }
                    id={apiData?.data?.noMedicalCertificateCheckbox.id}
                    name={apiData?.data?.noMedicalCertificateCheckbox.name}
                    onChange={(e) => {
                      setNoMedicalCertificate(e.target.checked);
                      setMedicalCertificate([]);
                    }}
                  />
                </div>
                {!noMedicalCertificate && (
                  <div className="last-renewal-container">
                    <FormField
                      label={apiData?.data?.medicalCertificateLastRenewal.label}
                      id={apiData?.data?.medicalCertificateLastRenewal.id}
                      value={apiData?.data?.medicalCertificateLastRenewal.value}
                      type={apiData?.data?.medicalCertificateLastRenewal.type}
                      name={apiData?.data?.medicalCertificateLastRenewal.name}
                      placeholder={
                        apiData?.data?.medicalCertificateLastRenewal.placeholder
                      }
                      layoutType={
                        apiData?.data?.medicalCertificateLastRenewal.layoutType
                      }
                      onChangeHandler={(e) =>
                        onFieldChangeHandler(e, 'medicalCertificateLastRenewal')
                      }
                    />
                  </div>
                )}
                <div className="line-separation "></div>
                <LabelRadioButtons
                  label={
                    apiData?.data?.medicalCertificateRadioFieldStatus?.label
                  }
                  options={
                    apiData?.data?.medicalCertificateRadioFieldStatus
                      ?.radioOptions
                  }
                  id={apiData?.data?.medicalCertificateRadioFieldStatus?.id}
                  onChangeHandler={medicalCertificateRadioHandler}
                />
                <div
                  className={
                    medicalCertificateRadioFieldStatus[
                      'medicalCertificateRadioFieldStatus-yes'
                    ] === 'yes'
                      ? 'show-component'
                      : 'hide-component'
                  }
                >
                  <FormField
                    label={apiData?.data?.medicalCertificateDeniedDetail.label}
                    id={apiData?.data?.medicalCertificateDeniedDetail.id}
                    value={apiData?.data?.medicalCertificateDeniedDetail.value}
                    type={apiData?.data?.medicalCertificateDeniedDetail.type}
                    name={apiData?.data?.medicalCertificateDeniedDetail.name}
                    placeholder={
                      apiData?.data?.medicalCertificateDeniedDetail.placeholder
                    }
                    layoutType={
                      apiData?.data?.medicalCertificateDeniedDetail.layoutType
                    }
                    onChangeHandler={(e) =>
                      onFieldChangeHandler(e, 'medicalCertificateDeniedDetail')
                    }
                  />
                </div>

                <LabelRadioButtons
                  label={
                    apiData?.data?.accidentOrViolationRadioFieldStatus?.label
                  }
                  options={
                    apiData?.data?.accidentOrViolationRadioFieldStatus
                      ?.radioOptions
                  }
                  id={apiData?.data?.accidentOrViolationRadioFieldStatus?.id}
                  onChangeHandler={accidentOrViolationRadioHandler}
                />
                {accidentOrViolationRadioFieldStatus[
                  'accidentOrViolationRadioFieldStatus-yes'
                ] === 'yes' && (
                  <FormField
                    label={apiData?.data?.accidentOrViolationDetail.label}
                    id={apiData?.data?.accidentOrViolationDetail.id}
                    value={apiData?.data?.accidentOrViolationDetail.value}
                    type={apiData?.data?.accidentOrViolationDetail.type}
                    name={apiData?.data?.accidentOrViolationDetail.name}
                    placeholder={
                      apiData?.data?.accidentOrViolationDetail.placeholder
                    }
                    layoutType={
                      apiData?.data?.accidentOrViolationDetail.layoutType
                    }
                    onChangeHandler={(e) =>
                      onFieldChangeHandler(e, 'accidentOrViolationDetail')
                    }
                  />
                )}
              </div>

              <LabelRadioButtons
                label={
                  apiData?.data?.pastMilitaryConnectionTravelRadioFieldStatus
                    ?.label
                }
                options={
                  apiData?.data?.pastMilitaryConnectionTravelRadioFieldStatus
                    ?.radioOptions
                }
                id={
                  apiData?.data?.pastMilitaryConnectionTravelRadioFieldStatus
                    ?.id
                }
                onChangeHandler={pastMilitaryConnectionRadioHandler}
              />
              {pastMilitaryConnectionTravelRadioFieldStatus[
                'pastMilitaryConnectionTravelRadioFieldStatus-yes'
              ] === 'yes' && (
                <>
                  <QuestionSection
                    setCheckBoxValue={setMilitaryBranch}
                    checkboxValue={militaryBranch}
                    checkboxFieldData={apiData?.data?.militaryBranchQuestions}
                  />
                  {militaryBranch.includes('other') && (
                    <FormField
                      id={apiData?.data?.militaryBranchOtherOption.id}
                      name={apiData?.data?.militaryBranchOtherOption.name}
                      type={apiData?.data?.militaryBranchOtherOption.type}
                      value={apiData?.data?.militaryBranchOtherOption.value}
                      layoutType={
                        apiData?.data?.militaryBranchOtherOption.layoutType
                      }
                      onChangeHandler={(e) =>
                        onFieldChangeHandler(e, 'militaryBranchOtherOption')
                      }
                      label={apiData?.data?.militaryBranchOtherOption.label}
                    />
                  )}
                </>
              )}
              <div>
                <LabelRadioButtons
                  label={apiData?.data?.crewMemberRadioFieldStatus?.label}
                  options={
                    apiData?.data?.crewMemberRadioFieldStatus?.radioOptions
                  }
                  id={apiData?.data?.crewMemberRadioFieldStatus?.id}
                  onChangeHandler={crewMemberRadioHandler}
                />
                {crewMemberRadioFieldStatus[
                  'crewMemberRadioFieldStatus-yes'
                ] === 'yes' && (
                  <FormField
                    label={apiData?.data?.crewMemberDetail.label}
                    id={apiData?.data?.crewMemberDetail.id}
                    value={apiData?.data?.crewMemberDetail.value}
                    type={apiData?.data?.crewMemberDetail.type}
                    name={apiData?.data?.crewMemberDetail.name}
                    placeholder={apiData?.data?.crewMemberDetail.placeholder}
                    layoutType={apiData?.data?.crewMemberDetail.layoutType}
                    onChangeHandler={(e) =>
                      onFieldChangeHandler(e, 'crewMemberDetail')
                    }
                  />
                )}
              </div>
              <div className="line-separation "></div>
              <QuestionSection
                setCheckBoxValue={setDutyAssignments}
                checkboxValue={dutyAssignments}
                checkboxFieldData={apiData?.data?.dutyAssignmentsQuestions}
              />
              {dutyAssignments.includes('other') && (
                <FormField
                  id={apiData?.data?.dutyAssignmentOtherOption.id}
                  name={apiData?.data?.dutyAssignmentOtherOption.name}
                  type={apiData?.data?.dutyAssignmentOtherOption.type}
                  value={apiData?.data?.dutyAssignmentOtherOption.value}
                  layoutType={
                    apiData?.data?.dutyAssignmentOtherOption.layoutType
                  }
                  onChangeHandler={(e) =>
                    onFieldChangeHandler(e, 'dutyAssignmentOtherOption')
                  }
                  label={apiData?.data?.dutyAssignmentOtherOption.label}
                />
              )}
              <div className="line-separation "></div>
              <div>
                <QuestionSection
                  setCheckBoxValue={setAircraftType}
                  checkboxValue={aircraftType}
                  checkboxFieldData={apiData?.data?.aircraftTypeQuestions}
                />
                {aircraftType.includes('other') && (
                  <FormField
                    id={apiData?.data?.aircraftTypeOtherOption.id}
                    name={apiData?.data?.aircraftTypeOtherOption.name}
                    type={apiData?.data?.aircraftTypeOtherOption.type}
                    value={apiData?.data?.aircraftTypeOtherOption.value}
                    layoutType={
                      apiData?.data?.aircraftTypeOtherOption.layoutType
                    }
                    onChangeHandler={(e) =>
                      onFieldChangeHandler(e, 'aircraftTypeOtherOption')
                    }
                    label={apiData?.data?.aircraftTypeOtherOption.label}
                  />
                )}
              </div>
            </>
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default AviationQuestionnaire;
