// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxTabs {
  position: relative;
  display: inline-block;
}
.checkboxField {
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.checkboxTabs .customCheckbox {
  padding: 8px 40px;
  display: flex;
  border: 1px solid #d6d6d6;
  margin-right: 16px;
  border-radius: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
}
.customCheckbox__image {
  display: none;
  margin-right: 5px;
  position: absolute;
  left: 18px;
  top: 10px;
}
.customCheckbox__label {
  font-size: 14px;
  color: #222222;
  display: inline-block;
}
.checkboxField:checked ~ .customCheckbox {
  border-color: #0177b5;
}
.checkboxField:checked ~ .customCheckbox .customCheckbox__image {
  display: inline-block;
}
.checkboxField:checked ~ .customCheckbox .customCheckbox__label {
  color: #0177b5;
}
.checkboxField:disabled ~ .customCheckbox {
  background-color: #f1f4f6;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/formField/Checkboxes.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,SAAS;EACT,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,OAAO;EACP,MAAM;AACR;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;AACA;EACE,eAAe;EACf,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".checkboxTabs {\n  position: relative;\n  display: inline-block;\n}\n.checkboxField {\n  opacity: 0;\n  height: 0;\n  width: 0;\n  margin: 0;\n  position: absolute;\n  left: 0;\n  top: 0;\n}\n.checkboxTabs .customCheckbox {\n  padding: 8px 40px;\n  display: flex;\n  border: 1px solid #d6d6d6;\n  margin-right: 16px;\n  border-radius: 20px;\n  margin-bottom: 12px;\n  cursor: pointer;\n  position: relative;\n}\n.customCheckbox__image {\n  display: none;\n  margin-right: 5px;\n  position: absolute;\n  left: 18px;\n  top: 10px;\n}\n.customCheckbox__label {\n  font-size: 14px;\n  color: #222222;\n  display: inline-block;\n}\n.checkboxField:checked ~ .customCheckbox {\n  border-color: #0177b5;\n}\n.checkboxField:checked ~ .customCheckbox .customCheckbox__image {\n  display: inline-block;\n}\n.checkboxField:checked ~ .customCheckbox .customCheckbox__label {\n  color: #0177b5;\n}\n.checkboxField:disabled ~ .customCheckbox {\n  background-color: #f1f4f6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
