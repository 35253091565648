import './FormField.css';
import PropTypes from 'prop-types';
import { usaStatefieldValue } from './stateJson';

const SelectBox = (props) => {
  const selectBox = (
    <>
      {props.fieldData.dropdownType !== undefined &&
      props.fieldData.dropdownType === 'state' ? (
        <select
          name={props.fieldData.name}
          id={props.fieldData.id}
          defaultValue={props.fieldData.value}
          onChange={props.fieldData.onChangeHandler}
          key={props.fieldData.id}
        >
          {usaStatefieldValue &&
            usaStatefieldValue.length > 0 &&
            usaStatefieldValue.map((option, index) => {
              return (
                <option
                  value={
                    option.name.toLowerCase() === 'select' ? '' : option.name
                  }
                  key={index}
                >
                  {option.name}
                </option>
              );
            })}
        </select>
      ) : (
        <select
          key={props.fieldData.id}
          name={props.fieldData.name}
          id={props.fieldData.id}
          defaultValue={props.fieldData.value}
          onChange={props.fieldData.onChangeHandler}
          disabled={props.fieldData.disabled}
        >
          {props.fieldData.selectOptions &&
            props.fieldData.selectOptions.length > 0 &&
            props.fieldData.selectOptions.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              );
            })}
        </select>
      )}
    </>
  );
  const addMoreState = (
    <select
      name={props.fieldData.name}
      id={props.fieldData.id}
      value={props.fieldData.value}
      onChange={props.fieldData.onChangeHandler}
      key={props.fieldData.id}
    >
      {usaStatefieldValue &&
        usaStatefieldValue.length > 0 &&
        usaStatefieldValue.map((option, index) => {
          return (
            <option
              value={option.name.toLowerCase() === 'select' ? '' : option.name}
              key={index}
            >
              {option.name}
            </option>
          );
        })}
    </select>
  );
  return (
    <div
      className={`label-value-wrapper ${
        props.fieldData.layoutType ? props.fieldData.layoutType : ''
      }`}
      key={props.fieldData.id}
    >
      {props.fieldData.label && (
        <label className="field-label" htmlFor={props.fieldData.id}>
          {props.fieldData.label}
        </label>
      )}

      <div>
        {props.fieldData.addMoreField && props.fieldData.addMoreField
          ? addMoreState
          : selectBox}
      </div>
    </div>
  );
};
export default SelectBox;

SelectBox.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fieldDataonChangeHandler: PropTypes.func,
};
