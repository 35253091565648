import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';

const LifeInsuranceSectionAnnuity = (props) => {
  const pageData = useSelector((state) => state.existingAnnuityData);

  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };

  const { loading, error, formData } = pageData;
  const onFieldChangeHandler = (e) => {
    props.setLifeInsuranceData((prevState) => ({
      ...prevState,

      [e.target.name]:
        e.target.dataset.validationtype === 'financialValue'
          ? removeCommaSeparator(e.target.value)
          : e.target.value,
    }));
  };
  return (
    <div className="flex">
      {formData?.data?.lifeInsuranceFields.map((item, index) => {
        return (
          <FormField
            key={index}
            label={item.label}
            id={item.id}
            value={item.value}
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            layoutType={item.layoutType}
            onChangeHandler={onFieldChangeHandler}
            selectOptions={item.selectOptions}
            dropdownType={item.dropdownType}
            validationType={item.validationType}
          />
        );
      })}
    </div>
  );
};

export default LifeInsuranceSectionAnnuity;
