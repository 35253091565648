import React, { useEffect } from 'react';
import { useToast } from './ToastContext';
import './Toast.css';
import ToastSuccessIcon from './../../assets/images/icon-toastMessage.svg';

const Toast = ({ toast }) => {
  const { removeToast, toasts } = useToast();

  useEffect(() => {
    const hideOtherToasts = () => {
      toasts.forEach((t) => {
        if (t.id !== toast.id) {
          removeToast(t.id);
        }
      });
    };

    hideOtherToasts();

    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, 3000);

    return () => clearTimeout(timer);
  }, [toast.id, toasts, removeToast]);

  return (
    <div className={`toast toast-${toast.type}`}>
      {toast.type === 'success' ? (
        <img
          src={ToastSuccessIcon}
          alt="Toast Message Icon"
          className="toast--iconSuccess"
        />
      ) : (
        <span className="toast--iconFail">!</span>
      )}
      <span>{toast.message}</span>
    </div>
  );
};

export default Toast;
