import {
  ANNUITY_DATA_REQUEST,
  ANNUITY_DATA_SUCCESS,
  ANNUITY_DATA_FAIL,
} from '../constants/existingAnnuityConstants';

export const exitingAnnuityReducer = (state = { formData: [] }, action) => {
  switch (action.type) {
    case ANNUITY_DATA_REQUEST:
      return { loading: true, formData: [] };
    case ANNUITY_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case ANNUITY_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
