import axios from '../axios/axios';
import {
  FAMILY_HEALTH_HISTORY_DATA_REQUEST,
  FAMILY_HEALTH_HISTORY_DATA_SUCCESS,
  FAMILY_HEALTH_HISTORY_DATA_FAIL,
} from '../constants/familyHealthHistoryConstants';

export const familyHealthHistoryAction = () => async (dispatch) => {
  try {
    dispatch({ type: FAMILY_HEALTH_HISTORY_DATA_REQUEST });
    const { data } = await axios.post('/rpiq-form-fetch', {
      section: 'f1v1s9',
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: FAMILY_HEALTH_HISTORY_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: FAMILY_HEALTH_HISTORY_DATA_FAIL,
      payload: error,
    });
  }
};
