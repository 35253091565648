// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy-details-fields{
    display: flex;
    -webkit-display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/policyDetails/PolicyDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,8BAA8B;AAClC","sourcesContent":[".policy-details-fields{\n    display: flex;\n    -webkit-display:flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
