import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notesAction } from '../../actions/notesAction';
import Note from './note';
import AddNote from './addNote';
import axios from '../../axios/axios';
const Notes = (props) => {
  const [notesList, setNotesList] = useState([]);
  const notesData = useSelector((state) => state.notesData);
  const { loading, error, formData } = notesData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notesAction());
  }, [dispatch]);

  useEffect(() => {
    setNotesList(formData?.data);
  }, [formData?.data]);

  const closeNoteSection = () => {
    props.setOpenNotesSection(false);
  };
  const addComment = (text) => {
    axios
      .post(`/rpiq-form-note-save`, {
        form_id: localStorage.getItem('formId'),
        note_text: text,
      })
      .then((response) => {
        setNotesList([response?.data?.data, ...notesList]);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };

  const deleteNote = (noteId) => {
    axios
      .post(`/rpiq-form-note-delete`, {
        form_id: localStorage.getItem('formId'),
        note_id: noteId,
      })
      .then((response) => {
        const updatedNotesList = notesList.filter(
          (notesList) => notesList.id !== noteId
        );
        setNotesList(updatedNotesList);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };

  return (
    <>
      <ul className="notesSection__listing">
        {notesList?.map((item, index) => {
          return <Note key={item.id} note={item} deleteNote={deleteNote} />;
        })}
        {notesList?.length === 0 && (
          <p className="notesSection__empty">No notes for this Case Id</p>
        )}
      </ul>
      <AddNote handleSubmit={addComment} closeNoteSection={closeNoteSection} />
    </>
  );
};
export default Notes;
