// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-fields-wrapper {
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
}
.form-fields-wrapper.financialForm-field-wrapper {
  background: #f4f7fa;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/formFieldsWrapper/FormFieldsWrapper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".form-fields-wrapper {\n  padding: 20px;\n  background: #fff;\n  margin-bottom: 20px;\n}\n.form-fields-wrapper.financialForm-field-wrapper {\n  background: #f4f7fa;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
