import { Link } from 'react-router-dom';
import './CommonFormHeader.css';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { RESET_FORM_FIELD_ERROR } from '../../constants/formValidationConstants';
import axios from '../../axios/axios';
import {
  RESET_SECONDRY_DATA,
  RESET_SEND_DATA,
} from '../../constants/sendDataConstants';
import { useNavigate } from 'react-router-dom';
import { pageNavigationAction } from '../../actions/pageNavigationAction';
import Loader from '../loader';
import { purposeOfCoverageAction } from '../../actions/purposeOfCoverageAction';
import { proposedInsuredAction } from '../../actions/proposedInsuredAction';
import { policyOwnerAction } from '../../actions/policyOwnerAction';
import { physicianInfomationAction } from '../../actions/physicianInfomationAction';
import { healthLifeStyleAction } from '../../actions/healthLifeStyleAction';
import { exerciseAction } from '../../actions/exerciseAction';
import { familyHealthHistoryAction } from '../../actions/familyHealthHistoryAction';
import { professionalAdvisorsAction } from '../../actions/professionalAdvisorsAction';
import { otherGeneralQuestionsAction } from '../../actions/otherGeneralQuestions';
import { exitingInsuranceAction } from '../../actions/exitingInsuranceAction';
import { foreignTravelAction } from '../../actions/foreignTravelAction';
import { avocationAction } from '../../actions/avocationAction';
import { foreignResidencyAction } from '../../actions/foreignResidencyAction';
import { financialInfoAction } from '../../actions/financialInfoAction';
import { longTermCareAction } from '../../actions/longTermCareAction';
import { beneficiaryAction } from '../../actions/beneficiaryAction';
import { businessInsuranceAction } from '../../actions/businessInsuranceAction';
import CopyCaseDataModal from '../modal/copyOtherCasesDataModal';
import BreadCrumb from '../ui/breadCrumb';

const CommonFormHeader = (props) => {
  const [confirm, setConfirm] = useState();
  const [showCopyCaseDataModal, setShowCopyCaseDataModal] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const pageNavData = useSelector((state) => state.pageNav);

  const dispatch = useDispatch();
  useEffect(() => {
    setConfirm(props.isCompleted);
  }, [props.isCompleted]);
  useEffect(() => {
    setConfirm(props.isCompleted);
  }, [props.isCompleted]);
  const payload = {
    form_id: localStorage.getItem('formId'),
    section_id: props.sectionId,
    is_completed: !confirm,
  };

  const errorFields = useSelector(
    (state) => state.validationFields.invalidFields
  );

  const formData = useSelector((state) => state.sendData);
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_FORM_FIELD_ERROR,
      });
    };
  }, []);

  const pageChangeHandler = (e) => {
    e.preventDefault();

    if (formData.secondrySectionId) {
      try {
        axios
          .post(
            `/rpiq-form-save/${localStorage.getItem('formId')}/${
              formData.secondrySectionId
            }`,
            formData.secondryPayload
          )
          .then((response) => {
            // showToastMessage(true);
          })

          .catch((error) => {
            if (
              error?.response?.status === 401 ||
              error?.response?.detail === 'Not authenticated'
            ) {
              window.location.replace('/');
            }
            console.error('There was an error!', error);
          });
      } finally {
        dispatch({
          type: RESET_SECONDRY_DATA,
        });
      }
    }
    if (formData.sectionId) {
      try {
        axios
          .post(
            `/rpiq-form-save/${localStorage.getItem('formId')}/${
              formData.sectionId
            }`,
            formData.payload
          )
          .then((response) => {
            // showToastMessage(true);
          })

          .catch((error) => {
            if (
              error?.response?.status === 401 ||
              error?.response?.detail === 'Not authenticated'
            ) {
              window.location.replace('/');
            }
            console.error('There was an error!', error);
          });
      } finally {
        dispatch({
          type: RESET_SEND_DATA,
        });

        navigate(e.target.pathname, { replace: true });
      }
    } else {
      navigate(e.target.pathname, { replace: true });
    }
  };

  const onSaveClick = () => {
    props.onSaveFormDataHandler();
  };
  const fetchPageData = () => {
    dispatch(pageNavigationAction());
    switch (props?.sectionId) {
      case 'f1v1s1':
        dispatch(purposeOfCoverageAction());
        break;
      case 'f1v1s2':
        //do nothing policy owner page
        break;
      case 'f1v1s3':
        dispatch(proposedInsuredAction());
        break;
      case 'f1v1s4':
        dispatch(policyOwnerAction());
        break;
      case 'f1v1s6':
        dispatch(physicianInfomationAction());
        break;
      case 'f1v1s7':
        dispatch(healthLifeStyleAction());
        break;
      case 'f1v1s8':
        dispatch(exerciseAction());
        break;
      case 'f1v1s9':
        dispatch(familyHealthHistoryAction());
        break;
      case 'f1v1s12':
        dispatch(professionalAdvisorsAction());
        break;
      case 'f1v1s13':
        dispatch(otherGeneralQuestionsAction());
        break;
      case 'f1v1s14':
        dispatch(exitingInsuranceAction());
        break;
      case 'f1v1s15':
        dispatch(foreignTravelAction());
        break;
      case 'f1v1s16':
        dispatch(avocationAction());
        break;
      case 'f1v1s17':
        dispatch(foreignResidencyAction());
        break;
      case 'f1v1s18':
        //aviation action missing
        break;
      case 'f1v1s19':
        dispatch(financialInfoAction());
        break;
      case 'f1v1s20':
        dispatch(longTermCareAction());
        break;
      case 'f1v1s21':
        dispatch(beneficiaryAction());
        break;
      case 'f1v1s22':
        dispatch(businessInsuranceAction());
        break;
      default: //do noting
    }
  };
  const otherCaseOnChangeHandler = (e) => {
    let [caseId, formId] = e.target.value.split(' ');

    setSelectedCaseId(caseId);
    setSelectedFormId(formId);

    setShowCopyCaseDataModal(true);
  };

  const onCopyCaseDataCancel = () => {
    setShowCopyCaseDataModal(false);

    document.getElementById('otherCasesSelectBox').selectedIndex = 0;
  };
  const onCopyCaseDataContinue = () => {
    setShowCopyCaseDataModal(false);
    axios
      .post(`/replicate-form-data`, {
        base_form_id: parseInt(selectedFormId),
        target_form_id: props.formId,
      })
      .then((response) => {
        fetchPageData();
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };
  return (
    <>
      {pageNavData.loading && <Loader />}
      <div className="stickySection">
        <BreadCrumb showCaseDetails={true} productType={props.productType} />
        <div className="common-form-header ">
          <h2 className="page-heading">{props.pageHeading}</h2>

          <div className="page-navigation-buttons">
            <div className={` ${props.hideCompleteCheckBox && 'hideBtn'} `}>
              <div
                className={` ${
                  props.hideCompleteCheckBox && 'hideBtn'
                } markSectionDone`}
              >
                <label className="mark-done-field-label" htmlFor="mark-done">
                  Mark Section as Complete
                </label>
                <input
                  type="checkbox"
                  id="mark-done"
                  value={true}
                  checked={confirm}
                  onChange={(e) => {
                    setConfirm(e.target.checked);
                    axios
                      .post(`/update-section-iscomplete`, payload)
                      .then((response) => {
                        dispatch(pageNavigationAction());
                      })
                      .catch((error) => {
                        if (
                          error?.response?.status === 401 ||
                          error?.response?.detail === 'Not authenticated'
                        ) {
                          window.location.replace('/');
                        }
                        console.error('There was an error!', error);
                      });
                  }}
                />
              </div>
            </div>
            <button
              className={`button ${props.hideSaveButton && 'hideBtn'}`}
              onClick={onSaveClick}
            >
              Save
            </button>
            {props.backPath && (
              <Link
                to={props.backPath}
                onClick={pageChangeHandler}
                className="back-navigation"
              ></Link>
            )}
            {props.nextPath && (
              <>
                <Link
                  to={props.nextPath}
                  onClick={pageChangeHandler}
                  className="forward-navigation"
                ></Link>
              </>
            )}
          </div>
        </div>

        {props?.otherCasesInfo?.length > 0 && (
          <div className="otherCaseData--selectbox">
            <div className="label-value-wrapper layout-2">
              <select
                onChange={otherCaseOnChangeHandler}
                id="otherCasesSelectBox"
              >
                <option value="" selected hidden>
                  Copy Case Data From
                </option>
                {props?.otherCasesInfo?.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item.case_id + ' ' + item.form_id}
                    >
                      {item.case_id +
                        ' - ' +
                        item.carrier +
                        ' - ' +
                        item.product_subtype +
                        ' - ' +
                        item.status}
                      {item.status === 'Inforce' &&
                        ' - Data as of ' + item.status_date}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
      </div>
      {showCopyCaseDataModal && (
        <CopyCaseDataModal
          baseCaseId={selectedCaseId}
          targetCaseId={props.caseIdValue}
          onCancelhandler={onCopyCaseDataCancel}
          onContinueHandler={onCopyCaseDataContinue}
        />
      )}
    </>
  );
};
export default CommonFormHeader;
