import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
const ForeignEmployment = (props) => {
  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;

  const onChangeHandler = (e) => {
    props.setForeignEmployment((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const label = formData?.data?.foreignEmploymentLabel;
  const field = formData?.data?.foreignEmployment.map((item, index) => {
    return (
      <FormField
        key={index}
        id={item.id}
        name={item.name}
        value={item.value}
        type={item.type}
        label={item.label}
        layoutType={item.layoutType}
        onChangeHandler={onChangeHandler}
      />
    );
  });
  return (
    <>
      <h3>{label}</h3>
      <div className="flex">{field}</div>
    </>
  );
};
export default ForeignEmployment;
