import './carrierProgressBar.css';
const CarrierProgressBar = ({
  label,
  progressCount,
  totalCount,
  toolTipContent,
  isNumeric,
}) => {
  const progressValue = parseFloat(progressCount ? progressCount : 0);
  const totalValue = parseFloat(totalCount ? totalCount : 0);
  let progressCountWidth = isNumeric
    ? (progressValue * 100) / totalValue
    : progressValue;
  return (
    <>
      <div className="carrierProgressbar">
        <div className="carrierProgressbar--labelToolTip">
          <span className="carrierProgressbar--label">{label}</span>
          <div className="carrierProgressbar--toolTip">
            <span className="carrierProgressbar--toolTipIcon">!</span>
            <div className="carrierProgressbar--toolTipContent">
              {toolTipContent}
            </div>
            <span className="carrierProgressbar--toolTipArrow"></span>
          </div>
        </div>
        <div className="carrierProgressbar--progressCount">
          <span className="carrierProgressbar--count">
            {isNumeric ? (
              <>
                {progressCount ? progressCount : 0}/
                {totalCount ? totalCount : 0}
              </>
            ) : (
              <>{progressCount ? Math.round(progressCount) : 0}%</>
            )}
          </span>
          <div className="carrierProgressbar--bar">
            <div
              className="carrierProgressbar--progress"
              style={{
                width: progressCountWidth + '%',
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CarrierProgressBar;
