// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.travel-radio-container {
  display: flex;
  align-items: center;
  height: 44px;
}
.travel-radio-label {
  font-size: 13px;
  font-weight: 600;
  margin: 0 5px 0 2px;
}
.ft-radio-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.ft-radio-container input[type="radio"]:checked ~ label {
  color: #0177b5;
}
.ft-dlt-btn {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}
.purpose-layout {
  width: 27%;
}
.destination-layout {
  width: 24%;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/foreignTravel/foreginTravel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ","sourcesContent":[".travel-radio-container {\n  display: flex;\n  align-items: center;\n  height: 44px;\n}\n.travel-radio-label {\n  font-size: 13px;\n  font-weight: 600;\n  margin: 0 5px 0 2px;\n}\n.ft-radio-container {\n  display: flex;\n  align-items: center;\n  margin-right: 5px;\n}\n.ft-radio-container input[type=\"radio\"]:checked ~ label {\n  color: #0177b5;\n}\n.ft-dlt-btn {\n  display: flex;\n  justify-content: end;\n  margin-bottom: 15px;\n}\n.purpose-layout {\n  width: 27%;\n}\n.destination-layout {\n  width: 24%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
