import CaseListingSearch from './caseListingSearch';
import CaseListingCard from './caseListingCard';
import './CaseListing.css';
import CaseListingSideNav from './caseListingSideNav';
import { useEffect, useState } from 'react';
import Header from '../../header';
import Footer from '../../footer';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers } from '../../../actions/caseListingAction';

const CaseListing = (props) => {
  const [active, setActive] = useState('');
  const [caseType, setCaseType] = useState('myCase');
  const [activeCase, setActiveCase] = useState('myCase');
  const [productType, setProductType] = useState('insurance');
  const [activeProduct, setActiveProduct] = useState('insurance');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [startValidation, setStartValidation] = useState(false);

  const dispatch = useDispatch();
  const usersList = useSelector((state) => state.usersList);
  const { loading, error, users } = usersList;

  useEffect(() => {
    dispatch(listUsers());
    localStorage.setItem('productType', 'insurance');
  }, [dispatch]);

  return (
    <>
      <Header />
      <div id="contents">
        <div className="page-navigation side-navigation">
          <CaseListingSideNav
            sideNavData={users?.sidebar_data}
            setFromDate={setFromDate}
            fromDate={fromDate}
            setToDate={setToDate}
            toDate={toDate}
            caseType={caseType}
            productType={productType}
            active={active}
            setActiveCase={setActiveCase}
            setActiveProduct={setActiveProduct}
            setActive={setActive}
            setStartValidation={setStartValidation}
            startValidation={startValidation}
          />
        </div>
        <div className="listing-page-wrapper">
          <CaseListingSearch
            setCaseType={setCaseType}
            setActive={setActive}
            caseType={caseType}
            productType={productType}
            setProductType={setProductType}
            activeCase={activeCase}
            setActiveCase={setActiveCase}
            activeProduct={activeProduct}
            setActiveProduct={setActiveProduct}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setStartValidation={setStartValidation}
            sortData={users?.sidebar_data}
          />

          {loading ? (
            <div className="caselisting__loaderWrapper">
              <div className="caselisting__loaderOverlay">
                <div className="loader">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <CaseListingCard
                caseType={caseType}
                productType={productType}
                cardData={users.pending_cases}
              />
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CaseListing;
