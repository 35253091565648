import { usaStatefieldValue } from '../../../ui/formField/stateJson';
import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
import AccordionPhysicianAddMore from './AccordionPhysicianAddMore';
import { useEffect, useState } from 'react';
import axios from '../../../../axios/axios';

const selectOptions = [
  { label: 'Select', value: '' },
  {
    label: 'Dr.',
    value: 'dr',
  },
  {
    label: 'Miss',
    value: 'miss',
  },
  {
    label: 'Mr.',
    value: 'mr',
  },
  {
    label: 'Mrs.',
    value: 'mrs',
  },
  {
    label: 'Ms.',
    value: 'ms',
  },
];
const PhysicianSection = (props) => {
  const pyFormData = useSelector((state) => state.pyFieldData);
  const { loading, error, pyData } = pyFormData;
  const [physicianLabel, setPhysicianLabel] = useState({});

  let handleChange = (i, e) => {
    let newFormValues = [...props.physicianData];
    newFormValues[i][e.target.name] = e.target.value;
    props.setPhysicianData(newFormValues);
  };
  useEffect(() => {
    setPhysicianLabel(pyData?.data?.physicianLabel);
  }, [pyData?.data]);
  let addFormFields = () => {
    props.setPhysicianData([
      ...props.physicianData,
      {
        addressBlock: '',
        additionalLine2: '',
        additionalLine3: '',
        areaCode: '',
        city: '',
        countryCode: '',
        dateOfAppointment: '',
        extension: '',
        firstName: '',
        lastName: '',
        lastSeenDate: '',
        middleName: '',
        phoneNumber: '',
        purpose: '',
        reason: '',
        remarks: '',
        phoneRemarks: '',
        speciality: '',
        state: '',
        suffix: '',
        title: '',
        zipCode: '',
        country: '',
      },
    ]);
  };

  let removeFormFields = (i, objectId) => {
    let newFormValues = [...props.physicianData];
    newFormValues.splice(i, 1);
    props.setPhysicianData(newFormValues);
    axios
      .post(`/so-api/delete-object/?object_id=${objectId}`)
      .then((response) => {})
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };

  return (
    <>
      <h3>{pyData?.data?.PhysicianDetailsLabel?.label1}</h3>
      {props.physicianData.length > 0 &&
        props.physicianData.map((element, index) => (
          <AccordionPhysicianAddMore
            index={index}
            key={index}
            openAccordion={props?.physicianData?.length > 1 ? false : true}
            accordionHeading={`Physician ${index + 1}`}
            physicianTitle={element?.title}
            physicianName={
              element?.firstName +
              ' ' +
              element?.middleName +
              ' ' +
              element?.lastName
            }
            physicianSpeciality={element?.speciality}
            onDeleteClick={
              index ? () => removeFormFields(index, element?.object_id) : null
            }
            newPhysicianAdded={!element.firstName && !element.speciality}
          >
            <div className="flex" key={index}>
              <div className="label-value-wrapper layout-5">
                <label className="field-label">{physicianLabel.title}</label>

                <div>
                  <select
                    name="title"
                    id="title"
                    value={element.title || ''}
                    onChange={(e) => handleChange(index, e)}
                  >
                    {selectOptions.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="label-value-wrapper ltc-text-field layout-5">
                <label className="field-label">
                  {physicianLabel.firstName}
                </label>

                <FormField
                  id={`firstName${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="firstName"
                  placeholder="Enter"
                  value={element.firstName || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper ltc-text-field layout-5">
                <label className="field-label">
                  {physicianLabel.middleName}
                </label>

                <FormField
                  id={`middleName${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="middleName"
                  placeholder="Enter"
                  value={element.middleName || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper ltc-text-field layout-5">
                <label className="field-label">{physicianLabel.lastName}</label>

                <FormField
                  id={`lastName${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="lastName"
                  placeholder="Enter"
                  value={element.lastName || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper ltc-text-field layout-5">
                <label className="field-label">{physicianLabel.suffix}</label>

                <FormField
                  id={`suffix${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="suffix"
                  placeholder="Enter"
                  value={element.suffix || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>

              <div
                className={`label-value-wrapper ${
                  element.speciality === 'other'
                    ? 'speciality-other-layout'
                    : 'layout-2'
                } `}
              >
                <label className="field-label">
                  {physicianLabel.speciality}
                </label>
                <div>
                  <select
                    name="speciality"
                    id="speciality"
                    value={element.speciality || ''}
                    onChange={(e) => handleChange(index, e)}
                  >
                    {pyData?.data?.specialityOption?.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {element.speciality === 'other' && (
                <div
                  className={`label-value-wrapper layout-4 ${
                    !element.otherSpeciality ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {physicianLabel.otherSpeciality}
                  </label>
                  <div>
                    <input
                      type="text"
                      name="otherSpeciality"
                      placeholder="Enter"
                      value={element.otherSpeciality || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
              )}
              <div className="label-value-wrapper no-margin-text-field layout-2">
                <label className="field-label">
                  {physicianLabel.lastSeenDate}
                </label>
                <FormField
                  type="month"
                  name="lastSeenDate"
                  placeholder="Enter"
                  value={element.lastSeenDate || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.reason ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.reason}</label>
                <div>
                  <input
                    type="text"
                    name="reason"
                    placeholder="Enter"
                    value={element.reason || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div className="label-value-wrapper no-margin-text-field layout-2">
                <label className="field-label">
                  {physicianLabel.dateOfAppointment}
                </label>
                <FormField
                  type="date"
                  name="dateOfAppointment"
                  placeholder="Enter"
                  validationType="addMoreDate"
                  value={element.dateOfAppointment || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.purpose ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.purpose}</label>
                <div>
                  <input
                    type="text"
                    name="purpose"
                    placeholder="Enter"
                    value={element.purpose || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-1 ${
                  !element.addressBlock ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">
                  {physicianLabel.addressBlock}
                </label>
                <div>
                  <textarea
                    type="text"
                    name="addressBlock"
                    placeholder="Enter"
                    value={element.addressBlock || ''}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.additionalLine2 ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">
                  {physicianLabel.additionalLine2}
                </label>
                <div>
                  <input
                    type="text"
                    name="additionalLine2"
                    placeholder="Enter"
                    value={element.additionalLine2 || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.additionalLine3 ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">
                  {physicianLabel.additionalLine3}
                </label>
                <div>
                  <input
                    type="text"
                    name="additionalLine3"
                    placeholder="Enter"
                    value={element.additionalLine3 || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-3 ${
                  !element.city ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.city}</label>
                <div>
                  <input
                    type="text"
                    name="city"
                    placeholder="Enter"
                    value={element.city || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div className="label-value-wrapper layout-3">
                <label className="field-label">{physicianLabel.state}</label>
                <div>
                  <select
                    name="state"
                    id="state"
                    value={element.state || ''}
                    onChange={(e) => handleChange(index, e)}
                  >
                    {usaStatefieldValue.map((item, index) => {
                      return (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div
                className={`label-value-wrapper layout-3 ${
                  !element.zipCode ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.zipCode}</label>
                <div>
                  <input
                    type="text"
                    name="zipCode"
                    placeholder="Enter"
                    value={element.zipCode || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.country ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.country}</label>
                <div>
                  <input
                    type="text"
                    name="country"
                    placeholder="Enter"
                    value={element.country || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-1 ${
                  !element.remarks ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.remarks}</label>
                <div>
                  <textarea
                    type="text"
                    name="remarks"
                    placeholder="Enter"
                    value={element.remarks || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>

              <div className="line-separation layout-1"></div>

              <div className="layout-1">
                <h3>
                  {physicianLabel.contactDetailsPhysician} {index + 1}
                </h3>
              </div>
              <div
                className={`label-value-wrapper  layout-2 ${
                  !element.countryCode ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">
                  {physicianLabel.countryCode}
                </label>
                <div>
                  <input
                    type="text"
                    placeholder="Enter"
                    name="countryCode"
                    value={element.countryCode || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper  layout-2 ${
                  !element.areaCode ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.areaCode}</label>
                <div>
                  <input
                    type="text"
                    placeholder="Enter"
                    name="areaCode"
                    value={element.areaCode || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.phoneNumber ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">
                  {physicianLabel.phoneNumber}
                </label>
                <div>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter"
                    value={element.phoneNumber || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper  layout-2 ${
                  !element.extension ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">
                  {physicianLabel.extension}
                </label>
                <div>
                  <input
                    type="text"
                    placeholder="Enter"
                    name="extension"
                    value={element.extension || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>

              <div
                className={`label-value-wrapper layout-1 ${
                  !element.phoneRemarks ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{physicianLabel.remarks}</label>
                <div>
                  <textarea
                    type="text"
                    name="phoneRemarks"
                    placeholder="Enter"
                    value={element.phoneRemarks || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
            </div>
          </AccordionPhysicianAddMore>
        ))}
      <div>
        <span className="addFieldButton" onClick={() => addFormFields()}>
          {physicianLabel.addPhysician}
        </span>
      </div>
    </>
  );
};

export default PhysicianSection;
