import { useState } from 'react';
import IconPlus from './../../../assets/images/icon-plus.svg';
import IconMinus from './../../../assets/images/icon-minus.svg';
const AccordionFinancialInfo = (props) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  const onAccordionClick = (e) => {
    switch (e.target.dataset.accordion) {
      case 'true':
        setOpenAccordion(false);
        break;
      case 'false':
        setOpenAccordion(true);
        break;
      default: // Do nothing
    }
  };
  return (
    <>
      <section className="financiaInfo_section">
        <div
          className={`financialInfo__accordionHeadingWrapper planHeading  ${
            openAccordion ? 'accordion-active' : ''
          } ${props.showTotalAmount ? 'amountHeader' : ''}`}
        >
          <span
            className="accordionClickCapture"
            onClickCapture={onAccordionClick}
            data-accordion={openAccordion}
          ></span>
          {props.accordionHeading && (
            <>
              {' '}
              <h3 className="financialInfo__accordionHeading">
                {props.accordionHeading}
              </h3>
            </>
          )}
          {props.showTotalAmount && (
            <>
              <div className="financialInfo__plansInfo">
                <div className="financialInfo__plansInfoTotal">
                  <p className="financialInfo__plansInfoTotalLabel ">
                    Current year
                  </p>
                  <p className="financialInfo__plansInfoTotalValue">
                    $ {props.currYearTotal}
                  </p>
                </div>
                <div className="financialInfo__plansInfoTotal">
                  <p className="financialInfo__plansInfoTotalLabel ">
                    Previous year
                  </p>
                  <p className="financialInfo__plansInfoTotalValue">
                    $ {props.prevYearTotal}
                  </p>
                </div>
              </div>
            </>
          )}

          <span className="acc_icon">
            <img src={openAccordion ? IconMinus : IconPlus} alt="icon" />
          </span>
        </div>
        <div
          className={`financialInfo__accordionContent   ${
            openAccordion ? 'financialInfo__accordionContent--open' : ''
          }`}
        >
          {props.children}
        </div>
      </section>
    </>
  );
};
export default AccordionFinancialInfo;
