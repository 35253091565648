import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonTextArea from '../../../ui/commonTextArea';
import Checkboxes from '../../../ui/formField/Checkboxes';

const MedicalSection = (props) => {
  const [medicalCheckBoxsSection, setMedicalCheckBoxsSection] = useState([]);
  const [medicalTextAreaValue, setMedicalTextAreaValue] = useState({});
  const [dosagesTextAreaValue, setDosagesTextAreaValue] = useState({});
  const longTermCareData = useSelector((state) => state.longTermCareData);
  const { loading, error, ltcData } = longTermCareData;

  useEffect(() => {
    let checkboxDefaltValuearr = [];
    ltcData?.data?.medicalCheckBoxsSection?.option?.map((item) => {
      if (item.defaultChecked) {
        checkboxDefaltValuearr.push(item.value);
      }
    });
    setMedicalCheckBoxsSection(checkboxDefaltValuearr);
  }, [ltcData?.data]);
  const handleCheckBox = (event) => {
    let updatedList = [...medicalCheckBoxsSection];
    if (event.target.checked) {
      updatedList = [...medicalCheckBoxsSection, event.target.value];
    } else {
      updatedList.splice(
        medicalCheckBoxsSection.indexOf(event.target.value),
        1
      );
    }
    setMedicalCheckBoxsSection(updatedList);
  };
  const onMedicalTextChange = (e) => {
    setMedicalTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onDosagesTextChange = (e) => {
    setDosagesTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  useEffect(() => {
    props.setMedicalData({
      medicalCheckBoxsSection,
      medicalTextAreaValue,
      dosagesTextAreaValue,
    });
  }, [medicalCheckBoxsSection, medicalTextAreaValue, dosagesTextAreaValue]);
  return (
    <>
      <div className="ltc-checkboxes-container">
        <p className="ltc-label">
          {ltcData?.data && ltcData?.data?.medicalCheckBoxsSection?.label}
        </p>
        {ltcData?.data &&
          ltcData?.data?.medicalCheckBoxsSection?.option?.map(
            (option, index) => {
              return (
                <Checkboxes
                  key={index}
                  name={option.name}
                  value={option.value}
                  type={option.type}
                  label={option.label}
                  defaultChecked={option.defaultChecked}
                  id={option.id}
                  onChangeHandler={handleCheckBox}
                />
              );
            }
          )}
        {medicalCheckBoxsSection.length > 0 && (
          <CommonTextArea
            fieldData={ltcData?.data && ltcData?.data?.medicalTextAreaValue}
            onChangeHandler={onMedicalTextChange}
          />
        )}
        {medicalCheckBoxsSection.length > 0 && (
          <CommonTextArea
            fieldData={ltcData?.data && ltcData?.data?.dosagesTextAreaValue}
            onChangeHandler={onDosagesTextChange}
          />
        )}
      </div>
    </>
  );
};

export default MedicalSection;
