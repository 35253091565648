import { useEffect, useRef, useState } from 'react';

const MappingSelectDropdown = ({
  options,
  lockFields,
  selectedValue,
  labelKey,
  onClickHandler,
  isSorted,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdowValues, setDropdownValues] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setDropdownValues([...new Set(options)]); // removing dublicate values for context dropdown
  }, [options]);

  return (
    <div ref={dropdownRef} className="carrierUploadFormDropdown">
      <div
        className={`carrierUploadFormDropdown--btn ${
          lockFields ? 'button--disable' : ''
        }`}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <span className="carrierUploadFormDropdown--selectedValues">
          {' '}
          {selectedValue ? selectedValue : 'Select'}
        </span>

        <span
          className={`carrierUploadFormDropdown--btnIcon ${
            isActive ? 'carrierUploadFormDropdown--btnIcon__rotate' : ''
          }`}
        ></span>
      </div>
      {isActive && (
        <div className="carrierUploadFormDropdown--content">
          {(isSorted //Condition to check weather sorting is require or not
            ? dropdowValues?.sort((a, b) => {
                if (labelKey) {
                  return a[labelKey]?.toLowerCase() > b[labelKey]?.toLowerCase()
                    ? 1
                    : -1;
                } else {
                  return a?.toLowerCase() > b?.toLowerCase() ? 1 : -1;
                }
              })
            : dropdowValues
          )?.map((item, index) => {
            return (
              <div
                className={`carrierUploadFormDropdown--item ${
                  labelKey
                    ? selectedValue === item[labelKey]
                      ? 'carrierUploadFormDropdown--selected'
                      : ''
                    : selectedValue === item
                    ? 'carrierUploadFormDropdown--selected'
                    : ''
                }`}
                key={index}
                onClick={(e) => {
                  onClickHandler(e);
                  setIsActive(false);
                }}
              >
                {labelKey ? item[labelKey] : item}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default MappingSelectDropdown;
