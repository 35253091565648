import {
  FINANCIAL_INFO_DATA_REQUEST,
  FINANCIAL_INFO_DATA_SUCCESS,
  FINANCIAL_INFO_DATA_FAIL,
} from '../constants/financialInfoConstants';

export const financialInfoReducer = (state = { formData: [] }, action) => {
  switch (action.type) {
    case FINANCIAL_INFO_DATA_REQUEST:
      return { loading: true, formData: [] };
    case FINANCIAL_INFO_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case FINANCIAL_INFO_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
