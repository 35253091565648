import {
  LONG_TERM_CARE_DATA_REQUEST,
  LONG_TERM_CARE_DATA_SUCCESS,
  LONG_TERM_CARE_DATA_FAIL,
} from '../constants/longTermCareConstants';

export const longTermCareReducer = (state = { ltcData: [] }, action) => {
  switch (action.type) {
    case LONG_TERM_CARE_DATA_REQUEST:
      return { loading: true, ltcData: [] };
    case LONG_TERM_CARE_DATA_SUCCESS:
      return { loading: false, ltcData: action.payload };
    case LONG_TERM_CARE_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
