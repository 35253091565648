import React, { useEffect, useState, useRef } from 'react';

const CarrierUploadFormMultipleDropdown = ({
  setSelectedIds,
  options,
  dropdownType,
  labelKey,
  labelDisplayKey,
  initialValueIds,
  hideAllText,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setDropdownOptions(
      options?.map((option) => ({
        ...option,
        isChecked: false,
      }))
    );
  }, [options]);

  const handleSelectedValues = () => {
    const unCheckedtems = dropdownOptions?.filter(
      (option) => !option?.isChecked
    );

    if (unCheckedtems?.length === 0 && dropdownOptions?.length > 1) {
      return 'All';
    } else {
      const selectedItems = dropdownOptions
        ?.filter((option) => option.isChecked)
        ?.map((option) => option[labelDisplayKey ? labelDisplayKey : labelKey]);
      return selectedItems?.length > 0 ? selectedItems?.join(', ') : 'Select';
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (options && initialValueIds) {
      setDropdownOptions(
        options.map((option) => ({
          ...option,
          isChecked: initialValueIds?.includes(option.id), // Check if the option id matches any of the initialValueIds
        }))
      );
    }
  }, [options, initialValueIds]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === dropdownType + '-all') {
      let tempDropdownOptions = dropdownOptions?.map((option) => {
        return { ...option, isChecked: checked };
      });
      setDropdownOptions(tempDropdownOptions);
      if (checked) {
        let selectedArr = tempDropdownOptions?.map((option) => {
          return option[labelDisplayKey ? labelDisplayKey : labelKey];
        });
        setSelectedValues(selectedArr);

        let selectedArrIds = tempDropdownOptions?.map((option) => {
          return option.id;
        });

        setSelectedIds(selectedArrIds);
      } else {
        setSelectedValues([]);
        setSelectedIds([]);
      }
    } else {
      let tempDropdownOptions = dropdownOptions?.map((option) => {
        return option[labelKey] === name
          ? { ...option, isChecked: checked }
          : option;
      });

      setDropdownOptions(tempDropdownOptions);
      let checkedValues = tempDropdownOptions?.filter((item) => {
        return item.isChecked && item[labelKey];
      });
      setSelectedValues(
        checkedValues.map((item) => {
          return item[labelDisplayKey ? labelDisplayKey : labelKey];
        })
      );
      setSelectedIds(
        checkedValues.map((item) => {
          return item.id;
        })
      );
    }
  };

  return (
    <div ref={dropdownRef} className="carrierUploadFormDropdown">
      <div
        className="carrierUploadFormDropdown--btn"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <span className="carrierUploadFormDropdown--selectedValues">
          {handleSelectedValues()}
        </span>
        <span
          className={`carrierUploadFormDropdown--btnIcon ${
            isActive ? 'carrierUploadFormDropdown--btnIcon__rotate' : ''
          }`}
        ></span>
      </div>
      {isActive && (
        <div className="carrierUploadFormDropdown--content">
          {dropdownOptions?.length > 0 && !hideAllText && (
            <>
              <label
                className="carrierUploadFormDropdown--item"
                htmlFor={dropdownType + '-all'}
              >
                <input
                  type="checkbox"
                  name={dropdownType + '-all'}
                  onChange={handleChange}
                  checked={
                    dropdownOptions?.filter(
                      (option) => option.isChecked !== true
                    ).length < 1
                  }
                  className="carrierUploadFormDropdown--itemCheckbox"
                  id={dropdownType + '-all'}
                />
                All
              </label>
            </>
          )}

          {dropdownOptions?.map((item, index) => {
            return (
              <label
                className="carrierUploadFormDropdown--item"
                key={index}
                htmlFor={
                  dropdownType + '-' + item[labelKey]?.replace(/\s+/g, '')
                }
              >
                <input
                  type="checkbox"
                  name={item[labelKey]}
                  onChange={handleChange}
                  id={dropdownType + '-' + item[labelKey]?.replace(/\s+/g, '')}
                  checked={item?.isChecked || false}
                  className="carrierUploadFormDropdown--itemCheckbox"
                />
                {item[labelKey]}
              </label>
            );
          })}
        </div>
      )}
      {options?.length < 1 && <p></p>}
    </div>
  );
};

export default CarrierUploadFormMultipleDropdown;
