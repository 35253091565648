import './RadioButton.css';
import PropTypes from 'prop-types';

const RadioButton = (props) => {
  const radioButton = (
    <div className="radioTabs">
      <input
        className="radioButton"
        type={props.type}
        value={props.value}
        name={props.name}
        id={props.id}
        defaultChecked={props.defaultChecked}
        // onChange={props.radioOptions.onChangeHandler}
      />
      <label className="radioWrapper" htmlFor={props.id}>
        <span className="radioWrapper__icon"></span>
        <span className="radioWrapper__label">{props.label}</span>
      </label>
      {/* <label className="radioTabs__label" htmlFor={props.id}>
        <span className="field-label">{props.label}</span>
      </label> */}
    </div>
  );
  return <>{radioButton}</>;
};
export default RadioButton;

RadioButton.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
};
