import { useSelector } from 'react-redux';
import AccordionSingleSection from '../../../ui/accordionSingleSection';
import FormField from '../../../ui/formField';
import VerificationCheckBox from '../verificationCheckBox';

const AccountantSection = (props) => {
  const paFormData = useSelector((state) => state.paFieldData);
  const { loading, error, paData } = paFormData;

  const verificationCheckBox = paData?.data?.accountantVerificationCheckBox;
  const onFieldChangeHandler = (event) => {
    props.setAccountantInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <AccordionSingleSection
        accordionHeading={
          paData?.data && paData?.data?.accountantSectionLabel?.label
        }
      >
        <div className="flex">
          {paData?.data?.accountantSectionFields?.map((item, index) => {
            return (
              <FormField
                key={index}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                dropdownType={item.dropdownType}
                layoutType={item.layoutType}
                onChangeHandler={onFieldChangeHandler}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                validationType={item?.validationType}
              />
            );
          })}
          {paData?.data && (
            <VerificationCheckBox
              isChecked={props.acVerification}
              setIsChecked={props.setAcVerification}
              verificationCheckBox={verificationCheckBox}
            />
          )}
        </div>
      </AccordionSingleSection>
    </>
  );
};

export default AccountantSection;
