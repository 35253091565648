import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonLabelRadioButtons from '../../../../ui/commonLabelRadioButton';
import FormField from '../../../../ui/formField';
import LabelRadioButtons from '../../../../ui/labelRadioButtons';
import PbTrusteeSection from './pbTrustee';
const PbTrustSection = (props) => {
  const [pbTrustTypeOptions, setPbTrustTypeOptions] = useState({});
  const [pbTrustAgreementSection, setPbTrustAgreementSection] = useState({});
  const [pbTrustAgreementYesOptions, setPbTrustAgreementYesOptions] =
    useState('');
  const [pbTrusteeSection, setPbTrusteeSection] = useState([]);
  const [pbBeneficiarySection, setPbBeneficiarySection] = useState([]);

  const beneficiaryFieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = beneficiaryFieldData;

  const pbTrustTypeFieldData = bnData?.data?.pbTrustTypeOptions?.options;
  const pbTrustAgreementSectionData = bnData?.data?.pbTrustAgreementSection;
  const pbTrustAgreementYesOptionsData =
    bnData?.data?.pbTrustAgreementYesOptions;
  const [pbTrustAgreementText, setPbTrustAgreementText] = useState(
    bnData?.data?.pbTrustAgreementYesOptions?.value
  );
  const pbTrustAgreementChangeHandler = (event) => {
    setPbTrustAgreementSection(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const pbTrustTypeChangeHandler = (event) => {
    setPbTrustTypeOptions(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onTrustNameFieldsChange = (event) => {
    props.setPBTrustName((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  useEffect(() => {
    const initialpbTrustAgreementValue =
      bnData?.data?.pbTrustAgreementSection?.options?.find(
        (item) => item.defaultChecked
      );
    if (initialpbTrustAgreementValue) {
      const key = initialpbTrustAgreementValue['id'];
      const value = initialpbTrustAgreementValue['value'];
      setPbTrustAgreementSection({ [key]: value });
    }
    const initialpbTrustOptionValue =
      bnData?.data?.pbTrustTypeOptions?.options?.find(
        (item) => item.defaultChecked
      );
    if (initialpbTrustOptionValue) {
      const key = initialpbTrustOptionValue['id'];
      const value = initialpbTrustOptionValue['value'];
      setPbTrustTypeOptions({ [key]: value });
    }

    if (bnData?.data?.pbTrusteeSection !== undefined) {
      setPbTrusteeSection(bnData?.data?.pbTrusteeSection);
    }
    if (bnData?.data?.pbBeneficiarySection !== undefined) {
      setPbBeneficiarySection(bnData?.data?.pbBeneficiarySection);
    }
  }, [bnData?.data]);
  useEffect(() => {
    props.setPbTrustData({
      pbTrustTypeOptions,
      pbTrusteeSection,
      pbBeneficiarySection,
      pbTrustAgreementYesOptions,
      pbTrustAgreementSection,
    });
  }, [
    pbTrustTypeOptions,
    pbTrusteeSection,
    pbBeneficiarySection,
    pbTrustAgreementYesOptions,
    pbTrustAgreementSection,
  ]);
  return (
    <>
      {bnData?.data && (
        <>
          <div className="flex">
            <div className="label-value-wrapper layout-2">
              <h3>{bnData?.data?.pbTrustTypeOptions?.label}</h3>
              <CommonLabelRadioButtons
                options={pbTrustTypeFieldData}
                onChangeHandler={pbTrustTypeChangeHandler}
              />
            </div>
            <div className="label-value-wrapper layout-2 trust-per">
              <label className="field-label">
                {bnData?.data?.pbTrustTotalPer?.label}
              </label>
              <div>
                <input
                  type={bnData?.data?.pbTrustTotalPer?.type}
                  name={bnData?.data?.pbTrustTotalPer?.name}
                  defaultValue={bnData?.data?.pbTrustTotalPer?.value}
                  placeholder="Enter"
                  onChange={(e) => props.setPbTrustTotalPer(e.target.value)}
                />
              </div>
            </div>
            {bnData?.data?.pbTrustName?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  layoutType={item.layoutType}
                  validationType={item.validationType}
                  onChangeHandler={onTrustNameFieldsChange}
                />
              );
            })}
          </div>
          <PbTrusteeSection
            trustee={pbTrusteeSection}
            setTrustee={setPbTrusteeSection}
            pbBeneficiarySection={pbBeneficiarySection}
            setPbBeneficiarySection={setPbBeneficiarySection}
            pbTrusteePercentage={props.pbTrusteePercentage}
            setPbTrusteePercentage={props.setPbTrusteePercentage}
            pbTrusteeIndividualPercentage={props.pbTrusteeIndividualPercentage}
            setPbTrusteeIndividualPercentage={
              props.setPbTrusteeIndividualPercentage
            }
            pbBeneficiaryPercentage={props.pbBeneficiaryPercentage}
            setPbBeneficiaryPercentage={props.setPbBeneficiaryPercentage}
          />
          <LabelRadioButtons
            label={pbTrustAgreementSectionData?.label}
            options={pbTrustAgreementSectionData?.options}
            id={pbTrustAgreementSectionData?.id}
            onChangeHandler={pbTrustAgreementChangeHandler}
          />
          {pbTrustAgreementSection['pbTrustAgreement-yes'] === 'yes' && (
            <div
              className={`label-value-wrapper field-remark-wrapper ${
                !pbTrustAgreementText ? 'value--empty' : ''
              }`}
            >
              <label>{pbTrustAgreementYesOptionsData?.label}</label>
              <textarea
                name={pbTrustAgreementYesOptionsData?.id}
                id={pbTrustAgreementYesOptionsData?.id}
                onChange={(event) => {
                  setPbTrustAgreementText(event.target.value);
                  setPbTrustAgreementYesOptions(() => ({
                    [event.target.id]: event.target.value,
                  }));
                }}
                //value={pbTrustAgreementYesOptions}
                defaultValue={pbTrustAgreementYesOptionsData?.value}
                placeholder={pbTrustAgreementYesOptionsData?.placeholder}
              ></textarea>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PbTrustSection;
