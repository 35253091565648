import Checkboxes from '../../../ui/formField/Checkboxes';
import AutoOption from './autoOption.js';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const AutoSection = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  const autoOther = formData?.data?.autoOtherValue;
  const [autoOtherValue, setAutoOtherValue] = useState(
    formData?.data?.autoOtherValue?.value
  );
  const handleCheckBox = (event) => {
    let updatedList = [...props.autoCheckBoxes];
    if (event.target.checked) {
      updatedList = [...props.autoCheckBoxes, event.target.value];
    } else {
      updatedList.splice(props.autoCheckBoxes.indexOf(event.target.value), 1);
    }
    props.setAutoCheckBoxes(updatedList);
  };
  return (
    <>
      <h3>Auto / Motorboat / Motorcycle Racing</h3>
      <p className="plan-question-label" id={formData?.data?.autoLabel?.id}>
        {formData?.data?.autoLabel?.label}
      </p>
      {formData?.data &&
        formData?.data?.autoCheckBoxes?.option?.map((option, index) => {
          return (
            <Checkboxes
              key={index}
              name={option.name}
              value={option.value}
              type={option.type}
              label={option.label}
              defaultChecked={option.defaultChecked}
              id={option.id}
              onChangeHandler={handleCheckBox}
              disabled={option.disable}
            />
          );
        })}
      {props.autoCheckBoxes.includes('other') && (
        <div className="flex">
          <div
            className={`label-value-wrapper layout-2 ${
              !autoOtherValue ? 'value--empty' : ''
            }`}
          >
            <label className="field-label" htmlFor={autoOther?.id}>
              {autoOther?.label}
            </label>
            <div>
              <input
                type={autoOther?.type}
                id={autoOther?.id}
                name={autoOther?.name}
                placeholder={autoOther?.placeholder}
                onChange={(e) => {
                  setAutoOtherValue(e.target.value);
                  props.setAutoOtherValue(e.target.value);
                }}
                defaultValue={autoOther?.value}
              />
            </div>
          </div>
        </div>
      )}

      {props.autoCheckBoxes.length > 0 && (
        <>
          <AutoOption
            setRacePlan={props.setRacePlan}
            racePlan={props.racePlan}
            setRacingFieldOptions={props.setRacingFieldOptions}
            setRacePlanTextArea={props.setRacePlanTextArea}
            setPurposeOfRacing={props.setPurposeOfRacing}
            setRacingCheckBoxes={props.setRacingCheckBoxes}
            racingCheckBoxes={props.racingCheckBoxes}
            setRacingOtherValue={props.setRacingOtherValue}
            autoCheckBoxes={props.autoCheckBoxes}
          />
        </>
      )}
    </>
  );
};

export default AutoSection;
