import './SideNav.css';
import { NavLink } from 'react-router-dom';
import IconDocument from './../../assets/images/icon-generatePDF.svg';
import axios from '../../axios/axios';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_SEND_DATA } from '../../constants/sendDataConstants';
import { useNavigate } from 'react-router-dom';
import ApplicationFilloutModal from '../modal/applicationFilloutModal';
import { useEffect, useState } from 'react';
import { pageNavigationAction } from '../../actions/pageNavigationAction';
import { otherGeneralQuestionsAction } from '../../actions/otherGeneralQuestions';
import { purposeOfCoverageAction } from '../../actions/purposeOfCoverageAction';
import { proposedInsuredAction } from '../../actions/proposedInsuredAction';
import { policyOwnerAction } from '../../actions/policyOwnerAction';
import { physicianInfomationAction } from '../../actions/physicianInfomationAction';
import { healthLifeStyleAction } from '../../actions/healthLifeStyleAction';
import { exerciseAction } from '../../actions/exerciseAction';
import { familyHealthHistoryAction } from '../../actions/familyHealthHistoryAction';
import { professionalAdvisorsAction } from '../../actions/professionalAdvisorsAction';
import { exitingInsuranceAction } from '../../actions/exitingInsuranceAction';
import { foreignTravelAction } from '../../actions/foreignTravelAction';
import { avocationAction } from '../../actions/avocationAction';
import { foreignResidencyAction } from '../../actions/foreignResidencyAction';
import { financialInfoAction } from '../../actions/financialInfoAction';
import { longTermCareAction } from '../../actions/longTermCareAction';
import { beneficiaryAction } from '../../actions/beneficiaryAction';
import { businessInsuranceAction } from '../../actions/businessInsuranceAction';
import SyncButtonToastMessage from '../ui/toastMessage/syncButtonToastMessage';
import Loader from '../loader';
import { policyDetailsAction } from '../../actions/policyDetailsAction';
import { ppaApplicationAction } from '../../actions/ppaApplicationAction';
import { policyOwnerAnnuityAction } from '../../actions/policyOwnerAnnuityAction';
import { purchaserOwnerAction } from '../../actions/purchaserOwnerAction';
import { dispOfNetPremiumAction } from '../../actions/dispOfNetPremiumAction';
import { agentAction } from '../../actions/agentAction';
import { exitingAnnuityAction } from '../../actions/existingAnnuityAction';
import { aviationAction } from '../../actions/aviationAction';

const SideNav = (props) => {
  const [showSyncToast, setShowSyncToast] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const pageData = useSelector((state) => state.pageNav);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(otherGeneralQuestionsAction());
  }, [dispatch]);

  const fieldData = useSelector((state) => state.sendData);
  const errorFields = useSelector(
    (state) => state.validationFields.invalidFields
  );
  const [showApplicationFillout, setShowApplicationFillout] = useState(false);
  const [highlightPrioritySections, setHighlightPrioritySections] =
    useState(false);
  const navigate = useNavigate();
  const pageChangeHandler = (e) => {
    e.preventDefault();
    localStorage.setItem('sectionId', e.target.dataset.sectionid);

    if (fieldData.sectionId) {
      try {
        axios
          .post(
            `/rpiq-form-save/${localStorage.getItem('formId')}/${
              fieldData.sectionId
            }`,
            fieldData.payload
          )
          .then((response) => {
            // showToastMessage(true);
          })

          .catch((error) => {
            if (
              error?.response?.status === 401 ||
              error?.response?.detail === 'Not authenticated'
            ) {
              window.location.replace('/');
            }
            console.error('There was an error!', error);
          });
      } finally {
        dispatch({
          type: RESET_SEND_DATA,
        });

        navigate(e.target.pathname, { replace: true });
      }
    } else {
      navigate(e.target.pathname, { replace: true });
    }
  };
  const applicationFilloutHandler = (e) => {
    e.preventDefault();
    if (fieldData.sectionId) {
      try {
        axios
          .post(
            `/rpiq-form-save/${localStorage.getItem('formId')}/${
              fieldData.sectionId
            }`,
            fieldData.payload
          )
          .then((response) => {
            // showToastMessage(true);
          })

          .catch((error) => {
            if (
              error?.response?.status === 401 ||
              error?.response?.detail === 'Not authenticated'
            ) {
              window.location.replace('/');
            }
            console.error('There was an error!', error);
          });
      } finally {
        dispatch({
          type: RESET_SEND_DATA,
        });
        setShowApplicationFillout(true);
      }
    } else {
      setShowApplicationFillout(true);
    }
  };

  const continueApplicationFillout = (pathname) => {
    setShowApplicationFillout(false);
    navigate(pathname, { replace: true });
  };

  const showUnFilledSections = () => {
    setShowApplicationFillout(false);
    props.setShowEmptyFields(true);
    switch (localStorage.getItem('sectionId')) {
      case 'f1v1s1':
        dispatch(purposeOfCoverageAction());
        break;
      case 'f1v1s2':
        dispatch(policyDetailsAction());
        break;
      case 'f1v1s3':
        dispatch(proposedInsuredAction());
        break;
      case 'f1v1s4':
        dispatch(policyOwnerAction());
        break;
      case 'f1v1s6':
        dispatch(physicianInfomationAction());
        break;
      case 'f1v1s7':
        dispatch(healthLifeStyleAction());
        break;
      case 'f1v1s8':
        dispatch(exerciseAction());
        break;
      case 'f1v1s9':
        dispatch(familyHealthHistoryAction());
        break;
      case 'f1v1s12':
        dispatch(professionalAdvisorsAction());
        break;
      case 'f1v1s13':
        dispatch(otherGeneralQuestionsAction());
        break;
      case 'f1v1s14':
        dispatch(exitingInsuranceAction());
        break;
      case 'f1v1s15':
        dispatch(foreignTravelAction());
        break;
      case 'f1v1s16':
        dispatch(avocationAction());
        break;
      case 'f1v1s17':
        dispatch(foreignResidencyAction());
        break;
      case 'f1v1s18':
        dispatch(aviationAction());
        break;
      case 'f1v1s19':
        dispatch(financialInfoAction());
        break;
      case 'f1v1s20':
        dispatch(longTermCareAction());
        break;
      case 'f1v1s21':
        dispatch(beneficiaryAction());
        break;
      case 'f1v1s22':
        dispatch(businessInsuranceAction());
        break;
      default: //do noting
    }
  };
  const onSyncHandler = () => {
    setShowLoader(true);
    axios
      .post(`/rpiq-form-update-so-fetch/${localStorage.getItem('formId')}`)
      .then(() => {
        setShowLoader(false);
        setShowSyncToast(true);
        switch (localStorage.getItem('sectionId')) {
          case 'f1v1s1':
            dispatch(purposeOfCoverageAction());
            break;
          case 'f1v1s2':
            dispatch(policyDetailsAction());
            break;
          case 'f1v1s3':
            dispatch(proposedInsuredAction());
            break;
          case 'f1v1s4':
            dispatch(policyOwnerAction());
            break;
          case 'f1v1s6':
            dispatch(physicianInfomationAction());
            break;
          case 'f1v1s7':
            dispatch(healthLifeStyleAction());
            break;
          case 'f1v1s8':
            dispatch(exerciseAction());
            break;
          case 'f1v1s9':
            dispatch(familyHealthHistoryAction());
            break;
          case 'f1v1s12':
            dispatch(professionalAdvisorsAction());
            break;
          case 'f1v1s13':
            dispatch(otherGeneralQuestionsAction());
            break;
          case 'f1v1s14':
            dispatch(exitingInsuranceAction());
            break;
          case 'f1v1s15':
            dispatch(foreignTravelAction());
            break;
          case 'f1v1s16':
            dispatch(avocationAction());
            break;
          case 'f1v1s17':
            dispatch(foreignResidencyAction());
            break;
          case 'f1v1s18':
            dispatch(aviationAction());
            break;
          case 'f1v1s19':
            dispatch(financialInfoAction());
            break;
          case 'f1v1s20':
            dispatch(longTermCareAction());
            break;
          case 'f1v1s21':
            dispatch(beneficiaryAction());
            break;
          case 'f1v1s22':
            dispatch(businessInsuranceAction());
            break;
          case 'f1v1s23':
            dispatch(ppaApplicationAction());
            break;
          case 'f1v1s24':
            dispatch(purchaserOwnerAction());
            break;
          case 'f1v1s25':
            dispatch(dispOfNetPremiumAction());
            break;
          case 'f1v1s26':
            dispatch(agentAction());
            break;
          case 'f1v1s27':
            dispatch(exitingAnnuityAction());
            break;
          case 'f1v1s28':
            dispatch(policyOwnerAnnuityAction());
            break;
          default: //do noting
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        setShowLoader(false);
        console.error('There was an error!', error);
      });
  };
  const onCloseSyncToastMessage = (e) => {
    setShowSyncToast(false);
  };
  const prioritySectionHandler = (e) => {
    e.target.checked
      ? setHighlightPrioritySections(true)
      : setHighlightPrioritySections(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSyncToast(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showSyncToast]);
  return (
    <>
      {showLoader && <Loader />}
      <div className="side-navigation stickySection">
        <div className="so-btn-container">
          <button className="button so-btn" onClick={onSyncHandler}>
            Update Data from Smart Office
          </button>
        </div>
        {localStorage.getItem('productType') !== 'annuity' && (
          <div className="highlight--prioritySections">
            <input
              type="checkbox"
              className="highlight--prioritySections__checkbox"
              onClick={prioritySectionHandler}
            />
            <label className="highlight--prioritySections__label">
              <span className="highlight--prioritySections__text">
                Highlight
              </span>{' '}
              Risk Profile Interview Questions
            </label>
          </div>
        )}

        <ul className="page-links-wrapper">
          {
            // navigationLinks

            formData?.map((item, index) => {
              let pageLink;
              switch (item.sectionId) {
                case 'f1v1s14':
                  pageLink = 'existinginsurance';
                  break;
                case 'f1v1s24':
                  pageLink = 'purchaserownerquestionnaire';
                  break;
                case 'f1v1s25':
                  pageLink = 'dispositionofnetpremium';
                  break;
                case 'f1v1s27':
                  pageLink = 'existingannuity';
                  break;
                case 'f1v1s28':
                  pageLink = 'policyownerannuity';
                  break;
                default:
                  pageLink = item.label.toLowerCase().replace(/\s+/g, '');
              }

              return (
                <li
                  key={index}
                  className={`${
                    item.show_section ? 'show-component' : 'hide-component'
                  }
                  ${
                    highlightPrioritySections && item.prioritised
                      ? 'show-highlighted'
                      : ''
                  }`}
                >
                  <NavLink
                    className={`sideNavLink ${
                      item.save_status ? 'page-visited' : ''
                    } ${
                      pageLink === 'aviationquestionnaire' ? 'sub-section' : ''
                    }
                    ${item.unfilled_status ? 'unfilledSection' : ''}
                    ${
                      item.navbar_disabled_status ? 'pageLinkUi__disabled' : ''
                    } 
                    
                    `}
                    to={pageLink}
                    onClick={pageChangeHandler}
                    data-sectionid={item.sectionId}
                  >
                    {item.label}
                  </NavLink>
                  {highlightPrioritySections && item.prioritised && (
                    <>
                      <span className="rpiq--label">
                        &nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;RPIQ
                      </span>
                    </>
                  )}
                </li>
              );
            })
          }
        </ul>
        {
          <div>
            <p className="fillout-application">
              <NavLink
                to={'/applicationfillout'}
                className="application-fillout-link"
                onClick={applicationFilloutHandler}
              >
                <img
                  src={IconDocument}
                  alt=""
                  className="icon-filloutApplication"
                />{' '}
                Fill Out Forms
              </NavLink>
            </p>
          </div>
        }
      </div>
      {showApplicationFillout === true && (
        <ApplicationFilloutModal
          onCancelhandler={showUnFilledSections}
          onContinueHandler={(e) =>
            continueApplicationFillout('/applicationfillout')
          }
        />
      )}
      {showSyncToast && (
        <SyncButtonToastMessage
          onCloseSyncToastMessage={onCloseSyncToastMessage}
        />
      )}
    </>
  );
};

export default SideNav;
