// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-component {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.modal-wrapper {
  width: 438px;
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.modal-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.modal-heading {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12px;
}
.modal-contents {
  font-size: 16px;
  color: #333;
  margin-bottom: 30px;
}
.modal-buttons {
  text-align: right;
}
.cancel-btn {
  color: #1984bc;
  background: #fff;
  margin-left: 10px;
  border-color: #1984bc;
  margin-right: 10px;
}
.cancel-btn:hover {
  background: #03669a;
  border-color: #03669a;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,YAAY;AACd;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,UAAU;EACV,gCAAgC;EAChC,oCAAoC;EACpC,wCAAwC;EACxC,qCAAqC;AACvC;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,OAAO;EACP,MAAM;AACR;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".modal-component {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n}\n.modal-wrapper {\n  width: 438px;\n  background: #fff;\n  border-radius: 8px;\n  padding: 30px;\n  text-align: left;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  z-index: 1;\n  transform: translate(-50%, -50%);\n  -ms-transform: translate(-50%, -50%);\n  -webkit-transform: translate(-50%, -50%);\n  -moz-transform: translate(-50%, -50%);\n}\n.modal-overlay {\n  background: rgba(0, 0, 0, 0.4);\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n}\n.modal-heading {\n  font-size: 22px;\n  font-weight: 500;\n  margin-bottom: 12px;\n}\n.modal-contents {\n  font-size: 16px;\n  color: #333;\n  margin-bottom: 30px;\n}\n.modal-buttons {\n  text-align: right;\n}\n.cancel-btn {\n  color: #1984bc;\n  background: #fff;\n  margin-left: 10px;\n  border-color: #1984bc;\n  margin-right: 10px;\n}\n.cancel-btn:hover {\n  background: #03669a;\n  border-color: #03669a;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
