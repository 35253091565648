export const addressTypeOptions = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'Mailing',
    value: 'Mailing',
  },
  {
    label: 'Residence',
    value: 'Residence',
  },
  {
    label: 'Business',
    value: 'Business',
  },
  {
    label: 'Vacation',
    value: 'Vacation',
  },
  {
    label: 'Regional Office',
    value: 'Regional Office',
  },
  {
    label: 'Temporary',
    value: 'Temporary',
  },
  {
    label: 'Previous',
    value: 'Previous',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Billing',
    value: 'Billing',
  },
  {
    label: 'Commission Statement Address',
    value: 'Commission Statement Address',
  },
  {
    label: 'Secondary Residence',
    value: 'Secondary Residence',
  },
];
