import FormField from '../../ui/formField';
import { useSelector } from 'react-redux';
const NameField = (props) => {
  const piFormData = useSelector((state) => state.piData);
  const { loading, error, formData } = piFormData;

  const onFieldChangeHandler = (event) => {
    props.setNameSection((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <>
      {formData?.data?.nameSection?.map((item, index) => {
        return (
          <FormField
            key={item.id}
            label={item.label}
            id={item.id}
            value={item.value}
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            min={item.min}
            max={item.max}
            layoutType={item.layoutType}
            onChangeHandler={onFieldChangeHandler}
            selectOptions={item.selectOptions}
            regex={item?.regex}
            errorMessage={item?.errorMessage}
            validationType={item?.validationType}
          />
        );
      })}
    </>
  );
};
export default NameField;
