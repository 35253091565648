import React, { useState } from 'react';
import moment from 'moment';
import './carrierFormAdministration.css';
import IconUpload from '../../../assets/images/carrier-upload.svg';
import IconDownload from '../../../assets/images/carrier-download.svg';
import FormListingActions from './FormListingActions';
import CustomMultiSelect from './customMultiSelect';
import RowOverlayPopup from './rowOverlay';
import { CgDetailsMore } from 'react-icons/cg';

const ExistingCarriersList = ({
  formList,
  filters,
  records,
  sortOrder,
  sortColumn,
  onDownloadClick,
  navigateToCarrierReUploadForm,
  extractionStatus,
  sorting,
  onPageNumberClick,
  onPrevPageClick,
  onNextPageClick,
  onRecordSelect,
  totalPages,
  currentPage,
  recordsPerPage,
  ITEMS_PER_PAGE_OPTIONS,
  dropDownData,
  handleFilterChange,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);

  return (
    <div>
      {overlayOpen && (
        <div className="overlay-container">
          <RowOverlayPopup
            rowData={selectedRow}
            onClose={() => setOverlayOpen(false)}
          />
        </div>
      )}
      <table className="formAdministration--table">
        <thead>
          <tr>
            <th>
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">CARRIER</span>

                <CustomMultiSelect
                  value={filters.carriers.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={dropDownData?.carriers?.map((carrier) => ({
                    value: carrier.carrier,
                    label: carrier.carrier,
                  }))}
                  onChange={(selectedOptions) =>
                    handleFilterChange('carriers', selectedOptions)
                  }
                />
              </div>
            </th>
            <th>
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">STATE</span>
                <CustomMultiSelect
                  value={filters.states.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={dropDownData?.states?.map((state) => ({
                    value: state.state_short_form,
                    label: state.state,
                  }))}
                  onChange={(selectedOptions) =>
                    handleFilterChange('states', selectedOptions)
                  }
                />
              </div>
            </th>

            <th>
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">PRODUCT</span>
                <CustomMultiSelect
                  value={filters.products.map((value) => ({
                    value,
                    label: value,
                  }))}
                  options={dropDownData?.products?.map((product) => ({
                    value: product.product,
                    label: product.product,
                  }))}
                  onChange={(selectedOptions) =>
                    handleFilterChange('products', selectedOptions)
                  }
                />
              </div>
            </th>
            <th>
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">
                  FORM NAME
                </span>
                <div
                  className="sorting-wrapper"
                  onClick={() => sorting('form_name')}
                >
                  <span
                    className={`sorting-asc ${
                      sortColumn === 'form_name' && sortOrder === 'dsc'
                        ? 'sortIcon--active'
                        : ''
                    }`}
                  ></span>
                  <span
                    className={`sorting-dsc ${
                      sortColumn === 'form_name' && sortOrder === 'asc'
                        ? 'sortIcon--active'
                        : ''
                    }`}
                  ></span>
                </div>
              </div>
            </th>
            <th>
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">
                  CATEGORY TAG
                </span>
              </div>
            </th>

            <th>
              <div
                className="formAdministration--header"
                onClick={() => sorting('uploaded_at')}
              >
                <span className="formAdministration--columnLabel">
                  LAST UPDATED DATE
                </span>
                <div className="sorting-wrapper">
                  <span
                    className={`sorting-asc ${
                      sortColumn === 'uploaded_at' && sortOrder === 'dsc'
                        ? 'sortIcon--active'
                        : ''
                    }`}
                  ></span>
                  <span
                    className={`sorting-dsc ${
                      sortColumn === 'uploaded_at' && sortOrder === 'asc'
                        ? 'sortIcon--active'
                        : ''
                    }`}
                  ></span>
                </div>
              </div>
            </th>
            <th>
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">
                  FORM VERSION NUMBER
                </span>
              </div>
            </th>
            <th>
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">
                  FORM DATE
                </span>
              </div>
            </th>
            <th className="formAdministration--actions">
              <div className="formAdministration--header">
                <span className="formAdministration--columnLabel">ACTIONS</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {formList?.length > 0 ? (
            <>
              {records?.map((item, index) => {
                const extractionStatus = item?.extraction_status?.toLowerCase();
                return (
                  <tr
                    key={index}
                    onClick={() => setSelectedRow(item)}
                    className={
                      item.upload_id === selectedRow ? 'selected-row' : ''
                    }
                  >
                    <td>{item?.carrier?.sort().join(', ')}</td>
                    <td>{item?.state_short_form?.sort().join(', ')}</td>
                    <td>{item?.product?.sort().join(', ')}</td>
                    <td title="Show Details">
                      <button
                        onClick={() => {
                          setSelectedRow(item);
                          setOverlayOpen(true);
                        }}
                        className="formAdministration--Reupload-actionLink formAdministration--ReuploadFormName"
                      >
                        {item?.form_name}
                      </button>{' '}
                    </td>
                    <td>{item?.category_tags?.join(', ')}</td>
                    <td>
                      {item?.uploaded_at
                        ? moment(new Date(item?.uploaded_at)).format(
                            'MM/DD/YYYY h:mm A'
                          )
                        : '-'}
                    </td>
                    <td>{item?.form_version}</td>
                    <td>{item?.form_date}</td>
                    <td
                      className={`formAdministration--actions ${
                        extractionStatus !== 'completed'
                          ? 'disable--actions'
                          : ''
                      }`}
                    >
                      <div>
                        <img
                          src={IconDownload}
                          alt="Icon Download"
                          title="Download PDF"
                          onClick={() => onDownloadClick(item?.upload_id)}
                          className="formAdministration--actionIcon"
                        />

                        {extractionStatus.toLowerCase() !== 'pending' && (
                          <img
                            src={IconUpload}
                            alt="Icon Upload"
                            className="formAdministration--actionIcon"
                            onClick={() =>
                              navigateToCarrierReUploadForm(item?.upload_id)
                            }
                            title="Edit Form"
                          />
                        )}
                        {extractionStatus.toLowerCase() == 'pending' && (
                          <img
                            src={IconUpload}
                            alt="Icon Upload"
                            className="formAdministration--actionIcon"
                            disabled
                            title="Wait for file upload"
                          />
                        )}
                        <FormListingActions
                          extractionStatus={extractionStatus}
                          upload_id={item?.upload_id}
                          title="More Actions'"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan={8} className="formAdministration--emptyList">
                Form List is empty
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {records?.length > 0 && (
        <div className="pagination--wrapper">
          <div className="pagination--infoWrapper">
            <p className="recordsPerPage">
              Items per page
              <select
                value={recordsPerPage}
                onChange={onRecordSelect}
                className="recordsPerPage--dropdown"
              >
                {ITEMS_PER_PAGE_OPTIONS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </p>
            <p className="pagination--info">
              Page {currentPage} of {totalPages}
            </p>
          </div>

          <ul className="pagination">
            <li
              className={`pagination--prev ${
                currentPage === 1 ? 'disable' : ''
              }`}
            >
              <span className="pagination--link" onClick={onPrevPageClick}>
                Prev
              </span>
            </li>

            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (number) => (
                <li
                  className={`pagination--number ${
                    currentPage === number ? 'pagination--item__active' : ''
                  }`}
                  key={number}
                >
                  <span
                    className="pagination--link"
                    onClick={() => onPageNumberClick(number)}
                  >
                    {number}
                  </span>
                </li>
              )
            )}

            <li
              className={`pagination--next ${
                currentPage === totalPages ? 'disable' : ''
              }`}
            >
              <span className="pagination--link" onClick={onNextPageClick}>
                Next
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default ExistingCarriersList;
