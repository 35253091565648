import { Link } from 'react-router-dom';
import BreadCrumbImage from './../../../../assets/images/breadcrumb-home.svg';

const CarrierBreadCrumb = ({
  currentPageName,
  showApplicationUpload,
  showFormAdministration,
  onClickHandler,
}) => {
  return (
    <>
      <div className="breadCrumb__wrapper">
        <Link to={'/caseListing'} onClick={onClickHandler}>
          <img src={BreadCrumbImage} alt="img" className="breadCrumb__img" />
        </Link>
        <Link to={'/caseListing'} onClick={onClickHandler}>
          <span className="breadCrumb__link">Case Listing</span>
        </Link>

        {Boolean(showApplicationUpload) && (
          <>
            {' '}
            <span className="breadCrumb__arrow"></span>
            <Link to={'/carrieruploadform'} onClick={onClickHandler}>
              <span className="breadCrumb__link">Form Upload</span>
            </Link>
          </>
        )}
        {Boolean(showFormAdministration) && (
          <>
            <span className="breadCrumb__arrow"></span>
            <Link to={'/formadministration'} onClick={onClickHandler}>
              <span className="breadCrumb__link">Form Administration</span>
            </Link>
          </>
        )}

        <span className="breadCrumb__arrow"></span>
        <span className="breadCrumb__link--static">{currentPageName}</span>
      </div>
    </>
  );
};
export default CarrierBreadCrumb;
