import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
import ParterDetailsAnnuity from './PartnerDetails';

const PartnershipSectionAnnuity = (props) => {
  const fieldData = useSelector((state) => state.policyOwnerAnnuityData);
  const { loading, error, formData } = fieldData;
  const onPartnershipSectionFieldsChange = (event) => {
    props.setPartnershipSectionFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPartnershipAddressFieldsChange = (event) => {
    props.setPartnershipAddressFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPartnershipContactDetailsFieldsChange = (event) => {
    props.setPartnershipContactDetailsFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      {formData?.data && (
        <>
          <div className="flex">
            {formData?.data?.partnershipSectionFields?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onPartnershipSectionFieldsChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  validationType={item?.validationType}
                />
              );
            })}
          </div>
          <div className="line-separation"></div>
          <h3 id={formData?.data?.partnershipAddressLabel.id}>
            {formData?.data?.partnershipAddressLabel.label}
          </h3>
          <div className="flex">
            {formData?.data?.partnershipAddressFields.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onPartnershipAddressFieldsChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  dropdownType={item.dropdownType}
                />
              );
            })}
          </div>
          <div className="line-separation"></div>
          <h3 id={formData?.data?.partnershipContactDetailsLabel?.id}>
            {formData?.data?.partnershipContactDetailsLabel?.label}
          </h3>
          <div className="flex">
            {formData?.data?.partnershipContactDetailsFields?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  layoutType={item.layoutType}
                  onChangeHandler={onPartnershipContactDetailsFieldsChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  dropdownType={item.dropdownType}
                />
              );
            })}
          </div>
          <div className="line-separation"></div>
          <ParterDetailsAnnuity
            partners={props.partners}
            setPartners={props.setPartners}
          />
        </>
      )}
    </>
  );
};

export default PartnershipSectionAnnuity;
