import { Routes, Route } from 'react-router-dom';
import CaseListing from './components/pages/caseListing';
import PurposeOfCoverage from './components/pages/purposeOfCoverage';
import PolicyDetails from './components/pages/policyDetails';
import ProposedInsured from './components/pages/proposedInsured';
import PolicyOwner from './components/pages/policyOwner';
import Beneficiary from './components/pages/beneficiary';
import MedicalInformation from './components/pages/medicalInformation';
import HealthAndLifestyle from './components/pages/healthAndLifestyle';
import FamilyHealthHistory from './components/pages/familyHealthHistory';
import FinancialInformation from './components/pages/financialInformation';
import OtherGeneralQuestions from './components/pages/otherGeneralQuestions';

import ForeignResidencyAndFinancial from './components/pages/foreignResidencyAndFinancial';
import './App.css';
import Exercise from './components/pages/exercise';
import ProfessionalAdvisors from './components/pages/professionalAdvisors';
import FormUi from './components/formUi';
import ExistingInsurance from './components/pages/existingInsurance';
import ForeignTravel from './components/pages/foreignTravel';
import AviationQuestionnaire from './components/pages/aviationQuestionnaire';
import LongTermCare from './components/pages/longTermCare';
import BusinessInsurance from './components/pages/businessInsurance';
import ApplicationFillout from './components/pages/applicationFillout';
import AvocationQuestionnaire from './components/pages/avocationQuestionnaire';
import LandingPage from './components/pages/landingPage';
import Auth from './components/pages/auth';
import LandingPageModal from './components/pages/landingModal';
import Unauthorized from './components/pages/unauthorized';
import PPAApplication from './components/pages/ppaApplication';
import PurchaserOwnerQuestionnaire from './components/pages/purchaserOwnerQuestionnaire';
import DispositionOfNetPremium from './components/pages/dispositionOfNetPremium';
import AgentQuestionnaire from './components/pages/agentQuestionnaire';
import PolicyOwnerAnnuity from './components/pages/policyOwnerAnnuity';
import ExistingAnnuity from './components/pages/existingAnnuity';
import ApplicationsAvailable from './components/pages/applicationsAvailable';
import CarrierUploadForm from './components/pages/carrierUploadForm';
import CarrierReUploadForm from './components/pages/carrierUploadForm/carrierReupload';
import CarrierFormAdministration from './components/pages/carrierFormAdministration';
import MarkValidFields from './components/pages/carrierOnboard/markValidFields';
import VerifyMapping from './components/pages/carrierOnboard/verifymapping';

function App() {
  // Disable Value Change on input type number on scrolling
  document.addEventListener('wheel', function (event) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });
  return (
    <div className="App">
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/caseListing" element={<CaseListing />} />
          <Route path="applicationfillout" element={<ApplicationFillout />} />
          <Route
            path="/applicationsavailable"
            element={<ApplicationsAvailable />}
          />
          <Route path="carrieruploadform" element={<CarrierUploadForm />} />
          <Route path="re-upload-form" element={<CarrierReUploadForm />} />
          <Route
            path="formadministration"
            element={<CarrierFormAdministration />}
          />
          <Route path="markvalidfields" element={<MarkValidFields />} />
          <Route path="verifymapping" element={<VerifyMapping />} />
          <Route path="form" element={<FormUi />}>
            <Route path="landingPageModal" element={<LandingPageModal />} />
            <Route path="products&coverage" element={<PurposeOfCoverage />} />
            <Route path="policydetails" element={<PolicyDetails />} />
            <Route path="proposedinsured" element={<ProposedInsured />} />
            <Route path="policyowner" element={<PolicyOwner />} />
            <Route path="beneficiaryinformation" element={<Beneficiary />} />
            <Route
              path="physicianinformation"
              element={<MedicalInformation />}
            />
            <Route path="health" element={<HealthAndLifestyle />} />
            <Route path="exercise" element={<Exercise />} />
            <Route
              path="familyhealthhistory"
              element={<FamilyHealthHistory />}
            />
            <Route
              path="financialinformation"
              element={<FinancialInformation />}
            />
            <Route path="businessinsurance" element={<BusinessInsurance />} />
            <Route
              path="professionaladvisors"
              element={<ProfessionalAdvisors />}
            />
            <Route
              path="lifestyle&othergeneralquestions"
              element={<OtherGeneralQuestions />}
            />
            <Route path="existinginsurance" element={<ExistingInsurance />} />
            <Route
              path="foreigntravelinformation"
              element={<ForeignTravel />}
            />
            <Route
              path="avocationquestionnaire"
              element={<AvocationQuestionnaire />}
            />
            <Route
              path="foreignresidencyandfinancialinformation-policyownerindividual"
              element={<ForeignResidencyAndFinancial />}
            />
            <Route
              path="aviationquestionnaire"
              element={<AviationQuestionnaire />}
            />
            <Route path="longtermcare" element={<LongTermCare />} />
            <Route path="ppaapplication" element={<PPAApplication />} />
            <Route
              path="purchaserownerquestionnaire"
              element={<PurchaserOwnerQuestionnaire />}
            />
            <Route
              path="dispositionofnetpremium"
              element={<DispositionOfNetPremium />}
            />
            <Route path="agentquestionnaire" element={<AgentQuestionnaire />} />
            <Route path="existingannuity" element={<ExistingAnnuity />} />
            <Route path="policyownerannuity" element={<PolicyOwnerAnnuity />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
