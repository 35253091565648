import './FormFieldsWrapper.css';
const FormFieldsWrapper = (props) => {
  return (
    <div
      className={`form-fields-wrapper ${
        props.financialInfoPage === true ? 'financialForm-field-wrapper' : ''
      }`}
    >
      {props.children}
    </div>
  );
};
export default FormFieldsWrapper;
