// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-label-radioButtons-wrapper {
  display: flex;
  -webkit-display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.common-label-radioButtons-wrapper > .group-label {
  color: #222222;
  font-size: 14px;
  line-height: 1.5;
  padding-right: 50px;
}
.common-label-radioButtons-wrapper .common-radio-buttons-wrapper {
  display: flex;
  align-items: center;
}
.common-label-radioButtons-wrapper .common-radio-button {
  position: relative;
  margin-right: 10px;
}
.common-label-radioButtons-wrapper .common-radio-button:last-child {
  margin-right: 0;
}
.common-label-radioButtons-wrapper .common-radio-button .radio {
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  opacity: 0;
  top: 0;
  left: 0;
}
.common-label-radioButtons-wrapper .common-radio-label {
  font-size: 14px;
  color: #505050;
  display: inline-block;
  background: #f4f7fa;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 8px;
}
.common-label-radioButtons-wrapper .radio:checked ~ .common-radio-label {
  background: #0177b5;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/commonLabelRadioButton/CommonLabelRadioButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,8BAA8B;EAC9B,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,SAAS;EACT,UAAU;EACV,MAAM;EACN,OAAO;AACT;AACA;EACE,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".common-label-radioButtons-wrapper {\n  display: flex;\n  -webkit-display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 100%;\n  margin-bottom: 10px;\n}\n.common-label-radioButtons-wrapper > .group-label {\n  color: #222222;\n  font-size: 14px;\n  line-height: 1.5;\n  padding-right: 50px;\n}\n.common-label-radioButtons-wrapper .common-radio-buttons-wrapper {\n  display: flex;\n  align-items: center;\n}\n.common-label-radioButtons-wrapper .common-radio-button {\n  position: relative;\n  margin-right: 10px;\n}\n.common-label-radioButtons-wrapper .common-radio-button:last-child {\n  margin-right: 0;\n}\n.common-label-radioButtons-wrapper .common-radio-button .radio {\n  position: absolute;\n  height: 0;\n  width: 0;\n  margin: 0;\n  opacity: 0;\n  top: 0;\n  left: 0;\n}\n.common-label-radioButtons-wrapper .common-radio-label {\n  font-size: 14px;\n  color: #505050;\n  display: inline-block;\n  background: #f4f7fa;\n  padding: 8px 12px;\n  border-radius: 20px;\n  cursor: pointer;\n  margin-right: 8px;\n}\n.common-label-radioButtons-wrapper .radio:checked ~ .common-radio-label {\n  background: #0177b5;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
