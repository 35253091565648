const VerificationCheckBox = (props) => {
  const handleOnChange = () => {
    props.setIsChecked(!props.isChecked);
  };

  return (
    <>
      <div
        className={`label-value-wrapper ${
          props.verificationCheckBox.layoutType
            ? props.verificationCheckBox.layoutType
            : ''
        }`}
      >
        <input
          type={props.verificationCheckBox.type}
          id={props.verificationCheckBox.id}
          name={props.verificationCheckBox.name}
          defaultChecked={props.verificationCheckBox.defaultChecked}
          onChange={handleOnChange}
        />
        <label
          className="checkbox-label"
          htmlFor={props.verificationCheckBox.id}
        >
          {props.verificationCheckBox.label}
        </label>
      </div>
    </>
  );
};

export default VerificationCheckBox;
