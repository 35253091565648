import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './markValidFields.css';
import Footer from '../../../footer';
import Header from '../../../header';
import IconCompleted from './../../../../assets/images/icon-completed.svg';
import IconChecked from './../../../../assets/images/icon-checked.svg';
import CarrierProgressBar from '../carrierProgressBar';

import { markValidFieldsAction } from '../../../../actions/markValidFieldsAction';
import axios from 'axios';
import Loader from '../../../loader';
import { useToast } from '../../../notifications/ToastContext';
import CarrierBreadCrumb from '../carrierBreadCrumb';

const MarkValidFields = () => {
  const pageData = useSelector((state) => state.markValidFieldsData);
  const { loading, formData } = pageData;
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const [markedKeys, setMarkedKeys] = useState([]);
  const [validPagesList, setValidPagesList] = useState([]);
  const [validPagesIndexList, setValidPagesIndexList] = useState([]);
  const [paginationFirstPage, setPaginationFirstPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [searchParams] = useSearchParams();
  const uploaded_id = searchParams.get('upload_id');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(markValidFieldsAction(uploaded_id));
  }, [dispatch, uploaded_id]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentPage(null);
      setCurrentPageIndex(null);
    }, []);
  }, []);

  useEffect(() => {
    setTotalPages(formData?.metadata?.total_pages);
    setPageNumbers(formData?.metadata?.pages_with_valid_fieds);
    setValidPagesList(formData?.metadata?.pages_with_valid_fieds);
    setValidPagesIndexList(
      formData?.metadata?.pages_with_valid_fieds?.map((item) => item - 1)
    );
    setPaginationFirstPage(formData?.metadata?.pages_with_valid_fieds[0]);
    if (!currentPage) {
      setCurrentPage(formData?.metadata?.pages_with_valid_fieds[0]);
    }
    if (!currentPageIndex) {
      setCurrentPageIndex(formData?.metadata?.pages_with_valid_fieds[0] - 1);
    }
    setTimeout(() => {
      document.getElementById('pagination--item__active')?.scrollIntoView(); //To make current active pagination number to be visible in view
      document
        .getElementById('pagination--item__active')
        ?.nextElementSibling?.scrollIntoView(); //To make current active pagination number  sibling to be visible in view
    }, []);
  }, [formData]);

  const { addToast } = useToast();

  const handleShowToast = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'success',
      message: message,
    };
    addToast(newToast);
  };

  const handleShowError = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'error',
      message: message,
    };
    addToast(newToast);
  };

  const onCheckboxChange = (e, keyId) => {
    const keys = {
      id: keyId,
      is_marked: e.target.checked,
    };

    let keyIndex = markedKeys?.findIndex((item) => item.id === keyId);

    if (e.target.checked) {
      if (keyIndex < 0) {
        markedKeys.push(keys);
      } else {
        markedKeys[keyIndex].is_marked = true;
      }
    } else {
      if (keyIndex < 0) {
        markedKeys.push(keys);
      } else {
        markedKeys[keyIndex].is_marked = false;
      }
    }
  };

  const onSaveClick = (confirmValidKey) => {
    /** For Saving Data */
    setShowLoader(true);
    const payload = {
      upload_id: parseInt(uploaded_id),
      page_no: Boolean(confirmValidKey) ? currentPage : 0,
      keys: markedKeys,
    };

    axios({
      url: '/mark-valid-keys/save ',
      method: 'POST',
      data: payload,
    })
      .then((response) => {
        setShowLoader(false);
        handleShowToast(response?.data?.message + ' for page ' + currentPage);

        dispatch(markValidFieldsAction(uploaded_id));
      })
      .catch(function (error) {
        setShowLoader(false);
        handleShowError(
          'Mark Valid Keys Failed!! Please contact administrator'
        );
      });
  };

  const onGenerateMapping = () => {
    setShowLoader(true);
    axios({
      url: '/generate-mapping ',
      method: 'POST',
      data: {
        upload_id: parseInt(uploaded_id),
      },
    })
      .then((response) => {
        setShowLoader(false);
        handleShowToast(response?.data?.message);
        onSaveClick(); //Date is Save on Click of Generate Mapping Button
        navigate('/verifymapping?upload_id=' + parseInt(uploaded_id), {
          replace: true,
        });
      })
      .catch(function (error) {
        setShowLoader(false);
        handleShowError(
          'Generate Mapping Failed!! Please contact administrator'
        );
      });
  };

  const onPrevPageClick = () => {
    if (currentPage > paginationFirstPage) {
      onSaveClick(); /**Auto Save Data on Page Navigaton */
      setCurrentPage((prevPage) => {
        let index = validPagesList?.findIndex((item) => item === prevPage) - 1;
        return validPagesList[index];
      });

      setCurrentPageIndex((prevPage) => {
        let index =
          validPagesIndexList?.findIndex((item) => item === prevPage) - 1;
        return validPagesIndexList[index];
      }); /**  Setting index of the page that is to be displayed */
      setMarkedKeys([]); //Setting Marked Key array empty when moving to next page
    }
  };
  const onNextPageClick = () => {
    if (currentPage < totalPages) {
      onSaveClick(); /**Auto Save Data on Page Navigaton */
      setCurrentPage((prevPage) => {
        let index = validPagesList?.findIndex((item) => item === prevPage) + 1;
        return validPagesList[index];
      });

      setCurrentPageIndex((prevPage) => {
        let index =
          validPagesIndexList?.findIndex((item) => item === prevPage) + 1;
        return validPagesIndexList[index];
      }); /**  Setting index of the page that is to be displayed */
      setMarkedKeys([]); //Setting Marked Key array empty when moving to next page
    }
  };
  const onPageNumberClick = (number) => {
    if (number >= paginationFirstPage && number <= totalPages) {
      onSaveClick(); /**Auto Save Data on Page Navigaton */
      setCurrentPage(number);
      setCurrentPageIndex(
        number - 1
      ); /**  Setting index of the page that is to be displayed */
      setMarkedKeys([]); //Setting Marked Key array empty when moving to next page
    }
  };
  const extractFirstAndLast100 = (inputString, separator) => {
    if (inputString.length <= 200) {
      return inputString;
    }

    const first100 = inputString.substring(0, 100);
    const last100 = inputString.substring(inputString.length - 100);

    return `${first100}${separator}${last100}`;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Header />
          <div id="contents">
            <div className="markValidFields--wrapper">
              <CarrierBreadCrumb
                currentPageName={'Mark Valid Fillable Fields'}
                showApplicationUpload={true}
                showFormAdministration={true}
                onClickHandler={() => onSaveClick(false)}
              />
              <div className="markValidFields--contents">
                <div className="markValidFields--headingWrapper">
                  <h2>
                    Carrier - CPW Mapping - Automated - Marking of Valid
                    Fillable Fields
                  </h2>
                  <div>
                    <button
                      className="button markValidFields--actionBtn"
                      onClick={() => onSaveClick(true)}
                    >
                      Confirm Valid Keys
                    </button>
                    <button
                      className="button markValidFields--actionBtn"
                      onClick={() => onGenerateMapping()}
                    >
                      Generate AI / ML mapping
                    </button>
                    {/* <button
                      className="button markValidFields--actionBtn"
                      onClick={() => onSaveClick()}
                    >
                      Save
                    </button> */}
                  </div>
                </div>
                <div className="markValidFields--carrierContentsWrapper">
                  <div className="markValidFields--progressBar flex">
                    <div className="layout-2">
                      <CarrierProgressBar
                        label="Valid Fields Count"
                        progressCount={formData?.valid_fields_count?.marked}
                        totalCount={formData?.valid_fields_count?.total}
                        toolTipContent="Total count of mark valid fields in the application"
                        isNumeric={true}
                      />
                    </div>
                    <div className="layout-2">
                      <CarrierProgressBar
                        label="Overall Completion %"
                        progressCount={formData?.completion_percent}
                        toolTipContent="Total coverage in terms of number of pages"
                        isNumeric={false}
                      />
                    </div>
                  </div>
                  <div className="markValidFields--carrierContents">
                    <h3 className="markValidFields--carrierName">
                      {formData?.form_name}
                      {formData?.pages &&
                        formData?.pages[currentPageIndex]?.is_completed && (
                          <span className="markValidFields--status">
                            <img
                              className="markValidFields--statusImg"
                              src={IconCompleted}
                              alt="Completed "
                            />
                            <span>Completed</span>
                          </span>
                        )}
                    </h3>
                    <div className="markValidFields--carrierKeysWrapper">
                      {formData?.pages &&
                        formData?.pages[currentPageIndex]?.keys?.map(
                          (item, index) => {
                            return (
                              <div
                                className="markValidFields--carrierFieldsWrapper"
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  name={`markValidKey-pageNo_${formData?.pages[currentPageIndex]?.page_no}-id_${item.id}`}
                                  value={item.extracted_key}
                                  id={`markValidKey-pageNo_${formData?.pages[currentPageIndex]?.page_no}-id_${item.id}`}
                                  className="markValidFields--inputCheck"
                                  defaultChecked={Boolean(item.is_marked)}
                                  onChange={(e) => onCheckboxChange(e, item.id)}
                                />
                                <label
                                  className="markValidFields--carrierFieldsLabel"
                                  htmlFor={`markValidKey-pageNo_${formData?.pages[currentPageIndex]?.page_no}-id_${item.id}`}
                                >
                                  <span className="markValidFields--unchecked"></span>
                                  <img
                                    className="markValidFields--checked"
                                    src={IconChecked}
                                    alt="checked"
                                  />
                                  <span className="markValidFields--checkboxLabel">
                                    {extractFirstAndLast100(
                                      item.extracted_key,
                                      '..............................'
                                    )}
                                  </span>
                                </label>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  {totalPages > 1 && (
                    <div className="pagination--wrapper">
                      {' '}
                      <p className="pagination--info">
                        Page {currentPage} of {totalPages}
                      </p>
                      <div className="pagination">
                        <div
                          className={`pagination--prev ${
                            currentPage === paginationFirstPage ? 'disable' : ''
                          }`}
                        >
                          <span
                            className="pagination--link"
                            onClick={onPrevPageClick}
                          >
                            Prev
                          </span>
                        </div>
                        <div className="pagination--numbersWrapper">
                          {pageNumbers?.map((number) => {
                            return (
                              <div
                                className={`pagination--number ${
                                  currentPage === number
                                    ? 'pagination--item__active'
                                    : ''
                                }`}
                                id={
                                  currentPage === number
                                    ? 'pagination--item__active'
                                    : ''
                                }
                                key={number}
                              >
                                <span
                                  className="pagination--link"
                                  onClick={() => onPageNumberClick(number)}
                                >
                                  {number}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className={`pagination--next ${
                            currentPage === totalPages ? 'disable' : ''
                          }`}
                        >
                          <span
                            className="pagination--link"
                            onClick={onNextPageClick}
                          >
                            Next
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {showLoader && <Loader />}
        </>
      )}
    </>
  );
};
export default MarkValidFields;
