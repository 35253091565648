import axios from '../../../axios/axios';
import './longTermCare.css';
import { useEffect, useState } from 'react';
import CommonFormHeader from '../../commonFormHeader';
import FormField from '../../ui/formField';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import HeightWeightSection from '../../ui/heightWeightSection';
import { useDispatch, useSelector } from 'react-redux';
import { longTermCareAction } from '../../../actions/longTermCareAction';
import ToastMessage from '../../ui/toastMessage';
import CommonTextArea from '../../ui/commonTextArea';
import MarriedSection from './marriedSection';
import LifeStyleSection from './lifeStyleSection';
import MedicalSection from './medicalSection';
import ActivitiesDailyLiving from './ativitiesDailyLiving';
import Loader from '../../loader';
import {
  SET_SEND_DATA,
  SET_SECONDRY_DATA,
} from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import { healthLifeStyleAction } from '../../../actions/healthLifeStyleAction';

const LongTermCare = () => {
  const [dob, setDob] = useState('');
  const [heightValue, setHeightValue] = useState('');
  const [feetValue, setFeetValue] = useState('');
  const [inchesValue, setInchesValue] = useState('');
  const [wightValue, setWeightValue] = useState('');
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [currentlyEmployedValue, setCurrentlyEmployedValue] = useState('');
  const [currentlyEmployedYesValue, setCurrentlyEmployedYesValue] =
    useState('');
  const [marriedData, setMarriedData] = useState({});
  const [lifeStyleData, setLifeStyleData] = useState({});
  const [medicalData, setMedicalData] = useState({});
  const [activitiesDailyLivingData, setActivitiesDailyLivingData] = useState(
    {}
  );
  const dispatch = useDispatch();
  const longTermCareData = useSelector((state) => state.longTermCareData);
  const { loading, error, ltcData } = longTermCareData;

  const hlFormData = useSelector((state) => state.hlData);

  useEffect(() => {
    dispatch(longTermCareAction());
    dispatch(healthLifeStyleAction());
  }, [dispatch]);

  useEffect(() => {
    const initialCurrentlyEmployedValue =
      ltcData?.data?.currentlyEmployed?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialCurrentlyEmployedValue) {
      const key = initialCurrentlyEmployedValue['id'];
      const value = initialCurrentlyEmployedValue['value'];
      setCurrentlyEmployedValue({ [key]: value });
    }
    setWeightValue(hlFormData?.formData?.data?.weightSectionFields?.value);
  }, [ltcData?.data, hlFormData]);

  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  const onDobFieldChange = (event) => {
    setDob((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCurrentlyEmployedRadioChange = (e) => {
    setCurrentlyEmployedValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onCurrentlyEmployedTextChange = (e) => {
    setCurrentlyEmployedYesValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    feetSelectValue: { value: feetValue },
    inchesSelectValue: { value: inchesValue },
    cmFieldValue: { value: heightValue },
    weightSectionFields: { 'mi-wight': wightValue },
    ltcDobSection: dob,
    currentlyEmployed: currentlyEmployedValue,
    currentlyEmployedTextAreaValue: currentlyEmployedYesValue,
    ...marriedData,
    ...lifeStyleData,
    ...medicalData,
    ...activitiesDailyLivingData,
  };
  const hlPayload = {
    weightSectionFields: { 'hal-wight': wightValue },
    feetSelectValue: { value: feetValue },
    inchesSelectValue: { value: inchesValue },
    cmFieldValue: { value: heightValue },
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s20',
      },
    });
    // dispatch({
    //   type: SET_SEND_DATA,
    //   payload: {
    //     payload: hlPayload,
    //     sectionId: "f1v1s7",
    //   },
    // });
  }, [payload]);
  useEffect(() => {
    dispatch({
      type: SET_SECONDRY_DATA,
      payload: {
        payload: hlPayload,
        sectionId: 'f1v1s7',
      },
    });
  }, [hlPayload]);
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s20`,
        payload
      )
      .then((response) => {
        setShowDataSaveLoader(false);
        showToastMessage(true);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s7`,
        hlPayload
      )
      .then((response) => {
        //showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CommonFormHeader
        backPath="/form/businessinsurance"
        pageHeading={ltcData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={ltcData?.common_details?.case_id}
        isCompleted={ltcData?.metadata?.isCompleted}
        sectionId="f1v1s20"
        userName={ltcData?.common_details?.name}
        productSubType={ltcData?.common_details?.product_subtype}
        formId={ltcData?.common_details?.form_id}
        otherCasesInfo={ltcData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {ltcData?.data && (
            <>
              <h3 id={ltcData?.data?.ltcQuestionSection?.id}>
                {ltcData?.data?.ltcQuestionSection?.label}
              </h3>
              <div className="flex">
                {ltcData?.data?.ltcDobSection?.map((item, index) => {
                  return (
                    <FormField
                      key={index}
                      label={item.label}
                      id={item.id}
                      value={item.value}
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      min={item.min}
                      max={item.max}
                      layoutType={item.layoutType}
                      onChangeHandler={onDobFieldChange}
                      selectOptions={item.selectOptions}
                    />
                  );
                })}
              </div>
            </>
          )}
          {ltcData?.data && (
            <HeightWeightSection
              heightValue={heightValue}
              setHeightValue={setHeightValue}
              wightValue={wightValue}
              setWeightValue={setWeightValue}
              setFeetValue={setFeetValue}
              feetValue={feetValue}
              setInchesValue={setInchesValue}
              inchesValue={inchesValue}
              apiData={hlFormData?.formData}
            />
          )}
          <div className="line-separation"></div>
          {ltcData?.data && (
            <LabelRadioButtons
              label={ltcData?.data?.currentlyEmployed?.label}
              options={ltcData?.data?.currentlyEmployed?.radioOptions}
              id={ltcData?.data?.currentlyEmployed?.id}
              onChangeHandler={onCurrentlyEmployedRadioChange}
            />
          )}

          {currentlyEmployedValue['currentlyEmployed-yes'] === 'yes' && (
            <CommonTextArea
              fieldData={ltcData?.data?.currentlyEmployedTextAreaValue}
              onChangeHandler={onCurrentlyEmployedTextChange}
            />
          )}
          <div className="line-separation"></div>
          <MarriedSection setMarriedData={setMarriedData} />
          <div className="line-separation"></div>
          <h3>Lifestyle</h3>
          <LifeStyleSection setLifeStyleData={setLifeStyleData} />
          <div className="line-separation"></div>
          <h3>Medical</h3>
          <MedicalSection setMedicalData={setMedicalData} />
          <div className="line-separation"></div>
          <h3>Activities of Daily Living</h3>
          <ActivitiesDailyLiving
            setActivitiesDailyLivingData={setActivitiesDailyLivingData}
          />
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default LongTermCare;
