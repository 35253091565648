import CommonLabelRadioButtons from '../../../ui/commonLabelRadioButton';
import CommonTextArea from '../../../ui/commonTextArea';
import FormField from '../../../ui/formField';
import Checkboxes from '../../../ui/formField/Checkboxes';
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import { useSelector } from 'react-redux';

const AutoOption = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  const racingOther = formData?.data?.racingOtherValue;
  const onFieldChangeHandler = (event) => {
    props.setRacingFieldOptions((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const racePlanChangeHandler = (event) => {
    props.setRacePlan(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const racePlanTextChangeHandler = (e) => {
    props.setRacePlanTextArea(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const purposeOfRacingChangeHandler = (event) => {
    props.setPurposeOfRacing(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const handleCheckBox = (event) => {
    let updatedList = [...props.racingCheckBoxes];
    if (event.target.checked) {
      updatedList = [...props.racingCheckBoxes, event.target.value];
    } else {
      updatedList.splice(props.racingCheckBoxes.indexOf(event.target.value), 1);
    }
    props.setRacingCheckBoxes(updatedList);
  };

  return (
    <>
      <h3>Racing</h3>

      {props.autoCheckBoxes.includes('motorcycle') && (
        <>
          <p className="plan-question-label">Motorcycle If so,which</p>
          {formData?.data &&
            formData?.data?.racingCheckBoxes?.option?.map((option, index) => {
              return (
                <Checkboxes
                  key={index}
                  name={option.name}
                  value={option.value}
                  type={option.type}
                  label={option.label}
                  defaultChecked={option.defaultChecked}
                  id={option.id}
                  onChangeHandler={handleCheckBox}
                  disabled={option.disable}
                />
              );
            })}
          {props.racingCheckBoxes.includes('other') && (
            <div className="flex">
              <div
                className={`label-value-wrapper layout-2 ${
                  !racingOther?.value ? 'value--empty' : ''
                }`}
              >
                <label className="field-label" htmlFor={racingOther?.id}>
                  {racingOther?.label}
                </label>
                <div>
                  <input
                    type={racingOther?.type}
                    id={racingOther?.id}
                    name={racingOther?.name}
                    placeholder={racingOther?.placeholder}
                    onChange={(e) => {
                      props.setRacingOtherValue(e.target.value);
                    }}
                    defaultValue={racingOther?.value}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <p className="plan-question-label">
        {formData?.data?.purposeOfRacing?.label}
      </p>

      <div className="plan-radio-btn-container">
        <CommonLabelRadioButtons
          options={formData?.data?.purposeOfRacing?.radioOptions}
          onChangeHandler={purposeOfRacingChangeHandler}
        />
      </div>
      <p className="plan-question-label">
        Please provide the following information
      </p>
      <p className="plan-question-label">Number of Races</p>
      <div className="flex">
        {formData?.data?.racingFieldOptions?.map((item, index) => {
          return (
            <FormField
              key={index}
              label={item.label}
              id={item.id}
              value={item.value}
              type={item.type}
              name={item.name}
              placeholder={item.placeholder}
              min={item.min}
              max={item.max}
              dropdownType={item.dropdownType}
              layoutType={item.layoutType}
              onChangeHandler={onFieldChangeHandler}
              selectOptions={item.selectOptions}
              regex={item?.regex}
              errorMessage={item?.errorMessage}
              validationType={item?.validationType}
            />
          );
        })}
      </div>
      <LabelRadioButtons
        label={formData?.data?.racePlan?.label}
        options={formData?.data?.racePlan?.options}
        onChangeHandler={racePlanChangeHandler}
      />
      {props.racePlan['racePlan-yes'] === 'yes' && (
        <CommonTextArea
          fieldData={formData?.data?.racePlanTextArea}
          onChangeHandler={racePlanTextChangeHandler}
        />
      )}
    </>
  );
};

export default AutoOption;
