import React, { useState, useRef } from 'react';
import './tagInput.css';

const TagInput = ({ suggestions, tags, onTagAdd, onTagRemove }) => {
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setShowSuggestions(value.trim().length > 0);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault();

      const trimmedValue = inputValue.trim();
      if (!tags.includes(trimmedValue)) {
        onTagAdd([...tags, trimmedValue]);
      }

      setInputValue('');
      setShowSuggestions(false);
    }
  };

  const handleTagRemove = (tagToRemove) => {
    onTagRemove(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleSuggestionClick = (suggestion) => {
    if (!tags.includes(suggestion)) {
      onTagAdd([...tags, suggestion]);
    }
    setInputValue(''); // Clear the input value after selecting a suggestion
    setShowSuggestions(false);
  };

  const filteredSuggestions = suggestions.filter(
    (suggestion) =>
      !tags.includes(suggestion) &&
      suggestion.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div className="tag-input-container">
      <div className="tag-list">
        {tags.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
            <span className="tag-remove" onClick={() => handleTagRemove(tag)}>
              &#x2715;
            </span>
          </span>
        ))}
      </div>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Enter tags here..."
        className="tag-input"
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="suggestion-list">
          <li className="suggestion-list-label">Recently Used:</li>
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className="suggestion"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TagInput;
