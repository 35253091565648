import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';

const LlcSectionAnnuity = (props) => {
  const fieldData = useSelector((state) => state.policyOwnerAnnuityData);
  const { loading, error, formData } = fieldData;

  const onFieldChangeHandler = (event) => {
    props.setLlcData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      {formData?.data && (
        <>
          <div className="flex llc-fields-wrapper ">
            {formData?.data?.southDakotaQuestionFields?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  min={item.min}
                  max={item.max}
                  dropdownType={item.dropdownType}
                  layoutType={item.layoutType}
                  onChangeHandler={onFieldChangeHandler}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  validationType={item.validationType}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default LlcSectionAnnuity;
