import { useSelector } from 'react-redux';
import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';
const ForeignAddress = (props) => {
  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;

  const sectionLabel = formData?.data?.foreignAddressLabel;
  const fieldLabel = {
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    state: 'State',
    city: 'City',
    zipcode: 'Zip Code',
    cellphone: 'Cell Phone',
    workphone: 'Work Phone',
    homephone: 'Home Phone',
  };
  let handleChange = (i, e) => {
    let newFormValues = [...props.foreignAddress];
    newFormValues[i][e.target.name] = e.target.value;
    props.setForeignAddress(newFormValues);
  };
  let addFormFields = () => {
    props.setForeignAddress([
      ...props.foreignAddress,
      {
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        zipcode: '',
        cellphone: '',
        homephone: '',
        workphone: '',
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...props.foreignAddress];
    newFormValues.splice(i, 1);
    props.setForeignAddress(newFormValues);
  };
  const fields = (
    <>
      {props.foreignAddress.length > 0 &&
        props.foreignAddress.map((element, index) => {
          return (
            <AccordionAddMoreSection
              key={index}
              index={index}
              accordionHeading={`${sectionLabel} - ${index + 1}`}
              onDeleteClick={index ? () => removeFormFields(index) : null}
            >
              <div className="flex">
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.addressLine1 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {fieldLabel.addressLine1}
                  </label>
                  <div>
                    <input
                      type="text"
                      name="addressLine1"
                      placeholder="Enter"
                      value={element.addressLine1 || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.addressLine2 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {fieldLabel.addressLine2}
                  </label>
                  <div>
                    <input
                      type="text"
                      name="addressLine2"
                      placeholder="Enter"
                      value={element.addressLine2 || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !element.city ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{fieldLabel.city}</label>
                  <div>
                    <input
                      type="text"
                      name="city"
                      placeholder="Enter"
                      value={element.city || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !element.state ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{fieldLabel.state}</label>
                  <div>
                    <input
                      type="text"
                      name="state"
                      placeholder="Enter"
                      value={element.state || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>

                <div
                  className={`label-value-wrapper layout-3 ${
                    !element.zipcode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{fieldLabel.zipcode}</label>
                  <div>
                    <input
                      type="text"
                      name="zipcode"
                      placeholder="Enter "
                      value={element.zipcode || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !element.cellphone ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{fieldLabel.cellphone}</label>
                  <div>
                    <input
                      type="text"
                      name="cellphone"
                      placeholder="Enter "
                      value={element.cellphone || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !element.workphone ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{fieldLabel.workphone}</label>
                  <div>
                    <input
                      type="text"
                      name="workphone"
                      placeholder="Enter "
                      value={element.workphone || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !element.homephone ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{fieldLabel.homephone}</label>
                  <div>
                    <input
                      type="text"
                      name="homephone"
                      placeholder="Enter "
                      value={element.homephone || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
              </div>
            </AccordionAddMoreSection>
          );
        })}
    </>
  );
  return (
    <>
      {props.foreignAddress.length > 0 && (
        <div className="foreignResidency__foreignAddress">
          {fields}
          <div>
            <span className="addFieldButton" onClick={() => addFormFields()}>
              ADD MORE
            </span>
          </div>
        </div>
      )}
    </>
  );
};
export default ForeignAddress;
