import React, { useState } from 'react';
import './Header.css';
import GolcondaLogo from './../../assets/images/GolcondaLogo.svg';
import axios from '../../axios/axios';
import { Link } from 'react-router-dom';
const Header = () => {
  const [userClick, setUserClick] = useState(false);

  const onLogoutClick = () => {
    axios
      .get('/getLogoutUrl')
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          window.location.replace(response?.data?.logout_url);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };
  const userName = 'Kevin Mendis';
  return (
    <>
      <header id="header">
        <div className="header-contents">
          <div className="logo">
            <Link to={'/caseListing'}>
              <img src={GolcondaLogo} alt="Logo" />
            </Link>
          </div>
          <div className="applicationUpload-userInfo--wrapper">
            {process.env.REACT_APP_ENABLE_APPLICATION_UPLOAD && (
              <Link
                className="button applicationUpload--btn"
                to={'/carrieruploadform'}
              >
                Form Upload
              </Link>
            )}

            <div className="user-info">
              <div className="user-info-wrapper">
                <span className="user-name">
                  {localStorage.getItem('userName')}
                </span>
                <div onClick={() => setUserClick(!userClick)}>
                  <div className="user-name-logo">
                    {localStorage.getItem('userName') &&
                      localStorage
                        .getItem('userName')
                        .match(/(^\S\S?|\b\S)?/g)
                        .join('')
                        .match(/(^\S|\S$)?/g)
                        .join('')
                        .toUpperCase()}
                  </div>
                </div>
              </div>
              {userClick && (
                <div className="logout-modal-component" onClick={onLogoutClick}>
                  <div className="logout-modal-wrapper">
                    <p className="logout-modal-heading">Log Out</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
