import {
  BUSINESS_INSURANCE_DATA_REQUEST,
  BUSINESS_INSURANCE_DATA_SUCCESS,
  BUSINESS_INSURANCE_DATA_FAIL,
} from '../constants/businessInsuranceConstants';

export const businessInsuranceReducer = (state = { formData: [] }, action) => {
  switch (action.type) {
    case BUSINESS_INSURANCE_DATA_REQUEST:
      return { loading: true, formData: [] };
    case BUSINESS_INSURANCE_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case BUSINESS_INSURANCE_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
