import './LabelRadioButtons.css';
import PropTypes from 'prop-types';

const LabelRadioButtons = (props) => {
  const radioButtonOptions = props?.options;
  const radioButtonFields = radioButtonOptions?.map((item) => {
    return (
      <div className="radio-button" key={item.id}>
        <input
          type={item.type}
          value={item.value}
          name={item.name}
          id={item.id}
          className="radio"
          defaultChecked={item.defaultChecked}
        />
        <label htmlFor={item.id} className="radio-label">
          {item.label}
        </label>
      </div>
    );
  });
  return (
    <>
      <div
        className={`label-radioButtons-wrapper ${
          props.boldLabel ? 'no-margin' : ''
        }`}
      >
        {props.boldLabel
          ? props.label && <h3 className="no-margin">{props.label}</h3>
          : props.label && <p className="group-label">{props.label}</p>}

        {props.options && (
          <div
            className="radio-buttons-wrapper"
            onChange={props.onChangeHandler}
          >
            {radioButtonFields}
          </div>
        )}
      </div>
    </>
  );
};
export default LabelRadioButtons;

LabelRadioButtons.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onChangeHandler: PropTypes.func,
};
