import { useEffect } from 'react';
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import TableHeader from '../tableHeader';
import MotherInformation from '../motherInformation';
import FatherInformation from '../fatherInforamtion';
import BrotherInformation from '../brotherInformation';
import SisterInformation from '../sisterInformation';
import { useSelector } from 'react-redux';

const FamilyHealthQuestions = (props) => {
  const pageData = useSelector((state) => state.familyHealthReducer);
  const { loading, error, formData } = pageData;

  useEffect(() => {
    const defaultChecked =
      formData?.data?.familyHealthQuestions?.radioOptions?.find(
        (element) => element.defaultChecked
      );
    props.setFamilyDiseaseCheck(() => ({
      [defaultChecked?.id]: defaultChecked?.value,
    }));
  }, [formData.data]);

  const onChangeHandler = (e) => {
    props.setFamilyDiseaseCheck(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const familyHealthData = formData?.data?.familyHealthQuestions;

  const familyHealthFields = (
    <LabelRadioButtons
      label={familyHealthData.label}
      options={familyHealthData.radioOptions}
      onChangeHandler={onChangeHandler}
    />
  );

  return (
    <>
      {familyHealthFields}

      <div className="familyHealthSection">
        <TableHeader />
        <MotherInformation setMotherData={props.setMotherData} />
        <FatherInformation setFatherData={props.setFatherData} />
        <BrotherInformation
          brothersDetails={props.brothersDetails}
          setBrothersDetails={props.setBrothersDetails}
        />
        <SisterInformation
          setSistersDetails={props.setSistersDetails}
          sistersDetails={props.sistersDetails}
        />
      </div>
    </>
  );
};

export default FamilyHealthQuestions;
