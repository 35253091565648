import { useEffect, useState } from 'react';
import './beneficiary.css';
import CommonFormHeader from '../../commonFormHeader';
import CommonLabelRadioButtons from '../../ui/commonLabelRadioButton';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import CbIndividualSection from './contingentBeneficiary/cbIndividualSection';
import PbIndividualSection from './primaryBeneficiary/pbIndividualSection.js/index.js';
import PbCompanySection from './primaryBeneficiary/pbCompanySection';
import PbPartnershipSection from './primaryBeneficiary/pbPartnershipSection';
import PbTrustSection from './primaryBeneficiary/pbTrustSection';
import PrimaryLLCSection from './primaryBeneficiary/pbLLCSection';
import CbCompanySection from './contingentBeneficiary/cbCompanySection';
import CbPartnershipSection from './contingentBeneficiary/cbPartnershipSection';
import CbTrustSection from './contingentBeneficiary/cbTrustSection';
import { useDispatch, useSelector } from 'react-redux';
import { beneficiaryAction } from '../../../actions/beneficiaryAction';
import Loader from '../../loader';
import axios from '../../../axios/axios';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import ErrorToastMessage from '../../ui/errorToastMessage';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const Beneficiary = () => {
  const [beneficiaryQuestion, setBeneficiaryQuestion] = useState({});
  const [pbIndividual, setPbIndividual] = useState([]);
  const [pbIndividualPercentage, setPbIndividualPercentage] = useState({});
  const [pbEntityQuestion, setPbEntityQuestion] = useState({});

  const [pbCompanyPercentage, setPbCompanyPercentage] = useState(0);
  const [pbParterDetails, setPbParterDetails] = useState([]);
  const [pbTrustData, setPbTrustData] = useState({});
  const [pbTrustName, setPBTrustName] = useState({});
  const [pbTrusteePercentage, setPbTrusteePercentage] = useState({});
  const [pbTrusteeIndividualPercentage, setPbTrusteeIndividualPercentage] =
    useState({});
  const [pbBeneficiaryPercentage, setPbBeneficiaryPercentage] = useState({});
  const [pbTrustTotalPer, setPbTrustTotalPer] = useState(0);
  const [pbPartnershipData, setpbPartnershipData] = useState({});
  const [pbPartnershipTotalPercentage, setPbPartnershipTotalPer] = useState(0);
  const [pbParterPercentage, setPbParterPercentage] = useState({});
  const [totalPbPercentage, setTotalPbPercentage] = useState(0);
  const [totalPbPendingPercentage, setTotalPbPendingPercentage] = useState(0);
  const [totalCbPendingPercentage, setTotalCbPendingPercentage] = useState(0);

  const [pbCompanyNameSection, setPbCompanyNameSection] = useState({});
  const [pbCompanyAuthorizedFields, setPbCompanyAuthorizedFields] = useState(
    {}
  );
  const [pbCompanyAddressFields, setPbCompanyAddressFields] = useState({});
  const [pbCompanyContactDetailsFields, setPbCompanyContactDetailsFields] =
    useState({});
  const [pbCompanyPhoneFields, setPbCompanyPhoneFields] = useState({});
  const [pbLLCJurisdicationData, setPBLLCJurisdicationData] = useState({});
  const [pbLLCFieldsData, setPBLLCFieldsData] = useState({});
  const [pbLLCPercentage, setPBLLCPercentage] = useState(0);
  //------------------------------------------------------------------------
  const [contingentQuestion, setContingentQuestion] = useState({});
  const [cbIndividual, setCbIndividual] = useState([]);
  const [cbIndividualPercentage, setCbIndividualPercentage] = useState({});
  const [cbEntityQuestion, setCbEntityQuestion] = useState({});
  const [cbCompanyData, setCbCompanyData] = useState({});
  const [cbCompanyPercentage, setCbCompanyPercentage] = useState(0);
  const [cbCompanyPhoneFields, setCbCompanyPhoneFields] = useState({});
  const [cbTrustData, setCbTrustData] = useState({});
  const [cbTrustTotalPer, setCbTrustTotalPer] = useState(0);
  const [cbTrustName, setCBTrustName] = useState({});
  const [cbTrusteePercentage, setCbTrusteePercentage] = useState({});
  const [cbTrusteeIndividualPercentage, setCbTrusteeIndividualPercentage] =
    useState({});
  const [cbBeneficiaryPercentage, setCbBeneficiaryPercentage] = useState({});
  const [cbPartnershipData, setCbPartnershipData] = useState({});
  const [cbParterDetails, setCbParterDetails] = useState([]);
  const [cbParterPercentage, setCbParterPercentage] = useState({});
  const [cbPartnershipTotalPercentage, setCbPartnershipTotalPer] = useState(0);
  const [totalCbPercentage, setTotalCbPercentage] = useState(0);

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const dispatch = useDispatch();
  const beneficiaryFieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = beneficiaryFieldData;

  useEffect(() => {
    dispatch(beneficiaryAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const beneficiaryQuestionData = bnData?.data?.beneficiaryQuestion;
  const contingentQuestionData = bnData?.data?.contingentQuestion;
  const pbEntityQuestionData = bnData?.data?.pbEntityQuestion;
  const cbEntityQuestionData = bnData?.data?.cbEntityQuestion;

  const beneficiaryChangeHandler = (event) => {
    const initpbEntityValue = bnData?.data?.pbEntityQuestion?.options.find(
      (item) => item.defaultChecked
    );
    if (initpbEntityValue) {
      const key = initpbEntityValue['id'];
      const value = initpbEntityValue['value'];
      setPbEntityQuestion({ [key]: value });
    }

    setBeneficiaryQuestion(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const pbEntityQuestionChangeHandler = (event) => {
    setPbEntityQuestion(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const cbEntityQuestionChangeHandler = (event) => {
    setCbEntityQuestion(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const contingentChangeHandler = (event) => {
    const initcbEntityValue = bnData?.data?.cbEntityQuestion?.options.find(
      (item) => item.defaultChecked
    );
    if (initcbEntityValue) {
      const key = initcbEntityValue['id'];
      const value = initcbEntityValue['value'];
      setCbEntityQuestion({ [key]: value });
    }
    setContingentQuestion(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const onCloseToastMessage = () => {
    showToastMessage(false);
  };

  useEffect(() => {
    const initialBeneficiaryValue =
      bnData?.data?.beneficiaryQuestion?.options.find(
        (item) => item.defaultChecked
      );
    if (initialBeneficiaryValue) {
      const key = initialBeneficiaryValue['id'];
      const value = initialBeneficiaryValue['value'];
      setBeneficiaryQuestion({ [key]: value });
    }
    const initialpbEntityValue = bnData?.data?.pbEntityQuestion?.options.find(
      (item) => item.defaultChecked
    );
    if (initialpbEntityValue) {
      const key = initialpbEntityValue['id'];
      const value = initialpbEntityValue['value'];
      setPbEntityQuestion({ [key]: value });
    }
    const initialcbEntityValue = bnData?.data?.cbEntityQuestion?.options.find(
      (item) => item.defaultChecked
    );
    if (initialcbEntityValue) {
      const key = initialcbEntityValue['id'];
      const value = initialcbEntityValue['value'];
      setCbEntityQuestion({ [key]: value });
    }
    const initialContingentValue =
      bnData?.data?.contingentQuestion?.options.find(
        (item) => item.defaultChecked
      );
    if (initialContingentValue) {
      const key = initialContingentValue['id'];
      const value = initialContingentValue['value'];
      setContingentQuestion({ [key]: value });
    }
    setPbIndividual(bnData?.data?.pbIndividual);
    setCbIndividual(bnData?.data?.cbIndividual);
    setPbParterDetails(bnData?.data?.pbParterDetails);
    setCbParterDetails(bnData?.data?.cbParterDetails);
    setPbCompanyPhoneFields(bnData?.data?.pbCompanyPhoneFields);

    bnData?.data?.pbIndividual?.map((item, index) => {
      setPbIndividualPercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.percentage ? item.percentage : 0,
      }));
    });

    bnData?.data?.cbIndividual?.map((item, index) => {
      setCbIndividualPercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.percentage ? item.percentage : 0,
      }));
    });

    bnData?.data?.pbParterDetails?.lenght > 0
      ? bnData?.data?.pbParterDetails?.map((item, index) => {
          setPbParterPercentage((prevState) => ({
            ...prevState,
            [index ? index : 0]: item.partnership ? item.partnership : 0,
          }));
        })
      : setPbParterPercentage({ 0: 0 });

    bnData?.data?.pbTrusteeSection?.map((item, index) => {
      setPbTrusteePercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.company_percentage
          ? item.company_percentage
          : 0,
      }));
    });
    bnData?.data?.pbTrusteeSection?.map((item, index) => {
      setPbTrusteeIndividualPercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.percentage ? item.percentage : 0,
      }));
    });
    bnData?.data?.pbLLCFields?.map((item, index) => {
      if (item.id === 'llcPercentage')
        setPBLLCPercentage(item.value ? item.value : 0);
    });

    bnData?.data?.pbBeneficiarySection?.map((item, index) => {
      setPbBeneficiaryPercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.percentage ? item.percentage : 0,
      }));
    });

    bnData?.data?.cbParterDetails?.map((item, index) => {
      setCbParterPercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.partnership ? item.partnership : 0,
      }));
    });

    bnData?.data?.cbTrusteeSection?.map((item, index) => {
      setCbTrusteePercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.company_percentage
          ? item.company_percentage
          : 0,
      }));
    });
    bnData?.data?.cbTrusteeSection?.map((item, index) => {
      setCbTrusteeIndividualPercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.percentage ? item.percentage : 0,
      }));
    });

    bnData?.data?.cbBeneficiarySection?.map((item, index) => {
      setCbBeneficiaryPercentage((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.percentage ? item.percentage : 0,
      }));
    });

    setPbCompanyPercentage(bnData?.data?.pbCompanyPercentage?.value);
    setPbPartnershipTotalPer(bnData?.data?.pbPartnershipTotalPercentage?.value);
    setPbTrustTotalPer(bnData?.data?.pbTrustTotalPer?.value);

    setCbCompanyPercentage(bnData?.data?.cbCompanyPercentage?.value);
    setCbPartnershipTotalPer(bnData?.data?.cbPartnershipTotalPercentage?.value);
    setCbTrustTotalPer(bnData?.data?.cbTrustTotalPer?.value);
    setCbCompanyPhoneFields(bnData?.data?.cbCompanyPhoneFields);
  }, [bnData?.data]);
  useEffect(() => {
    if (Object.keys(pbIndividualPercentage).length > 0) {
      setTotalPbPercentage(
        parseFloat(
          Object.values(pbIndividualPercentage).reduce(
            (a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0)
          )
        ) +
          parseFloat(pbTrustTotalPer ? pbTrustTotalPer : 0) +
          +parseFloat(pbCompanyPercentage ? pbCompanyPercentage : 0) +
          parseFloat(
            pbPartnershipTotalPercentage ? pbPartnershipTotalPercentage : 0
          ) +
          parseFloat(pbLLCPercentage ? pbLLCPercentage : 0)
      );
    }
  }, [
    pbIndividualPercentage,
    pbCompanyPercentage,
    pbTrustTotalPer,
    pbPartnershipTotalPercentage,
    pbLLCPercentage,
  ]);
  useEffect(() => {
    if (Object.keys(cbIndividualPercentage).length > 0) {
      setTotalCbPercentage(
        parseFloat(
          Object.values(cbIndividualPercentage).reduce(
            (a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0)
          )
        ) +
          parseFloat(cbTrustTotalPer ? cbTrustTotalPer : 0) +
          parseFloat(cbCompanyPercentage ? cbCompanyPercentage : 0) +
          parseFloat(
            cbPartnershipTotalPercentage ? cbPartnershipTotalPercentage : 0
          )
      );
    }
  }, [
    cbIndividualPercentage,
    cbTrustTotalPer,
    cbCompanyPercentage,
    cbPartnershipTotalPercentage,
  ]);

  useEffect(() => {
    setTotalPbPendingPercentage(100 - totalPbPercentage);
  }, [totalPbPercentage]);
  useEffect(() => {
    setTotalCbPendingPercentage(100 - totalCbPercentage);
  }, [totalCbPercentage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    beneficiaryQuestion,
    contingentQuestion,
    pbIndividual,
    cbIndividual,
    pbCompanyNameSection,
    pbCompanyAuthorizedFields,
    pbCompanyAddressFields,
    pbCompanyContactDetailsFields,
    pbCompanyPhoneFields: pbCompanyPhoneFields,
    pbEntityQuestion,

    pbCompanyPercentage: { pbCompanyPercentage },
    ...pbTrustData,
    pbTrustTotalPer: { pbTrustTotalPer },
    pbTrustName: pbTrustName,
    pbParterDetails,
    pbPartnershipTotalPercentage: {
      pbPercentage: pbPartnershipTotalPercentage,
    },
    ...pbPartnershipData,
    pbLLCJurisdiction: pbLLCJurisdicationData,
    pbLLCFields: pbLLCFieldsData,
    cbEntityQuestion,
    cbPartnershipTotalPercentage: {
      cbPercentage: cbPartnershipTotalPercentage,
    },
    ...cbCompanyData,
    cbCompanyPhoneFields: cbCompanyPhoneFields,
    cbCompanyPercentage: { cbCompanyPercentage },
    ...cbTrustData,
    cbTrustTotalPer: { cbTrustTotalPer },
    cbTrustName: cbTrustName,
    ...cbPartnershipData,
    cbParterDetails,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s21',
      },
    });
  }, [payload]);

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s21`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
        dispatch(beneficiaryAction());
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        setShowDataSaveLoader(false);
        console.error('There was an error!', error);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/foreignresidencyandfinancialinformation-policyownerindividual"
        nextPath="/form/professionaladvisors"
        pageHeading={bnData?.metadata?.label}
        caseIdValue={bnData?.common_details?.case_id}
        onSaveFormDataHandler={onSaveFormDataHandler}
        isCompleted={bnData?.metadata?.isCompleted}
        sectionId="f1v1s21"
        userName={bnData?.common_details?.name}
        productSubType={bnData?.common_details?.product_subtype}
        formId={bnData?.common_details?.form_id}
        otherCasesInfo={bnData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {bnData?.data && (
            <>
              {bnData?.data && (
                <>
                  <div className="flex beneficiaryAllocationBar full-width">
                    <div className="beneficiaryAllocationBar__subcontainer flex">
                      <div className="beneficiaryAllocationBar__contents">
                        {' '}
                        <h3 className="allocation__heading">
                          Primary Beneficiary
                        </h3>
                        {totalPbPendingPercentage < 0 ? (
                          <>
                            <h4 className="beneficiaryAllocation__error">
                              Warning: Percentage cannot exceed 100%
                            </h4>
                          </>
                        ) : (
                          <>
                            <div className="allocationText">
                              <div className="percentage__container flex">
                                <p>{totalPbPercentage}%</p>
                              </div>
                              <div className="allocatedLabel">Allocated</div>
                            </div>
                            <div id="progressbar">
                              <div
                                style={{
                                  width: totalPbPercentage + '%',
                                  maxWidth: '100%',
                                }}
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="beneficiaryAllocationBar__contents">
                        {' '}
                        <h3 className="allocation__heading">
                          Contingent Beneficiary
                        </h3>
                        {totalCbPendingPercentage < 0 ? (
                          <>
                            <h4 className="beneficiaryAllocation__error">
                              Warning: Percentage cannot exceed 100%
                            </h4>
                          </>
                        ) : (
                          <>
                            <div className="allocationText">
                              <div className="percentage__container flex">
                                <p>{totalCbPercentage}%</p>
                              </div>
                              <div className="allocatedLabel">Allocated</div>
                            </div>
                            <div id="progressbar">
                              <div
                                style={{
                                  width: totalCbPercentage + '%',
                                  maxWidth: '100%',
                                }}
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="layout-2 label-value-wrapper">
                    <h3 id={beneficiaryQuestionData?.id}>
                      {beneficiaryQuestionData?.label}
                    </h3>
                  </div>
                  <CommonLabelRadioButtons
                    options={beneficiaryQuestionData?.options}
                    onChangeHandler={beneficiaryChangeHandler}
                  />
                </>
              )}
              {beneficiaryQuestion['beneficiaryQuestion-individual'] ===
                'individual' &&
                bnData?.data?.pbIndividualPercentage && (
                  <PbIndividualSection
                    setPbIndividual={setPbIndividual}
                    pbIndividual={pbIndividual}
                    pbIndividualPercentage={pbIndividualPercentage}
                    setPbIndividualPercentage={setPbIndividualPercentage}
                  />
                )}

              <div
                className={
                  beneficiaryQuestion['beneficiaryQuestion-entity'] === 'entity'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <div className="line-separation layout-1"></div>

                <CommonLabelRadioButtons
                  options={bnData?.data && pbEntityQuestionData.options}
                  onChangeHandler={pbEntityQuestionChangeHandler}
                />
                <div className="line-separation layout-1"></div>
              </div>

              <div
                className={
                  beneficiaryQuestion['beneficiaryQuestion-entity'] ===
                    'entity' &&
                  pbEntityQuestion['pbEntityQuestion-cbCompany'] === 'company'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <PbCompanySection
                  setPbCompanyNameSection={setPbCompanyNameSection}
                  setPbCompanyAuthorizedFields={setPbCompanyAuthorizedFields}
                  setPbCompanyAddressFields={setPbCompanyAddressFields}
                  setPbCompanyContactDetailsFields={
                    setPbCompanyContactDetailsFields
                  }
                  setPbCompanyPhoneFields={setPbCompanyPhoneFields}
                  pbCompanyPhoneFields={pbCompanyPhoneFields}
                  pbCompanyPercentage={pbCompanyPercentage}
                  setPbCompanyPercentage={setPbCompanyPercentage}
                />
              </div>

              <div
                className={
                  beneficiaryQuestion['beneficiaryQuestion-entity'] ===
                    'entity' &&
                  pbEntityQuestion['pbEntityQuestion-trust'] === 'trust'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <PbTrustSection
                  setPbTrustData={setPbTrustData}
                  pbTrusteePercentage={pbTrusteePercentage}
                  setPbTrusteePercentage={setPbTrusteePercentage}
                  pbTrusteeIndividualPercentage={pbTrusteeIndividualPercentage}
                  setPbTrusteeIndividualPercentage={
                    setPbTrusteeIndividualPercentage
                  }
                  setPbBeneficiaryPercentage={setPbBeneficiaryPercentage}
                  pbBeneficiaryPercentage={pbBeneficiaryPercentage}
                  setPbTrustTotalPer={setPbTrustTotalPer}
                  setPBTrustName={setPBTrustName}
                />
              </div>
              <div
                className={
                  beneficiaryQuestion['beneficiaryQuestion-entity'] ===
                    'entity' &&
                  pbEntityQuestion['pbEntityQuestion-llc'] === 'llc'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <PrimaryLLCSection
                  setPBLLCJurisdicationData={setPBLLCJurisdicationData}
                  setPBLLCFieldsData={setPBLLCFieldsData}
                  setPBLLCPercentage={setPBLLCPercentage}
                />
              </div>

              <div
                className={
                  beneficiaryQuestion['beneficiaryQuestion-entity'] ===
                    'entity' &&
                  pbEntityQuestion['pbEntityQuestion-partnership'] ===
                    'partnership'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <PbPartnershipSection
                  setpbPartnershipData={setpbPartnershipData}
                  pbParterDetails={pbParterDetails}
                  setPbParterDetails={setPbParterDetails}
                  setPbPartnershipTotalPercentage={setPbPartnershipTotalPer}
                  setPbParterPercentage={setPbParterPercentage}
                  pbParterPercentage={pbParterPercentage}
                />
              </div>

              {bnData?.data?.contingentQuestion && (
                <>
                  <div className="line-separation layout-1"></div>
                  <div className="layout-2 label-value-wrapper">
                    <h3 id={contingentQuestionData.id}>
                      {contingentQuestionData.label}
                    </h3>
                  </div>

                  <CommonLabelRadioButtons
                    options={contingentQuestionData.options}
                    onChangeHandler={contingentChangeHandler}
                  />
                </>
              )}
              {contingentQuestion['contingentQuestion-individual'] ===
                'individual' && (
                <CbIndividualSection
                  setCbIndividual={setCbIndividual}
                  cbIndividual={cbIndividual}
                  cbIndividualPercentage={cbIndividualPercentage}
                  setCbIndividualPercentage={setCbIndividualPercentage}
                />
              )}

              <div
                className={
                  contingentQuestion['contingentQuestion-entity'] === 'entity'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <div className="line-separation layout-1"></div>
                <CommonLabelRadioButtons
                  options={bnData?.data && cbEntityQuestionData.options}
                  onChangeHandler={cbEntityQuestionChangeHandler}
                />
              </div>

              <div
                className={
                  contingentQuestion['contingentQuestion-entity'] ===
                    'entity' &&
                  cbEntityQuestion['cbEntityQuestion-cbCompany'] === 'company'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <CbCompanySection
                  setCbCompanyData={setCbCompanyData}
                  setCbCompanyPercentage={setCbCompanyPercentage}
                  setCbCompanyPhoneFields={setCbCompanyPhoneFields}
                  cbCompanyPhoneFields={cbCompanyPhoneFields}
                />
              </div>

              <div
                className={
                  contingentQuestion['contingentQuestion-entity'] ===
                    'entity' &&
                  cbEntityQuestion['cbEntityQuestion-trust'] === 'trust'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <CbTrustSection
                  setCbTrustData={setCbTrustData}
                  setCbTrustTotalPer={setCbTrustTotalPer}
                  cbTrusteePercentage={cbTrusteePercentage}
                  setCbTrusteePercentage={setCbTrusteePercentage}
                  cbTrusteeIndividualPercentage={cbTrusteeIndividualPercentage}
                  setCbTrusteeIndividualPercentage={
                    setCbTrusteeIndividualPercentage
                  }
                  setCbBeneficiaryPercentage={setCbBeneficiaryPercentage}
                  cbBeneficiaryPercentage={cbBeneficiaryPercentage}
                  setCBTrustName={setCBTrustName}
                />
              </div>

              <div
                className={
                  contingentQuestion['contingentQuestion-entity'] ===
                    'entity' &&
                  cbEntityQuestion['cbEntityQuestion-partnership'] ===
                    'partnership'
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <CbPartnershipSection
                  setCbPartnershipData={setCbPartnershipData}
                  cbParterDetails={cbParterDetails}
                  setCbParterDetails={setCbParterDetails}
                  setCbPartnershipTotalPercentage={setCbPartnershipTotalPer}
                  cbParterPercentage={cbParterPercentage}
                  setCbParterPercentage={setCbParterPercentage}
                />
              </div>
            </>
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default Beneficiary;
