import React, { useEffect, useState } from 'react';
import CommonFormHeader from '../../commonFormHeader';
import Loader from '../../loader';
import FormField from '../../ui/formField';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import './ppaApplication.css';
import LabelRadioOptions from '../../ui/labelRadioOptions';
import { useDispatch, useSelector } from 'react-redux';
import { ppaApplicationAction } from '../../../actions/ppaApplicationAction';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import axios from 'axios';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import ToastMessage from '../../ui/toastMessage';

const PPAApplication = () => {
  const ppaData = useSelector((state) => state.ppaApplicationData);
  const { loading, error, formData } = ppaData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ppaApplicationAction());
  }, [dispatch]);

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const [annuitantName, setAnnuitantName] = useState([]);
  const nameChangeHandler = (e) => {
    setAnnuitantName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [driverLicenseValue, setriverDLicenseValue] = useState([]);
  const driverChangeHandler = (e) => {
    setriverDLicenseValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [annuitantAddressValue, setAnnuitantAddressValue] = useState([]);
  const addressChangeHandler = (e) => {
    setAnnuitantAddressValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [annuitantBasicValue, setAnnuitantBasicValue] = useState([]);
  const annuitantValueChange = (e) => {
    setAnnuitantBasicValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [primaryBenEntityValue, setPrimaryBenEntityValue] = useState({});
  const primaryBenEntityChange = (e) => {
    setPrimaryBenEntityValue({ [e.target.name]: e.target.value });
  };

  const [primaryBenValues, setPrimaryBenValues] = useState([]);
  const primaryBenChange = (e) => {
    setPrimaryBenValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [contingentBenEntityValue, setContingentBenEntityValue] = useState({});
  const contingentBenEntityChange = (e) => {
    setContingentBenEntityValue({ [e.target.name]: e.target.value });
  };

  const [contingentBenValues, setContingentBenValues] = useState([]);
  const contingentBenChange = (e) => {
    setContingentBenValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [inititalPaymentValue, setinititalPaymentValue] = useState([]);
  const initialPaymentAmtChange = (e) => {
    setinititalPaymentValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const [planTypeValue, setPlanTypeValue] = useState({});
  const planTypeChangeHandler = (e) => {
    setPlanTypeValue((prevState) => ({
      [e.target.id]: e.target.value,
    }));
  };

  const [nonSpecialityDivisionsData, setNonSpecialityDivisionsData] = useState(
    []
  );
  const nonSpecialityDivisionsChange = (index, e) => {
    let newFormValues = [...nonSpecialityDivisionsData];
    newFormValues[index][e.target.name] = e.target.value;
    setNonSpecialityDivisionsData(newFormValues);
  };
  let addNonSpecialityDivisionsData = () => {
    setNonSpecialityDivisionsData([
      ...nonSpecialityDivisionsData,
      { fundName: '', percentage: '' },
    ]);
  };

  let removeNonSpecialityDivisionsData = (i) => {
    let newFormValues = [...nonSpecialityDivisionsData];
    newFormValues.splice(i, 1);
    setNonSpecialityDivisionsData(newFormValues);
  };

  useEffect(() => {
    if (formData?.data?.nonSpecialityDivisionsFields !== undefined) {
      setNonSpecialityDivisionsData(
        formData?.data?.nonSpecialityDivisionsFields
      );
    }
  }, [formData?.data]);

  const [otherDivisionsData, setotherDivisionsData] = useState([]);
  const otherDivisionsChange = (index, e) => {
    let newFormValues = [...otherDivisionsData];
    newFormValues[index][e.target.name] = e.target.value;
    setotherDivisionsData(newFormValues);
  };
  let addOtherDivisionsData = () => {
    setotherDivisionsData([
      ...otherDivisionsData,
      { fundName: '', percentage: '' },
    ]);
  };
  let removeOtherDivisionsData = (i) => {
    let newFormValues = [...otherDivisionsData];
    newFormValues.splice(i, 1);
    setotherDivisionsData(newFormValues);
  };
  useEffect(() => {
    if (formData?.data?.otherDivisionsFields !== undefined) {
      setotherDivisionsData(formData?.data?.otherDivisionsFields);
    }
  }, [formData?.data]);

  const [specialityDivisionsData, setSpecialityDivisionsData] = useState([]);
  const specialityDivisionChange = (index, e) => {
    let newFormValues = [...specialityDivisionsData];
    newFormValues[index][e.target.name] = e.target.value;
    setSpecialityDivisionsData(newFormValues);
  };
  let addSpecialityDivisionsData = () => {
    setSpecialityDivisionsData([
      ...specialityDivisionsData,
      { fundName: '', percentage: '' },
    ]);
  };
  let removeSpecialityDivisionsData = (i) => {
    let newFormValues = [...specialityDivisionsData];
    newFormValues.splice(i, 1);
    setSpecialityDivisionsData(newFormValues);
  };
  useEffect(() => {
    if (formData?.data?.specialityDivisionsFields !== undefined) {
      setSpecialityDivisionsData(formData?.data?.specialityDivisionsFields);
    }
  }, [formData?.data]);

  const [designatedNonSpecialityValue, setDesignatedNonSpecialityValue] =
    useState(null);

  const designatedNonSpecialityChange = (e) => {
    setDesignatedNonSpecialityValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [agentValues, setAgentValues] = useState([]);
  const agentValueChange = (e) => {
    setAgentValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [annuityDateValue, setAnnuityDateValue] = useState(null);
  const annuityDateChange = (e) => {
    setAnnuityDateValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    nameOfAnnuitant: annuitantName,
    driverLicenseFields: driverLicenseValue,
    addressOfAnnuitant: annuitantAddressValue,
    annuitantBasicDetails: annuitantBasicValue,
    primaryBenEntityType: primaryBenEntityValue,
    primaryBeneficiaryInfo: primaryBenValues,
    contingentBenEntityType: contingentBenEntityValue,
    contingentBeneficiaryInfo: contingentBenValues,
    initialPaymentAmount: inititalPaymentValue,
    planType: planTypeValue,
    nonSpecialityDivisionsFields: nonSpecialityDivisionsData,
    otherDivisionsFields: otherDivisionsData,
    specialityDivisionsFields: specialityDivisionsData,
    designatedNonSpecialityField: designatedNonSpecialityValue,
    agentInformation: agentValues,
    annuityDateField: annuityDateValue,
  };

  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s23',
      },
    });
  }, [payload]);
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s23`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        nextPath="/form/policyownerannuity"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId={formData?.common_details?.section_id}
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
        productType={formData?.common_details?.product_type}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          <div className="flex line-separation">
            {formData?.data?.nameOfAnnuitant?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  onChangeHandler={nameChangeHandler}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  validationType={item.validationType}
                />
              );
            })}
          </div>

          <div className=" line-separation">
            <h3>{formData?.data?.driverLicenseLabel}</h3>
            <div className="flex">
              {formData?.data?.driverLicenseFields?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    value={item.value}
                    layoutType={item.layoutType}
                    name={item.name}
                    dropdownType={item.dropdownType}
                    selectOptions={item.selectOptions}
                    onChangeHandler={driverChangeHandler}
                  />
                );
              })}
            </div>
          </div>

          <div className="flex line-separation">
            <h3>{formData?.data?.annuitantAddressLabel}</h3>
            {formData?.data?.addressOfAnnuitant?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  dropdownType={item.dropdownType}
                  selectOptions={item.selectOptions}
                  onChangeHandler={addressChangeHandler}
                />
              );
            })}
          </div>

          <div className="flex line-separation">
            {formData?.data?.annuitantBasicDetails?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  onChangeHandler={annuitantValueChange}
                  selectOptions={item.selectOptions}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  validationType={item.validationType}
                />
              );
            })}
          </div>

          <div className=" line-separation">
            <h3>{formData?.data?.primaryBeneficiaryLabel}</h3>
            <FormField
              key={formData?.data?.primaryBenEntityType?.id}
              id={formData?.data?.primaryBenEntityType?.id}
              label={formData?.data?.primaryBenEntityType?.label}
              type={formData?.data?.primaryBenEntityType?.type}
              value={formData?.data?.primaryBenEntityType?.value}
              layoutType={formData?.data?.primaryBenEntityType?.layoutType}
              name={formData?.data?.primaryBenEntityType?.name}
              selectOptions={
                formData?.data?.primaryBenEntityType?.selectOptions
              }
              onChangeHandler={primaryBenEntityChange}
            />
            <div
              className={`flex ${
                primaryBenEntityValue['ppa-primaryBenEntityType'] === 'LLC' ||
                primaryBenEntityValue['ppa-primaryBenEntityType'] === 'Trust'
                  ? 'disable-fields'
                  : ''
              } `}
            >
              {formData?.data?.primaryBeneficiaryInfo?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    value={item.value}
                    layoutType={item.layoutType}
                    name={item.name}
                    onChangeHandler={primaryBenChange}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item.validationType}
                  />
                );
              })}
            </div>
          </div>

          <div className=" line-separation">
            <h3>{formData?.data?.contingentBeneficiaryLabel}</h3>
            <FormField
              key={formData?.data?.contingentBenEntityType?.id}
              id={formData?.data?.contingentBenEntityType?.id}
              label={formData?.data?.contingentBenEntityType?.label}
              type={formData?.data?.contingentBenEntityType?.type}
              value={formData?.data?.contingentBenEntityType?.value}
              layoutType={formData?.data?.contingentBenEntityType?.layoutType}
              name={formData?.data?.contingentBenEntityType?.name}
              selectOptions={
                formData?.data?.contingentBenEntityType?.selectOptions
              }
              onChangeHandler={contingentBenEntityChange}
            />
            <div
              className={`flex ${
                contingentBenEntityValue['ppa-contingentBenEntityType'] ===
                  'LLC' ||
                contingentBenEntityValue['ppa-contingentBenEntityType'] ===
                  'Trust'
                  ? 'disable-fields'
                  : ''
              } `}
            >
              {formData?.data?.contingentBeneficiaryInfo?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    value={item.value}
                    layoutType={item.layoutType}
                    name={item.name}
                    onChangeHandler={contingentBenChange}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    validationType={item.validationType}
                  />
                );
              })}
            </div>
          </div>
          <div className="line-separation initialPaymentAmount">
            <h3>{formData?.data?.initialPaymentAmtLabel}</h3>
            {formData?.data?.initialPaymentAmount?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  onChangeHandler={initialPaymentAmtChange}
                />
              );
            })}

            <LabelRadioOptions
              label={formData?.data?.planType?.label}
              options={formData?.data?.planType?.radioOptions}
              id={formData?.data?.planType?.id}
              onChangeHandler={planTypeChangeHandler}
            />
          </div>
          <div className="line-separation allocationOfPayments__wrapper">
            <h3>
              {formData?.data?.allocationOfPaymentsLabels?.allocationOfPayment}
            </h3>
            <h3 className="allocation__subHeading">
              {
                formData?.data?.allocationOfPaymentsLabels
                  ?.nonSpecialityDivision
              }
            </h3>
            <h3 className="allocation__type">
              {
                formData?.data?.allocationOfPaymentsLabels
                  ?.registeredFundDivision
              }
            </h3>
            <div>
              {nonSpecialityDivisionsData.length > 0 &&
                nonSpecialityDivisionsData.map((element, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex allocationOfPayments__fields">
                        <div
                          className={`label-value-wrapper layout-2 ${
                            !element.fundName ? 'value--empty' : ''
                          }`}
                        >
                          <label className="field-label">Fund Name</label>
                          <div>
                            <input
                              type="text"
                              name="fundName"
                              placeholder="Enter"
                              value={element.fundName || ''}
                              onChange={(e) =>
                                nonSpecialityDivisionsChange(index, e)
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={`label-value-wrapper layout-2 ${
                            !element.percentage ? 'value--empty' : ''
                          }`}
                        >
                          <label className="field-label">Percentage</label>
                          <div>
                            <input
                              type="text"
                              name="percentage"
                              placeholder="Enter"
                              value={element.percentage || ''}
                              onChange={(e) =>
                                nonSpecialityDivisionsChange(index, e)
                              }
                            />
                          </div>
                        </div>
                        {index ? (
                          <span
                            onClick={() =>
                              removeNonSpecialityDivisionsData(index)
                            }
                            className="icon-delete icon-center"
                          >
                            <img
                              src={deleteIcon}
                              alt="deleteIcon"
                              className="deleteIcon remarks-remove"
                            />
                          </span>
                        ) : null}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            {nonSpecialityDivisionsData.length > 0 && (
              <span
                className="addFieldButton align-center"
                onClick={() => addNonSpecialityDivisionsData()}
              >
                Add More
              </span>
            )}
            <h3 className="allocation__type">
              {formData?.data?.allocationOfPaymentsLabels?.otherDivisions}
            </h3>
            <div>
              {otherDivisionsData.length > 0 &&
                otherDivisionsData.map((element, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex allocationOfPayments__fields">
                        <div
                          className={`label-value-wrapper layout-2 ${
                            !element.fundName ? 'value--empty' : ''
                          }`}
                        >
                          <label className="field-label">Fund Name</label>
                          <div>
                            <input
                              type="text"
                              name="fundName"
                              placeholder="Enter"
                              value={element.fundName || ''}
                              onChange={(e) => otherDivisionsChange(index, e)}
                            />
                          </div>
                        </div>
                        <div
                          className={`label-value-wrapper layout-2 ${
                            !element.percentage ? 'value--empty' : ''
                          }`}
                        >
                          <label className="field-label">Percentage</label>
                          <div>
                            <input
                              type="text"
                              name="percentage"
                              placeholder="Enter"
                              value={element.percentage || ''}
                              onChange={(e) => otherDivisionsChange(index, e)}
                            />
                          </div>
                        </div>
                        {index ? (
                          <span
                            onClick={() => removeOtherDivisionsData(index)}
                            className="icon-delete icon-center"
                          >
                            <img
                              src={deleteIcon}
                              alt="deleteIcon"
                              className="deleteIcon remarks-remove"
                            />
                          </span>
                        ) : null}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            {otherDivisionsData.length > 0 && (
              <span
                className="addFieldButton align-center"
                onClick={() => addOtherDivisionsData()}
              >
                Add More
              </span>
            )}
            <h3 className="allocation__subHeading">
              {formData?.data?.allocationOfPaymentsLabels?.specialityDivisions}
            </h3>
            <div>
              {specialityDivisionsData.length > 0 &&
                specialityDivisionsData.map((element, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex allocationOfPayments__fields">
                        <div
                          className={`label-value-wrapper layout-2 ${
                            !element.fundName ? 'value--empty' : ''
                          }`}
                        >
                          <label className="field-label">Fund Name</label>
                          <div>
                            <input
                              type="text"
                              name="fundName"
                              placeholder="Enter"
                              value={element.fundName || ''}
                              onChange={(e) =>
                                specialityDivisionChange(index, e)
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={`label-value-wrapper layout-2 ${
                            !element.percentage ? 'value--empty' : ''
                          }`}
                        >
                          <label className="field-label">Percentage</label>
                          <div>
                            <input
                              type="text"
                              name="percentage"
                              placeholder="Enter"
                              value={element.percentage || ''}
                              onChange={(e) =>
                                specialityDivisionChange(index, e)
                              }
                            />
                          </div>
                        </div>
                        {index ? (
                          <span
                            onClick={() => removeSpecialityDivisionsData(index)}
                            className="icon-delete icon-center"
                          >
                            <img
                              src={deleteIcon}
                              alt="deleteIcon"
                              className="deleteIcon remarks-remove"
                            />
                          </span>
                        ) : null}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            {specialityDivisionsData.length > 0 && (
              <span
                className="addFieldButton align-center"
                onClick={() => addSpecialityDivisionsData()}
              >
                Add More
              </span>
            )}
            <div className="flex">
              {formData?.data?.designatedNonSpecialityField?.map(
                (item, index) => {
                  return (
                    <FormField
                      key={index}
                      id={item.id}
                      label={item.label}
                      type={item.type}
                      value={item.value}
                      layoutType={item.layoutType}
                      name={item.name}
                      onChangeHandler={designatedNonSpecialityChange}
                    />
                  );
                }
              )}
            </div>
          </div>

          <div className=" line-separation">
            <h3>{formData?.data?.agentInformationLabel}</h3>
            <div className="flex">
              {formData?.data?.agentInformation?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    value={item.value}
                    layoutType={item.layoutType}
                    name={item.name}
                    onChangeHandler={agentValueChange}
                    dropdownType={item.dropdownType}
                  />
                );
              })}
            </div>
          </div>
          <div className=" line-separation">
            <h3>{formData?.data?.annuityDateLabel}</h3>
            <div className="flex">
              {formData?.data?.annuityDateField?.map((item, index) => {
                return (
                  <FormField
                    key={index}
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    value={item.value}
                    layoutType={item.layoutType}
                    name={item.name}
                    onChangeHandler={annuityDateChange}
                  />
                );
              })}
            </div>
          </div>
        </FormFieldsWrapper>
      )}

      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default PPAApplication;
