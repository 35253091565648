import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
import radioCheckedImg from '../../../../assets/images/radio-checked.svg';
import AccordionSingleSection from '../../../ui/accordionSingleSection';

const FinancialFigureAndInfo = (props) => {
  const pageData = useSelector((state) => state.businessInsuranceData);
  const { loading, error, formData } = pageData;

  useEffect(() => {
    if (formData?.data?.sourceFinancialInfo) {
      const selected =
        formData?.data?.sourceFinancialInfo?.checkOptions?.filter(
          (element) => element.defaultChecked
        );
      const values = selected?.map((ele) => ele.value);
      props.setFinancialInfoCheck(values);
    }
  }, [formData?.data]);

  const handleChange = (e) => {
    if (e.target.checked) {
      props.setFinancialInfoCheck((prevState) => [
        ...prevState,
        e.target.value,
      ]);
    } else {
      let checkedValuesArray = [...props.financialInfoCheck];

      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      props.setFinancialInfoCheck(checkedValuesArray);
    }
  };

  const sourceFinancialInfo =
    formData?.data?.sourceFinancialInfo?.checkOptions.map((item, index) => {
      return (
        <div className="checkboxTabs" key={index}>
          <input
            className="checkboxField"
            type="checkbox"
            value={item.value}
            name={item.name}
            id={item.id}
            defaultChecked={item.defaultChecked}
            onChange={handleChange}
          />
          <label className="customCheckbox" htmlFor={item.id}>
            <img
              src={radioCheckedImg}
              alt="checked "
              className="customCheckbox__image"
            />
            <span className="customCheckbox__label">{item.value}</span>
          </label>
        </div>
      );
    });

  const onAccFieldsChange = (e) => {
    props.setAccFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const accountantFields = formData?.data?.accountantFields?.map(
    (item, index) => {
      return (
        <FormField
          type={item.type}
          name={item.name}
          value={item.value}
          id={item.id}
          label={item.label}
          key={index}
          layoutType={item.layoutType}
          onChangeHandler={onAccFieldsChange}
          dropdownType={item.dropdownType}
          regex={item?.regex}
          errorMessage={item?.errorMessage}
          validationType={item.validationType}
        ></FormField>
      );
    }
  );

  const onFinanFieldsChange = (e) => {
    props.setCompFinanFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const companyFinancialFields = formData?.data?.companyFinancialFields?.map(
    (item, index) => {
      return (
        <FormField
          type={item.type}
          name={item.name}
          value={item.value}
          id={item.id}
          label={item.label}
          key={index}
          layoutType={item.layoutType}
          onChangeHandler={onFinanFieldsChange}
          dropdownType={item.dropdownType}
          regex={item?.regex}
          errorMessage={item?.errorMessage}
          validationType={item.validationType}
        ></FormField>
      );
    }
  );
  const onSourceFinFieldsChange = (e) => {
    props.setSourceFinanFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const sourceFinancialInfoOther =
    formData?.data?.sourceFinancialInfoOther?.map((item, index) => {
      return (
        <FormField
          type={item.type}
          name={item.name}
          value={item.value}
          id={item.id}
          label={item.label}
          key={index}
          layoutType={item.layoutType}
          onChangeHandler={onSourceFinFieldsChange}
          dropdownType={item.dropdownType}
          regex={item?.regex}
          errorMessage={item?.errorMessage}
          validationType={item.validationType}
        ></FormField>
      );
    });

  return (
    <>
      {sourceFinancialInfo && (
        <div className="line-separation ">
          <h3>Source of Financial Figures and Information</h3>
          <div>{sourceFinancialInfo}</div>
        </div>
      )}
      {props.financialInfoCheck.includes('Accountant') && (
        <>
          {accountantFields && (
            <div className="line-separation ">
              <AccordionSingleSection accordionHeading="Accountant">
                <div className="flex">{accountantFields}</div>
              </AccordionSingleSection>
            </div>
          )}
        </>
      )}

      {props.financialInfoCheck.includes('Company Financial Statements') && (
        <>
          {companyFinancialFields && (
            <div className="line-separation ">
              <AccordionSingleSection accordionHeading="Company Financial Statements">
                <div className="flex">{companyFinancialFields}</div>
              </AccordionSingleSection>
            </div>
          )}
        </>
      )}

      {props.financialInfoCheck.includes('Other') && (
        <>
          {sourceFinancialInfoOther && (
            <div className="line-separation ">
              <AccordionSingleSection accordionHeading="Other">
                <div className="flex">{sourceFinancialInfoOther}</div>
              </AccordionSingleSection>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default FinancialFigureAndInfo;
