import axios from '../axios/axios';
import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  NAV_DATA_REQUEST,
  NAV_DATA_SUCCESS,
  NAV_DATA_FAIL,
} from '../constants/caseListingConstants';

export const listUsers =
  (
    searchValue,
    field,
    fromDate,
    toDate,
    productType,
    caseType,
    sortValue,
    rolesValue,
    userNames
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_LIST_REQUEST });
      const { data } = await axios.post('/case-listing', {
        field: field || 'search_str',
        value: searchValue || '',
        roles: rolesValue || [],
        users: userNames || [],
        fromDate: fromDate || '',
        toDate: toDate || '',
        productType: productType || 'insurance',
        caseType: caseType || 'myCase',
        sortType: sortValue || '',
        userEmail: localStorage.getItem('userEmail'),
      });

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (
        error?.response?.status === 401 ||
        error?.response?.detail === 'Not authenticated'
      ) {
        window.location.replace('/');
      }
      dispatch({
        type: USER_LIST_FAIL,
        payload: error,
      });
    }
  };

export const navData = (caseType) => async (dispatch) => {
  try {
    dispatch({ type: NAV_DATA_REQUEST });
    const { data } = await axios.post('/case-listing-sidebar', {
      userEmail: localStorage.getItem('userEmail'),
      caseType: caseType || 'myCase',
    });

    dispatch({
      type: NAV_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: NAV_DATA_FAIL,
      payload: error,
    });
  }
};
