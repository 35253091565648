// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noLicenseCheckbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.noLicenseLabel {
  padding-left: 6px;
  color: #7d7d7d;
  font-weight: 600;
  font-size: 14px;
}

.totalHourseOutsideUSWrapper {
  padding-top: 12px;
  padding-bottom: 10px;
}

.customWrapper {
  padding-top: 12px;
}

.divider {
  width: 100%;
  border: 1px solid #edf0f2;
  opacity: 1;
}
.last-renewal-container {
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/aviationQuestionnaire/AviationQuestionnaire.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".noLicenseCheckbox {\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.noLicenseLabel {\n  padding-left: 6px;\n  color: #7d7d7d;\n  font-weight: 600;\n  font-size: 14px;\n}\n\n.totalHourseOutsideUSWrapper {\n  padding-top: 12px;\n  padding-bottom: 10px;\n}\n\n.customWrapper {\n  padding-top: 12px;\n}\n\n.divider {\n  width: 100%;\n  border: 1px solid #edf0f2;\n  opacity: 1;\n}\n.last-renewal-container {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
