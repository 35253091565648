import './FormField.css';
import PropTypes from 'prop-types';
import { useState } from 'react';

const InputDate = (props) => {
  const [fieldValue, setFieldValue] = useState(props.fieldData.value);
  const handleChange = (e) => {
    props.fieldData.onChangeHandler(e);
    setFieldValue(e.target.value);
  };
  const addMoreDate = 'addMoreDate';
  let inputDate = <></>;
  switch (props.fieldData.validationType) {
    case addMoreDate:
      inputDate = (
        <input
          type={props.fieldData.type}
          id={props.fieldData.id}
          name={props.fieldData.name}
          value={props.fieldData.value}
          placeholder={props.fieldData.placeholder || 'MM-DD-YYYY'}
          min={props.fieldData.min}
          max={props.fieldData.max ? props.fieldData.max : '3000-12-31'}
          onChange={handleChange}
          disabled={props.fieldData.disabled}
        />
      );
      break;
    default:
      inputDate = (
        <input
          type={props.fieldData.type}
          id={props.fieldData.id}
          name={props.fieldData.name}
          defaultValue={props.fieldData.value}
          placeholder={props.fieldData.placeholder || 'MM-DD-YYYY'}
          min={props.fieldData.min}
          max={props.fieldData.max ? props.fieldData.max : '3000-12-31'}
          onChange={handleChange}
          disabled={props.fieldData.disabled}
        />
      );
  }

  return (
    <div
      className={`label-value-wrapper ${
        props.fieldData.layoutType ? props.fieldData.layoutType : ''
      } ${!fieldValue ? 'value--empty' : ''}`}
      key={props.fieldData.id}
    >
      {props.fieldData.label && (
        <label className="field-label" htmlFor={props.fieldData.id}>
          {props.fieldData.label}
        </label>
      )}

      <div>{inputDate}</div>
    </div>
  );
};
export default InputDate;

InputDate.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
