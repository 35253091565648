import axios from '../axios/axios';
import {
  FINANCIAL_INFO_DATA_REQUEST,
  FINANCIAL_INFO_DATA_SUCCESS,
  FINANCIAL_INFO_DATA_FAIL,
} from '../constants/financialInfoConstants';

export const financialInfoAction = () => async (dispatch) => {
  try {
    dispatch({ type: FINANCIAL_INFO_DATA_REQUEST });
    const { data } = await axios.post('/rpiq-form-fetch', {
      section: 'f1v1s19',
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: FINANCIAL_INFO_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: FINANCIAL_INFO_DATA_FAIL,
      payload: error,
    });
  }
};
