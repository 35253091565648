import FormField from '../../../ui/formField';
import { useSelector } from 'react-redux';
const BloodPressureSection = (props) => {
  const hlFormData = useSelector((state) => state.hlData);
  const { loading, error, formData } = hlFormData;

  const onFieldChangeHandler = (event) => {
    props.setBloodPressureValue((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <h3>{formData?.data?.bloodPressureLabel?.label}</h3>
      <div className="flex">
        {formData &&
          formData?.data?.bloodPressureField?.map((item, index) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onFieldChangeHandler}
                selectOptions={item.selectOptions}
              />
            );
          })}
      </div>
    </>
  );
};

export default BloodPressureSection;
