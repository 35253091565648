import { useEffect, useState } from 'react';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioWithFields from '../../ui/labelRadioWithFields';
import './FinancialInformation.css';
import { financialInfoAction } from '../../../actions/financialInfoAction';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../ui/toastMessage';
import axios from '../../../axios/axios';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import AccordionSingleSection from '../../ui/accordionSingleSection';
import AccordionFinancialInfo from '../../ui/accordionFinancialInfo';
import Loader from '../../loader';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const FinancialInformation = (props) => {
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const pageData = useSelector((state) => state.financialInfoData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();
  const [formConfig, setFormConfig] = useState(formData.data);
  const [incomeInfo, setIncomeInfo] = useState({});
  const [assetsInfo, setAssetsInfo] = useState({});
  const [liabilityInfo, setLiabilityInfo] = useState({});
  const [spouseInfo, setSpouseInfo] = useState({});
  const [initialFinancialInfoPlan, setInitialFinancialInfoPlan] = useState({});
  const [proposedInsuredPlanInfo, setProposedInsuredPlanInfo] = useState({});
  const [retirementPlanInfo, setRetirementPlanInfo] = useState({});
  const [businessFinanacesInfo, setBusinessFinancesInfo] = useState({});

  useEffect(() => {
    setFormConfig(formData.data);
  }, [formData.data]);

  const updateIncomeAssetsLiabilities = (id, field, value, fundType) => {
    if (field != 'remark' && field != 'otherText' && isNaN(value)) return;
    let newState = { ...formConfig };
    switch (field) {
      case 'personalCurrent':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].personalCurrent = value;
        break;
      case 'fullyExemptTrustCurrent':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].fullyExemptTrustCurrent = value;
        break;
      case 'nonFullyExemptTrustCurrent':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].nonFullyExemptTrustCurrent = value;
        break;
      case 'personalPrev':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].personalPrev = value;
        break;
      case 'fullyExemptTrustPrev':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].fullyExemptTrustPrev = value;
        break;
      case 'nonFullyExemptTrustPrev':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].nonFullyExemptTrustPrev = value;
        break;
      case 'remark':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].remark = value;
        break;
      case 'otherText':
        newState[fundType].formRowData[
          newState[fundType].formRowData.findIndex((obj) => obj.id == id)
        ].otherText = value;
        break;
      default:
      // do nothing
    }
    let sumPersonalCurrent = 0;
    let sumFullyExemptTrustCurrent = 0;
    let sumNonFullyExemptTrustCurrent = 0;
    let sumTotalCurrent = 0;
    let sumPersonalPrev = 0;
    let sumFullyExemptTrustPrev = 0;
    let sumNonFullyExemptTrustPrev = 0;
    let sumTotalPrev = 0;
    newState[fundType].formRowData.forEach((item) => {
      item.totalCurrent =
        parseFloat(item.personalCurrent ? item.personalCurrent : 0) +
        parseFloat(
          item.fullyExemptTrustCurrent ? item.fullyExemptTrustCurrent : 0
        ) +
        parseFloat(
          item.nonFullyExemptTrustCurrent ? item.nonFullyExemptTrustCurrent : 0
        );

      item.totalPrev =
        parseFloat(item.personalPrev ? item.personalPrev : 0) +
        parseFloat(item.fullyExemptTrustPrev ? item.fullyExemptTrustPrev : 0) +
        parseFloat(
          item.nonFullyExemptTrustPrev ? item.nonFullyExemptTrustPrev : 0
        );
    });

    newState[fundType].formRowData.map((item) => {
      sumPersonalCurrent += parseFloat(
        item.personalCurrent ? item.personalCurrent : 0
      );
      sumFullyExemptTrustCurrent += parseFloat(
        item.fullyExemptTrustCurrent ? item.fullyExemptTrustCurrent : 0
      );
      sumNonFullyExemptTrustCurrent += parseFloat(
        item.nonFullyExemptTrustCurrent ? item.nonFullyExemptTrustCurrent : 0
      );
      sumTotalCurrent += parseFloat(item.totalCurrent ? item.totalCurrent : 0);
      sumPersonalPrev += parseFloat(item.personalPrev ? item.personalPrev : 0);
      sumFullyExemptTrustPrev += parseFloat(
        item.fullyExemptTrustPrev ? item.fullyExemptTrustPrev : 0
      );
      sumNonFullyExemptTrustPrev += parseFloat(
        item.nonFullyExemptTrustPrev ? item.nonFullyExemptTrustPrev : 0
      );
      sumTotalPrev += parseFloat(item.totalPrev ? item.totalPrev : 0);
    });
    newState[fundType].total.personalCurrent = sumPersonalCurrent;
    newState[fundType].total.fullyExemptTrustCurrent =
      sumFullyExemptTrustCurrent;
    newState[fundType].total.nonFullyExemptTrustCurrent =
      sumNonFullyExemptTrustCurrent;
    newState[fundType].total.totalCurrent = sumTotalCurrent;
    newState[fundType].total.personalPrev = sumPersonalPrev;
    newState[fundType].total.fullyExemptTrustPrev = sumFullyExemptTrustPrev;
    newState[fundType].total.nonFullyExemptTrustPrev =
      sumNonFullyExemptTrustPrev;
    newState[fundType].total.totalPrev = sumTotalPrev;
    newState.totalNet.currYear =
      newState.assets.total.totalCurrent -
      newState.liabilities.total.totalCurrent;
    newState.totalNet.prevYear =
      newState.assets.total.totalPrev - newState.liabilities.total.totalPrev;

    setFormConfig(newState);

    setIncomeInfo(newState.income);

    setAssetsInfo(newState.assets);

    setLiabilityInfo(newState.liabilities);
  };
  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };
  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };
  const updateInitialFinancialInfoIncome = (id, field, value) => {
    let newState = { ...formConfig };
    if (field != 'remark' && isNaN(value)) return;
    switch (field) {
      case 'current':
        newState.initialFinancialInfoIncome.formRowData[
          newState.initialFinancialInfoIncome.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].valueCurr = value;
        break;
      case 'previous':
        newState.initialFinancialInfoIncome.formRowData[
          newState.initialFinancialInfoIncome.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].valuePrev = value;
        break;
      case 'remark':
        newState.initialFinancialInfoIncome.formRowData[
          newState.initialFinancialInfoIncome.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].remark = value;
        break;

      default:
      // do nothing
    }
    let sumCurrent = 0;
    let sumPrevious = 0;

    let assetsCurr =
      newState.initialFinancialInfoIncome.formRowData[1].valueCurr;
    let assetsPrev =
      newState.initialFinancialInfoIncome.formRowData[1].valuePrev;
    let liabilitiesCurr =
      newState.initialFinancialInfoIncome.formRowData[2].valueCurr;
    let liabilitiesPrev =
      newState.initialFinancialInfoIncome.formRowData[2].valuePrev;
    sumCurrent = assetsCurr - liabilitiesCurr;
    sumPrevious = assetsPrev - liabilitiesPrev;
    newState.initialFinancialInfoIncome.totalCurrent = sumCurrent;
    newState.initialFinancialInfoIncome.totalPrev = sumPrevious;
    setFormConfig(newState);
    setInitialFinancialInfoPlan(newState.initialFinancialInfoIncome);
  };
  const updateProposedInsuredPlans = (id, field, value) => {
    let newState = { ...formConfig };
    if (field != 'remark' && isNaN(value)) return;
    switch (field) {
      case 'current':
        newState.incomeProposedInsured.formRowData[
          newState.incomeProposedInsured.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].valueCurr = value;
        break;
      case 'previous':
        newState.incomeProposedInsured.formRowData[
          newState.incomeProposedInsured.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].valuePrev = value;
        break;
      case 'remark':
        newState.incomeProposedInsured.formRowData[
          newState.incomeProposedInsured.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].remark = value;
        break;

      default:
      // do nothing
    }
    let sumCurrent = 0;
    let sumPrevious = 0;
    newState.incomeProposedInsured.formRowData.map((item) => {
      sumCurrent += parseFloat(item.valueCurr) || 0;
      sumPrevious += parseFloat(item.valuePrev) || 0;
    });
    newState.incomeProposedInsured.totalCurrent = sumCurrent;
    newState.incomeProposedInsured.totalPrev = sumPrevious;
    setFormConfig(newState);
    setProposedInsuredPlanInfo(newState.incomeProposedInsured);
  };
  const updateRetirementPlans = (id, field, value) => {
    let newState = { ...formConfig };
    if (field != 'remark' && isNaN(value)) return;
    switch (field) {
      case 'current':
        newState.retirementPlans.formRowData[
          newState.retirementPlans.formRowData.findIndex((obj) => obj.id == id)
        ].valueCurr = value;
        break;
      case 'previous':
        newState.retirementPlans.formRowData[
          newState.retirementPlans.formRowData.findIndex((obj) => obj.id == id)
        ].valuePrev = value;
        break;
      case 'remark':
        newState.retirementPlans.formRowData[
          newState.retirementPlans.formRowData.findIndex((obj) => obj.id == id)
        ].remark = value;
        break;

      default:
      // do nothing
    }
    let sumCurrent = 0;
    let sumPrevious = 0;
    newState.retirementPlans.formRowData.map((item) => {
      sumCurrent += parseFloat(item.valueCurr) || 0;
      sumPrevious += parseFloat(item.valuePrev) || 0;
    });
    newState.retirementPlans.totalCurrent = sumCurrent;
    newState.retirementPlans.totalPrev = sumPrevious;
    setFormConfig(newState);
    setRetirementPlanInfo(newState.retirementPlans);
  };
  const updateBusinessFinances = (id, field, value) => {
    let newState = { ...formConfig };
    if (field != 'remark' && isNaN(value)) return;
    switch (field) {
      case 'current':
        newState.businessFinances.formRowData[
          newState.businessFinances.formRowData.findIndex((obj) => obj.id == id)
        ].valueCurr = value;
        break;
      case 'previous':
        newState.businessFinances.formRowData[
          newState.businessFinances.formRowData.findIndex((obj) => obj.id == id)
        ].valuePrev = value;
        break;
      case 'remark':
        newState.businessFinances.formRowData[
          newState.businessFinances.formRowData.findIndex((obj) => obj.id == id)
        ].remark = value;
        break;

      default:
      // do nothing
    }
    let sumCurrent = 0;
    let sumPrevious = 0;
    newState.businessFinances.formRowData.map((item) => {
      sumCurrent += parseFloat(item.valueCurr) || 0;
      sumPrevious += parseFloat(item.valuePrev) || 0;
    });
    newState.businessFinances.totalCurrent = sumCurrent;
    newState.businessFinances.totalPrev = sumPrevious;
    setFormConfig(newState);
    setBusinessFinancesInfo(newState.businessFinances);
  };
  const updateSpouseInformation = (id, field, value) => {
    let newState = { ...formConfig };
    if (field != 'remark' && field != 'sourceOfUnearnedIncome' && isNaN(value))
      return;
    switch (field) {
      case 'current':
        newState.spouseInformation.formRowData[
          newState.spouseInformation.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].valueCurr = value;
        break;
      case 'previous':
        newState.spouseInformation.formRowData[
          newState.spouseInformation.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].valuePrev = value;
        break;
      case 'remark':
        newState.spouseInformation.formRowData[
          newState.spouseInformation.formRowData.findIndex(
            (obj) => obj.id == id
          )
        ].remark = value;
        break;
      case 'sourceOfUnearnedIncome':
        newState.spouseInformation.sourceOfUnearnedIncome = value;

      default:
      // do nothing
    }
    let sumCurrent = 0;
    let sumPrevious = 0;
    newState.spouseInformation.formRowData.map((item) => {
      sumCurrent += parseFloat(item.valueCurr) || 0;
      sumPrevious += parseFloat(item.valuePrev) || 0;
    });

    newState.spouseInformation.totalCurrent = sumCurrent;
    newState.spouseInformation.totalPrev = sumPrevious;
    setFormConfig(newState);
    setSpouseInfo(newState.spouseInformation);
  };

  const [additionalAssestRadio, setAdditionalAssestRadio] = useState({});
  const [additionalAssestFields, setAdditionalAssestFields] = useState({});
  const onAddAssestDetailsChangeHandler = (e) => {
    setAdditionalAssestFields(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };

  useEffect(() => {
    dispatch(financialInfoAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    initialFinancialInfoIncome: initialFinancialInfoPlan,
    totalNet: {
      currYear: formConfig?.totalNet?.currYear,
      prevYear: formConfig?.totalNet?.prevYear,
    },
    income: incomeInfo,
    incomeProposedInsured: proposedInsuredPlanInfo,
    assets: assetsInfo,
    liabilities: liabilityInfo,
    retirementPlans: retirementPlanInfo,
    businessFinances: businessFinanacesInfo,
    spouseInformation: spouseInfo,
    additionalAssestInfo: {
      radioOptions: additionalAssestRadio,
      provideDetails: additionalAssestFields,
    },
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s19',
      },
    });
  }, [payload]);

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s19`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/professionaladvisors"
        nextPath="/form/businessinsurance"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s19"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper financialInfoPage={true}>
          <AccordionSingleSection accordionHeading="Initial Financial Information">
            <section className="financiaInfo_section">
              <div className="financialInfo__accordionHeadingWrapper amountHeader financialInfo__initialFinanInfo">
                <h3 className="financialInfo__accordionHeading">
                  Income of Proposed Insured
                </h3>
                <div className="financialInfo__initialFinanplansInfo">
                  <div className="financialInfo__netWorthField">
                    <p className="financialInfo__netWorthLabel">
                      Total Net Worth{' '}
                    </p>
                    <p className="financialInfo__netWorthCalc">
                      (Assets - Liabilities)
                    </p>
                  </div>
                  <div className="financialInfo__plansInfo">
                    <div className="financialInfo__plansInfoTotal">
                      <p className="financialInfo__plansInfoTotalLabel ">
                        Current year
                      </p>
                      <p className="financialInfo__plansInfoTotalValue">
                        {' '}
                        ${' '}
                        {formConfig?.initialFinancialInfoIncome?.totalCurrent.toLocaleString()}
                      </p>
                    </div>
                    <div className="financialInfo__plansInfoTotal">
                      <p className="financialInfo__plansInfoTotalLabel ">
                        Previous year
                      </p>
                      <p className="financialInfo__plansInfoTotalValue">
                        ${' '}
                        {formConfig?.initialFinancialInfoIncome?.totalPrev.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="financialInfo__accordionContent financialInfo__accordionContent--open ">
                <div className="accordionContent__tableAmountContent ">
                  <table className="tableAmount">
                    <tbody>
                      {formConfig?.initialFinancialInfoIncome?.formRowData?.map(
                        (row, index) => {
                          return (
                            <tr key={row.id}>
                              <td className="tableAmount__label">
                                <span className="row__label">{row.label}</span>
                              </td>
                              <td className="tableAmount__currYear">
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.valueCurr ? 'value--empty' : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(row.valueCurr)}
                                    onChange={(e) => {
                                      updateInitialFinancialInfoIncome(
                                        row.id,
                                        'current',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        )
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td className="tableAmount__prevYear">
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.valuePrev ? 'value--empty' : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(row.valuePrev)}
                                    onChange={(e) => {
                                      updateInitialFinancialInfoIncome(
                                        row.id,
                                        'previous',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        )
                                      );
                                    }}
                                  />{' '}
                                </span>
                              </td>

                              <td className="tableAmount__remark">
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.remark ? 'value--empty' : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={row.remark}
                                    placeholder="Remarks"
                                    onChange={(e) => {
                                      updateInitialFinancialInfoIncome(
                                        row.id,
                                        'remark',
                                        e.target.value
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </AccordionSingleSection>

          <AccordionSingleSection accordionHeading="Detailed Financial Information">
            <div className="financialInfo__netWorth">
              <div className="financialInfo__netWorthField">
                <p className="financialInfo__netWorthLabel">Total Net Worth </p>
                <p className="financialInfo__netWorthCalc">
                  (Assets - Liabilities)
                </p>
              </div>

              <div className="financialInfo__netWorthField">
                <p className="financialInfo__netWorthYear"> Current year </p>
                <p className="financialInfo__netWorthValue">
                  $ {formConfig?.totalNet?.currYear.toLocaleString()}
                </p>
              </div>
              <div className="financialInfo__netWorthField">
                <p className="financialInfo__netWorthYear"> Previous year </p>
                <p className="financialInfo__netWorthValue">
                  $ {formConfig?.totalNet?.prevYear.toLocaleString()}
                </p>
              </div>
            </div>

            <AccordionFinancialInfo
              accordionHeading=" Income of Proposed Insured"
              showTotalAmount={true}
              currYearTotal={formConfig?.incomeProposedInsured?.totalCurrent.toLocaleString()}
              prevYearTotal={formConfig?.incomeProposedInsured?.totalPrev.toLocaleString()}
            >
              <div className="accordionContent__tableAmountContent ">
                <table className="tableAmount">
                  <tbody>
                    {formConfig?.incomeProposedInsured?.formRowData?.map(
                      (row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className="tableAmount__label">
                              <span className="row__label">{row.label}</span>
                            </td>
                            <td className="tableAmount__currYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valueCurr ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valueCurr)}
                                  onChange={(e) => {
                                    updateProposedInsuredPlans(
                                      row.id,
                                      'current',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td className="tableAmount__prevYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valuePrev ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valuePrev)}
                                  onChange={(e) => {
                                    updateProposedInsuredPlans(
                                      row.id,
                                      'previous',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />{' '}
                              </span>
                            </td>

                            <td className="tableAmount__remark">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.remark ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={row.remark}
                                  placeholder="Remarks"
                                  onChange={(e) => {
                                    updateProposedInsuredPlans(
                                      row.id,
                                      'remark',
                                      e.target.value
                                    );
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </AccordionFinancialInfo>

            <AccordionFinancialInfo
              accordionHeading="Spouse Information"
              showTotalAmount={true}
              currYearTotal={formConfig?.spouseInformation?.totalCurrent.toLocaleString()}
              prevYearTotal={formConfig?.spouseInformation?.totalPrev.toLocaleString()}
            >
              <div className="accordionContent__tableAmountContent ">
                <table className="tableAmount">
                  <tbody>
                    {formConfig?.spouseInformation?.formRowData?.map(
                      (row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className="tableAmount__label">
                              <span className="row__label">{row.label}</span>
                            </td>
                            <td className="tableAmount__currYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valueCurr ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valueCurr)}
                                  onChange={(e) => {
                                    updateSpouseInformation(
                                      row.id,
                                      'current',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td className="tableAmount__prevYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valuePrev ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valuePrev)}
                                  onChange={(e) => {
                                    updateSpouseInformation(
                                      row.id,
                                      'previous',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />
                              </span>
                            </td>

                            <td className="tableAmount__remark">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.remark ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={row.remark}
                                  placeholder="Remarks"
                                  onChange={(e) => {
                                    updateSpouseInformation(
                                      row.id,
                                      'remark',
                                      e.target.value
                                    );
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <div className="spouse__incomeSource">
                  <div className="tableAmount__label">
                    <span className="row__label">
                      Source of unearned Income
                    </span>
                  </div>
                  <div className="tableAmount__remark">
                    <span
                      className={`label-value-wrapper  ${
                        !formConfig?.spouseInformation?.sourceOfUnearnedIncome
                          ? 'value--empty'
                          : ''
                      }`}
                    >
                      <input
                        type="text"
                        placeholder="Remarks"
                        defaultValue={
                          formConfig?.spouseInformation?.sourceOfUnearnedIncome
                        }
                        onChange={(e) => {
                          updateSpouseInformation(
                            null,
                            'sourceOfUnearnedIncome',
                            e.target.value
                          );
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </AccordionFinancialInfo>

            <AccordionFinancialInfo
              accordionHeading="Assets"
              showTotalAmount={true}
              currYearTotal={formConfig?.assets?.total?.totalCurrent.toLocaleString()}
              prevYearTotal={formConfig?.assets?.total?.totalPrev.toLocaleString()}
            >
              <div className="financialInfo__tableWrapper financialInfo__tableFixedColWrapper">
                <div className="financialInfo__table">
                  <table>
                    <tbody>
                      <tr>
                        <th className="financialInfo__tableCol">
                          <span className="financialInfo__tableColHeading">
                            {' '}
                            Assets
                          </span>
                        </th>
                        <th colSpan="4">
                          <span className="label-value-wrapper financialInfo__tableColHeadTop">
                            Current year
                          </span>
                          <div>
                            <span className="label-value-wrapper ">
                              Personal
                            </span>
                            <span className="label-value-wrapper">
                              Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">
                              Non-Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">Total</span>
                          </div>
                        </th>
                        <th colSpan="4">
                          <span className="label-value-wrapper financialInfo__tableColHeadTop">
                            Previous year
                          </span>
                          <div>
                            <span className="label-value-wrapper">
                              Personal
                            </span>
                            <span className="label-value-wrapper">
                              Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">
                              Non-Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">Total</span>
                          </div>
                        </th>
                        <th className="financialInfo__remarkHeader">
                          <span className="label-value-wrapper">Remarks</span>
                        </th>
                      </tr>

                      {formConfig?.assets?.formRowData?.map((row, index) => {
                        return (
                          <tr
                            key={row.id}
                            className={
                              row.showOtherTextbox && 'textbox__describeOther'
                            }
                          >
                            <td className="financialInfo__tableCol">
                              <span className="financialInfo__tableColLabel">
                                {row.label}
                                {row.showOtherTextbox && (
                                  <>
                                    <br />
                                    <span
                                      className={`label-value-wrapper  ${
                                        !row.otherText ? 'value--empty' : ''
                                      }`}
                                    >
                                      <input
                                        type="text"
                                        defaultValue={row.otherText}
                                        name="otherLiotherTextabilitiesText"
                                        placeholder="Enter"
                                        onChange={(e) => {
                                          updateIncomeAssetsLiabilities(
                                            row.id,
                                            'otherText',
                                            e.target.value,
                                            'assets'
                                          );
                                        }}
                                      />
                                    </span>
                                  </>
                                )}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label-value-wrapper  ${
                                  !row.personalCurrent ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.personalCurrent)}
                                  onChange={(e) => {
                                    updateIncomeAssetsLiabilities(
                                      row.id,
                                      'personalCurrent',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      ),
                                      'assets'
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label-value-wrapper  ${
                                  !row.fullyExemptTrustCurrent
                                    ? 'value--empty'
                                    : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(
                                    row.fullyExemptTrustCurrent
                                  )}
                                  onChange={(e) => {
                                    updateIncomeAssetsLiabilities(
                                      row.id,
                                      'fullyExemptTrustCurrent',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      ),
                                      'assets'
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label-value-wrapper  ${
                                  !row.nonFullyExemptTrustCurrent
                                    ? 'value--empty'
                                    : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(
                                    row.nonFullyExemptTrustCurrent
                                  )}
                                  onChange={(e) => {
                                    updateIncomeAssetsLiabilities(
                                      row.id,
                                      'nonFullyExemptTrustCurrent',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      ),
                                      'assets'
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td>
                              <span className="label-value-wrapper">
                                <input
                                  type="text"
                                  value={row.totalCurrent.toLocaleString()}
                                  name="totalCurrent"
                                  disabled
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label-value-wrapper  ${
                                  !row.personalPrev ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.personalPrev)}
                                  onChange={(e) => {
                                    updateIncomeAssetsLiabilities(
                                      row.id,
                                      'personalPrev',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      ),
                                      'assets'
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label-value-wrapper  ${
                                  !row.fullyExemptTrustPrev
                                    ? 'value--empty'
                                    : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(
                                    row.fullyExemptTrustPrev
                                  )}
                                  onChange={(e) => {
                                    updateIncomeAssetsLiabilities(
                                      row.id,
                                      'fullyExemptTrustPrev',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      ),
                                      'assets'
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label-value-wrapper  ${
                                  !row.nonFullyExemptTrustPrev
                                    ? 'value--empty'
                                    : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(
                                    row.nonFullyExemptTrustPrev
                                  )}
                                  onChange={(e) => {
                                    updateIncomeAssetsLiabilities(
                                      row.id,
                                      'nonFullyExemptTrustPrev',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      ),
                                      'assets'
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td>
                              <span className="label-value-wrapper">
                                <input
                                  type="text"
                                  value={row.totalPrev.toLocaleString()}
                                  name="totalPrev"
                                  disabled
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label-value-wrapper financialInfo_remarkWrapper ${
                                  !row.nonFullyExemptTrustPrev
                                    ? 'value--empty'
                                    : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  placeholder="Remarks"
                                  value={row.remark}
                                  onChange={(e) => {
                                    updateIncomeAssetsLiabilities(
                                      row.id,
                                      'remark',
                                      e.target.value,
                                      'assets'
                                    );
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="financialInfo__tableCol">
                          <span className="financialInfo__tableColLabel">
                            {' '}
                            Total Assets
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.personalCurrent.toLocaleString()}
                              name="assetsTotalPersonalCurrent"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.fullyExemptTrustCurrent.toLocaleString()}
                              name="assetsTotalFullyExemptTrustCurrent"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.nonFullyExemptTrustCurrent.toLocaleString()}
                              name="assetsTotalNonFullyExemptTrustCurrent"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.totalCurrent.toLocaleString()}
                              name="assetsTotalCurrent"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.personalPrev.toLocaleString()}
                              name="assetsTotalPersonalPrev"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.fullyExemptTrustPrev.toLocaleString()}
                              name="assetsTotalFullyExemptTrustPrev"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.nonFullyExemptTrustPrev.toLocaleString()}
                              name="assetsTotalNonFullyExemptTrustPrev"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.assets?.total?.totalPrev.toLocaleString()}
                              name="assetsTotalPrev"
                              disabled
                            />
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </AccordionFinancialInfo>

            <AccordionFinancialInfo
              accordionHeading="Liabilities"
              showTotalAmount={true}
              currYearTotal={formConfig?.liabilities?.total?.totalCurrent.toLocaleString()}
              prevYearTotal={formConfig?.liabilities?.total?.totalPrev.toLocaleString()}
            >
              <div className="financialInfo__tableWrapper financialInfo__tableFixedColWrapper">
                <div className="financialInfo__table">
                  <table>
                    <tbody>
                      <tr>
                        <th className="financialInfo__tableCol">
                          <span className="financialInfo__tableColHeading">
                            Liabilities
                          </span>
                        </th>
                        <th colSpan="4">
                          <span className="label-value-wrapper financialInfo__tableColHeadTop">
                            Current year
                          </span>
                          <div>
                            <span className="label-value-wrapper ">
                              Personal
                            </span>
                            <span className="label-value-wrapper">
                              Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">
                              Non-Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">Total</span>
                          </div>
                        </th>
                        <th colSpan="4">
                          <span className="label-value-wrapper financialInfo__tableColHeadTop">
                            Previous year
                          </span>
                          <div>
                            <span className="label-value-wrapper">
                              Personal
                            </span>
                            <span className="label-value-wrapper">
                              Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">
                              Non-Fully Exempt Trust
                            </span>
                            <span className="label-value-wrapper">Total</span>
                          </div>
                        </th>
                        <th className="financialInfo__remarkHeader">
                          <span className="label-value-wrapper">Remarks</span>
                        </th>
                      </tr>

                      {formConfig?.liabilities?.formRowData?.map(
                        (row, index) => {
                          return (
                            <tr
                              key={row.id}
                              className={
                                row.showOtherTextbox && 'textbox__describeOther'
                              }
                            >
                              <td className="financialInfo__tableCol ">
                                <span className="financialInfo__tableColLabel ">
                                  {row.label}
                                  {row.showOtherTextbox && (
                                    <>
                                      <br />
                                      <span
                                        className={`label-value-wrapper  ${
                                          !row.otherText ? 'value--empty' : ''
                                        }`}
                                      >
                                        <input
                                          type="text"
                                          defaultValue={row.otherText}
                                          name="otherLiotherTextabilitiesText"
                                          placeholder="Enter"
                                          onChange={(e) => {
                                            updateIncomeAssetsLiabilities(
                                              row.id,
                                              'otherText',
                                              e.target.value,
                                              'liabilities'
                                            );
                                          }}
                                        />
                                      </span>
                                    </>
                                  )}
                                </span>
                              </td>

                              <td>
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.personalCurrent ? 'value--empty' : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(
                                      row.personalCurrent
                                    )}
                                    onChange={(e) => {
                                      updateIncomeAssetsLiabilities(
                                        row.id,
                                        'personalCurrent',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        ),
                                        'liabilities'
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.fullyExemptTrustCurrent
                                      ? 'value--empty'
                                      : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(
                                      row.fullyExemptTrustCurrent
                                    )}
                                    onChange={(e) => {
                                      updateIncomeAssetsLiabilities(
                                        row.id,
                                        'fullyExemptTrustCurrent',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        ),
                                        'liabilities'
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.nonFullyExemptTrustCurrent
                                      ? 'value--empty'
                                      : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(
                                      row.nonFullyExemptTrustCurrent
                                    )}
                                    onChange={(e) => {
                                      updateIncomeAssetsLiabilities(
                                        row.id,
                                        'nonFullyExemptTrustCurrent',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        ),
                                        'liabilities'
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td>
                                <span className="label-value-wrapper">
                                  <input
                                    type="text"
                                    value={row.totalCurrent.toLocaleString()}
                                    disabled
                                  />
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.personalPrev ? 'value--empty' : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(row.personalPrev)}
                                    onChange={(e) => {
                                      updateIncomeAssetsLiabilities(
                                        row.id,
                                        'personalPrev',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        ),
                                        'liabilities'
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.fullyExemptTrustPrev
                                      ? 'value--empty'
                                      : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(
                                      row.fullyExemptTrustPrev
                                    )}
                                    onChange={(e) => {
                                      updateIncomeAssetsLiabilities(
                                        row.id,
                                        'fullyExemptTrustPrev',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        ),
                                        'liabilities'
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`label-value-wrapper  ${
                                    !row.nonFullyExemptTrustPrev
                                      ? 'value--empty'
                                      : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    value={addCommaSeparator(
                                      row.nonFullyExemptTrustPrev
                                    )}
                                    onChange={(e) => {
                                      updateIncomeAssetsLiabilities(
                                        row.id,
                                        'nonFullyExemptTrustPrev',
                                        parseFloat(
                                          e.target.value
                                            ? removeCommaSeparator(
                                                e.target.value
                                              )
                                            : 0
                                        ),
                                        'liabilities'
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                              <td>
                                <span className="label-value-wrapper">
                                  <input
                                    type="text"
                                    value={row.totalPrev.toLocaleString()}
                                    disabled
                                  />
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`label-value-wrapper financialInfo_remarkWrapper ${
                                    !row.remark ? 'value--empty' : ''
                                  }`}
                                >
                                  <input
                                    type="text"
                                    placeholder="Remarks"
                                    value={row.remark}
                                    onChange={(e) => {
                                      updateIncomeAssetsLiabilities(
                                        row.id,
                                        'remark',
                                        e.target.value,
                                        'liabilities'
                                      );
                                    }}
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="financialInfo__tableCol">
                          <span className="financialInfo__tableColLabel">
                            Total Liabilities
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.personalCurrent.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.fullyExemptTrustCurrent.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.nonFullyExemptTrustCurrent.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.totalCurrent.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.personalPrev.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.fullyExemptTrustPrev.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.nonFullyExemptTrustPrev.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="label-value-wrapper">
                            <input
                              type="text"
                              value={formConfig?.liabilities?.total?.totalPrev.toLocaleString()}
                              disabled
                            />
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </AccordionFinancialInfo>

            <AccordionFinancialInfo
              accordionHeading="Retirement Plans"
              showTotalAmount={true}
              currYearTotal={formConfig?.retirementPlans?.totalCurrent.toLocaleString()}
              prevYearTotal={formConfig?.retirementPlans?.totalPrev.toLocaleString()}
            >
              <div className="accordionContent__tableAmountContent ">
                <table className="tableAmount">
                  <tbody>
                    {formConfig?.retirementPlans?.formRowData?.map(
                      (row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className="tableAmount__label">
                              <span className="row__label">{row.label}</span>
                            </td>
                            <td className="tableAmount__currYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valueCurr ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valueCurr)}
                                  onChange={(e) => {
                                    updateRetirementPlans(
                                      row.id,
                                      'current',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td className="tableAmount__prevYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valuePrev ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valuePrev)}
                                  onChange={(e) => {
                                    updateRetirementPlans(
                                      row.id,
                                      'previous',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />{' '}
                              </span>
                            </td>

                            <td className="tableAmount__remark">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.remark ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={row.remark}
                                  placeholder="Remarks"
                                  onChange={(e) => {
                                    updateRetirementPlans(
                                      row.id,
                                      'remark',
                                      e.target.value
                                    );
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </AccordionFinancialInfo>

            <AccordionFinancialInfo
              accordionHeading="Business Finances"
              showTotalAmount={true}
              currYearTotal={formConfig?.businessFinances?.totalCurrent.toLocaleString()}
              prevYearTotal={formConfig?.businessFinances?.totalPrev.toLocaleString()}
            >
              <div className="accordionContent__tableAmountContent ">
                <table className="tableAmount">
                  <tbody>
                    {formConfig?.businessFinances?.formRowData?.map(
                      (row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className="tableAmount__label">
                              <span className="row__label">{row.label}</span>
                            </td>
                            <td className="tableAmount__currYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valueCurr ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valueCurr)}
                                  onChange={(e) => {
                                    updateBusinessFinances(
                                      row.id,
                                      'current',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />
                              </span>
                            </td>
                            <td className="tableAmount__prevYear">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.valuePrev ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={addCommaSeparator(row.valuePrev)}
                                  onChange={(e) => {
                                    updateBusinessFinances(
                                      row.id,
                                      'previous',
                                      parseFloat(
                                        e.target.value
                                          ? removeCommaSeparator(e.target.value)
                                          : 0
                                      )
                                    );
                                  }}
                                />{' '}
                              </span>
                            </td>

                            <td className="tableAmount__remark">
                              <span
                                className={`label-value-wrapper  ${
                                  !row.remark ? 'value--empty' : ''
                                }`}
                              >
                                <input
                                  type="text"
                                  value={row.remark}
                                  placeholder="Remarks"
                                  onChange={(e) => {
                                    updateBusinessFinances(
                                      row.id,
                                      'remark',
                                      e.target.value
                                    );
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </AccordionFinancialInfo>

            <section className="financiaInfo_section financiaInfo_questionsection">
              <LabelRadioWithFields
                key={formConfig?.additionalAssestInfo?.id}
                id={formConfig?.additionalAssestInfo?.id}
                label={formConfig?.additionalAssestInfo?.label}
                options={formConfig?.additionalAssestInfo?.radioOptions}
                provideDetails={
                  formConfig?.additionalAssestInfo?.provideDetails
                }
                setRadioChangeValue={setAdditionalAssestRadio}
                onDetailsChangeHandler={onAddAssestDetailsChangeHandler}
              />
            </section>
          </AccordionSingleSection>
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default FinancialInformation;
