import { useState } from 'react';
import { useSelector } from 'react-redux';
import Checkboxes from '../../../ui/formField/Checkboxes';

const ExerciseCheckBoxes = (props) => {
  const exerciseFieldData = useSelector((state) => state.exerciseData);
  const { loading, error, exData } = exerciseFieldData;
  const [exerciseOtherValue, setExerciseOtherValue] = useState(
    exData?.data?.exerciseOtherValue?.value
  );
  const [exerciseRemark, setExerciseRemark] = useState(
    exData?.data?.exerciseRemark?.value
  );

  const handleCheckBox = (event) => {
    let updatedList = [...props.checkboxValue];
    if (event.target.checked) {
      updatedList = [...props.checkboxValue, event.target.value];
    } else {
      updatedList.splice(props.checkboxValue.indexOf(event.target.value), 1);
    }
    props.setCheckBoxValue(updatedList);
  };
  const onRemarksChangeHandler = (event) => {
    setExerciseRemark(event.target.value);
    props.setRemarksValue(event.target.value);
  };
  return (
    <>
      <div className="line-separation "></div>
      {exData?.data?.exerciseCheckBoxs?.option?.map((option, index) => {
        return (
          <Checkboxes
            key={index}
            name={option.name}
            value={option.value}
            type={option.type}
            label={option.label}
            defaultChecked={option.defaultChecked}
            id={option.id}
            onChangeHandler={handleCheckBox}
          />
        );
      })}
      {props.checkboxValue.includes('other') && (
        <div className="flex">
          <div
            className={`label-value-wrapper layout-2  ${
              !exerciseOtherValue ? 'value--empty' : ''
            }`}
          >
            <label
              className="field-label"
              htmlFor={exData?.data?.exerciseOtherValue?.id}
            >
              {exData?.data?.exerciseOtherValue?.label}
            </label>
            <div>
              <input
                type={exData?.data?.exerciseOtherValue?.type}
                id={exData?.data?.exerciseOtherValue?.id}
                name={exData?.data?.exerciseOtherValue?.name}
                placeholder={exData?.data?.exerciseOtherValue?.placeholder}
                onChange={(e) => {
                  props.setOtherValue(e.target.value);
                  setExerciseOtherValue(e.target.value);
                }}
                defaultValue={exData?.data?.exerciseOtherValue?.value}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className={`label-value-wrapper field-remark-wrapper ${
          !exerciseRemark ? 'value--empty' : ''
        }`}
      >
        <label>{exData?.data?.exerciseRemark?.label}</label>
        <textarea
          name={exData?.data?.exerciseRemark?.name}
          id={exData?.data?.exerciseRemark?.id}
          onChange={onRemarksChangeHandler}
          defaultValue={exData?.data?.exerciseRemark?.value}
          placeholder={exData?.data?.exerciseRemark?.placeholder}
        ></textarea>
      </div>
    </>
  );
};

export default ExerciseCheckBoxes;
