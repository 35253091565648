// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allocation__subHeading {
  font-weight: 600;
}
.allocation__type {
  font-weight: 400;
}
.allocationOfPayments__fields {
  justify-content: left;
}
.allocationOfPayments__fields > .layout-2 {
  width: calc(50% - 30px);
}
.allocationOfPayments__wrapper .addFieldButton {
  margin-bottom: 25px;
}
.initialPaymentAmount .labelRadioOptions__label {
  font-weight: 700;
}
.disable-fields #ppa-primaryFirstName,
.disable-fields #ppa-primaryMiddleName,
.disable-fields #ppa-contingentFirstName,
.disable-fields #ppa-contingentMiddleName {
  pointer-events: none;
  background: #f1f4f6;
  border: 1px solid #d6d6d6;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/ppaApplication/ppaApplication.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;;;;EAIE,oBAAoB;EACpB,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".allocation__subHeading {\n  font-weight: 600;\n}\n.allocation__type {\n  font-weight: 400;\n}\n.allocationOfPayments__fields {\n  justify-content: left;\n}\n.allocationOfPayments__fields > .layout-2 {\n  width: calc(50% - 30px);\n}\n.allocationOfPayments__wrapper .addFieldButton {\n  margin-bottom: 25px;\n}\n.initialPaymentAmount .labelRadioOptions__label {\n  font-weight: 700;\n}\n.disable-fields #ppa-primaryFirstName,\n.disable-fields #ppa-primaryMiddleName,\n.disable-fields #ppa-contingentFirstName,\n.disable-fields #ppa-contingentMiddleName {\n  pointer-events: none;\n  background: #f1f4f6;\n  border: 1px solid #d6d6d6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
