import AccordionAddMoreSection from '../../ui/accordionAddMoreSection';
import FormField from '../../ui/formField';
import { addressTypeOptions } from './addressTypeOption';

const withOutMailing = addressTypeOptions.filter(
  (item) => item.label !== 'Mailing'
);

const AddressDetailsForm = (props) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...props.addAddressList];
    switch (name) {
      case 'preferredAddress':
        list?.map((item, i) => {
          props.checkPreferredMailingRadio &&
            props.setMailingPreferredRadio(false);
          i === index
            ? (item.preferredAddress = true)
            : (item.preferredAddress = false);
          return item;
        });
        break;
      case 'primaryResidenceAddress':
        list?.map((item, i) => {
          i === index
            ? (item.primaryResidenceAddress = true)
            : (item.primaryResidenceAddress = false);
          return item;
        });
        break;
      case 'primaryBusinessAddress':
        list?.map((item, i) => {
          i === index
            ? (item.primaryBusinessAddress = true)
            : (item.primaryBusinessAddress = false);
          return item;
        });
        break;
      default:
        list[index][name] = value;
    }
    props.setAddAddressList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...props.addAddressList];
    list.splice(index, 1);
    props.setAddAddressList(list);
  };
  const handleAddClick = () => {
    props.setAddAddressList([
      ...props.addAddressList,
      {
        addressType: '',
        addressBlock: '',
        additionalLine2: '',
        additionalLine3: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        primaryResidenceAddress: false,
        primaryBusinessAddress: false,
        preferredAddress: false,
        livedAtPrimaryResidence: '',
        remarks: '',
      },
    ]);
  };

  return (
    <>
      {props?.addAddressList &&
        props?.addAddressList?.map((x, i) => {
          return (
            <AccordionAddMoreSection
              key={i}
              index={i}
              accordionHeading={`Address - ${i + 1}`}
              onDeleteClick={i ? () => handleRemoveClick(i) : null}
              hideDelete={props.hideDelete}
            >
              <div key={i} className="flex">
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">Address Type</label>

                  <div>
                    <select
                      name="addressType"
                      id="addressType"
                      value={x.addressType || ''}
                      onChange={(e) => handleInputChange(e, i)}
                    >
                      {props.hideMailingOption
                        ? withOutMailing?.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            );
                          })
                        : addressTypeOptions?.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            );
                          })}
                    </select>
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-1 ${
                    !x.addressBlock ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="addressBlock">
                    Address Block
                  </label>
                  <div>
                    <textarea
                      type="text"
                      name="addressBlock"
                      id="addressBlock"
                      placeholder="Enter"
                      value={x.addressBlock}
                      onChange={(e) => handleInputChange(e, i)}
                    ></textarea>
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !x.additionalLine2 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="additionalLine2">
                    Additional Line 2
                  </label>
                  <div>
                    <input
                      type="text"
                      name="additionalLine2"
                      id="additionalLine2"
                      placeholder="Enter"
                      value={x.additionalLine2}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !x.additionalLine3 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="additionalLine3">
                    Additional Line 3
                  </label>
                  <div>
                    <input
                      type="text"
                      name="additionalLine3"
                      id="additionalLine3"
                      placeholder="Enter"
                      value={x.additionalLine3}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !x.city ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="city">
                    City
                  </label>
                  <div>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="Enter"
                      value={x.city}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper layout-3">
                  <label className="field-label" htmlFor="state">
                    State
                  </label>
                  <FormField
                    id="state"
                    type="select"
                    name="state"
                    placeholder="Enter"
                    dropdownType="state"
                    addMoreField={true}
                    value={x.state || ''}
                    onChangeHandler={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !x.zipcode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="zipcode">
                    Zip Code
                  </label>
                  <div>
                    <input
                      type="text"
                      name="zipcode"
                      id="zipcode"
                      placeholder="Enter"
                      value={x.zipcode}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !x.country ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="country">
                    Country
                  </label>
                  <div>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      placeholder="Enter"
                      value={x.country}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper layout-3 make-primary-layout">
                  <input
                    type="radio"
                    id={`preferredAddress${i}`}
                    name="preferredAddress"
                    value={true}
                    onChange={(e) => handleInputChange(e, i)}
                    className="makePrimary-radio"
                    checked={x.preferredAddress}
                  />
                  <label
                    htmlFor={`preferredAddress${i}`}
                    className="makePrimary-label"
                  >
                    Mark as Preferred Address
                  </label>
                </div>
                {x.addressType.toLowerCase() === 'business' && (
                  <div className="label-value-wrapper layout-3 make-primary-layout">
                    <input
                      type="radio"
                      id={`primaryBusinessAddress${i}`}
                      name="primaryBusinessAddress"
                      value={true}
                      onChange={(e) => handleInputChange(e, i)}
                      className="makePrimary-radio"
                      checked={x.primaryBusinessAddress}
                    />
                    <label
                      htmlFor={`primaryBusinessAddress${i}`}
                      className="makePrimary-label"
                    >
                      Primary Business Address
                    </label>
                  </div>
                )}
                {x.addressType.toLowerCase() === 'residence' && (
                  <div className="label-value-wrapper layout-3 make-primary-layout">
                    <input
                      type="radio"
                      id={`primaryResidenceAddress${i}`}
                      name="primaryResidenceAddress"
                      value={true}
                      onChange={(e) => handleInputChange(e, i)}
                      className="makePrimary-radio"
                      checked={x.primaryResidenceAddress}
                    />
                    <label
                      htmlFor={`primaryResidenceAddress${i}`}
                      className="makePrimary-label"
                    >
                      Primary Residence Address
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`label-value-wrapper layout-1 field-remark-wrapper ${
                  !x.remarks ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Remarks</label>
                <textarea
                  name="remarks"
                  placeholder="Enter"
                  id="remarks"
                  value={x.remarks || ''}
                  onChange={(e) => handleInputChange(e, i)}
                ></textarea>
              </div>
            </AccordionAddMoreSection>
          );
        })}
      <div>
        <span className="addFieldButton" onClick={handleAddClick}>
          ADD ADDRESS
        </span>
      </div>
    </>
  );
};
export default AddressDetailsForm;
