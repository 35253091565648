import { useEffect, useState } from 'react';
import '../../../ui/accordionSingleSection/AccordionSingleSection.css';
import deleteIcon from './../../../../assets/images/delete-icon.svg';
import IconPlus from './../../../../assets/images/icon-plus.svg';
import IconMinus from './../../../../assets/images/icon-minus.svg';
import '../MedicalInformation.css';
const AccordionPhysicianAddMore = (props) => {
  const [openAccordion, setOpenAccordion] = useState(
    props?.openAccordion ? props?.openAccordion : true
  );
  let physicianTitle = '';

  switch (props.physicianTitle) {
    case 'dr':
      physicianTitle = 'Dr.';
      break;
    case 'miss':
      physicianTitle = 'Miss';
      break;
    case 'mr':
      physicianTitle = 'Mr.';
      break;
    case 'mrs':
      physicianTitle = 'Mrs.';
      break;
    case 'ms':
      physicianTitle = 'Ms.';
      break;
    default:
      physicianTitle = '';
  }

  const onAccordionClick = (e) => {
    switch (e.target.dataset.accordion) {
      case 'true':
        setOpenAccordion(false);
        break;
      case 'false':
        setOpenAccordion(true);
        break;
      default: //Do Nothing
    }
  };
  useEffect(() => {
    setOpenAccordion(props.openAccordion);
  }, [props.openAccordion]);
  useEffect(() => {
    props.newPhysicianAdded && setOpenAccordion(true);
  }, [props.newPhysicianAdded]);
  return (
    <>
      <div className="accordion__wrapper ">
        <div className="accordion__header accordion__header--physician">
          <span
            className="accordionClickField"
            onClickCapture={onAccordionClick}
            data-accordion={openAccordion}
          ></span>
          <h3 className="accordion__accordionHeading">
            {props.accordionHeading}
          </h3>
          <h3 className="accordion__physicianName">
            Physician Name:&nbsp;
            <span className="physicianName">
              {physicianTitle} {props?.physicianName}
            </span>
          </h3>
          <h3 className="accordion__physicianSpeciality">
            Speciality:&nbsp;
            <span className="physicianSpeciality">
              {props?.physicianSpeciality}
            </span>
          </h3>

          <div className="heading-container heading-container-physician">
            {props.hideDelete && (
              <p className="accordion__sub_header">
                Please use Smart Office to delete this record
              </p>
            )}
            {props.index ? (
              <>
                <span
                  onClick={props.onDeleteClick}
                  className={`icon-delete ${
                    props.hideDelete ? 'hide-component' : ''
                  }`}
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="deleteIcon remarks-remove"
                  />
                </span>
              </>
            ) : null}

            <img src={openAccordion ? IconMinus : IconPlus} alt="icon" />
          </div>
        </div>
        <div className={`accordion__content ${openAccordion ? 'open' : ''}`}>
          {props.children}
        </div>
      </div>
    </>
  );
};
export default AccordionPhysicianAddMore;
