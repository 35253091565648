import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonLabelRadioButtons from '../../../ui/commonLabelRadioButton';
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import RadioWithHeading from '../../../ui/radioWithHeading';

const MarriedSection = (props) => {
  const [currentlyMarried, setCurrentlyMarried] = useState();
  const [previouslyMarried, setPreviouslyMarried] = useState();
  const [widowQuestion, setWidowQuestion] = useState();
  const [majorLifeChanges, setMajorLifeChanges] = useState();
  const [hospitalizedQuestion, setHospitalizedQuestion] = useState();
  const [livingSituationOtherValue, setLivingSituationOtherValue] = useState(
    {}
  );
  const [livingSituationRadio, setLivingSituationRadio] = useState({});
  const longTermCareData = useSelector((state) => state.longTermCareData);
  const { loading, error, ltcData } = longTermCareData;

  const livingSituationOther = ltcData?.data?.livingSituationOtherValue;

  const onCurrentlyMarriedRadioChange = (e) => {
    setCurrentlyMarried(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onPreviouslyMarriedRadioChange = (e) => {
    setPreviouslyMarried(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onWidowQuestionRadioChange = (e) => {
    setWidowQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const onMajorLifeChangesRadioChange = (e) => {
    setMajorLifeChanges(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onHospitalizedQuestionRadioChange = (e) => {
    setHospitalizedQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const livingSituationRadioChange = (e) => {
    setLivingSituationRadio(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  useEffect(() => {
    const initialCurrentlyMarriedValue =
      ltcData?.data?.currentlyMarried?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialCurrentlyMarriedValue) {
      const key = initialCurrentlyMarriedValue['id'];
      const value = initialCurrentlyMarriedValue['value'];
      setCurrentlyMarried({ [key]: value });
    }
    const initialPreviouslyMarriedValue =
      ltcData?.data?.previouslyMarried?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialPreviouslyMarriedValue) {
      const key = initialPreviouslyMarriedValue['id'];
      const value = initialPreviouslyMarriedValue['value'];
      setPreviouslyMarried({ [key]: value });
    }
    const initialWidowQuestionValue =
      ltcData?.data?.widowQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialWidowQuestionValue) {
      const key = initialWidowQuestionValue['id'];
      const value = initialWidowQuestionValue['value'];
      setWidowQuestion({ [key]: value });
    }
    const initialMajorLifeChangesValue =
      ltcData?.data?.majorLifeChanges?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialMajorLifeChangesValue) {
      const key = initialMajorLifeChangesValue['id'];
      const value = initialMajorLifeChangesValue['value'];
      setMajorLifeChanges({ [key]: value });
    }
    const initialHospitalizedQuestionValue =
      ltcData?.data?.hospitalizedQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialHospitalizedQuestionValue) {
      const key = initialHospitalizedQuestionValue['id'];
      const value = initialHospitalizedQuestionValue['value'];
      setHospitalizedQuestion({ [key]: value });
    }

    const initialLivingSituationValue =
      ltcData?.data?.livingSituationRadio?.options?.find(
        (item) => item.defaultChecked
      );
    if (initialLivingSituationValue) {
      const key = initialLivingSituationValue['id'];
      const value = initialLivingSituationValue['value'];
      setLivingSituationRadio({ [key]: value });
    }
  }, [ltcData?.data]);
  useEffect(() => {
    props.setMarriedData({
      currentlyMarried,
      previouslyMarried,
      widowQuestion,
      majorLifeChanges,
      hospitalizedQuestion,
      livingSituationRadio,
      livingSituationOtherValue,
    });
  }, [
    currentlyMarried,
    previouslyMarried,
    widowQuestion,
    majorLifeChanges,
    hospitalizedQuestion,
    livingSituationRadio,
    livingSituationOtherValue,
  ]);
  return (
    <>
      <div className="flex">
        {ltcData?.data && (
          <div className="layout-2">
            <LabelRadioButtons
              label={ltcData?.data?.currentlyMarried?.label}
              options={ltcData?.data?.currentlyMarried?.radioOptions}
              id={ltcData?.data?.currentlyMarried?.id}
              onChangeHandler={onCurrentlyMarriedRadioChange}
            />
          </div>
        )}
        {ltcData?.data && (
          <div className="layout-2">
            <LabelRadioButtons
              label={ltcData?.data?.previouslyMarried?.label}
              options={ltcData?.data?.previouslyMarried?.radioOptions}
              id={ltcData?.data?.previouslyMarried?.id}
              onChangeHandler={onPreviouslyMarriedRadioChange}
            />
          </div>
        )}
        {ltcData?.data && (
          <div className="layout-2">
            <LabelRadioButtons
              label={ltcData?.data?.widowQuestion?.label}
              options={ltcData?.data?.widowQuestion?.radioOptions}
              id={ltcData?.data?.widowQuestion?.id}
              onChangeHandler={onWidowQuestionRadioChange}
            />
          </div>
        )}
        <div className="line-separation layout-1"></div>
        {ltcData?.data && (
          <p className="ltc-label layout-1">
            {ltcData?.data?.livingSituationRadio?.label}
          </p>
        )}
        {ltcData?.data && (
          <CommonLabelRadioButtons
            options={ltcData?.data?.livingSituationRadio?.options}
            onChangeHandler={livingSituationRadioChange}
          />
        )}
        {livingSituationRadio['other'] === 'other' && (
          <div className="flex">
            {ltcData?.data && (
              <div
                className={`label-value-wrapper layout-3 ${
                  !livingSituationOther.value ? 'value--empty' : ''
                }`}
              >
                <label
                  className="field-label"
                  htmlFor={livingSituationOther?.id}
                >
                  {livingSituationOther?.label}
                </label>
                <div>
                  <input
                    type={livingSituationOther?.type}
                    id={livingSituationOther?.id}
                    name={livingSituationOther.name}
                    placeholder={livingSituationOther.placeholder}
                    onChange={(e) => {
                      setLivingSituationOtherValue(() => ({
                        [e.target.id]: e.target.value,
                      }));
                    }}
                    defaultValue={livingSituationOther.value}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {ltcData?.data && (
          <LabelRadioButtons
            label={ltcData?.data?.majorLifeChanges?.label}
            options={ltcData?.data?.majorLifeChanges?.radioOptions}
            id={ltcData?.data?.majorLifeChanges?.id}
            onChangeHandler={onMajorLifeChangesRadioChange}
          />
        )}
        {ltcData?.data && (
          <LabelRadioButtons
            label={ltcData?.data?.hospitalizedQuestion?.label}
            options={ltcData?.data?.hospitalizedQuestion?.radioOptions}
            id={ltcData?.data?.hospitalizedQuestion?.id}
            onChangeHandler={onHospitalizedQuestionRadioChange}
          />
        )}
      </div>
    </>
  );
};

export default MarriedSection;
