import axios from '../axios/axios';
import {
  FORM_ADMINISTRATION_DATA_REQUEST,
  FORM_ADMINISTRATION_DATA_SUCCESS,
  FORM_ADMINISTRATION_DATA_FAIL,
} from '../constants/formAdministrationConstants';

export const formAdministrationAction = () => async (dispatch) => {
  try {
    dispatch({ type: FORM_ADMINISTRATION_DATA_REQUEST });
    const { data } = await axios.post('/carrier-upload/pdf-list');
    dispatch({
      type: FORM_ADMINISTRATION_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: FORM_ADMINISTRATION_DATA_FAIL,
      payload: error,
    });
  }
};
