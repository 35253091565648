// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-space {
  margin-bottom: 20px;
}
.partner-delete-icon {
  display: flex;
  justify-content: end;
  align-items: center;
}
.llc-msg {
  /* padding: 15px; */
  align-items: center;
  /* background-color: #fbf2e0; */
  margin-bottom: 15px;
}
.ltc-text-field > .label-value-wrapper {
  margin-right: 0px;
  margin-bottom: 0px;
}
.llc-fields-wrapper {
  margin-top: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/policyOwner/PolicyOwner.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,+BAA+B;EAC/B,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bottom-space {\n  margin-bottom: 20px;\n}\n.partner-delete-icon {\n  display: flex;\n  justify-content: end;\n  align-items: center;\n}\n.llc-msg {\n  /* padding: 15px; */\n  align-items: center;\n  /* background-color: #fbf2e0; */\n  margin-bottom: 15px;\n}\n.ltc-text-field > .label-value-wrapper {\n  margin-right: 0px;\n  margin-bottom: 0px;\n}\n.llc-fields-wrapper {\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
