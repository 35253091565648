import './CaseDataSection.css';
import { useSelector } from 'react-redux';

const CaseDataSection = (props) => {
  const productType = localStorage.getItem('productType');

  const pageData = useSelector((state) => {
    const apiData =
      productType === 'annuity'
        ? state.ppaApplicationData?.formData
        : state.purposeOfCoverageData?.formData;
    return apiData?.common_details;
  });

  if (!pageData) {
    return <></>;
  }

  return (
    <div className="wrapper">
      <div className="static">Insured Name</div>
      <div className="dynamic">{pageData.name || ' - '}</div>
      <div className="static">Carrier</div>
      <div className="dynamic">{pageData.carrier || ' - '}</div>
      <div className="static">Product Sub Type</div>
      <div className="dynamic">{pageData.product_subtype || ' - '}</div>
      <div className="static">CA 1</div>
      <div className="dynamic">{pageData.so_ca1 || ' - '}</div>
      <div className="static">CA 2</div>
      <div className="dynamic">{pageData.so_ca2 || ' - '}</div>
      <div className="static">CA 3</div>
      <div className="dynamic">{pageData.so_ca3 || ' - '}</div>
      <div className="static">Super RP</div>
      <div className="dynamic">{pageData.so_super_rp || ' - '}</div>
      <div className="static">Underwriter</div>
      <div className="dynamic">{pageData.so_underwriter || ' -  '}</div>
    </div>
  );
};

export default CaseDataSection;
