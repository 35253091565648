import axios from '../axios/axios';
import {
  NOTES_DATA_REQUEST,
  NOTES_DATA_SUCCESS,
  NOTES_DATA_FAIL,
} from '../constants/notesConstants';

export const notesAction = () => async (dispatch) => {
  try {
    dispatch({ type: NOTES_DATA_REQUEST });
    const { data } = await axios.post('/rpiq-form-note-fetch', {
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: NOTES_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }

    dispatch({
      type: NOTES_DATA_FAIL,
      payload: error,
    });
  }
};
