import FormField from '../../../ui/formField';
import { useSelector } from 'react-redux';

const OwnerOfPolicy = (props) => {
  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;

  const sectionLabel = formData?.data?.policyOwnerLabel;
  const primaryCountryFieldsChangeHandler = (e) => {
    props.setPrimaryCountryFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const primaryCountryLabel = formData?.data?.po_primaryResidenceLabel;
  const primaryCountryFields = formData?.data?.po_primaryResidence.map(
    (item, index) => {
      return (
        <FormField
          key={index}
          type={item.type}
          name={item.name}
          value={item.value}
          placeholder={item.placeholder}
          onChangeHandler={primaryCountryFieldsChangeHandler}
        />
      );
    }
  );
  const usResidenceFieldsChangehandler = (e) => {
    props.setUSResidenceFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const usResidenceLabel = formData?.data?.po_usResidenceLabel;
  const usResidenceFields = formData?.data?.po_usResidence.map(
    (item, index) => {
      return (
        <FormField
          key={index}
          type={item.type}
          name={item.name}
          value={item.value}
          placeholder={item.placeholder}
          onChangeHandler={usResidenceFieldsChangehandler}
        />
      );
    }
  );
  const otherResidenceFieldsChangeHandler = (e) => {
    props.setOtherResidenceFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const otherResidenceLabel = formData?.data?.po_otherCountryLabel;
  const otherResidenceFields = formData?.data?.po_otherCountryResidence.map(
    (item, index) => {
      return (
        <FormField
          key={index}
          type={item.type}
          name={item.name}
          value={item.value}
          placeholder={item.placeholder}
          onChangeHandler={otherResidenceFieldsChangeHandler}
        />
      );
    }
  );
  return (
    <>
      {sectionLabel && <h3>{sectionLabel}</h3>}

      <div className="foreignResidency__ownerInfo">
        <div className="foreignResidency__header">
          <span className="foreignResidency__headerLabel emptyLabel"></span>
          <span className="foreignResidency__headerLabel locationLabel">
            Location
          </span>
          <span className="foreignResidency__headerLabel">Last 12 Months</span>
          <span className="foreignResidency__headerLabel">Next 12 Months</span>
        </div>
        <div className="foreignResidency__fields ">
          <span className="foreignResidency__rowLable">
            {primaryCountryLabel}
          </span>
          <div className="foreignResidency__rowFields ">
            {primaryCountryFields}
          </div>
        </div>
        <div className="foreignResidency__fields">
          <span className="foreignResidency__rowLable">{usResidenceLabel}</span>
          <div className="foreignResidency__rowFields">{usResidenceFields}</div>
        </div>
        <div className="foreignResidency__fields">
          <span className="foreignResidency__rowLable">
            {otherResidenceLabel}
          </span>
          <div className="foreignResidency__rowFields">
            {otherResidenceFields}
          </div>
        </div>
      </div>
    </>
  );
};
export default OwnerOfPolicy;
