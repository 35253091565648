export const phoneTypeList = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'Residence',
    value: 'Residence',
  },
  {
    label: 'Business',
    value: 'Business',
  },
  {
    label: 'Vacation',
    value: 'Vacation',
  },
  {
    label: 'Business Fax',
    value: 'Business Fax',
  },
  {
    label: 'Marine',
    value: 'Marine',
  },
  {
    label: 'Corporate Office',
    value: 'Corporate Office',
  },
  {
    label: 'Regional Office',
    value: 'Regional Office',
  },
  {
    label: 'Emergency',
    value: 'Emergency',
  },
  {
    label: 'Temporary',
    value: 'Temporary',
  },
  {
    label: 'Mobile',
    value: 'Mobile',
  },
  {
    label: 'Pager(beeper)',
    value: 'Pager(beeper)',
  },
  {
    label: 'Modem/data line',
    value: 'Modem/data line',
  },
  {
    label: 'Residence Fax',
    value: 'Residence Fax',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Previous',
    value: 'Previous',
  },
  {
    label: 'Direct',
    value: 'Direct',
  },
];
