import CommonTextArea from '../../../ui/commonTextArea';
import Checkboxes from '../../../ui/formField/Checkboxes';
import LabelRadioButtons from '../../../ui/labelRadioButtons';

import UnderwaterDepthSection from './underwaterDepthSection';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const UnderwaterSection = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  const underwaterOther = formData?.data?.underwaterDivingOtherValue;
  const [underwaterOtherValue, setUnderwaterOtherValue] = useState(
    formData?.data?.underwaterDivingOtherValue?.value
  );
  const udlOther = formData?.data?.underwaterDivingLocationsOtherValue;
  const [uldOtherValue, setUldOtherValue] = useState(
    formData?.data?.underwaterDivingLocationsOtherValue?.value
  );
  const handleCheckBox = (event) => {
    let updatedList = [...props.underwaterDivingCheckBoxes];
    if (event.target.checked) {
      updatedList = [...props.underwaterDivingCheckBoxes, event.target.value];
    } else {
      updatedList.splice(
        props.underwaterDivingCheckBoxes.indexOf(event.target.value),
        1
      );
    }
    props.setUnderwaterDivingCheckBoxes(updatedList);
  };
  const underwaterDivingLocationsHandleCheckBox = (event) => {
    let udUpdatedList = [...props.underwaterDivingLocations];
    if (event.target.checked) {
      udUpdatedList = [...props.underwaterDivingLocations, event.target.value];
    } else {
      udUpdatedList.splice(
        props.underwaterDivingLocations.indexOf(event.target.value),
        1
      );
    }
    props.setUnderwaterDivingLocations(udUpdatedList);
  };
  const UWDTRadioChangeHandler = (event) => {
    props.setUWDTRadioOptions(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const UWDTDetailChangeHandler = (e) => {
    props.setUWDTDetail(e.target.value);
  };
  const UADDetailChangeHandler = (e) => {
    props.setUADDetail(e.target.value);
  };

  const UADRadioChangeHandler = (event) => {
    props.setUADRadioOptions(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  return (
    <>
      <h3>Underwater Diving</h3>
      <p
        className="plan-question-label"
        id={formData?.data?.underwaterDivingLabel?.id}
      >
        {formData?.data?.underwaterDivingLabel?.label}
      </p>
      {formData?.data &&
        formData?.data?.underwaterDivingCheckBoxes?.option?.map(
          (option, index) => {
            return (
              <Checkboxes
                key={index}
                name={option.name}
                value={option.value}
                type={option.type}
                label={option.label}
                defaultChecked={option.defaultChecked}
                id={option.id}
                onChangeHandler={handleCheckBox}
                disabled={option.disable}
              />
            );
          }
        )}
      {props.underwaterDivingCheckBoxes.includes('other') && (
        <div className="flex">
          <div
            className={`label-value-wrapper layout-2 ${
              !underwaterOtherValue ? 'value--empty' : ''
            }`}
          >
            <label className="field-label" htmlFor={underwaterOther?.id}>
              {underwaterOther?.label}
            </label>
            <div>
              <input
                type={underwaterOther?.type}
                id={underwaterOther?.id}
                name={underwaterOther?.name}
                placeholder={underwaterOther?.placeholder}
                onChange={(e) => {
                  setUnderwaterOtherValue(e.target.value);
                  props.setUnderwaterDivingOtherValue(e.target.value);
                }}
                defaultValue={underwaterOther?.value}
              />
            </div>
          </div>
        </div>
      )}
      {props.underwaterDivingCheckBoxes.length > 0 && (
        <>
          <p className="plan-question-label">
            {formData?.data?.underwaterDivingLocations?.label}
          </p>
          {formData?.data &&
            formData?.data?.underwaterDivingLocations?.option?.map(
              (option, index) => {
                return (
                  <Checkboxes
                    key={index}
                    name={option.name}
                    value={option.value}
                    type={option.type}
                    label={option.label}
                    defaultChecked={option.defaultChecked}
                    id={option.id}
                    onChangeHandler={underwaterDivingLocationsHandleCheckBox}
                    disabled={option.disable}
                  />
                );
              }
            )}
          {props.underwaterDivingLocations.includes('other') && (
            <div className="flex">
              <div
                className={`label-value-wrapper layout-2 ${
                  !uldOtherValue ? 'value--empty' : ''
                }`}
              >
                <label className="field-label" htmlFor={udlOther?.id}>
                  {udlOther?.label}
                </label>
                <div>
                  <input
                    type={udlOther?.type}
                    id={udlOther?.id}
                    name={udlOther?.name}
                    placeholder={udlOther?.placeholder}
                    onChange={(e) => {
                      setUldOtherValue(e.target.value);
                      props.setUnderwaterDivingLocationsOtherValue(
                        e.target.value
                      );
                    }}
                    defaultValue={udlOther?.value}
                  />
                </div>
              </div>
            </div>
          )}
          <LabelRadioButtons
            label={formData?.data?.UWDTRadioOptions?.label}
            options={formData?.data?.UWDTRadioOptions?.radioOptions}
            onChangeHandler={UWDTRadioChangeHandler}
          />
          {props.UWDTRadioOptions['underwaterDivingTraining-yes'] === 'yes' && (
            <CommonTextArea
              fieldData={formData?.data?.UWDTDetail}
              onChangeHandler={UWDTDetailChangeHandler}
            />
          )}
          <LabelRadioButtons
            label={formData?.data?.UADRadioOptions?.label}
            options={formData?.data?.UADRadioOptions?.radioOptions}
            onChangeHandler={UADRadioChangeHandler}
          />
          {props.UADRadioOptions['underwaterAloneDiving-yes'] === 'yes' && (
            <CommonTextArea
              fieldData={formData?.data?.UADDetail}
              onChangeHandler={UADDetailChangeHandler}
            />
          )}
          <UnderwaterDepthSection
            underwaterDepth={formData?.data?.underwaterDepthTable}
            setUnderwaterDivingData={props.setUnderwaterDepthTable}
          />
        </>
      )}
    </>
  );
};

export default UnderwaterSection;
