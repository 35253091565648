import React, { useState } from 'react';
import './matchingFormList.css';
import OverlayTable from './overlayTable';
import { PiWarningCircleBold } from 'react-icons/pi';

const MatchingFormList = ({ formData }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleLinkClick = () => {
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  if (formData.length == 0) {
    return <></>;
  }
  return (
    <div>
      <div></div>
      <div onClick={handleLinkClick} className="red-bold-warning-forms">
        <div>
          <PiWarningCircleBold />
        </div>
        <div style={{ marginLeft: '5px', cursor: 'pointer' }}>
          Check for {formData.length} Possible Duplicate Forms
        </div>
      </div>
      {showOverlay && (
        <OverlayTable formData={formData} onClose={handleCloseOverlay} />
      )}
    </div>
  );
};

export default MatchingFormList;
