// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 470px;
  height: 360px;
  background-color: #ffffff;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000000b;
  border-radius: 12px;
  opacity: 1;
}
.login-image-container {
  margin-top: 34px;
}

.welcome-heading {
  color: #222222;
  font-size: 41px;
  font-weight: 600;
  margin-top: 28px;
}
.welcome-sub-heading {
  font-size: 14px;
  color: #222222;
  margin-top: 15px;
}
.login-btn {
  font-size: 16px;
  display: inline-block;
  background: #1984bc;
  color: #fff;
  border: 1px solid #1984bc;
  padding: 6px 30px;
  cursor: pointer;
  line-height: 1.2;
  font-weight: 700;
  -webkit-appearance: none;
  border-radius: 4px;
  transition: all 0.4s;
  -ms-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  margin-top: 36px;
  width: 400px;
  height: 54px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/landingPage/landingPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,+CAA+C;EAC/C,kCAAkC;EAClC,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;EACX,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;EAClB,oBAAoB;EACpB,wBAAwB;EACxB,4BAA4B;EAC5B,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".landing-container {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.login-container {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 470px;\n  height: 360px;\n  background-color: #ffffff;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  box-shadow: 0px 3px 10px #0000000b;\n  border-radius: 12px;\n  opacity: 1;\n}\n.login-image-container {\n  margin-top: 34px;\n}\n\n.welcome-heading {\n  color: #222222;\n  font-size: 41px;\n  font-weight: 600;\n  margin-top: 28px;\n}\n.welcome-sub-heading {\n  font-size: 14px;\n  color: #222222;\n  margin-top: 15px;\n}\n.login-btn {\n  font-size: 16px;\n  display: inline-block;\n  background: #1984bc;\n  color: #fff;\n  border: 1px solid #1984bc;\n  padding: 6px 30px;\n  cursor: pointer;\n  line-height: 1.2;\n  font-weight: 700;\n  -webkit-appearance: none;\n  border-radius: 4px;\n  transition: all 0.4s;\n  -ms-transition: all 0.4s;\n  -webkit-transition: all 0.4s;\n  -moz-transition: all 0.4s;\n  margin-top: 36px;\n  width: 400px;\n  height: 54px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
