// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mi-paragraph-container {
  font-size: 12px;
  background-color: #f1f4f681;
  padding: 14px 12px 15px 12px;
  border: 1px solid #edf0f2;
  margin-bottom: 20px;
}
.height-select-width {
  width: 25%;
  margin-right: 10px;
}
.mi-height-container {
  align-items: center;
}
.height-container {
  display: flex;
  flex-direction: column;
}
.equalto {
  font-size: 18px;
  padding-right: 20px;
}
.feet-container {
  align-items: center;
}
.speciality-other-layout {
  width: calc(23% - 0px);
}
.accordion__header--physician {
  padding-right: 65px;
  justify-content: normal;
}
.heading-container-physician {
  position: absolute;
  right: 12px;
  top: 18px;
}
.accordion__header--physician .accordion__accordionHeading {
  margin-right: 45px;
}
.accordion__physicianName,
.accordion__physicianSpeciality {
  font-size: 16px;
  color: #222222;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 1.3;
  margin-right: 20px;
}
.accordion__physicianName {
  width: 40%;
}
.accordion__physicianName .physicianName {
  color: #e0a633;
}
.accordion__physicianSpeciality .physicianSpeciality {
  color: #e0a633;
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/medicalInformation/MedicalInformation.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,2BAA2B;EAC3B,4BAA4B;EAC5B,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;AACA;EACE,kBAAkB;AACpB;AACA;;EAEE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".mi-paragraph-container {\n  font-size: 12px;\n  background-color: #f1f4f681;\n  padding: 14px 12px 15px 12px;\n  border: 1px solid #edf0f2;\n  margin-bottom: 20px;\n}\n.height-select-width {\n  width: 25%;\n  margin-right: 10px;\n}\n.mi-height-container {\n  align-items: center;\n}\n.height-container {\n  display: flex;\n  flex-direction: column;\n}\n.equalto {\n  font-size: 18px;\n  padding-right: 20px;\n}\n.feet-container {\n  align-items: center;\n}\n.speciality-other-layout {\n  width: calc(23% - 0px);\n}\n.accordion__header--physician {\n  padding-right: 65px;\n  justify-content: normal;\n}\n.heading-container-physician {\n  position: absolute;\n  right: 12px;\n  top: 18px;\n}\n.accordion__header--physician .accordion__accordionHeading {\n  margin-right: 45px;\n}\n.accordion__physicianName,\n.accordion__physicianSpeciality {\n  font-size: 16px;\n  color: #222222;\n  font-weight: 700;\n  margin-bottom: 0px;\n  line-height: 1.3;\n  margin-right: 20px;\n}\n.accordion__physicianName {\n  width: 40%;\n}\n.accordion__physicianName .physicianName {\n  color: #e0a633;\n}\n.accordion__physicianSpeciality .physicianSpeciality {\n  color: #e0a633;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
