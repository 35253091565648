import LabelRadioWithFields from '../../../ui/labelRadioWithFields';
import './../healthAndLifestyle.css';
import { useSelector } from 'react-redux';

const FollowUpQuestions = (props) => {
  const hlFormData = useSelector((state) => state.hlData);
  const { loading, error, formData } = hlFormData;

  const onDetailsChangeHandler = (e) => {
    props.setFollowUpDetailsData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const followUpQuestionsLabel = formData?.data?.followUpQuestionsLabel?.label;
  const followUpQuestionsJson = formData?.data?.followUpQuestions;

  const followUpQuestionsFields = followUpQuestionsJson?.map((item, index) => {
    return (
      <div key={index} className="line-separation">
        {' '}
        <LabelRadioWithFields
          id={item.id}
          label={item.label}
          options={item.radioOptions}
          provideDetails={item.provideDetails}
          setRadioChangeValue={props.setFollowUpRadioData}
          onDetailsChangeHandler={onDetailsChangeHandler}
        />
      </div>
    );
  });
  return (
    <>
      {followUpQuestionsLabel && <h3>{followUpQuestionsLabel}</h3>}
      {followUpQuestionsFields}
    </>
  );
};

export default FollowUpQuestions;
