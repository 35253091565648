import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
import radioCheckedImg from '../../../../assets/images/radio-checked.svg';
import LabelRadioButtons from '../../../ui/labelRadioButtons';

const BusinessInfoAndCoverage = (props) => {
  const pageData = useSelector((state) => state.businessInsuranceData);
  const { loading, error, formData } = pageData;

  const businessInfoFieldChange = (e) => {
    props.setBusinessInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (formData?.data?.purposeOfCoverage) {
      const pocSelected =
        formData?.data?.purposeOfCoverage?.checkOptions?.filter(
          (element) => element.defaultChecked
        );
      const pocValues = pocSelected?.map((ele) => ele.value);
      props.setPocChecked(pocValues);
    }
  }, [formData?.data]);

  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };

  const businessInfo = formData?.data?.businessInfo?.map((item, index) => {
    return (
      <FormField
        type={item.type}
        name={item.name}
        value={item.value}
        id={item.id}
        label={item.label}
        key={index}
        layoutType={item.layoutType}
        onChangeHandler={businessInfoFieldChange}
      ></FormField>
    );
  });
  const pocChangeHandler = (e) => {
    if (e.target.checked) {
      props.setPocChecked((prevState) => [...prevState, e.target.value]);
    } else {
      let checkedValuesArray = [...props.pocChecked];

      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      props.setPocChecked(checkedValuesArray);
    }
  };

  const coveragePurpose = formData?.data?.purposeOfCoverage?.checkOptions.map(
    (item, index) => {
      return (
        <div className="checkboxTabs" key={index}>
          <input
            className="checkboxField"
            type="checkbox"
            value={item.value}
            name={item.name}
            id={item.id}
            defaultChecked={item.defaultChecked}
            onChange={pocChangeHandler}
          />
          <label className="customCheckbox" htmlFor={item.id}>
            <img
              src={radioCheckedImg}
              alt="checked "
              className="customCheckbox__image"
            />
            <span className="customCheckbox__label">{item.value}</span>
          </label>
        </div>
      );
    }
  );

  const collaterallyRadioChange = (e) => {
    props.setCollaterallyRadio(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const collaterallyRadio = (
    <LabelRadioButtons
      label={formData?.data?.collaterallyAssigned?.label}
      options={formData?.data?.collaterallyAssigned?.radioOptions}
      onChangeHandler={collaterallyRadioChange}
    />
  );
  const loanFieldsChange = (e) => {
    props.setLoanFields((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.dataset.validationtype === 'financialValue'
          ? removeCommaSeparator(e.target.value)
          : e.target.value,
    }));
  };
  const loanCoverageFields = formData?.data?.loanCoverageFields?.map(
    (item, index) => {
      return (
        <FormField
          type={item.type}
          name={item.name}
          value={item.value}
          id={item.id}
          label={item.label}
          key={index}
          layoutType={item.layoutType}
          onChangeHandler={loanFieldsChange}
          validationType={item.validationType}
        />
      );
    }
  );
  const otherCoverageFieldsChange = (e) => {
    props.setOtherCoverageValue(() => ({
      [e.target.name]: e.target.value,
    }));
  };
  const otherCoverageField = (
    <FormField
      type={formData?.data?.otherCoverageInfo?.type}
      name={formData?.data?.otherCoverageInfo?.name}
      value={formData?.data?.otherCoverageInfo?.value}
      id={formData?.data?.otherCoverageInfo?.id}
      label={formData?.data?.otherCoverageInfo?.label}
      key={formData?.data?.otherCoverageInfo?.id}
      layoutType={formData?.data?.otherCoverageInfo?.layoutType}
      onChangeHandler={otherCoverageFieldsChange}
    />
  );

  return (
    <>
      {businessInfo && <div className="flex">{businessInfo}</div>}
      {formData?.data?.purposeOfCoverage?.label && (
        <h3 className="businessInsurance__sectionHeading">
          {formData?.data?.purposeOfCoverage?.label}
        </h3>
      )}
      {coveragePurpose && (
        <div className="line-separation ">{coveragePurpose}</div>
      )}
      {props.pocChecked.includes('Loan') && (
        <>
          {collaterallyRadio && collaterallyRadio}
          {loanCoverageFields && (
            <div className="flex line-separation">{loanCoverageFields}</div>
          )}
        </>
      )}

      {props.pocChecked.includes('Other') && (
        <>
          {' '}
          {otherCoverageField && (
            <div className="flex ">{otherCoverageField}</div>
          )}
        </>
      )}
    </>
  );
};
export default BusinessInfoAndCoverage;
