import InputText from './InputText';
import InputNumber from './InputNumber';
import InputEmail from './InputEmail';
import InputDate from './InputDate';
import SelectBox from './SelectBox';
import RadioButton from './RadioButton';
import TextArea from './Textarea';
import PropTypes from 'prop-types';
import './FormField.css';
import InputCheckBox from './InputCheckBox';
import InputMonth from './InputMonth';

const FormField = (props) => {
  switch (props.type) {
    case 'text':
      return <InputText fieldData={props} />;

    case 'number':
      return <InputNumber fieldData={props} />;

    case 'email':
      return <InputEmail fieldData={props} />;

    case 'date':
      return <InputDate fieldData={props} />;

    case 'select':
      return <SelectBox fieldData={props} />;

    case 'textarea':
      return <TextArea fieldData={props} />;

    case 'radio':
      return (
        <RadioButton
          type={props.type}
          label={props.label}
          name={props.name}
          id={props.id}
          value={props.value}
        />
      );

    case 'checkbox':
      return <InputCheckBox fieldData={props} />;

    case 'month':
      return <InputMonth fieldData={props} />;

    default:
      return null;
  }
};
export default FormField;

FormField.propTypes = {
  props: PropTypes.object,
};
