import {
  PD_DATA_REQUEST,
  PD_DATA_SUCCESS,
  PD_DATA_FAIL,
} from '../constants/policyDetailsConstants';

export const policyDetailsReducer = (state = { pdData: [] }, action) => {
  switch (action.type) {
    case PD_DATA_REQUEST:
      return { loading: true, pdData: [] };
    case PD_DATA_SUCCESS:
      return { loading: false, pdData: action.payload };
    case PD_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
