import { useEffect, useState } from 'react';
import './MedicalInformation.css';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import FormField from '../../ui/formField';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import PhysicianSection from './physicianSection';
import EkgDoctorDetails from './ekgDoctorDetail';
import { useSelector, useDispatch } from 'react-redux';
import { physicianInfomationAction } from '../../../actions/physicianInfomationAction';
import axios from '../../../axios/axios';
import ToastMessage from '../../ui/toastMessage';
import Loader from '../../loader';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const MedicalInformation = () => {
  const [ekgQuestionValue, setEkgQuestionValue] = useState(false);
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [ekgDoctorDetails, setEkgDoctorDetails] = useState([]);
  const [ekgDate, setEkgDate] = useState('');
  const [ekgRadioValue, setEkgRadioValue] = useState({});
  const dispatch = useDispatch();
  const pyFormData = useSelector((state) => state.pyFieldData);
  const { loading, error, pyData } = pyFormData;

  const [physicianData, setPhysicianData] = useState([]);
  useEffect(() => {
    if (pyData?.data?.PhysicianDetailsFields !== undefined) {
      setPhysicianData(pyData?.data?.PhysicianDetailsFields);
    }
    const oldEkg = pyData?.data?.ekgQuestionSection?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (oldEkg) {
      const key = oldEkg['id'];
      const value = oldEkg['value'];
      setEkgRadioValue({ [key]: value });
    }
  }, [pyData?.data]);
  useEffect(() => {
    setEkgQuestionValue(
      pyData?.data?.ekgQuestionSection?.radioOptions[0]?.defaultChecked
    );
  }, [pyData?.data]);
  useEffect(() => {
    dispatch(physicianInfomationAction());
  }, [dispatch]);

  const ekgQuestionChangeHandler = (event) => {
    setEkgQuestionValue(event.target.value === 'yes');
    setEkgRadioValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  const onFieldChangeHandler = (event) => {
    setEkgDate((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  let resultJson = {
    ekgDoctorRadioOption: ekgQuestionValue,
    ekgDoctorSectionFields: ekgDoctorDetails,
    PhysicianDetailsFields: physicianData,
    ekgYesSectionFields: ekgDate,
    ekgQuestionSection: ekgRadioValue,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: resultJson,
        sectionId: 'f1v1s6',
      },
    });
  }, [resultJson]);
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s6`,
        resultJson
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
        dispatch(physicianInfomationAction());
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/proposedinsured"
        nextPath="/form/health"
        pageHeading={pyData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={pyData?.common_details?.case_id}
        isCompleted={pyData?.metadata?.isCompleted}
        sectionId="f1v1s6"
        userName={pyData?.common_details?.name}
        productSubType={pyData?.common_details?.product_subtype}
        formId={pyData?.common_details?.form_id}
        otherCasesInfo={pyData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {pyData?.data && (
            <LabelRadioButtons
              label={pyData?.data?.ekgQuestionSection?.label}
              options={pyData?.data?.ekgQuestionSection?.radioOptions}
              id={pyData?.data?.ekgQuestionSection?.id}
              onChangeHandler={ekgQuestionChangeHandler}
              boldLabel
            />
          )}
          <div className="line-separation "></div>
          {ekgQuestionValue &&
            pyData?.data?.ekgYesSectionFields?.map((item) => {
              return (
                <FormField
                  key={item.id}
                  id={item.id}
                  type={item.type}
                  label={item.label}
                  value={item.value}
                  name={item.name}
                  layoutType={item.layoutType}
                  onChangeHandler={onFieldChangeHandler}
                />
              );
            })}

          {ekgQuestionValue && (
            <EkgDoctorDetails setEkgDoctorDetails={setEkgDoctorDetails} />
          )}

          <PhysicianSection
            setPhysicianData={setPhysicianData}
            physicianData={physicianData}
          />
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default MedicalInformation;
