// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-modal-component {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.sort-modal-wrapper {
  width: 600px;
  background: #fff;
  border-radius: 8px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.sort-modal-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.sort-modal-heading {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12px;
}
.sort-modal-contents {
  font-size: 16px;
  color: #333;
  margin-bottom: 30px;
}
.sort-modal-heading-container {
  background-color: #0177b5;
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
}
.sort-modal-heading-container p {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.sort-modal-heading-container img {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/caseListing/sortModal/sortModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,YAAY;AACd;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,UAAU;EACV,gCAAgC;EAChC,oCAAoC;EACpC,wCAAwC;EACxC,qCAAqC;AACvC;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,OAAO;EACP,MAAM;AACR;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,2BAA2B;EAC3B,4BAA4B;EAC5B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;AACjB","sourcesContent":[".sort-modal-component {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n}\n.sort-modal-wrapper {\n  width: 600px;\n  background: #fff;\n  border-radius: 8px;\n  text-align: left;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  z-index: 1;\n  transform: translate(-50%, -50%);\n  -ms-transform: translate(-50%, -50%);\n  -webkit-transform: translate(-50%, -50%);\n  -moz-transform: translate(-50%, -50%);\n}\n.sort-modal-overlay {\n  background: rgba(0, 0, 0, 0.4);\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n}\n.sort-modal-heading {\n  font-size: 22px;\n  font-weight: 500;\n  margin-bottom: 12px;\n}\n.sort-modal-contents {\n  font-size: 16px;\n  color: #333;\n  margin-bottom: 30px;\n}\n.sort-modal-heading-container {\n  background-color: #0177b5;\n  height: 48px;\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 25px;\n}\n.sort-modal-heading-container p {\n  font-size: 16px;\n  font-weight: 600;\n  color: #ffffff;\n}\n.sort-modal-heading-container img {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
