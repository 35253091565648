import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';
import FormField from '../../../ui/formField';
import axios from '../../../../axios/axios';
import { SetTaxIDSeparator } from '../../../../constants/taxIdConstants';
import { RemoveTaxIdSeparator } from '../../../../constants/taxIdConstants';

const trusteeTypeOptions = [
  { label: 'Select', value: '' },
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Company',
    value: 'company',
  },
];
const selectOptions = [
  { label: 'Select', value: '' },
  {
    label: 'Dr.',
    value: 'dr',
  },
  {
    label: 'Miss',
    value: 'miss',
  },
  {
    label: 'Mr.',
    value: 'mr',
  },
  {
    label: 'Mrs.',
    value: 'mrs',
  },
  {
    label: 'Ms.',
    value: 'ms',
  },
];
const genderList = [
  { label: 'Select', value: '' },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Male',
    value: 'male',
  },

  {
    label: 'Non-Binary',
    value: 'nonBinary',
  },
  {
    label: 'Transgender',
    value: 'transgender',
  },
];
const TrusteeSection = (props) => {
  let handleChange = (i, e) => {
    let newFormValues = [...props.trustee];
    newFormValues[i][e.target.name] =
      e.target.name === 'company_taxIdNumber'
        ? RemoveTaxIdSeparator(e.target.value)
        : e.target.value;
    props.setTrustee(newFormValues);
  };

  let addFormFields = () => {
    props.setTrustee([
      ...props.trustee,
      {
        additionalLine2: '',
        additionalLine3: '',
        addressBlock: '',
        addressRemarks: '',
        areaCode: '',
        birthPlace: '',
        city: '',
        company_additionalLine2: '',
        company_additionalLine3: '',
        company_areaCode: '',
        company_city: '',
        company_country: '',
        company_countryCode: '',
        company_dateTrustExecuted: '',
        company_email: '',
        company_extension: '',
        company_legalName: '',
        company_phoneNumber: '',
        company_relationship: '',
        company_remarks: '',
        company_phoneRemarks: '',
        company_situsAddress: '',
        company_state: '',
        company_taxIdNumber: '',
        company_trustRelatedNotices: '',
        company_whoPreparedTheTrust: '',
        company_emailRemarks: '',
        company_zipCode: '',
        country: '',
        countryCode: '',
        dateOfBirth: '',
        dateTrustExecuted: '',
        email: '',
        extension: '',
        firstName: '',
        gender: '',
        lastName: '',
        legalName: '',
        middleName: '',
        phone: '',
        phoneNumber: '',
        relationship: '',
        remarks: '',
        phoneRemarks: '',
        situsAddress: '',
        ssn: '',
        state: '',
        suffix: '',
        taxIdNumber: '',
        trustRelatedNotices: '',
        trusteeType: '',
        whoPreparedTheTrust: '',
        zipCode: '',
      },
    ]);
  };

  let removeFormFields = (i, objectId) => {
    let newFormValues = [...props.trustee];
    newFormValues.splice(i, 1);
    props.setTrustee(newFormValues);
    axios
      .post(`/so-api/delete-object/?object_id=${objectId}`)
      .then((response) => {})
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };
  const beneficiaryHandleChange = (i, e) => {
    let newFormValues = [...props.beneficiary];
    newFormValues[i][e.target.name] = e.target.value;
    props.setBeneficiary(newFormValues);
  };
  const beneficiaryAddFormFields = () => {
    props.setBeneficiary([
      ...props.beneficiary,
      {
        beneficiaryName: '',
        beneficiaryRelationship: '',
        ssn: '',
        beneficiaryPercentBenefit: '',
        addressBlock: '',
        addressLine2: '',
        addressLine3: '',
        state: '',
        addressRemarks: '',
        beneficiaryCity: '',
        beneficiaryZipCode: '',
        country: '',
        remarks: '',
        countryCode: '',
        areaCode: '',
        beneficiaryPhoneNumber: '',
        extension: '',
        beneficiaryEmail: '',
      },
    ]);
  };
  const beneficiaryRemoveFields = (i, objectId) => {
    let newFormValues = [...props.beneficiary];
    newFormValues.splice(i, 1);
    props.setBeneficiary(newFormValues);
    axios
      .post(`/so-api/delete-object/?object_id=${objectId}`)
      .then((response) => {})
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };
  return (
    <>
      {props.trustee.map((element, index) => (
        <AccordionAddMoreSection
          key={index}
          index={index}
          accordionHeading={`Trustee ${index + 1}`}
          onDeleteClick={
            index ? () => removeFormFields(index, element?.object_id) : null
          }
        >
          <div className="flex" key={index}>
            <div className="label-value-wrapper layout-2">
              <label className="field-label">Trustee Type</label>

              <div>
                <select
                  name="trusteeType"
                  id="trusteeType"
                  value={element.trusteeType || ''}
                  onChange={(e) => handleChange(index, e)}
                >
                  {trusteeTypeOptions.map((item, index) => {
                    return (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {element.trusteeType === 'company' && (
              <>
                <div className="label-value-wrapper layout-2"></div>
                <div className="label-value-wrapper ltc-text-field layout-2">
                  <label className="field-label">
                    Full Legal Name (as on the Trust)
                  </label>

                  <FormField
                    id={`legalName${index}`}
                    regex="^[a-zA-Z -.,]*$"
                    errorMessage="Please enter a valid name."
                    type="text"
                    name="company_legalName"
                    placeholder="Enter"
                    value={element.company_legalName || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                    validationType="addMoreText"
                  />
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_relationship ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Relationship</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_relationship"
                      value={element.company_relationship || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper no-margin-text-field layout-2">
                  <label className="field-label">Date Trust Executed</label>

                  <FormField
                    type="date"
                    validationType="addMoreDate"
                    name="company_dateTrustExecuted"
                    placeholder="Enter"
                    value={element.company_dateTrustExecuted || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_taxIdNumber ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Tax ID Number</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_taxIdNumber"
                      maxLength={10}
                      value={
                        SetTaxIDSeparator(element.company_taxIdNumber) || ''
                      }
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-1 ${
                    !element.company_situsAddress ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    Situs or Address of the Trust
                  </label>
                  <div>
                    <textarea
                      placeholder="Enter"
                      name="company_situsAddress"
                      value={element.company_situsAddress || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_additionalLine2 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Additional Line 2</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_additionalLine2"
                      value={element.company_additionalLine2 || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_additionalLine3 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Additional Line 3</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_additionalLine3"
                      value={element.company_additionalLine3 || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-3 ${
                    !element.company_city ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">City</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_city"
                      value={element.company_city || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper ltc-text-field layout-3">
                  <label className="field-label">State</label>

                  <FormField
                    id="state"
                    type="select"
                    name="company_state"
                    placeholder="Enter"
                    dropdownType="state"
                    addMoreField={true}
                    value={element.company_state || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>

                <div
                  className={`label-value-wrapper  layout-3 ${
                    !element.company_zipCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Zip Code</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_zipCode"
                      value={element.company_zipCode || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_country ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Country</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_country"
                      value={element.company_country || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-1 ${
                    !element.company_remarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Remarks</label>
                  <div>
                    <textarea
                      type="textarea"
                      placeholder="Enter"
                      name="company_remarks"
                      value={element.company_remarks || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="line-separation layout-1"></div>
                <h3 className="layout-1">
                  Contact Details of Trustee {index + 1}
                </h3>
                <div
                  className={`label-value-wrapper  layout-3 ${
                    !element.company_countryCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Country Code</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_countryCode"
                      value={element.company_countryCode || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-3 ${
                    !element.company_areaCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Area Code</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_areaCode"
                      value={element.company_areaCode || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-3 ${
                    !element.company_phoneNumber ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Phone Number</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_phoneNumber"
                      value={element.company_phoneNumber || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_extension ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Extension</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_extension"
                      value={element.company_extension || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper ltc-text-field layout-2">
                  <label className="field-label">Email</label>
                  <FormField
                    id={`email${index}`}
                    type="email"
                    name="company_email"
                    placeholder="Enter"
                    addMoreField
                    value={element.company_email || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper  layout-1 ${
                    !element.company_emailRemarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Remarks</label>
                  <div>
                    <textarea
                      type="textarea"
                      placeholder="Enter"
                      name="company_emailRemarks"
                      value={element.company_emailRemarks || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_whoPreparedTheTrust ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Who Prepared the Trust?</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_whoPreparedTheTrust"
                      value={element.company_whoPreparedTheTrust || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.company_trustRelatedNotices ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    Which Trustee should receive Trust-related notices?
                  </label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="company_trustRelatedNotices"
                      value={element.company_trustRelatedNotices || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-1 ${
                    !element.company_phoneRemarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Remarks</label>
                  <div>
                    <textarea
                      type="textarea"
                      placeholder="Enter"
                      name="company_phoneRemarks"
                      value={element.company_phoneRemarks || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
              </>
            )}
            {element.trusteeType === 'individual' && (
              <>
                <div className="label-value-wrapper layout-2"></div>
                <div className="label-value-wrapper layout-5">
                  <label className="field-label">Title</label>

                  <div>
                    <select
                      name="title"
                      id="title"
                      defaultValue={element.title || ''}
                      onChange={(e) => handleChange(index, e)}
                    >
                      {selectOptions.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="label-value-wrapper ltc-text-field layout-5">
                  <label className="field-label">First Name</label>

                  <FormField
                    id={`firstName${index}`}
                    regex="^[a-zA-Z -.,]*$"
                    validationType="addMoreText"
                    errorMessage="Please enter a valid name."
                    type="text"
                    name="firstName"
                    placeholder="Enter"
                    value={element.firstName || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="label-value-wrapper ltc-text-field layout-5">
                  <label className="field-label">Middle Name</label>

                  <FormField
                    id={`middleName${index}`}
                    regex="^[a-zA-Z -.,]*$"
                    validationType="addMoreText"
                    errorMessage="Please enter a valid name."
                    type="text"
                    name="middleName"
                    placeholder="Enter"
                    value={element.middleName || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="label-value-wrapper ltc-text-field layout-5">
                  <label className="field-label">Last Name</label>

                  <FormField
                    id={`lastName${index}`}
                    regex="^[a-zA-Z -.,]*$"
                    validationType="addMoreText"
                    errorMessage="Please enter a valid name."
                    type="text"
                    name="lastName"
                    placeholder="Enter"
                    value={element.lastName || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="label-value-wrapper ltc-text-field layout-5">
                  <label className="field-label">Suffix</label>

                  <FormField
                    id={`suffix${index}`}
                    regex="^[a-zA-Z -.,]*$"
                    validationType="addMoreText"
                    errorMessage="Please enter a valid name."
                    type="text"
                    name="suffix"
                    placeholder="Enter"
                    value={element.suffix || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.relationship ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Relationship</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="relationship"
                      value={element.relationship || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper ltc-text-field layout-2">
                  <label className="field-label">Social Security Number</label>

                  <FormField
                    id={`ssn${index}`}
                    regex="^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$"
                    errorMessage="Please enter a valid value (ex: 123-45-6789)."
                    type="text"
                    name="ssn"
                    validationType="ssn"
                    placeholder="Enter"
                    value={element.ssn || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="label-value-wrapper no-margin-text-field layout-2">
                  <label className="field-label">Date of Birth</label>

                  <FormField
                    type="date"
                    name="dateOfBirth"
                    placeholder="Enter"
                    validationType="addMoreDate"
                    value={element.dateOfBirth || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">Gender</label>
                  <div>
                    <select
                      name="gender"
                      id="gender"
                      defaultValue={element.gender || ''}
                      onChange={(e) => handleChange(index, e)}
                    >
                      {genderList.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.birthPlace ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Birth Place</label>
                  <div>
                    <input
                      type="text"
                      name="birthPlace"
                      placeholder="Enter"
                      value={element.birthPlace || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-1 ${
                    !element.addressBlock ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Address Block</label>
                  <div>
                    <textarea
                      placeholder="Enter"
                      name="addressBlock"
                      value={element.addressBlock || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.additionalLine2 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Additional Line 2</label>
                  <div>
                    <input
                      type="text"
                      name="additionalLine2"
                      placeholder="Enter"
                      value={element.additionalLine2 || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.additionalLine3 ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Additional Line 3</label>
                  <div>
                    <input
                      type="text"
                      name="additionalLine3"
                      placeholder="Enter"
                      value={element.additionalLine3 || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.city ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">City</label>
                  <div>
                    <input
                      type="text"
                      name="city"
                      placeholder="Enter"
                      value={element.city || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper ltc-text-field layout-2">
                  <label className="field-label">State</label>

                  <FormField
                    id="state"
                    type="select"
                    name="state"
                    placeholder="Enter"
                    dropdownType="state"
                    addMoreField={true}
                    value={element.state || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>

                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.zipCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Zip Code</label>
                  <div>
                    <input
                      type="text"
                      name="zipCode"
                      placeholder="Enter"
                      value={element.zipCode || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.country ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Country</label>
                  <div>
                    <input
                      type="text"
                      name="country"
                      placeholder="Enter"
                      value={element.country || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-1 ${
                    !element.remarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Remarks</label>
                  <div>
                    <textarea
                      placeholder="Enter"
                      name="remarks"
                      defaultValue={element.remarks || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="line-separation layout-1"></div>
                <h3 className="layout-1">
                  Contact Details of Trustee {index + 1}
                </h3>
                <div
                  className={`label-value-wrapper  layout-3 ${
                    !element.countryCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Country Code</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="countryCode"
                      value={element.countryCode || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-3 ${
                    !element.areaCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Area Code</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="areaCode"
                      value={element.areaCode || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !element.phone ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Phone Number</label>
                  <div>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Enter"
                      value={element.phone || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.extension ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Extension</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="extension"
                      value={element.extension || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper ltc-text-field layout-2">
                  <label className="field-label">Email</label>

                  <FormField
                    id={`email${index}`}
                    type="email"
                    name="email"
                    placeholder="Enter"
                    addMoreField
                    value={element.email || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  className={`label-value-wrapper  layout-1 ${
                    !element.phoneRemarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Remarks</label>
                  <div>
                    <textarea
                      placeholder="Enter"
                      name="phoneRemarks"
                      defaultValue={element.phoneRemarks || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>

                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.whoPreparedTheTrust ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Who Prepared the Trust?</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="whoPreparedTheTrust"
                      value={element.whoPreparedTheTrust || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  layout-2 ${
                    !element.trustRelatedNotices ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    Which Trustee should receive Trust-related notices?
                  </label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="trustRelatedNotices"
                      value={element.trustRelatedNotices || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-1 ${
                    !element.addressRemarks ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">Remarks</label>
                  <div>
                    <textarea
                      placeholder="Enter"
                      name="addressRemarks"
                      value={element.addressRemarks || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </AccordionAddMoreSection>
      ))}
      <div>
        <span className="addFieldButton" onClick={() => addFormFields()}>
          ADD MORE TRUSTEE
        </span>
      </div>

      {props?.beneficiary?.map((item, i) => (
        <AccordionAddMoreSection
          key={i}
          index={i}
          accordionHeading={`Trust Beneficiary ${i + 1}`}
          onDeleteClick={
            i ? () => beneficiaryRemoveFields(i, item?.object_id) : null
          }
        >
          <div className="flex" style={{ width: '100%' }} key={i}>
            <div className="label-value-wrapper ltc-text-field layout-2">
              <label className="field-label">
                Full legal Name (as on the Trust)
              </label>
              <FormField
                id={`beneficiaryName${i}`}
                regex="^[a-zA-Z -.,]*$"
                errorMessage="Please enter a valid name."
                type="text"
                name="beneficiaryName"
                placeholder="Enter"
                value={item.beneficiaryName || ''}
                onChangeHandler={(e) => beneficiaryHandleChange(i, e)}
                validationType="name"
                addMoreField
              />
            </div>
            <div
              className={`label-value-wrapper  layout-2 ${
                !item.beneficiaryRelationship ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Relationship</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="beneficiaryRelationship"
                  value={item.beneficiaryRelationship || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div className="label-value-wrapper ltc-text-field layout-2">
              <label className="field-label">Social Security Number</label>

              <FormField
                id={`ssn${i}`}
                regex="^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$"
                errorMessage="Please enter a valid value (ex: 123-45-6789)."
                type="text"
                name="ssn"
                placeholder="Enter"
                validationType="ssn"
                value={item.ssn || ''}
                onChangeHandler={(e) => beneficiaryHandleChange(i, e)}
              />
            </div>
            <div
              className={`label-value-wrapper  layout-2 ${
                !item.beneficiaryPercentBenefit ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Percentage Benefit</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="beneficiaryPercentBenefit"
                  value={item.beneficiaryPercentBenefit || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-1 ${
                !item.addressBlock ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Address Block</label>
              <div>
                <textarea
                  placeholder="Enter"
                  name="addressBlock"
                  value={item.addressBlock || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-2 ${
                !item.additionalLine3 ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Additional Line 2</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="additionalLine2"
                  value={item.additionalLine2 || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-2 ${
                !item.additionalLine3 ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Additional Line 3</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="additionalLine3"
                  value={item.additionalLine3 || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-3 ${
                !item.beneficiaryCity ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">City</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="beneficiaryCity"
                  value={item.beneficiaryCity || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div className="label-value-wrapper ltc-text-field layout-3">
              <label className="field-label">State</label>

              <FormField
                id="state"
                type="select"
                name="beneficiaryState"
                placeholder="Enter"
                dropdownType="state"
                addMoreField={true}
                value={item.beneficiaryState || ''}
                onChangeHandler={(e) => beneficiaryHandleChange(i, e)}
              />
            </div>

            <div
              className={`label-value-wrapper  layout-3 ${
                !item.beneficiaryZipCode ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Zip Code</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="beneficiaryZipCode"
                  value={item.beneficiaryZipCode || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-2 ${
                !item.country ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Country</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="country"
                  value={item.country || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-1 ${
                !item.addressRemarks ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Remarks</label>
              <div>
                <textarea
                  placeholder="Enter"
                  name="addressRemarks"
                  defaultValue={item.addressRemarks || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div className="line-separation layout-1"></div>
            <h3 className="layout-1">
              Contact Details of Trust Beneficiary {i + 1}
            </h3>
            <div
              className={`label-value-wrapper  layout-3 ${
                !item.countryCode ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Country Code</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="countryCode"
                  value={item.countryCode || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-3 ${
                !item.areaCode ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Area Code</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="areaCode"
                  value={item.areaCode || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-3 ${
                !item.beneficiaryPhoneNumber ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Phone Number</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="beneficiaryPhoneNumber"
                  value={item.beneficiaryPhoneNumber || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div
              className={`label-value-wrapper  layout-2 ${
                !item.extension ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Extension</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="extension"
                  value={item.extension || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
            <div className="label-value-wrapper ltc-text-field layout-2">
              <label className="field-label">Email</label>

              <FormField
                id={`email${i}`}
                type="email"
                name="beneficiaryEmail"
                placeholder="Enter"
                addMoreField
                value={item.beneficiaryEmail || ''}
                onChangeHandler={(e) => beneficiaryHandleChange(i, e)}
              />
            </div>
            <div
              className={`label-value-wrapper  layout-1 ${
                !item.remarks ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">Remarks</label>
              <div>
                <textarea
                  placeholder="Enter"
                  name="remarks"
                  defaultValue={item.remarks || ''}
                  onChange={(e) => beneficiaryHandleChange(i, e)}
                />
              </div>
            </div>
          </div>
        </AccordionAddMoreSection>
      ))}
      <div>
        <span
          className="addFieldButton"
          onClick={() => beneficiaryAddFormFields()}
        >
          ADD MORE TRUST BENEFICIARY
        </span>
      </div>
    </>
  );
};

export default TrusteeSection;
