// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthorized-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unauthorized-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 245px;
  background-color: #ffffff;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000000b;
  border-radius: 12px;
  opacity: 1;
}
.unauthorized-image-container {
  margin-top: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unauthorized-image-container p {
  font-size: 24px;
  font-weight: 500;
  margin-left: 16px;
}
.unauthorized-sub-heading {
  margin-top: 23px;
}
.unauthorized-sub-heading p {
  font-size: 16px;
}

.administrator-text {
  margin-top: 5px;
}
.unauthorized-contact {
  font-size: 14px;
  margin: 23px;
}
.unauthorized-contact-phone {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/unauthorized/unauthorized.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,+CAA+C;EAC/C,kCAAkC;EAClC,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,eAAe;AACjB","sourcesContent":[".unauthorized-container {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.unauthorized-box {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 400px;\n  height: 245px;\n  background-color: #ffffff;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  box-shadow: 0px 3px 10px #0000000b;\n  border-radius: 12px;\n  opacity: 1;\n}\n.unauthorized-image-container {\n  margin-top: 23px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.unauthorized-image-container p {\n  font-size: 24px;\n  font-weight: 500;\n  margin-left: 16px;\n}\n.unauthorized-sub-heading {\n  margin-top: 23px;\n}\n.unauthorized-sub-heading p {\n  font-size: 16px;\n}\n\n.administrator-text {\n  margin-top: 5px;\n}\n.unauthorized-contact {\n  font-size: 14px;\n  margin: 23px;\n}\n.unauthorized-contact-phone {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
