// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.businessInsurance__radioTabsWrapper {
  justify-content: left;
}
.businessInsurance__radioTabsWrapper input[type="radio"]:checked ~ span {
  color: #0177b5;
}
.businessInsurance__radioTabs input[type="radio"] {
  margin-left: 0;
  margin-right: 7px;
}
.businessInsurance__radioTabs {
  margin-bottom: 10px;
  margin-right: 25px;
}
.businessInsurance__radioTabs label {
  cursor: pointer;
  display: inline-block;
}
.businessInsurance__sectionHeading {
  color: #000;
  font-weight: 400;
  line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/businessInsurance/businessInsurance.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".businessInsurance__radioTabsWrapper {\n  justify-content: left;\n}\n.businessInsurance__radioTabsWrapper input[type=\"radio\"]:checked ~ span {\n  color: #0177b5;\n}\n.businessInsurance__radioTabs input[type=\"radio\"] {\n  margin-left: 0;\n  margin-right: 7px;\n}\n.businessInsurance__radioTabs {\n  margin-bottom: 10px;\n  margin-right: 25px;\n}\n.businessInsurance__radioTabs label {\n  cursor: pointer;\n  display: inline-block;\n}\n.businessInsurance__sectionHeading {\n  color: #000;\n  font-weight: 400;\n  line-height: 1.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
