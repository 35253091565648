import { useEffect, useState } from 'react';
import './verifyMapping.css';
import Footer from '../../../footer';
import Header from '../../../header';
import CarrierBreadCrumb from '../carrierBreadCrumb';
import LockIcon from '../../../../assets/images/verifyMapping-lockIcon.svg';
import UnlockIcon from '../../../../assets/images/verifyMapping-unlockIcon.svg';
import { verifyMappingAction } from '../../../../actions/verifyMappingAction';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import VerifyMappingFields from './VerifyMappingFields';
import { useToast } from '../../../notifications/ToastContext';
import Loader from '../../../loader';

const VerifyMapping = () => {
  const pageData = useSelector((state) => state.verifyMappingData);
  const { loading, formData } = pageData;
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const uploaded_id = searchParams.get('upload_id');
  const [lockFields, setLockFields] = useState(true);
  const [lockFieldDetails, setLockFieldDetails] = useState({});
  const [disableLockIcon, setDisableLockIcon] = useState(false);
  const [dropdownData, setdropdownData] = useState([]);
  const [oldKeysData, setOldKeysData] = useState([]);
  const [newKeysData, setNewKeysData] = useState([]);

  const [validPagesList, setValidPagesList] = useState([]);
  const [validPagesIndexList, setValidPagesIndexList] = useState([]);
  const [paginationFirstPage, setPaginationFirstPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [pageNumbers, setPageNumbers] = useState([]);

  const [deletedIsCopied, setDeleteIsCopied] = useState(false);
  const [deletedIsCopiedId, setDeleteIsCopiedId] = useState(null);

  const { addToast } = useToast();
  const handleShowToast = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'success',
      message: message,
    };
    addToast(newToast);
  };

  const handleShowError = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'error',
      message: message,
    };
    addToast(newToast);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(verifyMappingAction(uploaded_id));
  }, [dispatch, uploaded_id]);

  useEffect(() => {
    setTotalPages(formData?.metadata?.last_page);
    setPageNumbers(formData?.metadata?.pages_available);
    setValidPagesList(formData?.metadata?.pages_available);
    setValidPagesIndexList(
      formData?.metadata?.pages_available?.map((item) => item - 1)
    );
    setPaginationFirstPage(formData?.metadata?.pages_available[0]);
    if (!currentPage) {
      setCurrentPage(formData?.metadata?.pages_available[0]);
    }
    if (!currentPageIndex) {
      setCurrentPageIndex(formData?.metadata?.pages_available[0] - 1);
    }
    setTimeout(() => {
      document.getElementById('pagination--item__active')?.scrollIntoView(); //To make current active pagination number to be visible in view
      document
        .getElementById('pagination--item__active')
        ?.nextElementSibling?.scrollIntoView(); //To make current active pagination number  sibling to be visible in view
    }, []);
    if (
      formData?.lock_details &&
      formData?.lock_details[0]?.email === localStorage.getItem('userEmail')
    ) {
      setLockFields(false);
    } else {
      setLockFields(true);
    }
    setLockFieldDetails(formData?.lock_details && formData?.lock_details[0]); // Setting lock field details

    if (formData?.lock_details) {
      if (!formData?.lock_details[0]?.email) {
        setDisableLockIcon(false);
      } else {
        if (
          formData?.lock_details[0]?.email === localStorage.getItem('userEmail')
        ) {
          setDisableLockIcon(false);
        } else {
          setDisableLockIcon(true);
        }
      }
    }
  }, [formData]);

  useEffect(() => {
    setShowLoader(true);
    if (formData?.metadata?.form_type)
      axios({
        url: '/template-flatten-data/',
        method: 'POST',
        data: {
          form_type: formData?.metadata?.form_type,
          nested: true,
        },
      })
        .then((response) => {
          setShowLoader(false);
          setdropdownData(response?.data);
        })
        .catch(function (error) {
          setShowLoader(false);
        });
  }, [formData?.metadata?.form_type]);

  useEffect(() => {
    if (deletedIsCopied && deletedIsCopiedId) {
      setShowLoader(true);
      axios({
        url: `/delete-copied-field?id=${deletedIsCopiedId}`,
        method: 'POST',
      })
        .then((response) => {
          console.log('deleted');
          setShowLoader(false);
          dispatch(verifyMappingAction(uploaded_id));
          setDeleteIsCopied(false);
          setDeleteIsCopiedId(null);
        })
        .catch(function (error) {
          setDeleteIsCopied(false);
          setDeleteIsCopiedId(null);
          setShowLoader(false);
          handleShowError(error?.response?.data?.message);
        });
    }
  }, [deletedIsCopied, deletedIsCopiedId]);

  const onPrevPageClick = () => {
    if (currentPage > paginationFirstPage) {
      dispatch(verifyMappingAction(uploaded_id)); //Fetch latest Data
      onConfirmMapping(false); /**Auto Save Data on Page Navigaton */
      setCurrentPage((prevPage) => {
        let index = validPagesList?.findIndex((item) => item === prevPage) - 1;
        return validPagesList[index];
      });

      setCurrentPageIndex((prevPage) => {
        let index =
          validPagesIndexList?.findIndex((item) => item === prevPage) - 1;
        return validPagesIndexList[index];
      }); /**  Setting index of the page that is to be displayed */
    }
  };
  const onNextPageClick = () => {
    if (currentPage < totalPages) {
      dispatch(verifyMappingAction(uploaded_id)); //Fetch latest Data
      onConfirmMapping(false); /**Auto Save Data on Page Navigaton */
      setCurrentPage((prevPage) => {
        let index = validPagesList?.findIndex((item) => item === prevPage) + 1;
        return validPagesList[index];
      });

      setCurrentPageIndex((prevPage) => {
        let index =
          validPagesIndexList?.findIndex((item) => item === prevPage) + 1;
        return validPagesIndexList[index];
      }); /**  Setting index of the page that is to be displayed */
    }
  };
  const onPageNumberClick = (number) => {
    if (number >= paginationFirstPage && number <= totalPages) {
      dispatch(verifyMappingAction(uploaded_id)); //Fetch latest Data
      onConfirmMapping(false); /**Auto Save Data on Page Navigaton */
      setCurrentPage(number);
      setCurrentPageIndex(
        number - 1
      ); /**  Setting index of the page that is to be displayed */
    }
  };

  const onLockIconClick = (e) => {
    let apiParam;
    if (lockFieldDetails?.email) {
      if (lockFieldDetails?.email === localStorage.getItem('userEmail')) {
        apiParam = 'unlock';
      }
    } else {
      apiParam = 'lock';
    }

    setShowLoader(true);
    axios({
      url: `/mapping_verification/${apiParam}`,
      method: 'POST',
      data: { upload_id: parseInt(uploaded_id) },
    })
      .then((response) => {
        setShowLoader(false);
        dispatch(verifyMappingAction(uploaded_id));
      })
      .catch(function (error) {
        setShowLoader(false);
      });
  };

  const onConfirmMapping = (confirmMapping) => {
    setShowLoader(true);
    const payload = {
      upload_id: parseInt(uploaded_id),
      confirm_mapping: confirmMapping,
      keys: oldKeysData,
      new_keys: newKeysData,
    };

    axios({
      url: '/save-mapping ',
      method: 'POST',
      data: payload,
    })
      .then((response) => {
        setShowLoader(false);
        handleShowToast(
          confirmMapping
            ? 'Confirm Mapping Successful'
            : 'Data Saved Successfully'
        );
        setOldKeysData([]);
        setNewKeysData([]);
        dispatch(verifyMappingAction(uploaded_id)); //Fetch latest Data
      })
      .catch(function (error) {
        setShowLoader(false);
        handleShowError(
          'Confirm mapping Failed!! Please contact administrator'
        );
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Header />
          <div id="contents">
            <div className="verifyMapping-wrapper">
              <CarrierBreadCrumb
                currentPageName={'CPW Mapping'}
                showApplicationUpload={true}
                showFormAdministration={true}
                onClickHandler={() => onConfirmMapping(false)}
              />
              <div className="verifyMapping--contents">
                <div className="verifyMapping--headingButtonWrapper">
                  <div className="verifyMapping--headingLockUnlock">
                    <h2>Form - CPW Mapping</h2>
                    <div
                      className={`verifyMapping--lockUnlock ${
                        lockFields
                          ? 'verifyMapping--lock'
                          : 'verifyMapping--unlock'
                      } ${
                        disableLockIcon && 'verifyMapping--lockUnlock__disable'
                      }`}
                      onClick={(e) => !disableLockIcon && onLockIconClick(e)}
                    >
                      <img
                        src={lockFields ? LockIcon : UnlockIcon}
                        alt={lockFields ? 'Lock Icon' : 'Unlock Icon'}
                      />
                      {lockFieldDetails?.email !==
                        localStorage.getItem('userEmail') && (
                        <div className="verifyMapping--toolTip">
                          <div className="verifyMapping--toolTipContent">
                            {`${
                              !lockFieldDetails?.email
                                ? 'Please click to unlock mapping'
                                : 'This mapping is being edited by ' +
                                  lockFieldDetails?.name
                            }`}
                          </div>
                          <span className="verifyMapping--toolTipArrow"></span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      className="button verifyMapping--actionBtn"
                      onClick={() => onConfirmMapping(true)}
                    >
                      Confirm Mapping
                    </button>
                  </div>
                </div>
                <div className="verifyMapping--carrierName">
                  <h2>{formData?.from_name}</h2>
                </div>
                <div className="verifyMapping--carrierFieldsWrapper">
                  {formData?.pages &&
                    dropdownData?.length > 0 &&
                    formData?.pages[currentPageIndex]?.keys?.map(
                      (item, index) => {
                        return (
                          <div key={index} className="line-separation">
                            <VerifyMappingFields
                              keyId={item.id}
                              carrierFieldValue={item?.carrier_field}
                              cpwSectionValue={item?.cpw_section}
                              cpwFieldValue={item.cpw_field}
                              contextValue={item.context}
                              isCopied={item.is_copied}
                              setDeleteIsCopied={setDeleteIsCopied}
                              setDeleteIsCopiedId={setDeleteIsCopiedId}
                              dropdownData={
                                dropdownData?.length > 0 && dropdownData
                              }
                              lockFields={lockFields}
                              oldKeysData={oldKeysData}
                              setOldKeysData={setOldKeysData}
                              newKeysData={newKeysData}
                              setNewKeysData={setNewKeysData}
                            />
                          </div>
                        );
                      }
                    )}
                  {formData?.pages?.length === 0 && <p>No Data Available</p>}
                </div>
                {totalPages > 1 && (
                  <div className="pagination--wrapper">
                    {' '}
                    <p className="pagination--info">
                      Page {currentPage} of {totalPages}
                    </p>
                    <div className="pagination">
                      <div
                        className={`pagination--prev ${
                          currentPage === paginationFirstPage ? 'disable' : ''
                        }`}
                      >
                        <span
                          className="pagination--link"
                          onClick={onPrevPageClick}
                        >
                          Prev
                        </span>
                      </div>
                      <div className="pagination--numbersWrapper">
                        {pageNumbers?.map((number) => {
                          return (
                            <div
                              className={`pagination--number ${
                                currentPage === number
                                  ? 'pagination--item__active'
                                  : ''
                              }`}
                              id={
                                currentPage === number
                                  ? 'pagination--item__active'
                                  : ''
                              }
                              key={number}
                            >
                              <span
                                className="pagination--link"
                                onClick={() => onPageNumberClick(number)}
                              >
                                {number}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className={`pagination--next ${
                          currentPage === totalPages ? 'disable' : ''
                        }`}
                      >
                        <span
                          className="pagination--link"
                          onClick={onNextPageClick}
                        >
                          Next
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
          {showLoader && <Loader />}
        </>
      )}
    </>
  );
};
export default VerifyMapping;
