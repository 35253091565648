import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonTextArea from '../../../ui/commonTextArea';
import Checkboxes from '../../../ui/formField/Checkboxes';
import LabelRadioButtons from '../../../ui/labelRadioButtons';
import RadioWithHeading from '../../../ui/radioWithHeading';

const ActivitiesDailyLiving = (props) => {
  const [wheelchairQuestion, setWheelchairQuestion] = useState({});
  const [wheelchairTextAreaValue, setWheelchairTextAreaValue] = useState({});
  const [driveQuestion, setDriveQuestion] = useState({});
  const [driveTextAreaValue, setDriveTextAreaValue] = useState({});
  const [historyOfFallsQuestion, setHistoryOfFallsQuestion] = useState({});
  const [historyOfFallsTextAreaValue, setHistoryOfFallsTextAreaValue] =
    useState({});
  const [exerciseQuestion, setExerciseQuestion] = useState({});
  const [exerciseTextAreaValue, setExerciseTextAreaValue] = useState({});
  const [activitiesCheckBoxs, setActivitiesCheckBoxs] = useState([]);
  const [activitiesTextAreaValue, setActivitiesTextAreaValue] = useState({});
  const [proposedInsuredTextAreaValue, setProposedInsuredTextAreaValue] =
    useState({});
  const longTermCareData = useSelector((state) => state.longTermCareData);
  const { loading, error, ltcData } = longTermCareData;

  const onWheelchairRadioChange = (e) => {
    setWheelchairQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onWheelchairTextChange = (e) => {
    setWheelchairTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onDriveRadioChange = (e) => {
    setDriveQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onDriveTextChange = (e) => {
    setDriveTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const onHistoryOfFallsRadioChange = (e) => {
    setHistoryOfFallsQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onHistoryOfFallsTextChange = (e) => {
    setHistoryOfFallsTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const onExerciseRadioChange = (e) => {
    setExerciseQuestion(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onExerciseTextChange = (e) => {
    setExerciseTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const handleCheckBox = (event) => {
    let updatedList = [...activitiesCheckBoxs];
    if (event.target.checked) {
      updatedList = [...activitiesCheckBoxs, event.target.value];
    } else {
      updatedList.splice(activitiesCheckBoxs.indexOf(event.target.value), 1);
    }
    setActivitiesCheckBoxs(updatedList);
  };
  const onActivitiesTextChange = (e) => {
    setActivitiesTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const onDosagesTextChange = (e) => {
    setProposedInsuredTextAreaValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  useEffect(() => {
    const initialWheelchairValue =
      ltcData?.data?.wheelchairQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialWheelchairValue) {
      const key = initialWheelchairValue['id'];
      const value = initialWheelchairValue['value'];
      setWheelchairQuestion({ [key]: value });
    }
    const initialDriveValue = ltcData?.data?.driveQuestion?.radioOptions?.find(
      (item) => item.defaultChecked
    );
    if (initialDriveValue) {
      const key = initialDriveValue['id'];
      const value = initialDriveValue['value'];
      setDriveQuestion({ [key]: value });
    }
    const initialhistoryOfFallsValue =
      ltcData?.data?.historyOfFallsQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialhistoryOfFallsValue) {
      const key = initialhistoryOfFallsValue['id'];
      const value = initialhistoryOfFallsValue['value'];
      setHistoryOfFallsQuestion({ [key]: value });
    }
    const initialExerciseValue =
      ltcData?.data?.exerciseQuestion?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (initialExerciseValue) {
      const key = initialExerciseValue['id'];
      const value = initialExerciseValue['value'];
      setExerciseQuestion({ [key]: value });
    }
    let checkboxDefaltValuearr = [];
    ltcData?.data?.activitiesCheckBoxs?.option?.map((item) => {
      if (item.defaultChecked) {
        checkboxDefaltValuearr.push(item.value);
      }
    });
    setActivitiesCheckBoxs(checkboxDefaltValuearr);
  }, [ltcData?.data]);
  useEffect(() => {
    props.setActivitiesDailyLivingData({
      wheelchairQuestion,
      wheelchairTextAreaValue,
      driveQuestion,
      driveTextAreaValue,
      historyOfFallsQuestion,
      historyOfFallsTextAreaValue,
      exerciseQuestion,
      exerciseTextAreaValue,
      activitiesCheckBoxs,
      activitiesTextAreaValue,
      proposedInsuredTextAreaValue,
    });
  }, [
    wheelchairQuestion,
    wheelchairTextAreaValue,
    driveQuestion,
    driveTextAreaValue,
    historyOfFallsQuestion,
    historyOfFallsTextAreaValue,
    exerciseQuestion,
    exerciseTextAreaValue,
    activitiesCheckBoxs,
    activitiesTextAreaValue,
    proposedInsuredTextAreaValue,
  ]);
  return (
    <>
      {ltcData?.data && (
        <LabelRadioButtons
          label={ltcData?.data?.wheelchairQuestion?.label}
          options={ltcData?.data?.wheelchairQuestion?.radioOptions}
          id={ltcData?.data?.wheelchairQuestion?.id}
          onChangeHandler={onWheelchairRadioChange}
        />
      )}
      {wheelchairQuestion['wheelchairQuestion-yes'] === 'yes' && (
        <CommonTextArea
          fieldData={ltcData?.data && ltcData?.data?.wheelchairTextAreaValue}
          onChangeHandler={onWheelchairTextChange}
        />
      )}
      {ltcData?.data && (
        <LabelRadioButtons
          label={ltcData?.data?.driveQuestion?.label}
          options={ltcData?.data?.driveQuestion?.radioOptions}
          id={ltcData?.data?.driveQuestion?.id}
          onChangeHandler={onDriveRadioChange}
        />
      )}
      {driveQuestion['driveQuestion-yes'] === 'yes' && (
        <CommonTextArea
          fieldData={ltcData?.data && ltcData?.data?.driveTextAreaValue}
          onChangeHandler={onDriveTextChange}
        />
      )}
      {ltcData?.data && (
        <LabelRadioButtons
          label={ltcData?.data?.historyOfFallsQuestion?.label}
          options={ltcData?.data?.historyOfFallsQuestion?.radioOptions}
          id={ltcData?.data?.historyOfFallsQuestion?.id}
          onChangeHandler={onHistoryOfFallsRadioChange}
        />
      )}
      {historyOfFallsQuestion['historyOfFallsQuestion-yes'] === 'yes' && (
        <CommonTextArea
          fieldData={
            ltcData?.data && ltcData?.data?.historyOfFallsTextAreaValue
          }
          onChangeHandler={onHistoryOfFallsTextChange}
        />
      )}
      {ltcData?.data && (
        <LabelRadioButtons
          label={ltcData?.data?.exerciseQuestion?.label}
          options={ltcData?.data?.exerciseQuestion?.radioOptions}
          id={ltcData?.data?.exerciseQuestion?.id}
          onChangeHandler={onExerciseRadioChange}
        />
      )}
      {exerciseQuestion['exerciseQuestion-yes'] === 'yes' && (
        <CommonTextArea
          fieldData={ltcData?.data && ltcData?.data?.exerciseTextAreaValue}
          onChangeHandler={onExerciseTextChange}
        />
      )}
      {ltcData?.data && (
        <div className="ltc-checkboxes-container">
          <p className="ltc-label">
            {ltcData?.data?.activitiesCheckBoxs?.label}
          </p>
          {ltcData?.data?.activitiesCheckBoxs?.option?.map((option, index) => {
            return (
              <Checkboxes
                key={index}
                name={option.name}
                value={option.value}
                type={option.type}
                label={option.label}
                defaultChecked={option.defaultChecked}
                id={option.id}
                onChangeHandler={handleCheckBox}
              />
            );
          })}
          {activitiesCheckBoxs.length > 0 && (
            <CommonTextArea
              fieldData={ltcData?.data?.activitiesTextAreaValue}
              onChangeHandler={onActivitiesTextChange}
            />
          )}

          <CommonTextArea
            fieldData={ltcData?.data?.proposedInsuredTextAreaValue}
            onChangeHandler={onDosagesTextChange}
          />
        </div>
      )}
    </>
  );
};

export default ActivitiesDailyLiving;
