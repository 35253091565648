import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '../../../ui/formField';

const BusinessFinance = (props) => {
  const [formConfig, setFormConfig] = useState({});
  const pageData = useSelector((state) => state.businessInsuranceData);
  const { loading, error, formData } = pageData;

  useEffect(() => {
    setFormConfig(formData?.data);
  }, [formData?.data]);

  const businessFinanceHeader = (
    <tr className="tableAmount__header">
      <th className="tableAmount__rowLabel">
        {formConfig?.businessFinanceHeader?.label}
      </th>
      <th className="tableAmount__rowCurryear">
        {formConfig?.businessFinanceHeader?.currYear}
      </th>
      <th className="tableAmount__rowPrevYear">
        {formConfig?.businessFinanceHeader?.prevYear}
      </th>
      <th className="tableAmount__rowRemark">
        {formConfig?.businessFinanceHeader?.remarks}
      </th>
    </tr>
  );
  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };
  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };
  const updateBusinessTableAmount = (id, field, value) => {
    let newState = { ...formConfig };
    if (field != 'remarks' && isNaN(value)) return;
    switch (field) {
      case 'currYear':
        newState.businessFinanceInfo[
          newState.businessFinanceInfo.findIndex((obj) => obj.id == id)
        ].currYear = value;
        break;
      case 'prevYear':
        newState.businessFinanceInfo[
          newState.businessFinanceInfo.findIndex((obj) => obj.id == id)
        ].prevYear = value;
        break;
      case 'remarks':
        newState.businessFinanceInfo[
          newState.businessFinanceInfo.findIndex((obj) => obj.id == id)
        ].remarks = value;
        break;

      default:
      // do nothing
    }
    setFormConfig(newState);
    props.setFinancialFigure(formConfig.businessFinanceInfo);
  };

  const businessFinanceFields = formConfig?.businessFinanceInfo?.map(
    (item, index) => {
      return (
        <tr key={index} className="">
          <td className="tableAmount__label">
            <span className="row__label">{item.label}</span>
          </td>
          <td className="tableAmount__currYear">
            <span
              className={`label-value-wrapper  ${
                !item.currYear ? 'value--empty' : ''
              }`}
            >
              <input
                type="text"
                value={addCommaSeparator(item.currYear)}
                name="currYear"
                onChange={(e) => {
                  updateBusinessTableAmount(
                    item.id,
                    'currYear',
                    parseFloat(
                      e.target.value ? removeCommaSeparator(e.target.value) : 0
                    )
                  );
                }}
              />
            </span>
          </td>
          <td className="tableAmount__prevYear">
            <span
              className={`label-value-wrapper  ${
                !item.prevYear ? 'value--empty' : ''
              }`}
            >
              <input
                type="text"
                value={addCommaSeparator(item.prevYear)}
                name="prevYear"
                onChange={(e) => {
                  updateBusinessTableAmount(
                    item.id,
                    'prevYear',
                    parseFloat(
                      e.target.value ? removeCommaSeparator(e.target.value) : 0
                    )
                  );
                }}
              />
            </span>
          </td>
          <td className="tableAmount__remark">
            <span
              className={`label-value-wrapper  ${
                !item.remarks ? 'value--empty' : ''
              }`}
            >
              <input
                type="text"
                value={item.remarks}
                name="remarks"
                placeholder="Enter"
                onChange={(e) => {
                  updateBusinessTableAmount(item.id, 'remarks', e.target.value);
                }}
              />
            </span>
          </td>
        </tr>
      );
    }
  );

  return (
    <>
      <div className="line-separation">
        <table className="tableAmount">
          <tbody>
            {businessFinanceHeader && businessFinanceHeader}
            {businessFinanceFields && businessFinanceFields}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default BusinessFinance;
