// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion__wrapper {
  width: 100%;
}
.accordion__header {
  padding: 20px 20px 15px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  display: flex;
  -webkit-display: flex;
  justify-content: space-between;
  background: #fff;
  position: relative;
  align-items: center;
}
.accordion__sub_header {
  font-size: 12px;
  font-weight: 400;
  color: red;
}
.accordion__content {
  height: 0;
  overflow: hidden;
  padding: 10px 10px 0;
  transition: height 0.4s ease;
}
.accordion__content.open {
  height: auto;
}
.accordion__header .accordionClickField {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  cursor: pointer;
}
.accordion__accordionHeading {
  font-size: 16px;
  color: #222222;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 1.3;
}
.accordion__header .icon-delete {
  position: relative;
  z-index: 1;
  top: -2px;
}
.heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/accordionSingleSection/AccordionSingleSection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,qBAAqB;EACrB,8BAA8B;EAC9B,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,SAAS;EACT,gBAAgB;EAChB,oBAAoB;EACpB,4BAA4B;AAC9B;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,OAAO;EACP,MAAM;EACN,eAAe;AACjB;AACA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".accordion__wrapper {\n  width: 100%;\n}\n.accordion__header {\n  padding: 20px 20px 15px;\n  border: 1px solid #d6d6d6;\n  border-radius: 6px;\n  display: flex;\n  -webkit-display: flex;\n  justify-content: space-between;\n  background: #fff;\n  position: relative;\n  align-items: center;\n}\n.accordion__sub_header {\n  font-size: 12px;\n  font-weight: 400;\n  color: red;\n}\n.accordion__content {\n  height: 0;\n  overflow: hidden;\n  padding: 10px 10px 0;\n  transition: height 0.4s ease;\n}\n.accordion__content.open {\n  height: auto;\n}\n.accordion__header .accordionClickField {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n  left: 0;\n  top: 0;\n  cursor: pointer;\n}\n.accordion__accordionHeading {\n  font-size: 16px;\n  color: #222222;\n  font-weight: 700;\n  margin-bottom: 0px;\n  line-height: 1.3;\n}\n.accordion__header .icon-delete {\n  position: relative;\n  z-index: 1;\n  top: -2px;\n}\n.heading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
