import { useEffect } from 'react';
import { useState } from 'react';
const HeightWeightSection = (props) => {
  const [weightValue, setWeightValue] = useState(
    props?.apiData?.data?.weightSectionFields?.value
  );
  const feetValuechange = (event) => {
    props.setFeetValue(event.target.value);
  };

  const inchesValuechange = (event) => {
    props.setInchesValue(event.target.value);
  };

  useEffect(() => {
    props.setHeightValue(
      (props.feetValue * 30.48 + props.inchesValue * 2.54).toFixed(2)
    );
  }, [props.feetValue, props.inchesValue]);

  useEffect(() => {
    props?.apiData?.data?.feetSelectValue?.map((item) => {
      if (item.defaultValue) {
        props.setFeetValue(item.value);
      }
    });
    props?.apiData?.data?.inchesSelectValue?.map((item) => {
      if (item.defaultValue) {
        props.setInchesValue(item.value);
      }
    });
  }, [props?.apiData?.data]);

  return (
    <>
      <div className="flex mi-height-container ">
        <div className="height-container layout-2">
          <div>
            <h3>{props?.apiData?.data?.heightlabel?.heightHeading}</h3>
          </div>
          <div className="flex feet-container">
            <div className="flex label-value-wrapper height-select-width">
              <label className="field-label">
                {props?.apiData?.data?.heightlabel?.feetHeading}
              </label>
              <select
                name="feet"
                id="feet"
                value={props.feetValue}
                onChange={feetValuechange}
              >
                {props?.apiData?.data?.feetSelectValue?.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex label-value-wrapper height-select-width">
              <label className="field-label">
                {props?.apiData?.data?.heightlabel?.inchesHeading}
              </label>
              <select
                name="inches"
                id="inches"
                value={props.inchesValue}
                onChange={inchesValuechange}
              >
                {props?.apiData?.data?.inchesSelectValue?.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <p className="equalto">=</p>
            <div className="flex label-value-wrapper height-select-width">
              <label className="field-label">
                {props?.apiData?.data?.heightlabel?.cmHeading}
              </label>
              <div>
                <input
                  type="text"
                  value={props.heightValue}
                  placeholder="Enter"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`label-value-wrapper layout-2 ${
            !weightValue ? 'value--empty' : ''
          } ${props.weightValue}`}
        >
          <h3>{props?.apiData?.data?.weightSectionlabel?.label}</h3>
          <label className="field-label">
            {props?.apiData?.data?.weightSectionFields?.label}
          </label>
          <div>
            <input
              type={props?.apiData?.data?.weightSectionFields?.type}
              placeholder="Enter"
              defaultValue={props?.apiData?.data?.weightSectionFields?.value}
              onChange={(event) => {
                props.setWeightValue(event.target.value);
                setWeightValue(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeightWeightSection;
