import { useEffect, useState } from 'react';
import VerifyMappingAddMoreFields from './VerifyMappingAddMoreFields';
import MappingMultiSelectDropdown from './mappingMultiSelectDropdown';
import MappingSelectDropdown from './mappingSelectDropdown';
import DeleteIcon from '../../../../assets/images/delete-icon.svg';
const VerifyMappingFields = ({
  keyId,
  carrierFieldValue,
  cpwSectionValue,
  cpwFieldValue,
  contextValue,
  isCopied,
  setDeleteIsCopied,
  setDeleteIsCopiedId,
  dropdownData,
  lockFields,
  oldKeysData,
  setOldKeysData,
  newKeysData,
  setNewKeysData,
}) => {
  const [cpwSection, setCPWSection] = useState('');
  const [cpwField, setCPWField] = useState([]);
  const [cpwFieldArray, setCPWFieldArray] = useState([]);
  const [cpwContext, setCPWContext] = useState('');
  const [cpwContextArray, setCPWContextArray] = useState([]);
  const [addMoreFieldsList, setAddMoreFieldsList] = useState([]);

  const onOldKeysDataChange = (
    dropdownValue,
    dropdownName,
    id,
    carrierFieldValue
  ) => {
    let newFormValues = [...oldKeysData];
    let index = newFormValues.findIndex((item) => item.id === id);

    if (index > -1) {
      switch (dropdownName) {
        case 'cpw_section':
          newFormValues[index]['cpw_section'] = dropdownValue;
          newFormValues[index]['cpw_field'] = null;
          newFormValues[index]['context'] = null;
          break;
        case 'cpw_field':
          newFormValues[index]['cpw_field'] = dropdownValue;
          newFormValues[index]['context'] = null;
          break;
        case 'context':
          newFormValues[index]['context'] = dropdownValue;

        default:
        // Do Nothing
      }
    } else {
      newFormValues.push({
        id: parseInt(id),
        carrier_field: carrierFieldValue,
        [dropdownName]: dropdownValue,
      });
      setOldKeysData(newFormValues);
    }
  };
  const onCPWSectionChange = (e) => {
    setCPWSection(e.target.innerText);
    let selectedValueIndex = dropdownData.findIndex(
      (item) => item.section === e.target.innerHTML
    );

    setCPWFieldArray(dropdownData[selectedValueIndex]?.fields); //Initializing  Values to CPW Field Dropdown

    setCPWField([]); // Resetting CPW field Selected Value to empty
    setCPWContext(''); // Resetting  CPW field Selected Value to empty
    setCPWContextArray([]); // Resetting Field of CPW context Dropdown
  };

  const addMoreFields = () => {
    setAddMoreFieldsList([
      ...addMoreFieldsList,
      {
        carrier_field: carrierFieldValue,
        section: cpwSectionValue,
        field_label: '',
        contexts: [],
      },
    ]);
  };
  let deleteAddMoreField = (i) => {
    let newFormValues = [...addMoreFieldsList];
    newFormValues.splice(i, 1);
    setAddMoreFieldsList(newFormValues);
  };
  const deleteIsCopiedField = (id) => {
    setDeleteIsCopied(true);
    setDeleteIsCopiedId(id);
  };
  useEffect(() => {
    setCPWSection(cpwSectionValue ? cpwSectionValue : '');
  }, [cpwSectionValue]);

  useEffect(() => {
    setCPWField(cpwFieldValue?.length > 0 ? cpwFieldValue : []);
  }, [cpwFieldValue]);

  useEffect(() => {
    setCPWContext(contextValue ? contextValue : '');
  }, [contextValue]);

  useEffect(() => {
    let selectedValueIndex = dropdownData.findIndex(
      (item) => item.section === cpwSection
    );
    setCPWFieldArray(dropdownData[selectedValueIndex]?.fields);
  }, [dropdownData, cpwSection]);

  useEffect(() => {
    setCPWContextArray([]); //Making CPW Context Array empty
    cpwFieldArray?.filter((item) => {
      cpwField?.map((value) => {
        if (value === item.field_label)
          setCPWContextArray((prevState) => [...prevState, ...item.contexts]);
      });
    });
  }, [cpwFieldArray, cpwField]);

  return (
    <>
      <div className="verifyMapping--fields">
        <div className="flex">
          <div className="label-value-wrapper verifyMapping--dropdownFields">
            <label className="field-label">Form field</label>
            <div>
              <p className="verifyMapping--cpwFieldLabel">
                {carrierFieldValue}
              </p>
            </div>
          </div>
          <div className="label-value-wrapper verifyMapping--dropdownFields">
            <label className="field-label" htmlFor={`cpw_section-${keyId}`}>
              CPW Section <sup>*</sup>
            </label>
            <div>
              <MappingSelectDropdown
                options={dropdownData}
                lockFields={lockFields}
                selectedValue={cpwSection}
                labelKey={'section'}
                onClickHandler={(e) => {
                  onCPWSectionChange(e);
                  onOldKeysDataChange(
                    e.target.innerText,
                    'cpw_section',
                    keyId,
                    carrierFieldValue
                  );
                }}
              />
            </div>
          </div>
          <div className="label-value-wrapper verifyMapping--dropdownFields">
            <label className="field-label" htmlFor={`cpw_field-${keyId}`}>
              CPW Field <sup>*</sup>
            </label>
            <div>
              <MappingMultiSelectDropdown
                selectedValues={cpwField}
                lockFields={lockFields}
                options={cpwFieldArray}
                labelKey="field_label"
                dropdownType="mapping--cpwField"
                setCPWContext={setCPWContext}
                setCPWContextArray={setCPWContextArray}
                onFieldsClick={() => {
                  setCPWContext('');
                  onOldKeysDataChange(
                    cpwField,
                    'cpw_field',
                    keyId,
                    carrierFieldValue
                  );
                }}
              />
            </div>
          </div>
          <div className="label-value-wrapper verifyMapping--dropdownFields">
            <label className="field-label" htmlFor={`context-${keyId}`}>
              CPW Context <sup>*</sup>
            </label>
            <div>
              <MappingSelectDropdown
                options={cpwContextArray}
                lockFields={lockFields}
                selectedValue={cpwContext}
                setSelectedValue={setCPWContext}
                isSorted={true}
                onClickHandler={(e) => {
                  setCPWContext(e.target.innerText);
                  onOldKeysDataChange(
                    e.target.innerText,
                    'context',
                    keyId,
                    carrierFieldValue
                  );
                }}
              />
            </div>
          </div>
          {isCopied && (
            <div className="verifyMapping--deleteAddMore">
              <span
                onClick={() => deleteIsCopiedField(keyId)}
                className="icon-delete"
              >
                <img
                  src={DeleteIcon}
                  alt="deleteIcon"
                  className="deleteIcon "
                />
              </span>
            </div>
          )}
        </div>

        {addMoreFieldsList?.map((item, index) => {
          return (
            <VerifyMappingAddMoreFields
              key={index}
              index={index}
              keyId={keyId}
              carrierFieldValue={item.carrier_field}
              cpwSectionValue={cpwSection}
              dropdownData={dropdownData}
              lockFields={lockFields}
              deleteAddMoreField={deleteAddMoreField}
              newKeysData={newKeysData}
              setNewKeysData={setNewKeysData}
            />
          );
        })}
        <span
          className={`addFieldButton ${lockFields ? 'button--disable' : ''}`}
          onClick={() => addMoreFields()}
        >
          ADD MORE
        </span>
      </div>
    </>
  );
};
export default VerifyMappingFields;
