import CommonFormHeader from '../../commonFormHeader';
import radioCheckedImg from '../../../assets/images/radio-checked.svg';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import { useEffect, useState } from 'react';
import axios from '../../../axios/axios';
import './PurposeOfCoverage.css';
import { purposeOfCoverageAction } from '../../../actions/purposeOfCoverageAction';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../ui/toastMessage';
import Loader from '../../loader';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const PurposeOfCoverage = () => {
  const pageData = useSelector((state) => state.purposeOfCoverageData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(purposeOfCoverageAction());
  }, [dispatch]);

  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  const [individualProductSelected, setIndividualProductSelected] = useState(
    []
  );
  const [survialProductSelected, setSurvialProductSelected] = useState([]);
  const [privatePlacementSelected, setPrivatePlacementSelected] = useState([]);
  const [coverageOptionsSelected, setCoverageOptionSelected] = useState({});

  const [personalSelectedCheckboxes, setPersonalSelectedCheckboxes] = useState(
    []
  );
  const [businessSelectedCheckboxes, setBusinesSelectedCheckboxes] = useState(
    []
  );
  const [showPersonalOtherTextbox, setShowPersonalOtherTextbox] =
    useState(false);
  const [showBusinessOthertextbox, setShowBusinessOtherTextbox] =
    useState(false);
  useEffect(() => {
    if (formData?.data?.individualProductFields) {
      const selected =
        formData?.data?.individualProductFields?.checkOptions?.filter(
          (ele) => ele?.defaultChecked
        );
      const values = selected?.map((ele) => ele.value);
      setIndividualProductSelected(values);
    }

    if (formData?.data?.survialProduct) {
      const selected = formData?.data?.survialProduct?.checkOptions?.filter(
        (ele) => ele?.defaultChecked
      );
      const values = selected?.map((ele) => ele.value);
      setSurvialProductSelected(values);
    }

    if (formData?.data?.privatePlacement) {
      const selected = formData?.data?.privatePlacement?.checkOptions?.filter(
        (ele) => ele?.defaultChecked
      );
      const values = selected?.map((ele) => ele.value);
      setPrivatePlacementSelected(values);
    }

    if (formData?.data?.coverage) {
      const selectedCoverage = formData?.data?.coverage?.radioOptions?.find(
        (element) => element.defaultChecked
      );

      setCoverageOptionSelected(() => ({
        [selectedCoverage?.id]: selectedCoverage?.value,
      }));
    }

    if (formData?.data?.personalFields) {
      const selected = formData?.data?.personalFields?.checkOptions?.filter(
        (ele) => ele?.defaultChecked
      );
      const values = selected?.map((ele) => ele.value);
      setPersonalSelectedCheckboxes(values);

      const otherSelected = values.findIndex((ele) => ele === 'Other');
      if (otherSelected > -1) {
        setShowPersonalOtherTextbox(true);
      }
    }

    if (formData?.data?.businessFields) {
      const selected = formData?.data?.businessFields?.checkOptions?.filter(
        (ele) => ele?.defaultChecked
      );
      const values = selected?.map((ele) => ele.value);
      setBusinesSelectedCheckboxes(values);
      const otherSelected = values.findIndex((ele) => ele === 'Other');

      if (otherSelected > -1) {
        setShowBusinessOtherTextbox(true);
      }
    }
  }, [formData?.data]);

  const [personalOtherCheckboxText, setPerosnalOtherCheckboxText] = useState(
    {}
  );
  const [personalOtherValue, setPersonalOtherValue] = useState(
    formData?.data?.personalOthertext.value
  );
  const [businessOtherCheckboxText, setBusinessOtherCheckboxText] = useState(
    {}
  );
  const [businessOtherValue, setBusinessOtherValue] = useState(
    formData?.data?.businessOthertext.value
  );
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const onIndividualProductsChange = (e) => {
    if (e.target.checked) {
      setIndividualProductSelected((prevState) => [
        ...prevState,
        e.target.value,
      ]);
    } else {
      let checkedValuesArray = [...individualProductSelected];
      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      setIndividualProductSelected(checkedValuesArray);
    }
  };
  const individualProductOptions =
    formData?.data?.individualProductFields?.checkOptions?.map(
      (item, index) => {
        return (
          <div className="checkboxTabs" key={index}>
            <input
              className="checkboxField"
              type="checkbox"
              value={item.value}
              name={item.name}
              id={item.id}
              defaultChecked={item.defaultChecked}
              onChange={onIndividualProductsChange}
            />
            <label className="customCheckbox" htmlFor={item.id}>
              <img
                src={radioCheckedImg}
                alt="checked "
                className="customCheckbox__image"
              />
              <span className="customCheckbox__label">{item.value}</span>
            </label>
          </div>
        );
      }
    );

  const onSurvivalProductsChange = (e) => {
    if (e.target.checked) {
      setSurvialProductSelected((prevState) => [...prevState, e.target.value]);
    } else {
      let checkedValuesArray = [...survialProductSelected];
      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      setSurvialProductSelected(checkedValuesArray);
    }
  };
  const survialProductOptions =
    formData?.data?.survialProduct?.checkOptions?.map((item, index) => {
      return (
        <div className="checkboxTabs" key={index}>
          <input
            className="checkboxField"
            type="checkbox"
            value={item.value}
            name={item.name}
            id={item.id}
            defaultChecked={item.defaultChecked}
            onChange={onSurvivalProductsChange}
          />
          <label className="customCheckbox" htmlFor={item.id}>
            <img
              src={radioCheckedImg}
              alt="checked "
              className="customCheckbox__image"
            />
            <span className="customCheckbox__label">{item.value}</span>
          </label>
        </div>
      );
    });

  const onPrivatePlacementChange = (e) => {
    if (e.target.checked) {
      setPrivatePlacementSelected((prevState) => [
        ...prevState,
        e.target.value,
      ]);
    } else {
      let checkedValuesArray = [...privatePlacementSelected];
      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      setPrivatePlacementSelected(checkedValuesArray);
    }
  };
  const privatePlacementOptions =
    formData?.data?.privatePlacement?.checkOptions?.map((item, index) => {
      return (
        <div className="checkboxTabs" key={index}>
          <input
            className="checkboxField"
            type="checkbox"
            value={item.value}
            name={item.name}
            id={item.id}
            defaultChecked={item.defaultChecked}
            onChange={onPrivatePlacementChange}
          />
          <label className="customCheckbox" htmlFor={item.id}>
            <img
              src={radioCheckedImg}
              alt="checked "
              className="customCheckbox__image"
            />
            <span className="customCheckbox__label">{item.value}</span>
          </label>
        </div>
      );
    });
  const onCoverageRadioChange = (e) => {
    setCoverageOptionSelected(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const coverageOptions = (
    <>
      <h3 className="purposeOfCoverage__heading">
        {formData?.data?.coverage?.label}
      </h3>
      <div className="flex purposeOfCoverage__radioTabsWrapper">
        {formData?.data?.coverage?.radioOptions?.map((item, index) => {
          return (
            <div key={index} className="purposeOfCoverage__radioTabs">
              <label className="field-label">
                <input
                  id={item.id}
                  type="radio"
                  name={item.name}
                  value={item.value}
                  onChange={onCoverageRadioChange}
                  defaultChecked={item.defaultChecked}
                />{' '}
                <span>{item.value}</span>
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
  const onPersonalCheckboxChange = (e) => {
    if (e.target.name === 'personal-other') {
      if (e.target.checked) {
        setShowPersonalOtherTextbox(true);
      } else {
        setShowPersonalOtherTextbox(false);
      }
    }

    if (e.target.checked) {
      setPersonalSelectedCheckboxes((prevState) => [
        ...prevState,
        e.target.value,
      ]);
    } else {
      let checkedValuesArray = [...personalSelectedCheckboxes];
      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      setPersonalSelectedCheckboxes(checkedValuesArray);
    }
  };
  const personalOptions = formData?.data?.personalFields?.checkOptions?.map(
    (item, index) => {
      return (
        <div className="checkboxTabs" key={index}>
          <input
            className="checkboxField"
            type="checkbox"
            value={item.value}
            name={item.name}
            id={item.id}
            defaultChecked={item.defaultChecked}
            onChange={onPersonalCheckboxChange}
          />
          <label className="customCheckbox" htmlFor={item.id}>
            <img
              src={radioCheckedImg}
              alt="checked "
              className="customCheckbox__image"
            />
            <span className="customCheckbox__label">{item.value}</span>
          </label>
        </div>
      );
    }
  );
  const onPersonalTextChange = (e) => {
    setPersonalOtherValue(e.target.value);
    setPerosnalOtherCheckboxText(() => ({
      [e.target.name]: e.target.value,
    }));
  };
  const personalOtherText = formData?.data?.personalOthertext && (
    <div
      className={`label-value-wrapper  purposeOfCoverage__textbox layout-3 ${
        !personalOtherValue ? 'value--empty' : ''
      }`}
    >
      {formData?.data?.personalOthertext?.label && (
        <label className="field-label">
          {formData?.data?.personalOthertext?.label}
        </label>
      )}

      <div>
        <input
          type="text"
          name={formData?.data?.personalOthertext?.name}
          defaultValue={formData?.data?.personalOthertext?.value}
          onChange={onPersonalTextChange}
          placeholder="Enter"
        />
      </div>
    </div>
  );
  const onBusinessCheckboxChange = (e) => {
    if (e.target.name === 'business-other') {
      if (e.target.checked) {
        setShowBusinessOtherTextbox(true);
      } else {
        setShowBusinessOtherTextbox(false);
      }
    }

    if (e.target.checked) {
      setBusinesSelectedCheckboxes((prevState) => [
        ...prevState,
        e.target.value,
      ]);
    } else {
      let checkedValuesArray = [...businessSelectedCheckboxes];

      const unCheckedValue = (element) => element === e.target.value;
      const unCheckedValueIndex = checkedValuesArray.findIndex(unCheckedValue);
      checkedValuesArray.splice(unCheckedValueIndex, 1);
      setBusinesSelectedCheckboxes(checkedValuesArray);
    }
  };
  const businessOptions = formData?.data?.businessFields?.checkOptions?.map(
    (item, index) => {
      return (
        <div className="checkboxTabs" key={index}>
          <input
            className="checkboxField"
            type="checkbox"
            value={item.value}
            name={item.name}
            id={item.id}
            defaultChecked={item.defaultChecked}
            onChange={onBusinessCheckboxChange}
          />
          <label className="customCheckbox" htmlFor={item.id}>
            <img
              src={radioCheckedImg}
              alt="checked "
              className="customCheckbox__image"
            />
            <span className="customCheckbox__label">{item.value}</span>
          </label>
        </div>
      );
    }
  );
  const businessTextChange = (e) => {
    setBusinessOtherValue(e.target.value);
    setBusinessOtherCheckboxText(() => ({
      [e.target.name]: e.target.value,
    }));
  };
  const businessOtherText = formData?.data?.businessOthertext && (
    <div
      className={`label-value-wrapper  purposeOfCoverage__textbox layout-3 ${
        !businessOtherValue ? 'value--empty' : ''
      }`}
    >
      <label className="field-label">
        {formData?.data?.businessOthertext?.label}
      </label>
      <div>
        <input
          type="text"
          name={formData?.data?.businessOthertext?.name}
          defaultValue={formData?.data?.businessOthertext?.value}
          onChange={businessTextChange}
          placeholder="Enter"
        />
      </div>
    </div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    individualProductFields: individualProductSelected,
    survialProduct: survialProductSelected,
    privatePlacement: privatePlacementSelected,
    coverage: coverageOptionsSelected,
    personalFields: personalSelectedCheckboxes,
    personalOthertext: personalOtherCheckboxText,
    businessFields: businessSelectedCheckboxes,
    businessOthertext: businessOtherCheckboxText,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s1',
      },
    });
  }, [payload]);
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(`/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s1`, payload)
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };

  return (
    <>
      <CommonFormHeader
        backPath={null}
        nextPath="/form/policydetails"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId="f1v1s1"
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          <h3 className="purposeOfCoverage__heading">
            {formData?.data?.productText}
          </h3>
          <div className="poc_productsWrapper">
            <div className="poc_products">
              <h3 className="purposeOfCoverage__heading">
                {formData?.data?.individualProductFields?.label}
              </h3>
              <div>{individualProductOptions}</div>
            </div>
            <div className="poc_products">
              <h3 className="purposeOfCoverage__heading">
                {formData?.data?.survialProduct?.label}
              </h3>
              <div>{survialProductOptions}</div>
            </div>
            <div className="poc_products">
              <h3 className="purposeOfCoverage__heading">
                {formData?.data?.privatePlacement?.label}
              </h3>

              <div>{privatePlacementOptions}</div>
            </div>
          </div>

          <div className=" purposeOfCoverage__radioHeading">
            {coverageOptions}
          </div>
          {(coverageOptionsSelected.hasOwnProperty('coverage-personal') ||
            coverageOptionsSelected.hasOwnProperty('coverage-all')) && (
            <div>
              {formData?.data?.personalFields?.label &&
                coverageOptionsSelected.hasOwnProperty('coverage-all') && (
                  <h3 className="purposeOfCoverage__heading">
                    {formData?.data?.personalFields?.label}
                  </h3>
                )}
              <div>{personalOptions}</div>
              {showPersonalOtherTextbox && personalOtherText}
            </div>
          )}

          {coverageOptionsSelected.hasOwnProperty('coverage-all') && (
            <div className="line-separation layout-1"></div>
          )}
          {(coverageOptionsSelected.hasOwnProperty('coverage-business') ||
            coverageOptionsSelected.hasOwnProperty('coverage-all')) && (
            <div>
              {formData?.data?.businessFields?.label &&
                coverageOptionsSelected.hasOwnProperty('coverage-all') && (
                  <h3 className="purposeOfCoverage__heading">
                    {formData?.data?.businessFields?.label}
                  </h3>
                )}
              <div>{businessOptions}</div>
              {showBusinessOthertextbox && businessOtherText}
              {coverageOptionsSelected.hasOwnProperty('coverage-all') && (
                <div className="line-separation layout-1"></div>
              )}
              {coverageOptionsSelected.hasOwnProperty('coverage-all') && (
                <label className="field-label">PPLI</label>
              )}{' '}
            </div>
          )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}{' '}
    </>
  );
};
export default PurposeOfCoverage;
