import ToastMessageIcon from '../../../assets/images/icon-toastMessage.svg';

const CarrierAdminToastMessage = ({
  messageType,
  message,
  onCloseToastMessage,
}) => {
  return (
    <div
      className={`formAdministration--toastMessage toastMessage ${
        messageType === 'success'
          ? 'formAdministration--toastSuccess'
          : 'formAdministration--toastFail'
      }`}
    >
      {messageType === 'success' ? (
        <img
          className="toastmessage-icon"
          src={ToastMessageIcon}
          alt="Toast message Icon"
        />
      ) : (
        <span className="toastmessage-icon toastmessage-faiIcon">!</span>
      )}

      <span className="toastMessage-text">{message}</span>
      <span className="toastMessage-close" onClick={onCloseToastMessage}>
        X
      </span>
    </div>
  );
};
export default CarrierAdminToastMessage;
