import { useState, useRef, useEffect } from 'react';
import IconActions from '../../../assets/images/carrier-actions.svg';
import { Link } from 'react-router-dom';
const FormListingActions = ({ upload_id, extractionStatus }) => {
  const [active, setActive] = useState(false);
  const dropdownRef = useRef(null);
  const disabled = extractionStatus !== 'completed';
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div style={{ display: 'flex' }}>
      {!disabled && (
        <div className="formListingActions" ref={dropdownRef}>
          <span
            className="formListingActions--btn"
            onClick={() => {
              setActive(!active);
            }}
          >
            <img src={IconActions} alt="Icon Action" />
          </span>
          {active && !disabled && (
            <div className="formListingActions--content">
              <div
                className="formListingActions--item"
                onClick={() => {
                  setActive(false);
                }}
              >
                <Link to={`/markvalidfields?upload_id=${upload_id}`}>
                  {' '}
                  Mark Valid Keys
                </Link>
              </div>
              <div
                className="formListingActions--item"
                onClick={() => {
                  setActive(false);
                }}
              >
                <Link to={`/verifymapping?upload_id=${upload_id}`}>
                  {' '}
                  Verify Mapping
                </Link>
              </div>
            </div>
          )}
        </div>
      )}

      {disabled && (
        <span
          className={`formAdministration--${extractionStatus?.toLowerCase()} `}
          style={{
            marginLeft: '5px',
            marginTop: '8px',
            width: '14px',
            height: '14px',
          }}
          title={`File upload is ${extractionStatus}`}
        ></span>
      )}
    </div>
  );
};
export default FormListingActions;
