import FormField from '../../../ui/formField';
import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';
import axios from '../../../../axios/axios';

import {
  RemoveTaxIdSeparator,
  SetTaxIDSeparator,
} from '../../../../constants/taxIdConstants';
const ParterDetails = (props) => {
  const parterTypeOptions = [
    { label: 'Select', value: '' },
    {
      label: 'Individual',
      value: 'individual',
    },
    {
      label: 'Company',
      value: 'company',
    },
  ];
  const selectOptions = [
    { label: 'Select', value: '' },
    {
      label: 'Dr.',
      value: 'dr',
    },
    {
      label: 'Miss',
      value: 'miss',
    },
    {
      label: 'Mr.',
      value: 'mr',
    },
    {
      label: 'Mrs.',
      value: 'mrs',
    },
    {
      label: 'Ms.',
      value: 'ms',
    },
  ];
  const genderList = [
    { label: 'Select', value: '' },
    {
      label: 'Female',
      value: 'female',
    },
    {
      label: 'Male',
      value: 'male',
    },

    {
      label: 'Non-Binary',
      value: 'nonBinary',
    },
    {
      label: 'Transgender',
      value: 'transgender',
    },
  ];
  let handleChange = (i, e) => {
    let newFormValues = [...props.partners];
    newFormValues[i][e.target.name] =
      e.target.name === 'company_taxIdNumber'
        ? RemoveTaxIdSeparator(e.target.value)
        : e.target.value;
    props.setPartners(newFormValues);
  };

  let addFormFields = () => {
    props.setPartners([
      ...props.partners,
      {
        additionalLine2: '',
        additionalLine3: '',
        addressBlock: '',
        addressRemarks: '',
        areaCode: '',
        birthPlace: '',
        city: '',
        company_additionalLine2: '',
        company_additionalLine3: '',
        company_addressBlock: '',
        company_areaCode: '',
        company_city: '',
        company_countryCode: '',
        company_email: '',
        company_extension: '',
        company_partnershipPer: '',
        company_phone: '',
        company_state: '',
        company_taxIdNumber: '',
        company_zipCode: '',
        company_addressRemarks: '',
        company_country: '',
        company_fullName: '',
        company_phoneRemarks: '',
        country: '',
        countryCode: '',
        dateOfBirth: '',
        driverLicense: '',
        email: '',
        expirationDate: '',
        extension: '',
        firstName: '',
        gender: '',
        issueDate: '',
        lastName: '',
        middleName: '',
        parterType: '',
        partnership: '',
        phone: '',
        remarks: '',
        phoneRemarks: '',
        ssn: '',
        state: '',
        stateofIssue: '',
        suffix: '',
        title: '',
        zipCode: '',
      },
    ]);
  };

  let removeFormFields = (i, objectId) => {
    let newFormValues = [...props.partners];
    newFormValues.splice(i, 1);
    props.setPartners(newFormValues);
    axios
      .post(`/so-api/delete-object/?object_id=${objectId}`)
      .then((response) => {})
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };

  return (
    <>
      {props?.partners &&
        props?.partners?.map((element, index) => (
          <AccordionAddMoreSection
            key={index}
            index={index}
            accordionHeading={`Partner ${index + 1}`}
            onDeleteClick={
              index ? () => removeFormFields(index, element?.object_id) : null
            }
          >
            <div className="flex" key={index}>
              <div className="label-value-wrapper layout-2">
                <label className="field-label">Partner Type</label>

                <div>
                  <select
                    name="parterType"
                    id="parterType"
                    value={element.parterType || ''}
                    onChange={(e) => handleChange(index, e)}
                  >
                    {parterTypeOptions.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="label-value-wrapper layout-2"></div>
              {element.parterType === 'individual' && (
                <>
                  <div className="label-value-wrapper layout-5">
                    <label className="field-label">Title</label>

                    <div>
                      <select
                        name="title"
                        id="title"
                        defaultValue={element.title || ''}
                        onChange={(e) => handleChange(index, e)}
                      >
                        {selectOptions.map((item, index) => {
                          return (
                            <option value={item.value} key={index}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-5">
                    <label className="field-label">First Name</label>

                    <FormField
                      id={`firstName${index}`}
                      regex="^[a-zA-Z -.,]*$"
                      validationType="addMoreText"
                      errorMessage="Please enter a valid name."
                      type="text"
                      name="firstName"
                      placeholder="Enter"
                      value={element.firstName || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-5">
                    <label className="field-label">Middle Name</label>

                    <FormField
                      id={`middleName${index}`}
                      regex="^[a-zA-Z -.,]*$"
                      validationType="addMoreText"
                      errorMessage="Please enter a valid name."
                      type="text"
                      name="middleName"
                      placeholder="Enter"
                      value={element.middleName || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-5">
                    <label className="field-label">Last Name</label>

                    <FormField
                      id={`lastName${index}`}
                      regex="^[a-zA-Z -.,]*$"
                      validationType="addMoreText"
                      errorMessage="Please enter a valid name."
                      type="text"
                      name="lastName"
                      placeholder="Enter"
                      value={element.lastName || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-5">
                    <label className="field-label">Suffix</label>

                    <FormField
                      id={`suffix${index}`}
                      regex="^[a-zA-Z -.,]*$"
                      validationType="addMoreText"
                      errorMessage="Please enter a valid name."
                      type="text"
                      name="suffix"
                      placeholder="Enter"
                      value={element.suffix || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.partnership ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Partnership %</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter"
                        name="partnership"
                        value={element.partnership || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-2">
                    <label className="field-label">
                      Social Security Number
                    </label>

                    <FormField
                      id={`ssn${index}`}
                      regex="^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$"
                      errorMessage="Please enter a valid value (ex: 123-45-6789)."
                      type="text"
                      name="ssn"
                      validationType="ssn"
                      placeholder="Enter"
                      value={element.ssn || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="label-value-wrapper no-margin-text-field layout-2">
                    <label className="field-label">Date of Birth</label>

                    <FormField
                      type="date"
                      validationType="addMoreDate"
                      name="dateOfBirth"
                      placeholder="Enter"
                      value={element.dateOfBirth || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="label-value-wrapper layout-2">
                    <label className="field-label">Gender</label>
                    <div>
                      <select
                        name="gender"
                        id="gender"
                        defaultValue={element.gender || ''}
                        onChange={(e) => handleChange(index, e)}
                      >
                        {genderList.map((item, index) => {
                          return (
                            <option value={item.value} key={index}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.birthPlace ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Birth Place</label>
                    <div>
                      <input
                        type="text"
                        name="birthPlace"
                        placeholder="Enter"
                        value={element.birthPlace || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-1 ${
                      !element.addressBlock ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Address Block</label>
                    <div>
                      <textarea
                        placeholder="Enter"
                        name="addressBlock"
                        value={element.addressBlock || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.additionalLine2 ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Additional Line 2</label>
                    <div>
                      <input
                        type="text"
                        name="additionalLine2"
                        placeholder="Enter"
                        value={element.additionalLine2 || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.additionalLine3 ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Additional Line 3</label>
                    <div>
                      <input
                        type="text"
                        name="additionalLine3"
                        placeholder="Enter"
                        value={element.additionalLine3 || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.city ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">City</label>
                    <div>
                      <input
                        type="text"
                        name="city"
                        placeholder="Enter"
                        value={element.city || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-2">
                    <label className="field-label">State</label>

                    <FormField
                      id="state"
                      type="select"
                      name="state"
                      placeholder="Enter"
                      dropdownType="state"
                      addMoreField={true}
                      value={element.state || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>

                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.zipCode ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Zip Code</label>
                    <div>
                      <input
                        type="text"
                        name="zipCode"
                        placeholder="Enter"
                        value={element.zipCode || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.country ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Country</label>
                    <div>
                      <input
                        type="text"
                        name="country"
                        placeholder="Enter"
                        value={element.country || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>

                  <div
                    className={`label-value-wrapper layout-1 ${
                      !element.addressRemarks ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Remarks</label>
                    <div>
                      <textarea
                        placeholder="Enter"
                        name="addressRemarks"
                        value={element.addressRemarks || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="line-separation layout-1"></div>

                  <div className="layout-1">
                    <h3>Contact Details of Partner {index + 1}</h3>
                  </div>

                  <div
                    className={`label-value-wrapper  layout-3 ${
                      !element.countryCode ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Country Code</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter"
                        name="countryCode"
                        value={element.countryCode || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper  layout-3 ${
                      !element.areaCode ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Area Code</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter"
                        name="areaCode"
                        value={element.areaCode || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-3 ${
                      !element.phone ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Phone Number</label>
                    <div>
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter"
                        value={element.phone || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper  layout-2 ${
                      !element.extension ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Extension</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter"
                        name="extension"
                        value={element.extension || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-2">
                    <label className="field-label">Email</label>

                    <FormField
                      id={`email${index}`}
                      type="email"
                      name="email"
                      placeholder="Enter"
                      addMoreField
                      value={element.email || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div
                    className={`label-value-wrapper layout-1 ${
                      !element.phoneRemarks ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Remarks</label>
                    <div>
                      <textarea
                        placeholder="Enter"
                        name="phoneRemarks"
                        value={element.phoneRemarks || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="line-separation layout-1"></div>

                  <div className="layout-1">
                    <h3>Driver’s License</h3>
                  </div>

                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.driverLicense ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">ID Number</label>
                    <div>
                      <input
                        type="text"
                        name="driverLicense"
                        placeholder="Enter"
                        value={element.driverLicense || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-2">
                    <label className="field-label">State of Issue</label>

                    <FormField
                      id="stateofIssue"
                      type="select"
                      name="stateofIssue"
                      placeholder="Enter"
                      dropdownType="state"
                      addMoreField={true}
                      value={element.stateofIssue || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="label-value-wrapper no-margin-text-field layout-2">
                    <label className="field-label">Issue Date</label>

                    <FormField
                      type="date"
                      validationType="addMoreDate"
                      name="issueDate"
                      placeholder="Enter"
                      value={element.issueDate || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="label-value-wrapper no-margin-text-field layout-2">
                    <label className="field-label">Expiration Date</label>

                    <FormField
                      type="date"
                      validationType="addMoreDate"
                      name="expirationDate"
                      placeholder="Enter"
                      value={element.expirationDate || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div
                    className={`label-value-wrapper layout-1 ${
                      !element.remarks ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Remarks</label>
                    <div>
                      <textarea
                        placeholder="Enter"
                        name="remarks"
                        value={element.remarks || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                </>
              )}
              {element.parterType === 'company' && (
                <>
                  <div className="label-value-wrapper ltc-text-field layout-2">
                    <label className="field-label">Full Name</label>

                    <FormField
                      id={`company_fullName${index}`}
                      regex="^[a-zA-Z -.,]*$"
                      validationType="name"
                      errorMessage="Please enter a valid name."
                      type="text"
                      name="company_fullName"
                      placeholder="Enter"
                      value={element.company_fullName || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.company_taxIdNumber ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Tax ID Number</label>
                    <div>
                      <input
                        type="text"
                        name="company_taxIdNumber"
                        placeholder="Enter"
                        maxLength={10}
                        value={
                          SetTaxIDSeparator(element.company_taxIdNumber) || ''
                        }
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.company_partnershipPer ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Partnership %</label>
                    <div>
                      <input
                        type="text"
                        name="company_partnershipPer"
                        placeholder="Enter"
                        value={element.company_partnershipPer || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-1 ${
                      !element.company_addressBlock ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Address Block</label>
                    <div>
                      <textarea
                        placeholder="Enter"
                        name="company_addressBlock"
                        value={element.company_addressBlock || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.company_additionalLine2 ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Additional Line 2</label>
                    <div>
                      <input
                        type="text"
                        name="company_additionalLine2"
                        placeholder="Enter"
                        value={element.company_additionalLine2 || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.company_additionalLine3 ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Additional Line 3</label>
                    <div>
                      <input
                        type="text"
                        name="company_additionalLine3"
                        placeholder="Enter"
                        value={element.company_additionalLine3 || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.company_city ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">City</label>
                    <div>
                      <input
                        type="text"
                        name="company_city"
                        placeholder="Enter"
                        value={element.company_city || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-2">
                    <label className="field-label">State</label>

                    <FormField
                      id="state"
                      type="select"
                      name="company_state"
                      placeholder="Enter"
                      dropdownType="state"
                      addMoreField={true}
                      value={element.company_state || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>

                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.company_zipCode ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Zip Code</label>
                    <div>
                      <input
                        type="text"
                        name="company_zipCode"
                        placeholder="Enter"
                        value={element.company_zipCode || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-2 ${
                      !element.company_country ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Country</label>
                    <div>
                      <input
                        type="text"
                        name="company_country"
                        placeholder="Enter"
                        value={element.company_country || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>

                  <div
                    className={`label-value-wrapper layout-1 ${
                      !element.company_addressRemarks ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Remarks</label>
                    <div>
                      <textarea
                        placeholder="Enter"
                        name="company_addressRemarks"
                        value={element.company_addressRemarks || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="layout-1">
                    <h3>Contact Details of Partner {index + 1}</h3>
                  </div>

                  <div
                    className={`label-value-wrapper  layout-3 ${
                      !element.company_countryCode ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Country Code</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter"
                        name="company_countryCode"
                        value={element.company_countryCode || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper  layout-3 ${
                      !element.company_areaCode ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Area Code</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter"
                        name="company_areaCode"
                        value={element.company_areaCode || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper layout-3 ${
                      !element.company_phone ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Phone Number</label>
                    <div>
                      <input
                        type="text"
                        name="company_phone"
                        placeholder="Enter"
                        value={element.company_phone || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div
                    className={`label-value-wrapper  layout-2 ${
                      !element.company_extension ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Extension</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter"
                        name="company_extension"
                        value={element.company_extension || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="label-value-wrapper ltc-text-field layout-2">
                    <label className="field-label">Email</label>

                    <FormField
                      id={`email${index}`}
                      type="email"
                      name="company_email"
                      placeholder="Enter"
                      addMoreField
                      value={element.company_email || ''}
                      onChangeHandler={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div
                    className={`label-value-wrapper layout-1 ${
                      !element.company_phoneRemarks ? 'value--empty' : ''
                    }`}
                  >
                    <label className="field-label">Remarks</label>
                    <div>
                      <textarea
                        placeholder="Enter"
                        name="company_phoneRemarks"
                        value={element.company_phoneRemarks || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </AccordionAddMoreSection>
        ))}
      <div>
        <span className="addFieldButton" onClick={() => addFormFields()}>
          ADD MORE
        </span>
      </div>
    </>
  );
};

export default ParterDetails;
