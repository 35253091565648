import { useState } from 'react';
const AddNote = ({ handleSubmit, closeNoteSection }) => {
  const [text, setText] = useState('');
  const isTextAreaDisabled = text.length === 0;
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(text);
    setText('');
  };
  const onNotesClose = () => {
    closeNoteSection();
    setText('');
  };

  return (
    <>
      <form onSubmit={onSubmit} className="addNotes__form">
        <textarea
          placeholder="Add Notes"
          className="addNotes_textarea"
          value={text}
          tabIndex="-1"
          onChange={(e) => setText(e.target.value)}
        />
        <div className="addNotes__action">
          <span onClick={onNotesClose} className="button button--cancel">
            Cancel
          </span>

          <button
            className="button"
            disabled={isTextAreaDisabled}
            tabIndex="-1"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};
export default AddNote;
