import FormField from '../../ui/formField';
import LabelRadioButtons from '../../ui/labelRadioButtons/index.js';
import { useSelector } from 'react-redux';
import { countryFieldValue } from '../../ui/countryState/countryList';
const USCitizenSection = (props) => {
  const piFormData = useSelector((state) => state.piData);
  const { loading, error, formData } = piFormData;

  const USCitizenData = formData?.data?.usCitizenLabel;
  const CitizenshipCountry = formData?.data?.citizenshipCountry;
  const onFieldChangeHandler = (event) => {
    props.setCountryofCitizenship((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onUsaVisaFieldChangeHandler = (event) => {
    props.setUsaVisaDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const greenCardFieldsChangeHandler = (event) => {
    props.setGreenCardFieldsDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const usCitizenChangeHandler = (event) => {
    props.setShowUSCitizenFields(event.target.value);
    props.setShowUSCitizenValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const haveUSVizaChangeHandler = (event) => {
    props.setShowUSVizaInfoFields(event.target.value);
    props.setUSVisaCheck(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const greenCardChangeHandler = (event) => {
    props.showGreenCardInfoFields(event.target.value);
    props.setGreenCardCheck(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const applyUSCitizenshipChangeHandler = (event) => {
    props.setApplyUSCitizen(event.target.value);
    props.setApplyUSCitizenCheck(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const haveUSVizaData = formData?.data?.usVizaLabel;
  const haveUSVizaFields = (
    <LabelRadioButtons
      label={haveUSVizaData.label}
      options={haveUSVizaData.radioOptions}
      onChangeHandler={haveUSVizaChangeHandler}
    />
  );

  const usVizaDataFields = formData?.data?.usVizaFields?.map((item) => {
    return (
      <>
        <FormField
          key={item.id}
          label={item.label}
          name={item.name}
          value={item.value}
          type={item.type}
          layoutType={item.layoutType}
          onChangeHandler={onUsaVisaFieldChangeHandler}
        />
      </>
    );
  });

  const greenCardData = formData?.data?.greenCardLabel;
  const greenCardInfo = (
    <LabelRadioButtons
      label={greenCardData.label}
      options={greenCardData.radioOptions}
      onChangeHandler={greenCardChangeHandler}
    />
  );

  const greenCardDataFields = formData?.data?.greenCardFields?.map((item) => {
    return (
      <>
        <FormField
          key={item.id}
          label={item.label}
          name={item.name}
          value={item.value}
          type={item.type}
          layoutType={item.layoutType}
          onChangeHandler={greenCardFieldsChangeHandler}
        />
      </>
    );
  });

  const applyUSCitizenshipData = formData?.data?.applyUSCitizenshipLabel;
  const applyUSCitizenFields = (
    <LabelRadioButtons
      label={applyUSCitizenshipData.label}
      options={applyUSCitizenshipData.radioOptions}
      onChangeHandler={applyUSCitizenshipChangeHandler}
    />
  );

  return (
    <>
      <LabelRadioButtons
        label={USCitizenData.label}
        options={USCitizenData.radioOptions}
        onChangeHandler={usCitizenChangeHandler}
      />
      {props.showUSCitizenFields === 'no' && (
        <>
          <FormField
            id={CitizenshipCountry.id}
            type={CitizenshipCountry.type}
            label={CitizenshipCountry.label}
            selectOptions={CitizenshipCountry.selectOptions}
            value={props.countryofCitizenship}
            name={CitizenshipCountry.name}
            layoutType={CitizenshipCountry.layoutType}
            onChangeHandler={onFieldChangeHandler}
          />
        </>
      )}
      {props.showUSCitizenFields === 'no' && haveUSVizaFields}
      {props.showUSCitizenFields === 'no' &&
        props.usVisaCheck['usVisa-yes'] === 'yes' && (
          <div className="flex full-width">{usVizaDataFields}</div>
        )}
      {props.showUSCitizenFields === 'no' && greenCardInfo}
      {props.showUSCitizenFields === 'no' &&
        props.greenCardCheck['greenCard-yes'] === 'yes' && (
          <div className="flex full-width">{greenCardDataFields}</div>
        )}
      {props.showUSCitizenFields === 'no' && applyUSCitizenFields}
    </>
  );
};
export default USCitizenSection;
