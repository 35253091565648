import {
  OTHER_GENERAL_QUESTIONS_DATA_REQUEST,
  OTHER_GENERAL_QUESTIONS_DATA_SUCCESS,
  OTHER_GENERAL_QUESTIONS_DATA_FAIL,
} from '../constants/otherGeneralQuestionsConstants';

export const otherGeneralQuestionsReducer = (
  state = { formData: [] },
  action
) => {
  switch (action.type) {
    case OTHER_GENERAL_QUESTIONS_DATA_REQUEST:
      return { loading: true, formData: [] };
    case OTHER_GENERAL_QUESTIONS_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case OTHER_GENERAL_QUESTIONS_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
