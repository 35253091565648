import AccordionAddMoreSection from '../../../../ui/accordionAddMoreSection';
import FormField from '../../../../ui/formField';

const PbIndividualSection = (props) => {
  const selectOptions = [
    { label: 'Select', value: '' },
    {
      label: 'Dr.',
      value: 'dr',
    },
    {
      label: 'Miss',
      value: 'miss',
    },
    {
      label: 'Mr.',
      value: 'mr',
    },
    {
      label: 'Mrs.',
      value: 'mrs',
    },
    {
      label: 'Ms.',
      value: 'ms',
    },
  ];
  let handleChange = (i, e) => {
    let newFormValues = [...props.pbIndividual];
    newFormValues[i][e.target.name] = e.target.value;
    props.setPbIndividual(newFormValues);
    if (e.target.name === 'percentage') {
      if (!isNaN(e.target.value)) {
        props.setPbIndividualPercentage((prevState) => ({
          ...prevState,
          [i]: e.target.value ? e.target.value : 0,
        }));
      }
    }
  };

  let addFormFields = () => {
    props.setPbIndividual([
      ...props.pbIndividual,
      {
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        addressBlock: '',
        additionalLine2: '',
        additionalLine3: '',
        city: '',
        dateOfBirth: '',
        percentage: '',
        relationship: '',
        ssn: '',
        state: '',
        zipCode: '',
        country: '',
        remarks: '',
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...props.pbIndividual];
    newFormValues.splice(i, 1);
    props.setPbIndividual(newFormValues);
    let newPercentage = [...Object.values(props.pbIndividualPercentage)];
    newPercentage.splice(i, 1);
    props.setPbIndividualPercentage(newPercentage);
  };

  return (
    <>
      <div className="line-separation"></div>
      {props?.pbIndividual &&
        props?.pbIndividual.map((element, index) => (
          <AccordionAddMoreSection
            key={index}
            index={index}
            accordionHeading={`Primary Beneficiary ${index + 1}`}
            onDeleteClick={index ? () => removeFormFields(index) : null}
          >
            <div className="flex" key={index}>
              <div className="label-value-wrapper layout-5">
                <label className="field-label">Title</label>

                <div>
                  <select
                    name="title"
                    id="title"
                    value={element.title || ''}
                    onChange={(e) => handleChange(index, e)}
                  >
                    {selectOptions.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="label-value-wrapper beneficiary-text-field layout-5">
                <label className="field-label">First Name</label>
                <FormField
                  id={`firstName${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="firstName"
                  placeholder="Enter"
                  value={element.firstName || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper beneficiary-text-field layout-5">
                <label className="field-label">Middle Name</label>
                <FormField
                  id={`middleName${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="middleName"
                  placeholder="Enter"
                  value={element.middleName || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper beneficiary-text-field layout-5">
                <label className="field-label">Last Name</label>
                <FormField
                  id={`lastName${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="lastName"
                  placeholder="Enter"
                  value={element.lastName || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper beneficiary-text-field layout-5">
                <label className="field-label">Suffix</label>
                <FormField
                  id={`suffix${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="addMoreText"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="suffix"
                  placeholder="Enter"
                  value={element.suffix || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper no-margin-text-field layout-2">
                <label className="field-label">Date of Birth</label>
                <FormField
                  type="date"
                  validationType="addMoreDate"
                  name="dateOfBirth"
                  placeholder="Enter"
                  value={element.dateOfBirth || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div className="label-value-wrapper beneficiary-text-field layout-2">
                <label className="field-label">Social Security Number</label>

                <FormField
                  id={`ssn${index}`}
                  regex="^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$"
                  errorMessage="Please enter a valid value (ex: 123-45-6789)."
                  type="text"
                  name="ssn"
                  validationType="ssn"
                  placeholder="Enter"
                  value={element.ssn || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.relationship ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Relationship</label>
                <div>
                  <input
                    type="text"
                    name="relationship"
                    placeholder="Enter"
                    value={element.relationship || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.percentage ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Percentage</label>
                <div>
                  <input
                    type="number"
                    name="percentage"
                    placeholder="Enter"
                    value={element.percentage || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-1 ${
                  !element.addressBlock ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Address Block</label>
                <div>
                  <textarea
                    name="addressBlock"
                    placeholder="Enter"
                    value={element.addressBlock || ''}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.additionalLine2 ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Additional Line 2</label>
                <div>
                  <input
                    type="text"
                    name="additionalLine2"
                    placeholder="Enter"
                    value={element.additionalLine2 || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.additionalLine3 ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Additional Line 3</label>
                <div>
                  <input
                    type="text"
                    name="additionalLine3"
                    placeholder="Enter"
                    value={element.additionalLine3 || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-3 ${
                  !element.city ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">City</label>
                <div>
                  <input
                    type="text"
                    name="city"
                    placeholder="Enter"
                    value={element.city || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div className="label-value-wrapper ltc-text-field layout-3">
                <label className="field-label">State</label>

                <FormField
                  id="state"
                  type="select"
                  name="state"
                  placeholder="Enter"
                  dropdownType="state"
                  addMoreField={true}
                  value={element.state || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>

              <div
                className={`label-value-wrapper layout-3 ${
                  !element.zipCode ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Zip Code</label>
                <div>
                  <input
                    type="text"
                    name="zipCode"
                    placeholder="Enter"
                    value={element.zipCode || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-2 ${
                  !element.country ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Country</label>
                <div>
                  <input
                    type="text"
                    name="country"
                    placeholder="Enter"
                    value={element.country || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-1 ${
                  !element.remarks ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Remarks</label>
                <div>
                  <textarea
                    type="text"
                    name="remarks"
                    placeholder="Enter"
                    value={element.remarks || ''}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
              </div>
            </div>
          </AccordionAddMoreSection>
        ))}

      <div>
        <span className="addFieldButton" onClick={() => addFormFields()}>
          ADD BENEFICIARY
        </span>
      </div>
    </>
  );
};

export default PbIndividualSection;
