import './ToastMessage.css';
import ToastMessageIcon from './../../../assets/images/icon-toastMessage.svg';
const ToastMessage = (props) => {
  return (
    <div className="toastMessage">
      <img
        className="toastmessage-icon"
        src={ToastMessageIcon}
        alt="Toast message Icon"
      />
      <span className="toastMessage-text">
        The changes are saved successfully
      </span>
      <span className="toastMessage-close" onClick={props.onCloseToastMessage}>
        X
      </span>
    </div>
  );
};

export default ToastMessage;
