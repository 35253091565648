// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proposedInsured-nameFields {
  margin-bottom: 15px;
}
.proposedInsured__emailForm {
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.4;
  font-size: 14px;
}
.make-primary-layout {
  align-items: center;
  justify-content: left;
  display: flex;
  -webkit-display: flex;
}
.make-primary-layout .deleteIcon {
  margin-right: 7px;
  margin-left: 7px;
}
.make-primary-layout .makePrimary-radio {
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.make-primary-layout .makePrimary-label {
  font-size: 12px;
  color: #7d7d7d;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-top: 2px;
  cursor: pointer;
}
.make-primary-layout .makePrimary-radio:checked ~ .makePrimary-label {
  color: #0177b5;
  font-weight: bold;
}
.residenceDelete {
  justify-content: space-between;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/proposedInsured/ProposedInsured.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;AACjB;AACA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;EACf,eAAe;AACjB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,8BAA8B;EAC9B,WAAW;AACb","sourcesContent":[".proposedInsured-nameFields {\n  margin-bottom: 15px;\n}\n.proposedInsured__emailForm {\n  font-weight: 400;\n  margin-bottom: 10px;\n  line-height: 1.4;\n  font-size: 14px;\n}\n.make-primary-layout {\n  align-items: center;\n  justify-content: left;\n  display: flex;\n  -webkit-display: flex;\n}\n.make-primary-layout .deleteIcon {\n  margin-right: 7px;\n  margin-left: 7px;\n}\n.make-primary-layout .makePrimary-radio {\n  margin-right: 7px;\n  display: inline-block;\n  vertical-align: middle;\n  cursor: pointer;\n}\n.make-primary-layout .makePrimary-label {\n  font-size: 12px;\n  color: #7d7d7d;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: middle;\n  margin-top: 2px;\n  cursor: pointer;\n}\n.make-primary-layout .makePrimary-radio:checked ~ .makePrimary-label {\n  color: #0177b5;\n  font-weight: bold;\n}\n.residenceDelete {\n  justify-content: space-between;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
