// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.professionalAdvisors-heading {
  font-size: 14px;
  margin-bottom:18px;
}
.checkbox-label {
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/professionalAdvisors/professionalAdvisors.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB","sourcesContent":[".professionalAdvisors-heading {\n  font-size: 14px;\n  margin-bottom:18px;\n}\n.checkbox-label {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
