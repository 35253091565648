import { SET_SHOW_AVOCATION } from '../constants/showHideConstants';

const initialState = {
  showAvocation: false,
};
export const showHideReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_AVOCATION:
      return {
        ...state,
        showAvocation: initialState.showAvocation,
      };
    default:
      return state;
  }
};
