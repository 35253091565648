import './Loader.css';

const Loader = (props) => {
  return (
    <>
      <div className="loader-wrapper">
        <div className="loader-overlay">
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {props.showText && (
              <p className="loader-text">
                Form might take 15-20 seconds to load
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Loader;
