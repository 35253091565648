// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formAdministration--Reupload-actionLink {
  background: none;
  border: none;
  padding: 0;
  color: #0074d9;
  text-decoration: underline;
  cursor: pointer;
}

.formAdministration--Reupload-actionLink:hover {
  color: #0056b3;
}
.formAdministration--Reupload-actionLink.formAdministration--ReuploadFormName {
  text-decoration: none;
  text-align: left;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/carrierUploadForm/carrierReupload.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,cAAc;EACd,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".formAdministration--Reupload-actionLink {\n  background: none;\n  border: none;\n  padding: 0;\n  color: #0074d9;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.formAdministration--Reupload-actionLink:hover {\n  color: #0056b3;\n}\n.formAdministration--Reupload-actionLink.formAdministration--ReuploadFormName {\n  text-decoration: none;\n  text-align: left;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
