// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radioTabs{
    position:relative;
}
.radioTabs input[type="radio"]{
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
}
.radioWrapper{
    cursor: pointer;
    margin-right: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.radioWrapper__label{
    font-size: 14px;
    color: #222222;
    font-weight: 600;
    display: inline-block;
}
.radioWrapper__icon{
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid #D6D6D6;
    position: relative;
    margin-right: 8px;
}
.radioWrapper__icon:before{
    content:'';
    position:absolute;
    height: 10px;
    width:10px;
    border-radius:50%;
    left:50%;
    top:50%;
    background:#fff;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
}
.radioButton:checked ~ .radioWrapper .radioWrapper__icon{
    border-color: #0177B5;
}
.radioButton:checked ~ .radioWrapper .radioWrapper__icon:before{
    background: #0177B5;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/formField/RadioButton.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,SAAS;IACT,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,OAAO;IACP,MAAM;AACV;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,qBAAqB;AACzB;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,iBAAiB;IACjB,YAAY;IACZ,UAAU;IACV,iBAAiB;IACjB,QAAQ;IACR,OAAO;IACP,eAAe;IACf,+BAA+B;IAC/B,mCAAmC;IACnC,uCAAuC;IACvC,oCAAoC;AACxC;AACA;IACI,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".radioTabs{\n    position:relative;\n}\n.radioTabs input[type=\"radio\"]{\n    opacity: 0;\n    height: 0;\n    width: 0;\n    margin: 0;\n    position: absolute;\n    left: 0;\n    top: 0;\n}\n.radioWrapper{\n    cursor: pointer;\n    margin-right: 20px;\n    display: flex;\n    align-items: center;\n    margin-bottom: 16px;\n}\n.radioWrapper__label{\n    font-size: 14px;\n    color: #222222;\n    font-weight: 600;\n    display: inline-block;\n}\n.radioWrapper__icon{\n    display: inline-block;\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    border: 1px solid #D6D6D6;\n    position: relative;\n    margin-right: 8px;\n}\n.radioWrapper__icon:before{\n    content:'';\n    position:absolute;\n    height: 10px;\n    width:10px;\n    border-radius:50%;\n    left:50%;\n    top:50%;\n    background:#fff;\n    transform: translate(-50%,-50%);\n    -ms-transform: translate(-50%,-50%);\n    -webkit-transform: translate(-50%,-50%);\n    -moz-transform: translate(-50%,-50%);\n}\n.radioButton:checked ~ .radioWrapper .radioWrapper__icon{\n    border-color: #0177B5;\n}\n.radioButton:checked ~ .radioWrapper .radioWrapper__icon:before{\n    background: #0177B5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
