import {
  FOREIGNTRAVEL_DATA_REQUEST,
  FOREIGNTRAVEL_DATA_SUCCESS,
  FOREIGNTRAVEL_DATA_FAIL,
} from '../constants/foreignTravelConstants';

export const foreignTravelReducer = (state = { ftData: [] }, action) => {
  switch (action.type) {
    case FOREIGNTRAVEL_DATA_REQUEST:
      return { loading: true, ftData: [] };
    case FOREIGNTRAVEL_DATA_SUCCESS:
      return { loading: false, ftData: action.payload };
    case FOREIGNTRAVEL_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
