// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-title {
  color: #222222;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 12px;
}

.header-row {
  padding-bottom: 14px;
}

.row-name {
  font-size: 14px;
  font-weight: normal;
  padding-top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/aviationQuestionnaire/FlightPurposeTable.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".header-title {\n  color: #222222;\n  margin-bottom: 5px;\n  font-weight: 600;\n  font-size: 12px;\n}\n\n.header-row {\n  padding-bottom: 14px;\n}\n\n.row-name {\n  font-size: 14px;\n  font-weight: normal;\n  padding-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
