import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormField from '../../../../ui/formField';
import PhoneDetailsForm from '../../../../ui/phoneDetailsForm';

const CbCompanySection = (props) => {
  const [cbCompanyNameSection, setCbCompanyNameSection] = useState({});
  const [cbCompanyAuthorizedFields, setCbCompanyAuthorizedFields] = useState(
    {}
  );
  const [cbCompanyAddressFields, setCbCompanyAddressFields] = useState({});
  const [cbCompanyContactDetailsFields, setCbCompanyContactDetailsFields] =
    useState({});
  const beneficiaryFieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = beneficiaryFieldData;

  const onCbCompanyNameFieldChange = (event) => {
    setCbCompanyNameSection((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCbCompanyAuthorizedFieldChange = (event) => {
    setCbCompanyAuthorizedFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCbCompanyAddressFieldChange = (event) => {
    setCbCompanyAddressFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onCbCompanyContactDetailsFieldsChange = (event) => {
    setCbCompanyContactDetailsFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  useEffect(() => {
    props.setCbCompanyData({
      cbCompanyNameSection,
      cbCompanyAuthorizedFields,
      cbCompanyAddressFields,
      cbCompanyContactDetailsFields,
    });
  }, [
    cbCompanyNameSection,
    cbCompanyAuthorizedFields,
    cbCompanyAddressFields,
    cbCompanyContactDetailsFields,
  ]);
  return (
    <>
      {bnData?.data && (
        <>
          <div className="flex">
            <div className="line-separation layout-1"></div>

            {bnData?.data &&
              bnData?.data?.cbCompanyNameSection?.map((item) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onCbCompanyNameFieldChange}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    dropdownType={item.dropdownType}
                    validationType={item.validationType}
                  />
                );
              })}
            <div
              className={`label-value-wrapper layout-2 ${
                !bnData?.data?.cbCompanyPercentage?.value ? 'value--empty' : ''
              }`}
            >
              <label className="field-label">
                {bnData?.data?.cbCompanyPercentage?.label}
              </label>
              <div>
                <input
                  type={bnData?.data?.cbCompanyPercentage?.type}
                  name={bnData?.data?.cbCompanyPercentage?.name}
                  defaultValue={bnData?.data?.cbCompanyPercentage?.value}
                  placeholder="Enter"
                  onChange={(e) => props.setCbCompanyPercentage(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="line-separation layout-1"></div>

          <h3 id={bnData?.data?.cbCompanyAuthorizedLabel?.id}>
            {bnData?.data?.cbCompanyAuthorizedLabel?.label}
          </h3>
          <div className="flex">
            {bnData?.data &&
              bnData?.data?.cbCompanyAuthorizedFields?.map((item) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onCbCompanyAuthorizedFieldChange}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    dropdownType={item.dropdownType}
                    validationType={item.validationType}
                  />
                );
              })}
          </div>

          <div className="flex">
            {bnData?.data &&
              bnData?.data?.cbCompanyAddressFields?.map((item) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onCbCompanyAddressFieldChange}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    dropdownType={item.dropdownType}
                  />
                );
              })}
          </div>
          <div className="line-separation layout-1"></div>

          <h3 id={bnData?.data?.cbCompanyContactDetailsLabel?.id}>
            {bnData?.data?.cbCompanyContactDetailsLabel?.label}
          </h3>
          <div className="flex">
            {bnData?.data &&
              bnData?.data?.cbCompanyContactDetailsFields?.map((item) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onCbCompanyContactDetailsFieldsChange}
                    selectOptions={item.selectOptions}
                    regex={item?.regex}
                    errorMessage={item?.errorMessage}
                    dropdownType={item.dropdownType}
                  />
                );
              })}
          </div>
          <PhoneDetailsForm
            setAddPhoneList={props.setCbCompanyPhoneFields}
            addPhoneList={props.cbCompanyPhoneFields}
            hideDelete
          />
        </>
      )}
    </>
  );
};

export default CbCompanySection;
