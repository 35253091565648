import './professionalAdvisors.css';
import { useEffect, useState } from 'react';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import AccountantSection from './accountantSection';
import AttorneySection from './attorneySection';
import BankerSection from './bankerSection';
import ProfessionalAdvisorSection from './professionalAdvisorSection';
import OtherSection from './otherSection';
import QuestionSection from './questionSection';
import axios from '../../../axios/axios';
import { useSelector, useDispatch } from 'react-redux';
import { professionalAdvisorsAction } from '../../../actions/professionalAdvisorsAction';
import Loader from '../../loader';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';

const ProfessionalAdvisors = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(professionalAdvisorsAction());
  }, [dispatch]);

  const paFormData = useSelector((state) => state.paFieldData);
  const { loading, error, paData } = paFormData;
  useEffect(() => {
    let checkboxDefaltValuearr = [];
    paData?.data?.professionalAdvisorsPerson?.option?.map((item) => {
      if (item.defaultChecked) {
        checkboxDefaltValuearr.push(item.value);
      }
    });
    setCheckBoxValue(checkboxDefaltValuearr);
  }, [paData?.data]);

  const [checkboxValue, setCheckBoxValue] = useState([]);
  const [otherValue, setOtherValue] = useState();
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const [accountantInfo, setAccountantInfo] = useState([]);
  const [attorneyInfo, setAttorneyInfo] = useState([]);
  const [bankerInfo, setBankerInfo] = useState([]);
  const [professionalAdvisorInfo, setProfessionalAdvisorInfo] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);

  const [acVerification, setAcVerification] = useState();
  const [atVerification, setAtVerification] = useState();
  const [bnVerification, setBnVerification] = useState();
  const [paVerification, setPaVerification] = useState();
  const [otherVerification, setOtherVerification] = useState();

  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  useEffect(() => {
    setAcVerification(
      paData?.data?.accountantVerificationCheckBox?.defaultChecked
    );
    setAtVerification(
      paData?.data?.attorneyVerificationCheckBox?.defaultChecked
    );
    setBnVerification(paData?.data?.bankerVerificationCheckBox?.defaultChecked);
    setPaVerification(
      paData?.data?.professionalAdvisorVerificationCheckBox?.defaultChecked
    );
    setOtherVerification(
      paData?.data?.otherVerificationCheckBox?.defaultChecked
    );
    setOtherValue(paData?.data?.otherValue?.value);
  }, [paData?.data]);

  let result;
  checkboxValue.length > 0 &&
    checkboxValue.forEach((item) => {
      switch (item) {
        case 'professionalAdvisor':
          result = {
            professionalAdvisorFields: professionalAdvisorInfo,
            professionalAdvisorVerificationCheckBox: paVerification,
            ...result,
          };
          break;
        case 'accountant':
          result = {
            accountantSectionFields: accountantInfo,
            accountantVerificationCheckBox: acVerification,
            ...result,
          };
          break;
        case 'attorney':
          result = {
            attorneySectionFields: attorneyInfo,
            attorneyVerificationCheckBox: atVerification,
            ...result,
          };
          break;
        case 'banker':
          result = {
            bankerSectionFields: bankerInfo,
            bankerVerificationCheckBox: bnVerification,
            ...result,
          };
          break;
        case 'other':
          result = {
            otherSectionFields: otherInfo,
            otherValue: { 'pa-otherValue': otherValue },
            otherVerificationCheckBox: otherVerification,
            ...result,
          };
          break;
        default:
          return null;
      }
    });

  let payload = {
    ...result,
    professionalAdvisorsPerson: checkboxValue,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s12`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s12',
      },
    });
  }, [payload]);

  return (
    <>
      <CommonFormHeader
        backPath="/form/beneficiaryinformation"
        nextPath="/form/financialinformation"
        pageHeading={paData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={paData?.common_details?.case_id}
        isCompleted={paData?.metadata?.isCompleted}
        sectionId="f1v1s12"
        userName={paData?.common_details?.name}
        productSubType={paData?.common_details?.product_subtype}
        formId={paData?.common_details?.form_id}
        otherCasesInfo={paData?.common_details?.other_cases}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          <QuestionSection
            setCheckBoxValue={setCheckBoxValue}
            checkboxValue={checkboxValue}
            otherValue={otherValue}
            setOtherValue={setOtherValue}
          />

          {checkboxValue.includes('accountant') && paData?.data && (
            <AccountantSection
              setAccountantInfo={setAccountantInfo}
              acVerification={acVerification}
              setAcVerification={setAcVerification}
            />
          )}

          {checkboxValue.includes('attorney') && paData?.data && (
            <AttorneySection
              setAttorneyInfo={setAttorneyInfo}
              atVerification={atVerification}
              setAtVerification={setAtVerification}
            />
          )}

          {checkboxValue.includes('banker') && paData?.data && (
            <BankerSection
              setBankerInfo={setBankerInfo}
              bnVerification={bnVerification}
              setBnVerification={setBnVerification}
            />
          )}

          {checkboxValue.includes('professionalAdvisor') && paData?.data && (
            <ProfessionalAdvisorSection
              setProfessionalAdvisorInfo={setProfessionalAdvisorInfo}
              paVerification={paVerification}
              setPaVerification={setPaVerification}
            />
          )}

          {checkboxValue.includes('other') &&
            Boolean(otherValue) &&
            paData?.data && (
              <OtherSection
                setOtherInfo={setOtherInfo}
                otherVerification={otherVerification}
                setOtherVerification={setOtherVerification}
                otherValue={otherValue}
              />
            )}
        </FormFieldsWrapper>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}{' '}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default ProfessionalAdvisors;
