import {
  INSURANCE_DATA_REQUEST,
  INSURANCE_DATA_SUCCESS,
  INSURANCE_DATA_FAIL,
} from '../constants/exitingInsuranceConstants';

export const exitingInsuranceReducers = (state = { eiData: [] }, action) => {
  switch (action.type) {
    case INSURANCE_DATA_REQUEST:
      return { loading: true, eiData: [] };
    case INSURANCE_DATA_SUCCESS:
      return { loading: false, eiData: action.payload };
    case INSURANCE_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
