import '../formField/FormField.css';
import PropTypes from 'prop-types';
import { useState } from 'react';

const CommonTextArea = (props) => {
  const [textareaValue, setTextareaValue] = useState(props?.fieldData?.value);
  const handleChange = (e) => {
    props.onChangeHandler(e);
    setTextareaValue(e.target.value);
  };
  const textarea = (
    <textarea
      id={props.fieldData.id}
      name={props.fieldData.name}
      onChange={handleChange}
      defaultValue={props.fieldData.value}
      placeholder={props.fieldData.placeholder || 'Enter'}
    />
  );
  return (
    <>
      <div
        className={`label-value-wrapper ${
          props.fieldData.layoutType ? props.fieldData.layoutType : ''
        } ${!textareaValue ? 'value--empty' : ''}`}
        key={props.fieldData.id}
      >
        {props.fieldData.label && (
          <label className="field-label" htmlFor={props.fieldData.id}>
            {props.fieldData.label}
          </label>
        )}

        <div>{textarea}</div>
      </div>
    </>
  );
};
export default CommonTextArea;
CommonTextArea.propTypes = {
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
