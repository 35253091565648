import { useEffect, useState } from 'react';
import DeleteIcon from '../../../../assets/images/delete-icon.svg';
import MappingMultiSelectDropdown from './mappingMultiSelectDropdown';
import MappingSelectDropdown from './mappingSelectDropdown';
const VerifyMappingAddMoreFields = ({
  index,
  keyId,
  carrierFieldValue,
  cpwSectionValue,
  dropdownData,
  lockFields,
  deleteAddMoreField,
  newKeysData,
  setNewKeysData,
}) => {
  const [cpwSection, setCPWSection] = useState('');
  const [cpwField, setCPWField] = useState([]);
  const [cpwFieldArray, setCPWFieldArray] = useState([]);
  const [cpwContext, setCPWContext] = useState('');
  const [cpwContextArray, setCPWContextArray] = useState([]);

  let handleChange = (
    i,
    id,
    value,
    dropdownName,
    carrierFieldValue,
    cpwSection
  ) => {
    let newFormValues = [...newKeysData];
    let index = newFormValues.findIndex((item) => {
      return item.tempId === id + 'new-' + i;
    });

    if (index > -1) {
      newFormValues[index]['cpw_section'] = cpwSection;
      newFormValues[index][dropdownName] = value;
    } else {
      newFormValues.push({
        tempId: parseInt(id) + 'new-' + i,
        id: parseInt(id),
        cpw_section: cpwSection,
        carrier_field: carrierFieldValue,
        [dropdownName]: value,
      });
      setNewKeysData(newFormValues);
    }
  };

  useEffect(() => {
    setCPWSection(cpwSectionValue);
    setCPWField([]);
    setCPWContext('');
  }, [cpwSectionValue]);

  useEffect(() => {
    let selectedValueIndex = dropdownData.findIndex(
      (item) => item.section === cpwSection
    );
    setCPWFieldArray(dropdownData[selectedValueIndex]?.fields);
  }, [dropdownData, cpwSection]);

  useEffect(() => {
    let selectedValueIndex = cpwFieldArray?.findIndex(
      (item) => item.field_label === cpwField
    );

    if (selectedValueIndex) {
      setCPWContextArray(cpwFieldArray[selectedValueIndex]?.contexts);
    }
  }, [cpwFieldArray, cpwField]);
  return (
    <div className="flex verifyMapping--addMore" key={index}>
      <div className="label-value-wrapper verifyMapping--dropdownFields">
        <label className="field-label">Form field</label>
        <div>
          <p className="verifyMapping--cpwFieldLabel">{carrierFieldValue}</p>
        </div>
      </div>
      <div className="label-value-wrapper verifyMapping--dropdownFields">
        <label
          className="field-label"
          htmlFor={`addMore-cpw_section-${keyId}-${index}`}
        >
          CPW Section <sup>*</sup>
        </label>
        <div>
          <select
            name="cpw_section"
            id={`addMore-cpw_section-${keyId}-${index}`}
            defaultValue={cpwSection ? cpwSection : ''}
            disabled
          >
            <option value={cpwSection}>{cpwSection}</option>
          </select>
        </div>
      </div>
      <div className="label-value-wrapper verifyMapping--dropdownFields">
        <label
          className="field-label"
          htmlFor={`addMore-cpw_field-${keyId}-${index}`}
        >
          CPW Field <sup>*</sup>
        </label>
        <div>
          <MappingMultiSelectDropdown
            selectedValues={cpwField}
            options={cpwFieldArray}
            labelKey="field_label"
            dropdownType="addmore__mappingCPWField"
            setCPWContext={setCPWContext}
            setCPWContextArray={setCPWContextArray}
            onFieldsClick={() => {
              setCPWContext('');
              handleChange(
                index,
                keyId,
                cpwField,
                'cpw_field',
                carrierFieldValue,
                cpwSection
              );
            }}
          />
        </div>
      </div>

      <div className="label-value-wrapper verifyMapping--dropdownFields">
        <label
          className="field-label"
          htmlFor={`addMore-context-${keyId}-${index}`}
        >
          CPW Context <sup>*</sup>
        </label>
        <div>
          <MappingSelectDropdown
            options={cpwContextArray}
            lockFields={lockFields}
            selectedValue={cpwContext}
            setSelectedValue={setCPWContext}
            onClickHandler={(e) => {
              setCPWContext(e.target.innerText);
              handleChange(
                index,
                keyId,
                e.target.innerText,
                'context',
                carrierFieldValue,
                cpwSection
              );
            }}
          />
        </div>
      </div>
      {index > -1 ? (
        <div className="verifyMapping--deleteAddMore">
          <span
            onClick={() => deleteAddMoreField(index)}
            className="icon-delete"
          >
            <img src={DeleteIcon} alt="deleteIcon" className="deleteIcon " />
          </span>
        </div>
      ) : null}
    </div>
  );
};
export default VerifyMappingAddMoreFields;
