import {
  PAGE_NAVIGATION_DATA_REQUEST,
  PAGE_NAVIGATION_DATA_SUCCESS,
  PAGE_NAVIGATION_DATA_FAIL,
} from '../constants/pageNavigationConstants';

export const pageNavigationReducer = (state = { formData: [] }, action) => {
  switch (action.type) {
    case PAGE_NAVIGATION_DATA_REQUEST:
      return { loading: true, formData: [] };
    case PAGE_NAVIGATION_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case PAGE_NAVIGATION_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
