// import { HealthAndLifestyleJson } from "./../healthAndLifestyleJson";
import LabelRadioWithFields from '../../../ui/labelRadioWithFields';
import './../healthAndLifestyle.css';
import { useSelector } from 'react-redux';

const AdditionalQuestions = (props) => {
  const hlFormData = useSelector((state) => state.hlData);
  const { loading, error, formData } = hlFormData;

  const onDetailsChangeHandler = (e) => {
    props.setAdditionalQuestionDetailsData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const additionalQuestionsLabel =
    formData?.data?.additionalQuestionsLabel.label;
  const additionalQuestionsJson = formData?.data?.additionalQuestions;

  const additionalQuestionsFields = additionalQuestionsJson.map(
    (item, index) => {
      return (
        <div key={index} className="line-separation">
          <LabelRadioWithFields
            id={item.id}
            label={item.label}
            options={item.radioOptions}
            provideDetails={item.provideDetails}
            setRadioChangeValue={props.setAdditionalQuestionRadioData}
            onDetailsChangeHandler={onDetailsChangeHandler}
          />
        </div>
      );
    }
  );
  return (
    <>
      {additionalQuestionsLabel && <h3>{additionalQuestionsLabel}</h3>}
      {additionalQuestionsFields && additionalQuestionsFields}
    </>
  );
};

export default AdditionalQuestions;
