import axios from '../axios/axios';
import {
  PURCHASER_OWNER_DATA_REQUEST,
  PURCHASER_OWNER_DATA_SUCCESS,
  PURCHASER_OWNER_DATA_FAIL,
} from '../constants/purchaserOwnerConstants';

export const purchaserOwnerAction = () => async (dispatch) => {
  try {
    dispatch({ type: PURCHASER_OWNER_DATA_REQUEST });
    const { data } = await axios.post('/rpiq-form-fetch', {
      section: 'f1v1s24',
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: PURCHASER_OWNER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: PURCHASER_OWNER_DATA_FAIL,
      payload: error,
    });
  }
};
