import axios from '../../../axios/axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../loader';

const LandingPageModal = () => {
  const [showLoader, setShowLoader] = useState(true);
  const productType = localStorage.getItem('productType');
  useEffect(() => {
    axios
      .post(`/rpiq-form-update-so-fetch/${localStorage.getItem('formId')}`)
      .then(() => {
        setShowLoader(false);
        if (productType === 'annuity') {
          navigate('/form/ppaapplication');
          localStorage.setItem('sectionId', 'f1v1s23');
        } else {
          navigate('/form/products&coverage');
          localStorage.setItem('sectionId', 'f1v1s1');
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  }, []);
  let navigate = useNavigate();
  return <>{showLoader && <Loader />}</>;
};
export default LandingPageModal;
