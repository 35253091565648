import deleteIcon from './../../../assets/images/delete-icon.svg';
import FormField from '../../ui/formField/index.js';
import AccordionAddMoreSection from '../accordionAddMoreSection';

const EmailDetailsForm = (props) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...props.addEmailList];
    if (name === 'makeDefault') {
      list.map((item, i) => {
        i === index ? (item.makeDefault = true) : (item.makeDefault = false);
        return item;
      });
    } else {
      list[index][name] = value;
    }

    props.setAddEmailList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...props.addEmailList];
    list.splice(index, 1);
    props.setAddEmailList(list);
  };
  const handleAddClick = () => {
    props.setAddEmailList([
      ...props.addEmailList,
      { email: '', description: '', makeDefault: false },
    ]);
  };

  return (
    <div className="full-width">
      {props?.addEmailList?.length === 0 && (
        <span
          className="addFieldButton"
          onClick={() =>
            props.setAddEmailList((oldArray) => [
              ...oldArray,
              { email: '', description: '', makeDefault: '' },
            ])
          }
        >
          ADD EMAIL ADDRESS
        </span>
      )}
      {props?.addEmailList &&
        props?.addEmailList?.map((x, i) => {
          return (
            <AccordionAddMoreSection
              key={i}
              index={i}
              accordionHeading={`Email - ${i + 1}`}
              onDeleteClick={i ? () => handleRemoveClick(i) : null}
              hideDelete={props.hideDelete}
            >
              <div key={i} className="flex">
                <div className="label-value-wrapper layout-3">
                  <label htmlFor={`email${i}`} className="field-label">
                    Email
                  </label>
                  <FormField
                    type="email"
                    name="email"
                    id={`email${i}`}
                    placeholder="Enter"
                    value={x.email}
                    onChangeHandler={(e) => handleInputChange(e, i)}
                    addMoreField={true}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-3 ${
                    !x.description ? 'value--empty' : ''
                  }`}
                >
                  <label htmlFor={`description${i}`} className="field-label">
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    id={`description${i}`}
                    placeholder="Enter"
                    value={x.description}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="label-value-wrapper layout-3 make-primary-layout">
                  <input
                    type="radio"
                    id={`makePrimary${i}`}
                    name="makeDefault"
                    value={true}
                    onChange={(e) => handleInputChange(e, i)}
                    className="makePrimary-radio"
                    checked={x.makeDefault}
                  />
                  <label
                    htmlFor={`makePrimary${i}`}
                    className="makePrimary-label"
                  >
                    Make Primary
                  </label>
                </div>
                {props.addEmailList.length - 1 === i && (
                  <span onClick={handleAddClick} className="addFieldButton">
                    ADD EMAIL ADDRESS
                  </span>
                )}
              </div>
            </AccordionAddMoreSection>
          );
        })}
    </div>
  );
};
export default EmailDetailsForm;
