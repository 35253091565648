import {
  NOTES_DATA_REQUEST,
  NOTES_DATA_SUCCESS,
  NOTES_DATA_FAIL,
} from '../constants/notesConstants';

export const notesReducer = (state = { formData: [] }, action) => {
  switch (action.type) {
    case NOTES_DATA_REQUEST:
      return { loading: true, formData: [] };
    case NOTES_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case NOTES_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
