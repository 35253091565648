import {
  AGENT_QUESTIONNAIRE_DATA_REQUEST,
  AGENT_QUESTIONNAIRE_DATA_SUCCESS,
  AGENT_QUESTIONNAIRE_DATA_FAIL,
} from '../constants/agentConstant';

export const agentReducer = (state = { formData: [] }, action) => {
  switch (action.type) {
    case AGENT_QUESTIONNAIRE_DATA_REQUEST:
      return { loading: true, formData: [] };
    case AGENT_QUESTIONNAIRE_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case AGENT_QUESTIONNAIRE_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
