import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { productListReducer } from './reducers/productReducers';
import { caseListingReducer } from './reducers/caseListingReducers';
import { proposedInsuredReducer } from './reducers/proposedInsuredReducers';
import { healthLifeStyleReducer } from './reducers/healthLifeStyleReducers';
import { otherGeneralQuestionsReducer } from './reducers/otherGeneralQuestionsReducers';
import { physicianInformationReducer } from './reducers/physicianInfomationReducer';
import { professionalAdvisorsReducer } from './reducers/professionalAdvisorsReducers';
import { exerciseReducer } from './reducers/exerciseReducers';
import { foreignTravelReducer } from './reducers/foreignTravelReducers';
import { familyHealthHistoryReducer } from './reducers/familyHealthHistoryReducer';
import { exitingInsuranceReducers } from './reducers/exitingInsuranceReducers';
import { avocationReducer } from './reducers/avocationReducer';
import { foreignResidencyReducer } from './reducers/foreignResidencyReducers';
import { formValidationReducer } from './reducers/formValidationReducer';
import { financialInfoReducer } from './reducers/financialInfoReducer';
import { purposeOfCoverageReducer } from './reducers/purposeOfCoverageReducer';
import { sendDataReducer } from './reducers/sendDataReducer';
import { policyOwnerReducer } from './reducers/policyOwnerReducer';
import { longTermCareReducer } from './reducers/longTermCareReducers';
import { notesReducer } from './reducers/notesReducer';
import { businessInsuranceReducer } from './reducers/businessInsuranceReducer';
import { beneficiaryReducers } from './reducers/beneficiaryReducers';
import { applicationFilloutReducer } from './reducers/applicationFilloutReducer';
import { pageNavigationReducer } from './reducers/pageNavigationReducer';
import { navDataReducer } from './reducers/caseListingReducers';
import { showHideReducer } from './reducers/showHideReducers';
import { policyDetailsReducer } from './reducers/policyDetailsReducer';
import { ppaApplicationReducer } from './reducers/ppaApplicationReducer';
import { purchaserOwnerReducer } from './reducers/purchaserOwnerReducer';
import { dispOfNetPremiumReducer } from './reducers/dispOfNetPremiumReducer';
import { agentReducer } from './reducers/agentReducer';
import { exitingAnnuityReducer } from './reducers/existingAnnuityReducer';
import { policyOwnerAnnuityReducer } from './reducers/policyOwnerAnnuityReducer';
import { aviationReducer } from './reducers/aviationReducer';
import { applicationAvailableReducer } from './reducers/applicationAvailableReducer';
import { carrierUploadReducer } from './reducers/carrierUploadReducer';
import { formAdministrationReducer } from './reducers/formAdministrationReducer';
import { markValidFieldsReducer } from './reducers/markValidFieldsReducer';
import { verifyMappingReducer } from './reducers/verifyMappingReducer';
const reducer = combineReducers({
  productList: productListReducer,
  usersList: caseListingReducer,
  piData: proposedInsuredReducer,
  hlData: healthLifeStyleReducer,
  ogqData: otherGeneralQuestionsReducer,
  pyFieldData: physicianInformationReducer,
  paFieldData: professionalAdvisorsReducer,
  exerciseData: exerciseReducer,
  ftFieldData: foreignTravelReducer,
  familyHealthReducer: familyHealthHistoryReducer,
  exitingInsuranceData: exitingInsuranceReducers,
  avocationData: avocationReducer,
  aviationData: aviationReducer,
  foreignResidencyData: foreignResidencyReducer,
  validationFields: formValidationReducer,
  financialInfoData: financialInfoReducer,
  policyOwnerData: policyOwnerReducer,
  purposeOfCoverageData: purposeOfCoverageReducer,
  longTermCareData: longTermCareReducer,
  notesData: notesReducer,
  businessInsuranceData: businessInsuranceReducer,
  beneficiaryData: beneficiaryReducers,
  applicationFilloutData: applicationFilloutReducer,
  pageNav: pageNavigationReducer,
  navData: navDataReducer,
  ppaApplicationData: ppaApplicationReducer,
  purchaserOwnerData: purchaserOwnerReducer,
  dispOfNetPremiumData: dispOfNetPremiumReducer,
  agentData: agentReducer,
  existingAnnuityData: exitingAnnuityReducer,
  policyOwnerAnnuityData: policyOwnerAnnuityReducer,
  //store for sending data
  sendData: sendDataReducer,
  showHideData: showHideReducer,
  policyDetailsData: policyDetailsReducer,
  applicationAvailabeData: applicationAvailableReducer,
  carrierUploadData: carrierUploadReducer,
  formAdministrationData: formAdministrationReducer,
  markValidFieldsData: markValidFieldsReducer,
  verifyMappingData: verifyMappingReducer,
});

const initialStore = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialStore,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
