import axios from '../axios/axios';
import {
  APPLICATION_AVAILABLE_REQUEST,
  APPLICATION_AVAILABLE_SUCCESS,
  APPLICATION_AVAILABLE_FAIL,
} from '../constants/applicationAvailableConstants';

export const applicationAvailableAction = () => async (dispatch) => {
  try {
    dispatch({ type: APPLICATION_AVAILABLE_REQUEST });
    const { data } = await axios.get('/get-applications');

    dispatch({
      type: APPLICATION_AVAILABLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: APPLICATION_AVAILABLE_FAIL,
      payload: error,
    });
  }
};
