import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispOfNetPremiumAction } from '../../../actions/dispOfNetPremiumAction';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import CommonFormHeader from '../../commonFormHeader';
import Loader from '../../loader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioOptions from '../../ui/labelRadioOptions/index.js';
import ToastMessage from '../../ui/toastMessage';

const DispositionOfNetPremium = () => {
  const pageData = useSelector((state) => state.dispOfNetPremiumData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dispOfNetPremiumAction());
  }, [dispatch]);

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const [netPremiumValues, setNetPremiumValues] = useState({});
  const netPremiumChangeHandler = (e) => {
    setNetPremiumValues(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    netPremiumValues: netPremiumValues,
  };

  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s25',
      },
    });
  }, [payload]);

  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };

  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s25`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/purchaserownerquestionnaire"
        nextPath="/form/agentquestionnaire"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId={formData?.common_details?.section_id}
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
        productType={formData?.common_details?.product_type}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          <LabelRadioOptions
            label={formData?.data?.netPremiumValues?.label}
            options={formData?.data?.netPremiumValues?.radioOptions}
            onChangeHandler={netPremiumChangeHandler}
          ></LabelRadioOptions>
        </FormFieldsWrapper>
      )}

      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default DispositionOfNetPremium;
