import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '../../ui/formField';
import moment from 'moment';
import './carrierUpload.css';
import './carrierReupload.css';
import CarrierUploadFormDropdown from './carrierUploadFormDropdown';
import CarrierUploadFormMultipleDropdown from './carrierUploadFormMultipleDropdown';
import TagInput from './tagInput';
import Header from '../../header';
import Footer from '../../footer';
import Loader from '../../loader';
import MatchingFormList from './matchingForm';
import { carrierUploadAction } from '../../../actions/carrierUploadAction';
import { formAdministrationAction } from '../../../actions/fromAdministrationAction';
import { useToast } from '../../notifications/ToastContext';
import axios from 'axios';

const CarrierReUploadForm = () => {
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [formTypeSelected, setFormTypeSelected] = useState('');
  const [formName, setFormName] = useState('');
  const [productCategorySelected, setProductCategorySelected] = useState('');
  const [carrierSelected, setCarrierSelected] = useState([]);
  const [productTypeValues, setProductTypeValue] = useState([]);
  const [productTypeSelected, setProductTypeSelected] = useState([]);
  const [stateSelected, setStateSelected] = useState([]);
  const [carrierAttachemnt, setCarrierAttachment] = useState(null);
  const [fileUploadErr, setFileUploadErr] = useState('');
  const [toastMessage, setToastMessage] = useState(null);
  const [formVersionNumber, setFormVersionNumber] = useState('');
  const [formDate, setFormDate] = useState(null);
  let productDisability = 'disability';
  let productGeneralForms = 'General Forms';
  let brokerDealerFormText = 'Broker Dealer Forms';
  const [brokerDealerFormId, setBrokerDealerFormId] = useState(null);
  let golcondaFormText = 'Golconda Forms';
  const [golcondaFormId, setGolcondaFormId] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uploadId = queryParams.get('upload_id');
  const navigate = useNavigate();
  const { addToast } = useToast();
  const handleShowToast = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'success',
      message: message,
    };
    addToast(newToast);
  };

  const handleShowError = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'error',
      message: message,
    };
    addToast(newToast);
  };

  const onFormNameChange = (e) => {
    const name = e.target.value.replace(/[^a-zA-Z0-9./\\_-\s]/g, '');
    setFormName(name);
  };
  const onCarrierAttachmentChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type !== 'application/pdf') {
        setFileUploadErr(
          'Selected file type is not supported, please upload a PDF file'
        );
        setCarrierAttachment(null);
      } else if (file.size > 26214400) {
        setFileUploadErr('Please upload a file that is less than 25MB');
        setCarrierAttachment(null);
      } else {
        setFileUploadErr('');
        setCarrierAttachment(file);
      }
    } else {
      setFileUploadErr('');
      setCarrierAttachment(null);
    }
  };

  const handleSelectedTagsChange = (tags) => {
    setSelectedTags(tags);
  };

  const clearForm = () => {
    setCarrierAttachment(null);
    setFormTypeSelected('');
    setFormName('');
    setProductCategorySelected('');
    setCarrierSelected([]);
    setProductTypeSelected([]);
    setStateSelected([]);
    setCarrierAttachment(null);
    setFileUploadErr('');
    setSelectedTags([]);
    setFormVersionNumber('');
    setFormDate(null);
    dispatch(carrierUploadAction());
  };

  const pageData = useSelector((state) => state.carrierUploadData);
  const existingFormsData = useSelector((state) => {
    const systemForms = state.formAdministrationData?.formData?.system || [];
    const uploadedForms =
      state.formAdministrationData?.formData?.uploaded || [];
    return [...systemForms, ...uploadedForms];
  });

  const { loading, formData } = pageData;

  const suggestedTags = formData?.category_tags ? formData.category_tags : [];
  const [selectedTags, setSelectedTags] = useState([]);
  const dispatch = useDispatch();
  const reuploadFormDetails = existingFormsData.find(
    (obj) => obj.upload_id == uploadId
  );

  useEffect(() => {
    dispatch(carrierUploadAction());
    dispatch(formAdministrationAction());
  }, [dispatch]);

  useEffect(() => {
    if (reuploadFormDetails) {
      setFormTypeSelected(reuploadFormDetails.form_type);
      setFormName(reuploadFormDetails.form_name);
      setProductCategorySelected(reuploadFormDetails.product_category);
      setCarrierSelected(reuploadFormDetails.carrier_id);
      setProductTypeSelected(reuploadFormDetails.product_id);
      setStateSelected(reuploadFormDetails.state_id);
      setSelectedTags(reuploadFormDetails.category_tags);
      setFormVersionNumber(reuploadFormDetails.form_version);
      setFormDate(
        moment(new Date(reuploadFormDetails.form_date)).format('YYYY-MM-DD')
      );
    }
  }, [reuploadFormDetails]);
  const onUploadClick = () => {
    setShowDataSaveLoader(true);
    const payload = {
      upload_id: uploadId,
      form_types: formTypeSelected,
      form_name: formName,
      product_categories: productCategorySelected,
      carriers: JSON.stringify(carrierSelected),
      products: JSON.stringify(productTypeSelected),
      states: JSON.stringify(stateSelected),
      category_tags: JSON.stringify(selectedTags),
      form_version: formVersionNumber,
      form_date: formDate ? moment(formDate).format('YYYY-MM-DD') : null,
    };
    if (carrierAttachemnt) {
      payload.pdf_file = carrierAttachemnt;
    }
    // Set a timeout to show success after 3 seconds
    const successTimeout = setTimeout(() => {
      setShowDataSaveLoader(false);
      handleShowToast('File Uploaded Successfully');
      navigate('/formadministration', { replace: true });
    }, 3000);

    axios({
      url: 'carrier-upload/update',
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        // Clear the timeout since the response came within 3 seconds
        clearTimeout(successTimeout);

        setShowDataSaveLoader(false);
        clearForm();
        handleShowToast('File Uploaded Successfully');
        navigate('/formadministration', { replace: true });
      })
      .catch(function (error) {
        // Clear the timeout in case of an error
        clearTimeout(successTimeout);

        setShowDataSaveLoader(false);
        handleShowError(
          'File Upload Failed!! Please contact the administrator'
        );
      });
  };

  const onDownloadClick = () => {
    const uploadId = reuploadFormDetails?.upload_id;
    setShowDataSaveLoader(true);

    axios({
      url: 'carrier-upload/pdf-download',
      method: 'POST',
      data: { upload_id: uploadId },
      responseType: 'blob',
    })
      .then((response) => {
        setShowDataSaveLoader(false);
        let disposition = response.headers['content-disposition'];
        const filename = disposition.split('filename=')[1].replace(/["]+/g, '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setToastMessage('File downloaded successfully');
      })
      .catch(function (error) {
        setShowDataSaveLoader(false);
        handleShowError('Unexpected error ! Please contact administrator');
      });
  };

  useEffect(() => {
    // Initialize variables to store the found objects
    let brokerDealerFormObj;
    let golcondaFormObj;

    // Iterate over the carriers array to find both brokerDealerFormObj and golcondaFormObj
    formData?.carriers?.forEach((item) => {
      if (item?.carrier === brokerDealerFormText) {
        brokerDealerFormObj = item;
      } else if (item?.carrier === golcondaFormText) {
        golcondaFormObj = item;
      }
    });

    // Update state variables with the found IDs
    setBrokerDealerFormId(brokerDealerFormObj?.id);
    setGolcondaFormId(golcondaFormObj?.id);
  }, [formData?.carriers, brokerDealerFormText, golcondaFormText]); // Getting the ID's of Broker Dealer Form Carrier and Golconda Forms Carrier and assigning it in state

  useEffect(() => {
    //Selecting All the states if carrier is Broker Dealer Form or Golconda Form
    if (
      carrierSelected?.includes(brokerDealerFormId) ||
      carrierSelected?.includes(golcondaFormId)
    ) {
      let stateIds = formData?.states?.map((item) => item.id);
      setStateSelected(stateIds);
    }
  }, [formData?.states, carrierSelected]);

  useEffect(() => {
    let productTypeArr = formData?.products?.filter(
      (item) => item?.product_category === productCategorySelected
    );

    if (productCategorySelected.toLowerCase() === productDisability) {
      //if Category "Disability" is Selected
      const selectedObj = formData?.products?.find(
        (item) => item?.product_category?.toLowerCase() === productDisability
      );
      setProductTypeSelected([selectedObj?.id]);
    } else if (productCategorySelected === productGeneralForms) {
      //if Category "General Forms" is Selected
      const selectedObj = formData?.products?.find(
        (item) => item?.product_category === productGeneralForms
      );
      setProductTypeSelected([selectedObj?.id]);
    }

    setProductTypeValue(productTypeArr);
  }, [formData, productCategorySelected]);

  return (
    <>
      {loading || reuploadFormDetails === undefined ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Header />
          <div className="carrierUploadFormWrapper">
            <div className="carrierUploadForm">
              <h2 className="carrierUploadForm--heading">Edit Uploaded Form</h2>
              <div className="carrierUploadForm--contents flex">
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">
                    Carrier
                    <span className="carrierUploadForm--mandatoryField">*</span>
                  </label>
                  <div>
                    <CarrierUploadFormMultipleDropdown
                      setSelectedIds={setCarrierSelected}
                      options={formData?.carriers}
                      dropdownType="carriers"
                      labelKey="carrier"
                      hideAllText={true}
                      initialValueIds={reuploadFormDetails?.carrier_id || []}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">
                    State
                    <span className="carrierUploadForm--mandatoryField">*</span>
                  </label>
                  <div>
                    {carrierSelected?.includes(brokerDealerFormId) ||
                    carrierSelected?.includes(golcondaFormId) ? (
                      <input type="text" name="states" value="All" disabled />
                    ) : (
                      <CarrierUploadFormMultipleDropdown
                        setSelectedIds={setStateSelected}
                        options={formData?.states}
                        dropdownType="states"
                        labelKey="state"
                        labelDisplayKey="state_short_form"
                        initialValueIds={reuploadFormDetails?.state_id || []}
                      />
                    )}
                  </div>
                </div>
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">
                    Category
                    <span className="carrierUploadForm--mandatoryField">*</span>
                  </label>
                  <div>
                    <CarrierUploadFormDropdown
                      selected={productCategorySelected}
                      setSelected={setProductCategorySelected}
                      options={formData?.product_categories}
                      labelKey="product_category"
                    />
                  </div>
                </div>

                <div className="label-value-wrapper layout-2">
                  <label className="field-label">
                    Product
                    <span className="carrierUploadForm--mandatoryField">*</span>
                  </label>
                  <div>
                    {productCategorySelected?.toLowerCase() ===
                      productDisability ||
                    productCategorySelected === productGeneralForms ? (
                      <input
                        type="text"
                        name="products"
                        placeholder="Enter"
                        value={productCategorySelected}
                        disabled
                      />
                    ) : (
                      <CarrierUploadFormMultipleDropdown
                        setSelectedIds={setProductTypeSelected}
                        options={productTypeValues}
                        dropdownType="products"
                        labelKey="product"
                        initialValueIds={reuploadFormDetails?.product_id || []}
                      />
                    )}
                  </div>
                </div>
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">
                    Form Name
                    <span className="carrierUploadForm--mandatoryField">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      name="formName"
                      id="carrierFormName"
                      placeholder="Enter"
                      value={formName}
                      onChange={onFormNameChange}
                      maxLength="100"
                    />
                  </div>
                </div>

                <div className="label-value-wrapper layout-2">
                  <label className="field-label">
                    Form Category
                    <span className="carrierUploadForm--mandatoryField">*</span>
                  </label>
                  <div>
                    <CarrierUploadFormDropdown
                      selected={formTypeSelected}
                      setSelected={setFormTypeSelected}
                      options={formData?.form_types}
                      labelKey="form_type"
                    />
                  </div>
                </div>
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">Form Version Number</label>
                  <div>
                    <input
                      type="text"
                      name="formVersionNumber"
                      id="formVersionNumber"
                      placeholder="Enter version number"
                      value={formVersionNumber}
                      onChange={(e) => setFormVersionNumber(e.target.value)}
                      maxLength="100"
                    />
                  </div>
                </div>

                <div className="label-value-wrapper layout-2">
                  <label className="field-label">Form Date</label>
                  <div>
                    <FormField
                      type="date"
                      validationType="inputDate"
                      name="formDate"
                      max="3000-12-31"
                      placeholder="Enter"
                      value={formDate}
                      onChangeHandler={(date) => setFormDate(date.target.value)}
                    />
                  </div>
                </div>

                <div className="label-value-wrapper layout-1">
                  <label className="field-label">Category Tag </label>
                  <div>
                    <TagInput
                      suggestions={suggestedTags}
                      tags={selectedTags}
                      onTagAdd={handleSelectedTagsChange}
                      onTagRemove={handleSelectedTagsChange}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper layout-1">
                  <div>
                    <label className="field-label">
                      You can review{' '}
                      <button
                        onClick={onDownloadClick}
                        className="formAdministration--Reupload-actionLink"
                      >
                        Existing PDF
                      </button>{' '}
                      or attach a new version (Upto 25MB) from below
                    </label>
                  </div>
                  <div>
                    <input
                      type="file"
                      name="carrierUploadAttachment"
                      id="carrierUploadAttachment"
                      accept=".pdf"
                      onChange={onCarrierAttachmentChange}
                      hidden
                    />
                    <label
                      htmlFor="carrierUploadAttachment"
                      className="carrierUploadForm--customUpload"
                    >
                      <span className="carrierUploadForm-fileName">
                        {carrierAttachemnt
                          ? carrierAttachemnt.name
                          : 'Upload PDF file'}
                      </span>
                      <span className="carrierUploadForm-fileBrowse">
                        Browse
                      </span>
                    </label>
                  </div>
                  {fileUploadErr && (
                    <p className="carrierUpload-err">{fileUploadErr}</p>
                  )}
                </div>
              </div>

              {/* {process.env.REACT_APP_ENABLE_FILTERS === 'Y' && (
                <MatchingFormList formData={matchingExistingForms} />
              )} */}

              <button
                className="button carrierUploadForm--button"
                disabled={
                  formTypeSelected &&
                  formName &&
                  productCategorySelected &&
                  carrierSelected?.length > 0 &&
                  productTypeSelected?.length &&
                  stateSelected?.length
                    ? false
                    : true
                }
                onClick={onUploadClick}
              >
                Submit
              </button>
            </div>
          </div>
          <Footer />
        </>
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default CarrierReUploadForm;
