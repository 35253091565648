import './FormField.css';
import PropTypes from 'prop-types';

const InputCheckBox = (props) => {
  const inputCheckBox = (
    <input
      type={props.fieldData.type}
      id={props.fieldData.id}
      name={props.fieldData.name}
      defaultValue={props.fieldData.value}
      //   placeholder={props.fieldData.placeholder || "Enter"}
      onChange={props.fieldData.onChangeHandler}
    />
  );
  return (
    <div
      className={`label-value-wrapper ${
        props.fieldData.layoutType ? props.fieldData.layoutType : ''
      }`}
      key={props.fieldData.id}
    >
      {inputCheckBox}
      {props.fieldData.label && (
        <label className="checkbox-label" htmlFor={props.fieldData.id}>
          {props.fieldData.label}
        </label>
      )}
    </div>
  );
};
export default InputCheckBox;

InputCheckBox.propTypes = {
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
