import FormField from '../../../ui/formField';
import { useSelector } from 'react-redux';

const EkgDoctorDetails = (props) => {
  const pyFormData = useSelector((state) => state.pyFieldData);
  const { loading, error, pyData } = pyFormData;
  const onFieldChangeHandler = (event) => {
    props.setEkgDoctorDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <h3>{pyData?.data?.ekgDoctorSectionLabel?.label}</h3>
      <div className="flex">
        {pyData?.data?.ekgDoctorSectionFields?.map((item, index) => {
          return (
            <FormField
              key={item.id}
              label={item.label}
              id={item.id}
              value={item.value}
              type={item.type}
              name={item.name}
              placeholder={item.placeholder}
              min={item.min}
              max={item.max}
              layoutType={item.layoutType}
              onChangeHandler={onFieldChangeHandler}
              selectOptions={item.selectOptions}
              dropdownType={item.dropdownType}
              regex={item?.regex}
              errorMessage={item?.errorMessage}
              validationType={item?.validationType}
            />
          );
        })}
      </div>
      <div className="line-separation "></div>
    </>
  );
};

export default EkgDoctorDetails;
