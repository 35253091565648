import axios from '../../../axios/axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import ToastMessage from '../../ui/toastMessage';
import { exitingInsuranceAction } from '../../../actions/exitingInsuranceAction';
import Loader from '../../loader';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import './../existingInsurance/existingInsurance.css';
import ExistingPoliciesAnnuity from './existingPoliciesSection';
import LifeInsuranceSectionAnnuity from './lifeInsuranceSection';
import { exitingAnnuityAction } from '../../../actions/existingAnnuityAction';

const ExistingAnnuity = () => {
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.existingAnnuityData);
  const { loading, error, formData } = pageData;
  const sideNavData = useSelector((state) => state.pageNav);

  useEffect(() => {
    dispatch(exitingAnnuityAction());
  }, [dispatch]);

  const existingInsuranceQuestion =
    formData?.data?.existingInsuranceQuestionSection;
  const existingCoverageQuestion =
    formData?.data?.existingCoverageQuestionSection;
  const lifeInsuranceQuestion = formData?.data?.lifeInsuranceQuestionSection;
  const remarkField = formData?.data?.existingCoverageRemark;
  const [remarkFieldValue, setRemarkFieldValue] = useState(
    formData?.data?.existingCoverageRemark
  );
  const showExtraFieldsCheck = 'yes';
  const hideExtraFieldsCheck = 'no';
  const [existingInsuranceValue, setExistingInsuranceValue] = useState({});
  const [existingPoliciesData, setExistingPoliciesData] = useState([]);
  const [existingCoverageValue, setExistingCoverageValue] = useState({});
  const [remarksValue, setRemarksValue] = useState('');
  const [lifeInsuranceAnswerValue, setLifeInsuranceAnswerValue] = useState({});
  const [lifeInsuranceData, setLifeInsuranceData] = useState();
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  useEffect(() => {
    if (formData?.data?.existingInsuranceFields !== undefined) {
      setExistingPoliciesData(formData?.data?.existingInsuranceFields);
    }

    const oldExistingInsurance =
      formData?.data?.existingInsuranceQuestionSection?.options?.find(
        (item) => item.defaultChecked
      );
    if (oldExistingInsurance) {
      const key = oldExistingInsurance['id'];
      const value = oldExistingInsurance['value'];
      setExistingInsuranceValue({ [key]: value });
    }
    const oldExistingCoverage =
      formData?.data?.existingCoverageQuestionSection?.options?.find(
        (item) => item.defaultChecked
      );
    if (oldExistingCoverage) {
      const key = oldExistingCoverage['id'];
      const value = oldExistingCoverage['value'];
      setExistingCoverageValue({ [key]: value });
    }
    const oldLifeInsurance =
      formData?.data?.lifeInsuranceQuestionSection?.options?.find(
        (item) => item.defaultChecked
      );
    if (oldLifeInsurance) {
      const key = oldLifeInsurance['id'];
      const value = oldLifeInsurance['value'];
      setLifeInsuranceAnswerValue({ [key]: value });
    }
    setRemarksValue(formData?.data?.existingCoverageRemark?.value);
  }, [formData?.data]);

  const checkPolicyNumbers = existingPoliciesData?.filter(
    (policy) => policy?.policyNumber
  );

  const existingInsuranceQuestionChangeHandler = (event) => {
    setExistingInsuranceValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const existingCoverageQuestionChangeHandler = (event) => {
    setExistingCoverageValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onRemarksChangeHandler = (event) => {
    setRemarkFieldValue(event.target.value);
    setRemarksValue(event.target.value);
  };

  const lifeInsuranceQuestionChangeHandler = (event) => {
    setLifeInsuranceAnswerValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const onCloseToastMessage = () => {
    showToastMessage(false);
  };

  let existingInsurance;
  let existingCoverage;
  let lifeInsurance;
  if (existingInsuranceValue['ea-ei-yes'] === showExtraFieldsCheck) {
    existingInsurance = {
      existingInsuranceQuestionSection: existingInsuranceValue,
      existingInsuranceFields: existingPoliciesData,
    };
  } else {
    existingInsurance = {
      existingInsuranceQuestionSection: existingInsuranceValue,
    };
  }
  if (existingCoverageValue['ea-ec-yes'] === showExtraFieldsCheck) {
    existingCoverage = {
      existingCoverageQuestionSection: existingCoverageValue,
      existingCoverageRemark: { 'ea-ec-remark': remarksValue },
    };
  } else {
    existingCoverage = {
      existingCoverageQuestionSection: existingCoverageValue,
    };
  }
  if (lifeInsuranceAnswerValue['ea-li-yes'] === showExtraFieldsCheck) {
    lifeInsurance = {
      lifeInsuranceQuestionSection: lifeInsuranceAnswerValue,
      lifeInsuranceFields: lifeInsuranceData,
    };
  } else {
    lifeInsurance = {
      lifeInsuranceQuestionSection: lifeInsuranceAnswerValue,
    };
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  let payLoad = {
    ...existingInsurance,
    ...existingCoverage,
    ...lifeInsurance,
  };
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s27`,
        payLoad
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
        dispatch(exitingInsuranceAction());
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payLoad,
        sectionId: 'f1v1s27',
      },
    });
  }, [payLoad]);
  return (
    <>
      <CommonFormHeader
        backPath="/form/agentquestionnaire"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId={formData?.common_details?.section_id}
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
        productType={formData?.common_details?.product_type}
      />

      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          {formData?.data && (
            <>
              <div className="label-radioButtons-wrapper ">
                {existingInsuranceQuestion?.label && (
                  <p className="group-label">
                    {existingInsuranceQuestion?.label}
                  </p>
                )}
                <div
                  className="radio-buttons-wrapper"
                  onChange={existingInsuranceQuestionChangeHandler}
                >
                  <div className="radio-button">
                    <input
                      type={existingInsuranceQuestion.options[0].type}
                      value={existingInsuranceQuestion.options[0].value}
                      name={existingInsuranceQuestion.options[0].name}
                      id={existingInsuranceQuestion.options[0].id}
                      className="radio"
                      defaultChecked={
                        existingInsuranceQuestion.options[0].defaultChecked
                      }
                    />
                    <label
                      htmlFor={existingInsuranceQuestion.options[0].id}
                      className="radio-label"
                    >
                      {existingInsuranceQuestion.options[0].label}
                    </label>
                  </div>
                  <div className="radio-button">
                    <input
                      type={existingInsuranceQuestion.options[1].type}
                      value={existingInsuranceQuestion.options[1].value}
                      name={existingInsuranceQuestion.options[1].name}
                      id={existingInsuranceQuestion.options[1].id}
                      className="radio"
                      defaultChecked={
                        existingInsuranceQuestion.options[1].defaultChecked
                      }
                      disabled={
                        existingPoliciesData?.length > 0 &&
                        checkPolicyNumbers?.length > 0
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor={existingInsuranceQuestion.options[1].id}
                      className="radio-label"
                    >
                      {existingInsuranceQuestion.options[1].label}
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="line-separation "></div>
          {formData?.data &&
            existingInsuranceValue['ea-ei-yes'] === showExtraFieldsCheck && (
              <ExistingPoliciesAnnuity
                setExistingPoliciesData={setExistingPoliciesData}
                existingPoliciesData={existingPoliciesData}
              />
            )}
          {formData?.data && (
            <LabelRadioButtons
              label={existingCoverageQuestion.label}
              options={existingCoverageQuestion.options}
              id={existingCoverageQuestion.id}
              onChangeHandler={existingCoverageQuestionChangeHandler}
            />
          )}
          <div className="line-separation "></div>
          {formData?.data &&
            existingCoverageValue['ea-ec-yes'] === showExtraFieldsCheck && (
              <div
                className={`label-value-wrapper field-remark-wrapper ${
                  !remarkFieldValue ? 'value--empty' : ''
                }`}
              >
                <label>{remarkField.label}</label>
                <textarea
                  name={remarkField.name}
                  id={remarkField.id}
                  onChange={onRemarksChangeHandler}
                  defaultValue={remarkField.value}
                  placeholder="Enter"
                ></textarea>
                <div className="line-separation "></div>
              </div>
            )}
          {formData?.data && (
            <LabelRadioButtons
              label={lifeInsuranceQuestion.label}
              options={lifeInsuranceQuestion.options}
              id={lifeInsuranceQuestion.id}
              onChangeHandler={lifeInsuranceQuestionChangeHandler}
            />
          )}

          {formData?.data &&
            lifeInsuranceAnswerValue['ea-li-yes'] === showExtraFieldsCheck && (
              <LifeInsuranceSectionAnnuity
                setLifeInsuranceData={setLifeInsuranceData}
              />
            )}
        </FormFieldsWrapper>
      )}
      {formData?.data && toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default ExistingAnnuity;
