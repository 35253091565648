// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#contents {
  display: flex;
  padding: 20px 0 0;
  -webkit-display: flex;
  min-height: calc(100vh - 155px);
}
.page-contents {
  width: 60%;
  padding: 0 16px;
}
.page-navigation {
  width: 20%;
}

.notes-section-wrapper {
  width: 20%;
  height: 100vh;
}
.page-contents {
  width: 60%;
  padding: 0 16px;
}
.show-component {
  display: block;
}
.hide-component {
  display: none;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,qBAAqB;EACrB,+BAA+B;AACjC;AACA;EACE,UAAU;EACV,eAAe;AACjB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,aAAa;AACf;AACA;EACE,UAAU;EACV,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,wBAAwB;AAC1B","sourcesContent":["#contents {\n  display: flex;\n  padding: 20px 0 0;\n  -webkit-display: flex;\n  min-height: calc(100vh - 155px);\n}\n.page-contents {\n  width: 60%;\n  padding: 0 16px;\n}\n.page-navigation {\n  width: 20%;\n}\n\n.notes-section-wrapper {\n  width: 20%;\n  height: 100vh;\n}\n.page-contents {\n  width: 60%;\n  padding: 0 16px;\n}\n.show-component {\n  display: block;\n}\n.hide-component {\n  display: none;\n}\niframe#webpack-dev-server-client-overlay {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
