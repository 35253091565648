import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';
import FormField from '../../../ui/formField';

const BusinessOwners = (props) => {
  const pageData = useSelector((state) => state.businessInsuranceData);
  const { loading, error, formData } = pageData;
  const [ownership, setOwnership] = useState({ 0: 0 });
  const totalOwnership = 100;

  useEffect(() => {
    formData.data.businessOwnerFields.map((item, index) => {
      setOwnership((prevState) => ({
        ...prevState,
        [index ? index : 0]: item.ownership ? item.ownership : 0,
      }));
    });
  }, [formData?.data]);
  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };
  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };

  let handleChange = (i, e) => {
    let newFormValues = [...props.businessOwnerDetails];

    if (
      e.target.name === 'businessInsuranceAmount' ||
      e.target.name === 'newInsuranceAmount'
    ) {
      newFormValues[i][e.target.name] = removeCommaSeparator(e.target.value);
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    props.setBusinessOwnerDetails(newFormValues);
    if (e.target.name === 'ownership') {
      if (!isNaN(e.target.value)) {
        setOwnership((prevState) => ({
          ...prevState,
          [i]: e.target.value ? e.target.value : 0,
        }));
      }
    }
  };

  let addBusinessOwnerFields = () => {
    props.setBusinessOwnerDetails([
      ...props.businessOwnerDetails,
      {
        name: '',
        ownership: '',
        businessInsuranceAmount: '',
        newInsuranceAmount: '',
      },
    ]);
  };

  let removeBusinessOwnerFields = (i) => {
    let newFormValues = [...props.businessOwnerDetails];
    newFormValues.splice(i, 1);
    props.setBusinessOwnerDetails(newFormValues);
  };

  const businessOwnerFields = (
    <div className="flex">
      {props.businessOwnerDetails.length > 0 &&
        props.businessOwnerDetails.map((element, index) => {
          return (
            <AccordionAddMoreSection
              key={index}
              index={index}
              accordionHeading={`Business Owner ${index + 1}`}
              onDeleteClick={
                index ? () => removeBusinessOwnerFields(index) : null
              }
            >
              <div className="flex" key={index}>
                <div className="label-value-wrapper layout-2 no-margin-text-field">
                  <label className="field-label">
                    {formData?.data?.businessOwnerFieldsLabel?.name}
                  </label>
                  <FormField
                    id={`name${index}`}
                    regex="^[a-zA-Z -.,]*$"
                    validationType="name"
                    errorMessage="Please enter a valid name."
                    type="text"
                    name="name"
                    placeholder="Enter"
                    value={element.name || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                    addMoreField
                  />
                </div>
                <div className="label-value-wrapper layout-2">
                  <label className="field-label">
                    {formData?.data?.businessOwnerFieldsLabel?.ownership}
                  </label>

                  <div>
                    <input
                      id={`amount-${index}`}
                      type="number"
                      name="ownership"
                      placeholder="Enter"
                      value={element.ownership || ''}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                  {Object.values(ownership).reduce(
                    (a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0)
                  ) > 100 && (
                    <div className="errorMessage">
                      Total Ownership exceeding 100%
                    </div>
                  )}
                </div>
                <div
                  className={`label-value-wrapper layout-2  ${
                    !element.businessInsuranceAmount ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {
                      formData?.data?.businessOwnerFieldsLabel
                        ?.businessInsuranceAmount
                    }
                  </label>
                  <div>
                    <input
                      id={`businessInsuranceAmount-${index}`}
                      type="text"
                      name="businessInsuranceAmount"
                      placeholder="Enter"
                      onChange={(e) => handleChange(index, e)}
                      value={
                        element?.businessInsuranceAmount
                          ? addCommaSeparator(element.businessInsuranceAmount)
                          : ''
                      }
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2  ${
                    !element.newInsuranceAmount ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {
                      formData?.data?.businessOwnerFieldsLabel
                        ?.newInsuranceAmount
                    }
                  </label>
                  <div>
                    <input
                      id={`newInsuranceAmount-${index}`}
                      type="text"
                      name="newInsuranceAmount"
                      placeholder="Enter"
                      value={
                        element?.newInsuranceAmount
                          ? addCommaSeparator(element.newInsuranceAmount)
                          : ''
                      }
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
              </div>
            </AccordionAddMoreSection>
          );
        })}
    </div>
  );

  const businessOwnerLabel = formData?.data?.businessOwnerLabel;

  return (
    <>
      <div className="line-separation">
        {businessOwnerLabel && <h3>{businessOwnerLabel}</h3>}

        {businessOwnerFields}

        {props.businessOwnerDetails.length > 0 &&
          Object.values(ownership).reduce(
            (a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0)
          ) < totalOwnership && (
            <span
              className="addFieldButton"
              onClick={() => addBusinessOwnerFields()}
            >
              ADD MORE
            </span>
          )}
      </div>
    </>
  );
};
export default BusinessOwners;
