import axios from '../axios/axios';
import {
  PAGE_NAVIGATION_DATA_REQUEST,
  PAGE_NAVIGATION_DATA_SUCCESS,
  PAGE_NAVIGATION_DATA_FAIL,
} from '../constants/pageNavigationConstants';

export const pageNavigationAction = (sideNavdata) => async (dispatch) => {
  try {
    dispatch({ type: PAGE_NAVIGATION_DATA_REQUEST });
    const { data } = await axios.post(
      '/rpiq-form-side-nav/' + localStorage.getItem('formId'),
      {
        show_section: sideNavdata || {},
      }
    );

    dispatch({
      type: PAGE_NAVIGATION_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: PAGE_NAVIGATION_DATA_FAIL,
      payload: error,
    });
  }
};
