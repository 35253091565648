import FormField from '../../../ui/formField';
import { useSelector } from 'react-redux';
import PhoneDetailsForm from '../../../ui/phoneDetailsForm';
import EmailDetailsForm from '../../../ui/emailDetailsForm';
import AddressDetailsForm from '../../../ui/addressDetailsForm';

const IndividualSectionAnnuity = (props) => {
  const fieldData = useSelector((state) => state.policyOwnerAnnuityData);
  const { loading, error, formData } = fieldData;

  const onRelationshipFieldChange = (e) => {
    props.setIndividualRelationship(e.target.value);
  };

  const onTitleChange = (e) => {
    props.setIndividualTitle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onFirstNameChange = (e) => {
    props.setIndividualFirstName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onMiddleNameChange = (e) => {
    props.setIndividualMiddleName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onLastNameChange = (e) => {
    props.setIndividualLastName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onSuffixChange = (e) => {
    props.setIndividualSuffix((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onDOBChange = (e) => {
    props.setIndividualDOB((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onSSNChange = (e) => {
    props.setIndividualSSN((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onBirthPlaceChange = (e) => {
    props.setIndividualBirthPlace((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onGenderChange = (e) => {
    props.setIndividualGender((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onDriverLicenseFieldChange = (event) => {
    props.setIndividualDriverLicenseFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const RelationshipData = formData?.data?.insuredRelationship;
  const IndividualTitle = formData?.data?.individualTitle;
  const IndividualFirstName = formData?.data?.individualFirstName;
  const IndividualMiddleName = formData?.data?.individualMiddleName;
  const IndividualLastName = formData?.data?.individualLastName;
  const IndividualSuffix = formData?.data?.individualSuffix;
  const IndividualDOB = formData?.data?.individualDOB;
  const IndividualSSN = formData?.data?.individualSSN;
  const IndividualBirthPlace = formData?.data?.individualBirthPlace;
  const IndividualGender = formData?.data?.individualGender;
  const DriverLicenseFieldData = formData?.data?.individualDriverLicenseFields;

  return (
    <>
      <div className="flex line-separation">
        <FormField
          label={RelationshipData?.label}
          value={RelationshipData?.value}
          type={RelationshipData?.type}
          name={RelationshipData?.name}
          placeholder={RelationshipData?.placeholder}
          layoutType={RelationshipData?.layoutType}
          onChangeHandler={onRelationshipFieldChange}
          selectOptions={RelationshipData?.selectOptions}
        />
      </div>

      <div className="flex">
        <FormField
          label={IndividualTitle?.label}
          value={IndividualTitle?.value}
          type={IndividualTitle?.type}
          name={IndividualTitle?.name}
          placeholder={IndividualTitle?.placeholder}
          layoutType={IndividualTitle?.layoutType}
          onChangeHandler={onTitleChange}
          selectOptions={IndividualTitle?.selectOptions}
        />

        <FormField
          label={IndividualFirstName?.label}
          value={IndividualFirstName?.value}
          type={IndividualFirstName?.type}
          name={IndividualFirstName?.name}
          placeholder={IndividualFirstName?.placeholder}
          layoutType={IndividualFirstName?.layoutType}
          onChangeHandler={onFirstNameChange}
          regex={IndividualFirstName?.regex}
          errorMessage={IndividualFirstName?.errorMessage}
          validationType={IndividualFirstName?.validationType}
        />

        <FormField
          label={IndividualMiddleName?.label}
          value={IndividualMiddleName?.value}
          type={IndividualMiddleName?.type}
          name={IndividualMiddleName?.name}
          placeholder={IndividualMiddleName?.placeholder}
          layoutType={IndividualMiddleName?.layoutType}
          onChangeHandler={onMiddleNameChange}
          regex={IndividualMiddleName?.regex}
          errorMessage={IndividualMiddleName?.errorMessage}
          validationType={IndividualMiddleName?.validationType}
        />
        <FormField
          label={IndividualLastName?.label}
          value={IndividualLastName?.value}
          type={IndividualLastName?.type}
          name={IndividualLastName?.name}
          placeholder={IndividualLastName?.placeholder}
          layoutType={IndividualLastName?.layoutType}
          onChangeHandler={onLastNameChange}
          regex={IndividualLastName?.regex}
          errorMessage={IndividualLastName?.errorMessage}
          validationType={IndividualLastName?.validationType}
        />
        <FormField
          label={IndividualSuffix?.label}
          value={IndividualSuffix?.value}
          type={IndividualSuffix?.type}
          name={IndividualSuffix?.name}
          placeholder={IndividualSuffix?.placeholder}
          layoutType={IndividualSuffix?.layoutType}
          onChangeHandler={onSuffixChange}
          regex={IndividualSuffix?.regex}
          errorMessage={IndividualSuffix?.errorMessage}
          validationType={IndividualSuffix?.validationType}
        />

        <FormField
          label={IndividualDOB?.label}
          value={IndividualDOB?.value}
          type={IndividualDOB?.type}
          name={IndividualDOB?.name}
          placeholder={IndividualDOB?.placeholder}
          layoutType={IndividualDOB?.layoutType}
          onChangeHandler={onDOBChange}
        />

        <FormField
          label={IndividualSSN?.label}
          value={IndividualSSN?.value}
          type={IndividualSSN?.type}
          name={IndividualSSN?.name}
          placeholder={IndividualSSN?.placeholder}
          layoutType={IndividualSSN?.layoutType}
          onChangeHandler={onSSNChange}
          regex={IndividualSSN?.regex}
          errorMessage={IndividualSSN?.errorMessage}
          validationType={IndividualSSN?.validationType}
        />
        <FormField
          label={IndividualBirthPlace?.label}
          value={IndividualBirthPlace?.value}
          type={IndividualBirthPlace?.type}
          name={IndividualBirthPlace?.name}
          placeholder={IndividualBirthPlace?.placeholder}
          layoutType={IndividualBirthPlace?.layoutType}
          onChangeHandler={onBirthPlaceChange}
        />
        <FormField
          label={IndividualGender?.label}
          value={IndividualGender?.value}
          type={IndividualGender?.type}
          name={IndividualGender?.name}
          placeholder={IndividualGender?.placeholder}
          layoutType={IndividualGender?.layoutType}
          onChangeHandler={onGenderChange}
          selectOptions={IndividualGender?.selectOptions}
        />
      </div>
      <div className="flex">
        {formData?.data && (
          <>
            <div className="line-separation layout-1"></div>
            <EmailDetailsForm
              addEmailList={props.individualEmailList}
              setAddEmailList={props.setIndividualEmailList}
              hideDelete
            />
            <div className="line-separation layout-1"></div>
            <PhoneDetailsForm
              setAddPhoneList={props.setIndividualPhoneList}
              addPhoneList={props.individualPhoneList}
              hideDelete
            />
            <div className="line-separation layout-1"></div>

            <AddressDetailsForm
              setAddAddressList={props.setIndividualaddressSection}
              addAddressList={props.individualaddressSection}
              hideDelete
            />

            <div className="line-separation layout-1"></div>

            <h3 id={formData?.data?.individualDriverLicenseLabel?.id}>
              {formData?.data?.individualDriverLicenseLabel?.label}
            </h3>

            <div className="flex full-width">
              {DriverLicenseFieldData.map((item, index) => {
                return (
                  <FormField
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    value={item.value}
                    type={item.type}
                    name={item.name}
                    placeholder={item.placeholder}
                    min={item.min}
                    max={item.max}
                    layoutType={item.layoutType}
                    onChangeHandler={onDriverLicenseFieldChange}
                    selectOptions={item.selectOptions}
                    dropdownType={item.dropdownType}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default IndividualSectionAnnuity;
