import './UnderwaterDepthSection.css';
import FormField from '../../../../ui/formField';
import { useSelector } from 'react-redux';

const UnderwaterDepthSection = (props) => {
  const pageData = useSelector((state) => state.avocationData);
  const { loading, error, formData } = pageData;

  const onFieldChangeHandler = (event, key) => {
    props.setUnderwaterDivingData((prevState) => ({
      ...prevState,
      underwaterDepthTable: {
        ...prevState.underwaterDepthTable,
        [`${key}`]: {
          ...(Object.keys(prevState?.underwaterDepthTable || {}).includes(key)
            ? prevState?.underwaterDepthTable[`${key}`]
            : {}),
          [event.target.name]: event.target.value,
        },
      },
    }));
  };
  return (
    <div>
      <p className="professionalAdvisors-heading">
        Please provide the following information
      </p>
      <div id="headers" className=" header-row flex">
        <div className="header-title layout-5">Depth(feet)</div>
        <div className="header-title layout-5">Average Time</div>
        <div className="header-title layout-5">Last 12 months</div>
        <div className="header-title layout-5">Last 24 months</div>
        <div className="header-title layout-5">Next 12 months</div>
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">Less than 30 ft.</div>
        {props.underwaterDepth['less-than-30-ft']?.map((item, index) => {
          return (
            <div key={`less-than-30-ft_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={'text'}
                name={item.name}
                placeholder={'Enter'}
                onChangeHandler={(e) =>
                  onFieldChangeHandler(e, 'less-than-30-ft')
                }
              />
            </div>
          );
        })}
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">30 - 65 ft.</div>
        {props.underwaterDepth['30-65-ft']?.map((item, index) => {
          return (
            <div key={`30-65-ft_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={'text'}
                name={item.name}
                placeholder={'Enter'}
                onChangeHandler={(e) => onFieldChangeHandler(e, '30-65-ft')}
              />
            </div>
          );
        })}
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">66 - 130 ft.</div>
        {props.underwaterDepth['66-130-ft']?.map((item, index) => {
          return (
            <div key={`66-130-ft_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={'text'}
                name={item.name}
                placeholder={'Enter'}
                onChangeHandler={(e) => onFieldChangeHandler(e, '66-130-ft')}
              />
            </div>
          );
        })}
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">131 - 200 ft.</div>
        {props.underwaterDepth['131-200-ft']?.map((item, index) => {
          return (
            <div key={`131-200-ft_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={'text'}
                name={item.name}
                placeholder={'Enter'}
                onChangeHandler={(e) => onFieldChangeHandler(e, '131-200-ft')}
              />
            </div>
          );
        })}
      </div>
      <div id="rows" className="table-row flex">
        <div className="row-name layout-5">More than 200 ft.</div>
        {props.underwaterDepth['more-than-200-ft']?.map((item, index) => {
          return (
            <div key={`more-than-200-ft_${index}`} className="layout-5">
              <FormField
                key={index}
                label={''}
                id={item.id}
                value={item.value}
                type={'text'}
                name={item.name}
                placeholder={'Enter'}
                onChangeHandler={(e) =>
                  onFieldChangeHandler(e, 'more-than-200-ft')
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UnderwaterDepthSection;
