import axios from '../axios/axios';
import {
  LONG_TERM_CARE_DATA_REQUEST,
  LONG_TERM_CARE_DATA_SUCCESS,
  LONG_TERM_CARE_DATA_FAIL,
} from '../constants/longTermCareConstants';

export const longTermCareAction = () => async (dispatch) => {
  try {
    dispatch({ type: LONG_TERM_CARE_DATA_REQUEST });
    const { data } = await axios.post('/rpiq-form-fetch', {
      section: 'f1v1s20',
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: LONG_TERM_CARE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }
    dispatch({
      type: LONG_TERM_CARE_DATA_FAIL,
      payload: error,
    });
  }
};
