import { usaStatefieldValue } from './countryStateJson';
import { countryFieldValue } from './countryList';
import './countryState.css';
const CountryState = (props) => {
  const countryValuechange = (event) => {
    props.setStateValue('');
    props.setUsaStateValue('');
    props.setCountryValue(event.target.value);
  };
  const usaStateValuechange = (event) => {
    props.setUsaStateValue(event.target.value);
  };
  const stateValueChange = (event) => {
    props.setStateValue(event.target.value);
  };
  const otherCountryNameValueChange = (event) => {
    props.setOtherCountryNameValue(event.target.value);
  };
  return (
    <>
      <div className="flex full-width ">
        <div
          className={`flex label-value-wrapper ${
            props.countryValue === 'Other' ? 'layout-5' : 'layout-2'
          }`}
        >
          <label className="field-label">Birth Country</label>
          <select
            name="birthCountry"
            id="birthCountry"
            onChange={countryValuechange}
            value={props.countryValue}
          >
            {countryFieldValue.map((item, index) => {
              return (
                <option
                  value={item.value}
                  key={index}
                  selected={item.defaultValue}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
        {props.countryValue === 'Other' && (
          <div
            className="flex label-value-wrapper state-container full-width layout-4"
            onChange={otherCountryNameValueChange}
          >
            <label className="field-label">Country Name</label>
            <div>
              <input
                type="text"
                value={props.otherCountryNameValue}
                placeholder="Enter"
              />
            </div>
          </div>
        )}
        {props.countryValue === 'USA' && (
          <div className="flex label-value-wrapper layout-2">
            <label className="field-label">Birth State</label>
            <select
              name="feet"
              id="feet"
              value={props.usaStateValue}
              onChange={usaStateValuechange}
            >
              {usaStatefieldValue.map((item, index) => {
                return (
                  <option
                    value={item.code}
                    key={index}
                    //selected={item.defaultValue}
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {props.countryValue !== 'USA' && (
          <div
            className="flex label-value-wrapper state-container full-width layout-2"
            onChange={stateValueChange}
          >
            <label className="field-label">Birth State</label>
            <div>
              <input type="text" value={props.stateValue} placeholder="Enter" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CountryState;
