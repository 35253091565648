import FormField from '../../../ui/formField';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const FinancialBackground = (props) => {
  const [foreignAssets, setForeignAssets] = useState(0);
  const [foreignLiabilities, setForeignLiabilities] = useState(0);
  const [usTotalAssets, setUsTotalAssets] = useState(0);
  const [usTotalLiabilities, setUsTotalLiabilities] = useState(0);

  const [foreignTotal, setForeignTotal] = useState();
  const [usTotal, setUSTotal] = useState();
  const [totalNetWorth, setTotalNewWorth] = useState();
  const [showAPIValues, setShowAPIValues] = useState(true);

  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;

  const sectionLabel = formData?.data?.financialBackgroundLabel;

  useEffect(() => {
    setShowAPIValues(true);
    setForeignAssets(formData?.data?.financial_foreignFields[0].value);
    setForeignLiabilities(formData?.data?.financial_foreignFields[1].value);
    setUsTotalAssets(formData?.data?.financial_usTotalFields[0].value);
    setUsTotalLiabilities(formData?.data?.financial_usTotalFields[1].value);
    setForeignTotal(formData?.data?.financial_usForeignTotalValue);
    setUSTotal(formData?.data?.financial_usTotalValue);
    setTotalNewWorth(formData?.data?.financialBackgroundNewWorth);
  }, [formData?.data]);

  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };
  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };
  const foreignFieldsChangeHandler = (e) => {
    setShowAPIValues(false);
    if (e.target.name === 'foreignTotal-assets') {
      setForeignAssets(removeCommaSeparator(e.target.value));
    }
    if (e.target.name === 'foreignTotal-liabilities') {
      setForeignLiabilities(removeCommaSeparator(e.target.value));
    }
    props.setForeignFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
        ? removeCommaSeparator(e.target.value)
        : 0,
    }));
  };
  const usFieldsChangeHandler = (e) => {
    setShowAPIValues(false);

    if (e.target.name === 'usTotal-assets') {
      setUsTotalAssets(removeCommaSeparator(e.target.value));
    }
    if (e.target.name === 'usTotal-liabilities') {
      setUsTotalLiabilities(removeCommaSeparator(e.target.value));
    }

    props.setUSTotalFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
        ? removeCommaSeparator(e.target.value)
        : 0,
    }));
  };
  const fieldLabel1 = formData?.data?.financial_foreignLabel;
  const fields1 = formData?.data?.financial_foreignFields.map(function (
    item,
    index
  ) {
    return (
      <FormField
        key={index}
        type={item.type}
        name={item.name}
        value={item.value}
        placeholder={item.placeholder}
        onChangeHandler={foreignFieldsChangeHandler}
        validationType={item.validationType}
      />
    );
  });
  const fieldLabel2 = formData?.data?.financial_usTotalLabel;
  const fields2 = formData?.data?.financial_usTotalFields.map(function (
    item,
    index
  ) {
    return (
      <FormField
        key={index}
        type={item.type}
        name={item.name}
        value={item.value}
        placeholder={item.placeholder}
        onChangeHandler={usFieldsChangeHandler}
        validationType={item.validationType}
      />
    );
  });
  useEffect(() => {
    props.setFinancial_foreignTotalValue(
      parseFloat(foreignAssets) - parseFloat(foreignLiabilities)
    );
    props.setFinancial_usTotalValue(
      parseFloat(usTotalAssets) - parseFloat(usTotalLiabilities)
    );
  }, [foreignAssets, foreignLiabilities, usTotalAssets, usTotalLiabilities]);
  return (
    <>
      <div className="financialBackground__nfo">
        {sectionLabel && <h3>{sectionLabel}</h3>}

        <div>
          <div className="financialBackground__header">
            <span className="financialBackground__headerLabel">
              Verifiable Net Worth
            </span>
            <span className="financialBackground__headerLabel">
              Assets (verifiable) ($)
            </span>
            <span className="financialBackground__headerLabel">
              Liabilities ($)
            </span>
            <span className="financialBackground__headerLabel">
              Net Worth ($)
            </span>
          </div>
          <div className="financialBackground__fields">
            <span className="financialBackground__rowLable">{fieldLabel1}</span>
            <div className="financialBackground__rowFields">
              {fields1}
              <div className="label-value-wrapper">
                <input
                  type="text"
                  value={
                    showAPIValues === true
                      ? addCommaSeparator(foreignTotal)
                      : addCommaSeparator(
                          parseFloat(foreignAssets) -
                            parseFloat(foreignLiabilities)
                        )
                  }
                  name="financial_usForeignTotalValue"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="financialBackground__fields">
            <span className="financialBackground__rowLable">{fieldLabel2}</span>
            <div className="financialBackground__rowFields">
              {fields2}
              <div className="label-value-wrapper">
                <input
                  type="text"
                  value={
                    showAPIValues
                      ? addCommaSeparator(usTotal)
                      : addCommaSeparator(
                          parseFloat(usTotalAssets) -
                            parseFloat(usTotalLiabilities)
                        )
                  }
                  name="financial_usTotalValue"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="financialTotalAssetValue">
            <span className="financialBackground__rowLable">Total</span>
            <div className="financialBackground__rowFields">
              <div className="label-value-wrapper ">
                <input
                  type="text"
                  name=""
                  value={
                    showAPIValues
                      ? addCommaSeparator(totalNetWorth)
                      : addCommaSeparator(
                          parseFloat(foreignAssets) -
                            parseFloat(foreignLiabilities) +
                            (parseFloat(usTotalAssets) -
                              parseFloat(usTotalLiabilities))
                        )
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FinancialBackground;
