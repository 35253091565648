import FormField from '../../../ui/formField';
import AccordionAddMoreSection from '../../../ui/accordionAddMoreSection';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../../axios/axios';

const ExistingPolicies = (props) => {
  const [labels, setLabels] = useState({});

  const exitingInsuranceData = useSelector(
    (state) => state.exitingInsuranceData
  );
  const { loading, error, eiData } = exitingInsuranceData;

  useEffect(() => {
    setLabels(eiData?.data?.labels);
  }, [eiData?.data]);

  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };
  const removeCommaSeparator = (value) => {
    return value.replace(/\,/g, '');
  };
  let handleChange = (i, e) => {
    let newFormValues = [...props.existingPoliciesData];
    newFormValues[i][e.target.name.slice(0, -1)] = removeCommaSeparator(
      e.target.value
    );
    props.setExistingPoliciesData(newFormValues);
  };

  let addFormFields = () => {
    props.setExistingPoliciesData([
      ...props.existingPoliciesData,
      {
        policyType: '',
        productType: '',
        insuranceCompany: '',
        policyNumber: '',
        yearIssued: '',
        expirationDate: '',
        faceAmount: '',
        estimateCashValue: '',
        lengthOfTerm: '',
      },
    ]);
  };

  let removeFormFields = (i, objectId) => {
    let newFormValues = [...props.existingPoliciesData];
    newFormValues.splice(i, 1);
    props.setExistingPoliciesData(newFormValues);
    if (objectId) {
      axios
        .post(`/so-api/delete-object/?object_id=${objectId}`)
        .then((response) => {})
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.detail === 'Not authenticated'
          ) {
            window.location.replace('/');
          }
          console.error('There was an error!', error);
        });
    }
  };
  return (
    <>
      <h3>{labels.existingPolicie}</h3>
      {props?.existingPoliciesData &&
        props?.existingPoliciesData?.map((element, index) => {
          return (
            <AccordionAddMoreSection
              key={index}
              index={index}
              accordionHeading={`Policy ${index + 1}`}
              onDeleteClick={() => removeFormFields(index, element?.object_id)}
              hideDeleteIcon={element?.from_so}
              showDeleteIconOnFirstRecord={true}
            >
              <div className="flex" key={index}>
                <div className="label-value-wrapper layout-2">
                  <div>
                    <label className="field-label">{labels.policyType}</label>
                    <div
                      className="existingPolicie-radio-container"
                      onChange={(e) => handleChange(index, e)}
                    >
                      <div className="existing-radio">
                        <input
                          type="radio"
                          id={`personal${index}`}
                          name={`policyType${index}`}
                          value="personal"
                          checked={element.policyType === 'personal'}
                          disabled={element?.from_so ? true : false}
                        />
                        <label
                          htmlFor={`personal${index}`}
                          className="existingPolicie-radio-label"
                        >
                          {labels.personal}
                        </label>
                      </div>
                      <div className="existing-radio">
                        <input
                          type="radio"
                          id={`group${index}`}
                          name={`policyType${index}`}
                          value="group"
                          checked={element.policyType === 'group'}
                          disabled={element?.from_so ? true : false}
                        />
                        <label
                          htmlFor={`group${index}`}
                          className="existingPolicie-radio-label"
                        >
                          {labels.group}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-value-wrapper layout-2">
                  <div>
                    <label className="field-label">{labels.productType}</label>
                    <div
                      className="existingPolicie-radio-container"
                      onChange={(e) => handleChange(index, e)}
                    >
                      <div className="existing-radio">
                        <input
                          type="radio"
                          id={`permanent${index}`}
                          name={`productType${index}`}
                          value="permanent"
                          checked={element.productType === 'permanent'}
                          disabled={element?.from_so ? true : false}
                        />
                        <label
                          htmlFor={`permanent${index}`}
                          className="existingPolicie-radio-label"
                        >
                          {labels.permanent}
                        </label>
                      </div>
                      <div className="existing-radio">
                        <input
                          type="radio"
                          id={`term${index}`}
                          name={`productType${index}`}
                          value="term"
                          checked={element.productType === 'term'}
                          disabled={element?.from_so ? true : false}
                        />
                        <label
                          htmlFor={`term${index}`}
                          className="existingPolicie-radio-label"
                        >
                          {labels.term}
                        </label>
                      </div>
                      <div className="existing-radio">
                        <input
                          type="radio"
                          id={`disability${index}`}
                          name={`productType${index}`}
                          value="disability"
                          checked={element.productType === 'disability'}
                          disabled={element?.from_so ? true : false}
                        />
                        <label
                          htmlFor={`disability${index}`}
                          className="existingPolicie-radio-label"
                        >
                          {labels.disability}
                        </label>
                      </div>
                      <div className="existing-radio">
                        <input
                          type="radio"
                          id={`annuity${index}`}
                          name={`productType${index}`}
                          value="annuity"
                          checked={element.productType === 'annuity'}
                          disabled={element?.from_so ? true : false}
                        />
                        <label
                          htmlFor={`annuity${index}`}
                          className="existingPolicie-radio-label"
                        >
                          {labels.annuity}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.insuranceCompany ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {labels.insuranceCompany}
                  </label>
                  <div>
                    <input
                      type="text"
                      name="insuranceCompany."
                      id="insuranceCompany"
                      placeholder="Enter"
                      value={element.insuranceCompany || ''}
                      onChange={(e) => handleChange(index, e)}
                      disabled={element?.from_so ? true : false}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-2 ${
                    !element.policyNumber ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{labels.policyNumber}</label>
                  <div>
                    <input
                      type="text"
                      name="policyNumber."
                      id="policyNumber"
                      placeholder="Enter"
                      value={element.policyNumber || ''}
                      onChange={(e) => handleChange(index, e)}
                      disabled={element?.from_so ? true : false}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-5 ${
                    !element.yearIssued ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{labels.yearIssued}</label>
                  <div>
                    <input
                      type="text"
                      name="yearIssued."
                      id="yearIssued"
                      placeholder="Enter"
                      value={element.yearIssued || ''}
                      onChange={(e) => handleChange(index, e)}
                      disabled={element?.from_so ? true : false}
                    />
                  </div>
                </div>
                <div className="label-value-wrapper no-margin-text-field layout-5">
                  <label className="field-label">{labels.expirationDate}</label>

                  <FormField
                    type="date"
                    id="expirationDate"
                    validationType="addMoreDate"
                    name="expirationDate."
                    placeholder="Enter"
                    value={element.expirationDate || ''}
                    onChangeHandler={(e) => handleChange(index, e)}
                    disabled={element?.from_so ? true : false}
                  />
                </div>
                <div
                  className={`label-value-wrapper layout-5 ${
                    !element.faceAmount ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{labels.faceAmount}</label>
                  <div>
                    <input
                      type="text"
                      name="faceAmount."
                      id="faceAmount"
                      placeholder="Enter"
                      value={addCommaSeparator(element.faceAmount) || ''}
                      onChange={(e) => handleChange(index, e)}
                      disabled={element?.from_so ? true : false}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-5 ${
                    !element.estimateCashValue ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">
                    {labels.estimateCashValue}
                  </label>
                  <div>
                    <input
                      type="text"
                      name="estimateCashValue."
                      id="estimateCashValue"
                      placeholder="Enter"
                      value={addCommaSeparator(element.estimateCashValue) || ''}
                      onChange={(e) => handleChange(index, e)}
                      disabled={element?.from_so ? true : false}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-5 ${
                    !element.lengthOfTerm ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label">{labels.lengthOfTerm}</label>
                  <div>
                    <input
                      type="text"
                      name="lengthOfTerm."
                      id="lengthOfTerm"
                      placeholder="Enter"
                      value={element.lengthOfTerm || ''}
                      onChange={(e) => handleChange(index, e)}
                      disabled={element?.from_so ? true : false}
                    />
                  </div>
                </div>
              </div>
            </AccordionAddMoreSection>
          );
        })}
      <div>
        <span className="addFieldButton" onClick={() => addFormFields()}>
          {labels.addMore}
        </span>
      </div>
      <div className="line-separation "></div>
    </>
  );
};

export default ExistingPolicies;
