import FormField from '../../../ui/formField';
import deleteIcon from '../../../../assets/images/delete-icon.svg';
import { useSelector } from 'react-redux';
const USAccountInfo = (props) => {
  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;

  let handleChange = (i, e) => {
    let newFormValues = [...props.usAccountInfo];
    newFormValues[i][e.target.name] = e.target.value;
    props.setUsAccountInfo(newFormValues);
  };
  let addFormFields = () => {
    props.setUsAccountInfo([
      ...props.usAccountInfo,
      {
        name: '',
        address: '',
        accountNumber: '',
        dateAccountOpened: '',
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...props.usAccountInfo];
    newFormValues.splice(i, 1);
    props.setUsAccountInfo(newFormValues);
  };
  const sectionLabel = formData?.data?.usAccountLabel;
  const fieldLabel = {
    name: 'Name',
    address: 'Address',
    accountNumber: 'Account Number',
    dateAccountOpened: 'Date Account Opened',
  };
  const fields = (
    <div className="flex">
      {props.usAccountInfo.length > 0 &&
        props.usAccountInfo.map((element, index) => {
          return (
            <div className="flex" key={index}>
              <div className="label-value-wrapper no-margin-text-field layout-4">
                <label className="field-label">{fieldLabel.name}</label>

                <FormField
                  id={`name${index}`}
                  regex="^[a-zA-Z -.,]*$"
                  validationType="name"
                  errorMessage="Please enter a valid name."
                  type="text"
                  name="name"
                  placeholder="Enter"
                  value={element.name || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                  addMoreField
                />
              </div>
              <div
                className={`label-value-wrapper layout-4 ${
                  !element.address ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">{fieldLabel.address}</label>
                <div>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter"
                    value={element.address || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div
                className={`label-value-wrapper layout-4 ${
                  !element.accountNumber ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">
                  {fieldLabel.accountNumber}
                </label>
                <div>
                  <input
                    type="text"
                    name="accountNumber"
                    placeholder="Enter"
                    value={element.accountNumber || ''}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div className="label-value-wrapper no-margin-text-field layout-4">
                <label className="field-label">
                  {fieldLabel.dateAccountOpened}
                </label>
                <FormField
                  type="date"
                  validationType="addMoreDate"
                  name="dateAccountOpened"
                  placeholder="Enter"
                  value={element.dateAccountOpened || ''}
                  onChangeHandler={(e) => handleChange(index, e)}
                />
              </div>
              {index ? (
                <div className="usAccountInfo__delete">
                  <span
                    onClick={() => removeFormFields(index)}
                    className="icon-delete"
                  >
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      className="deleteIcon remarks-remove"
                    />
                  </span>
                </div>
              ) : null}
            </div>
          );
        })}
    </div>
  );
  return (
    <>
      <div className="foreignResidency__usAccountInfo">
        {sectionLabel && (
          <h3 className="usAccountInfo__heading">{sectionLabel}</h3>
        )}
        <div className="flex">{fields}</div>
        {props.usAccountInfo.length > 0 && (
          <div>
            <span className="addFieldButton" onClick={() => addFormFields()}>
              ADD MORE
            </span>
          </div>
        )}
      </div>
    </>
  );
};
export default USAccountInfo;
