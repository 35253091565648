import AccordionAddMoreSection from '../../ui/accordionAddMoreSection';
import { phoneTypeList } from './phoneTypeList';

const PhoneDetailsForm = (props) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...props.addPhoneList];

    switch (name) {
      case 'preferredPhone':
        list.map((item, i) => {
          i === index
            ? (item.preferredPhone = true)
            : (item.preferredPhone = false);
          return item;
        });
        break;
      case 'primaryResidencePhone':
        list.map((item, i) => {
          i === index
            ? (item.primaryResidencePhone = true)
            : (item.primaryResidencePhone = false);
          return item;
        });
        break;
      case 'primaryBusinessPhone':
        list.map((item, i) => {
          i === index
            ? (item.primaryBusinessPhone = true)
            : (item.primaryBusinessPhone = false);
          return item;
        });
        break;
      default:
        list[index][name] = value;
    }
    props.setAddPhoneList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...props.addPhoneList];
    list.splice(index, 1);
    props.setAddPhoneList(list);
  };
  const handleAddClick = () => {
    props.setAddPhoneList([
      ...props.addPhoneList,
      {
        areaCode: '',
        countryCode: '',
        extension: '',
        number: '',
        phone: '',
        phoneType: '',
        preferredPhone: false,
        primaryResidencePhone: false,
        primaryBusinessPhone: false,
        remarks: '',
      },
    ]);
  };
  return (
    <>
      {props?.addPhoneList?.length > 0 &&
        props?.addPhoneList?.map((x, i) => {
          return (
            <AccordionAddMoreSection
              key={i}
              index={i}
              accordionHeading={`Phone - ${i + 1}`}
              onDeleteClick={i ? () => handleRemoveClick(i) : null}
              hideDelete={props.hideDelete}
            >
              <div key={i} className="flex">
                <div className="label-value-wrapper layout-4">
                  <label className="field-label">Phone Type</label>

                  <div>
                    <select
                      name="phoneType"
                      id="phoneType"
                      value={x.phoneType || ''}
                      onChange={(e) => handleInputChange(e, i)}
                    >
                      {phoneTypeList.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !x.countryCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="countryCode">
                    Country Code
                  </label>
                  <div>
                    <input
                      type="text"
                      name="countryCode"
                      id="countryCode"
                      placeholder="Enter"
                      value={x.countryCode}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !x.areaCode ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="areaCode">
                    Area Code
                  </label>
                  <div>
                    <input
                      type="text"
                      name="areaCode"
                      id="areaCode"
                      placeholder="Enter"
                      value={x.areaCode}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper layout-4 ${
                    !x.number ? 'value--empty' : ''
                  }`}
                >
                  <label className="field-label" htmlFor="number">
                    Phone Number
                  </label>
                  <div>
                    <input
                      type="text"
                      name="number"
                      id="number"
                      placeholder="Enter"
                      value={x.number}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                <div
                  className={`label-value-wrapper  ${
                    x.phoneType.toLowerCase() === 'business' ||
                    x.phoneType.toLowerCase() === 'residence'
                      ? 'layout-3'
                      : 'layout-2'
                  } ${!x.extension ? 'value--empty' : ''}`}
                >
                  <label className="field-label" htmlFor="extension">
                    Extension
                  </label>
                  <div>
                    <input
                      type="text"
                      name="extension"
                      id="extension"
                      placeholder="Enter"
                      value={x.extension}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>
                {!(
                  x.phoneType === 'Residence Fax' ||
                  x.phoneType === 'Business Fax' ||
                  x.phoneType === 'Modem/data line'
                ) && (
                  <div className="label-value-wrapper layout-3 make-primary-layout">
                    <input
                      type="radio"
                      id={`preferredPhone${i}`}
                      name="preferredPhone"
                      value={true}
                      onChange={(e) => handleInputChange(e, i)}
                      className="makePrimary-radio"
                      checked={x.preferredPhone}
                    />
                    <label
                      htmlFor={`preferredPhone${i}`}
                      className="makePrimary-label"
                    >
                      Mark as Preferred Phone
                    </label>
                  </div>
                )}
                {x.phoneType.toLowerCase() === 'business' && (
                  <div className="label-value-wrapper layout-3 make-primary-layout">
                    <input
                      type="radio"
                      id={`primaryBusinessPhone${i}`}
                      name="primaryBusinessPhone"
                      value={true}
                      onChange={(e) => handleInputChange(e, i)}
                      className="makePrimary-radio"
                      defaultChecked={x.primaryBusinessPhone}
                    />
                    <label
                      htmlFor={`primaryBusinessPhone${i}`}
                      className="makePrimary-label"
                    >
                      Primary Business Phone
                    </label>
                  </div>
                )}
                {x.phoneType.toLowerCase() === 'residence' && (
                  <div className="label-value-wrapper layout-3 make-primary-layout">
                    <input
                      type="radio"
                      id={`primaryResidencePhone${i}`}
                      name="primaryResidencePhone"
                      value={true}
                      onChange={(e) => handleInputChange(e, i)}
                      className="makePrimary-radio"
                      checked={x.primaryResidencePhone}
                    />
                    <label
                      htmlFor={`primaryResidencePhone${i}`}
                      className="makePrimary-label"
                    >
                      Primary Residence Phone
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`label-value-wrapper layout-1 field-remark-wrapper ${
                  !x.remarks ? 'value--empty' : ''
                }`}
              >
                <label className="field-label">Remarks</label>
                <textarea
                  name="remarks"
                  placeholder="Enter"
                  id="remarks"
                  value={x.remarks || ''}
                  onChange={(e) => handleInputChange(e, i)}
                ></textarea>
              </div>
            </AccordionAddMoreSection>
          );
        })}
      <div>
        <span className="addFieldButton" onClick={handleAddClick}>
          ADD PHONE
        </span>
      </div>
    </>
  );
};
export default PhoneDetailsForm;
