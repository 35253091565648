import './../CaseListing.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const CaseListingCard = (props) => {
  let navigate = useNavigate();

  const addCommaSeparator = (value) => {
    if (value === 0) return 0;
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };

  const onCardClick = (formId) => {
    localStorage.setItem('formId', formId);
    navigate('/form/landingPageModal');
  };

  return (
    <>
      {props?.cardData?.length !== 0 || props?.cardData !== undefined ? (
        <div className="cards-listing-wrapper">
          <table className="caselisting__table">
            <thead>
              <tr>
                <th>
                  <span className="caselisting__tableHeading">
                    INSURED NAME
                  </span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">CARRIER</span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">PRODUCT</span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">
                    PRODUCT SUB TYPE
                  </span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">STATE</span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">UNDERWRITER</span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">
                    INSURANCE PROCEEDS
                  </span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">
                    PREMIUM AMOUNT
                  </span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">
                    DATE CREATED
                  </span>
                </th>
                <th>
                  <span className="caselisting__tableHeading">
                    LAST UPDATED
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props?.cardData
                ?.sort((a, b) =>
                  a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
                )
                .map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => onCardClick(item.FormId)}
                      className="caselisting__rowInfo"
                    >
                      <td>
                        <span className="caseListing__insuredName">
                          {item.Name ? item.Name : '-'}
                        </span>
                      </td>
                      <td>
                        <span>{item.Carrier ? item.Carrier : '-'}</span>
                      </td>
                      <td>
                        <span>{item.Product ? item.Product : '-'}</span>
                      </td>
                      <td>
                        <span>
                          {item.product_subtype ? item.product_subtype : '-'}
                        </span>
                      </td>
                      <td>
                        <span>{item.State ? item.State : '-'}</span>
                      </td>
                      <td>
                        <span>
                          {item.so_underwriter ? item.so_underwriter : '-'}
                        </span>
                      </td>
                      <td>
                        <span>
                          {item.Benefit
                            ? '$' + addCommaSeparator(item.Benefit)
                            : '-'}
                        </span>
                      </td>
                      <td>
                        <span>
                          {item.Premium
                            ? '$' + addCommaSeparator(item.Premium)
                            : '-'}
                        </span>
                      </td>
                      <td>
                        <span>
                          {item.so_created_at
                            ? moment(new Date(item.so_created_at)).format(
                                'MM/DD/YYYY'
                              )
                            : '-'}
                        </span>
                      </td>
                      <td>
                        <span>
                          {item.ModifiedOn
                            ? moment(new Date(item.ModifiedOn)).format(
                                'MM/DD/YYYY'
                              )
                            : '-'}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-data-container">
          <p className="no-data-text">No search data found</p>
        </div>
      )}
    </>
  );
};

export default CaseListingCard;
