import { useSelector } from 'react-redux';
import FormField from '../../../ui/formField';
const OtherCitizen = (props) => {
  const pageData = useSelector((state) => state.foreignResidencyData);
  const { loading, error, formData } = pageData;

  const onChangeHandler = (e) => {
    props.setOtherCitizen((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const label = formData?.data?.otherCitizenLabel;
  const field = formData?.data?.otherCitizenInfo.map((item, index) => {
    return (
      <FormField
        key={index}
        id={item.id}
        name={item.name}
        value={item.value}
        type={item.type}
        label={item.label}
        layoutType={item.layoutType}
        onChangeHandler={onChangeHandler}
      />
    );
  });
  return (
    <>
      <h3>{label}</h3>
      <div className="flex">{field}</div>
    </>
  );
};
export default OtherCitizen;
