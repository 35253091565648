import {
  AVIATION_QUESTIONS_DATA_REQUEST,
  AVIATION_QUESTIONS_DATA_SUCCESS,
  AVIATION_QUESTIONS_DATA_FAIL,
} from '../constants/aviationConstants';

export const aviationReducer = (state = { apiData: [] }, action) => {
  switch (action.type) {
    case AVIATION_QUESTIONS_DATA_REQUEST:
      return { loading: true, apiData: [] };
    case AVIATION_QUESTIONS_DATA_SUCCESS:
      return { loading: false, apiData: action.payload };
    case AVIATION_QUESTIONS_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
