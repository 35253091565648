import axios from '../axios/axios';
import {
  PA_DATA_REQUEST,
  PA_DATA_SUCCESS,
  PA_DATA_FAIL,
} from '../constants/professionalAdvisorsConstants';

export const professionalAdvisorsAction = () => async (dispatch) => {
  try {
    dispatch({ type: PA_DATA_REQUEST });
    const { data } = await axios.post('/rpiq-form-fetch', {
      section: 'f1v1s12',
      form_id: localStorage.getItem('formId'),
    });

    dispatch({
      type: PA_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.detail === 'Not authenticated'
    ) {
      window.location.replace('/');
    }

    dispatch({
      type: PA_DATA_FAIL,
      payload: error,
    });
  }
};
