import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormField from '../../../../ui/formField';
import PbParterDetails from './pbPartnerDetails';
const PbPartnershipSection = (props) => {
  const [pbPartnershipSectionFields, setPbPartnershipSectionFields] = useState(
    {}
  );
  const [pbPartnershipAddressFields, setPbPartnershipAddressFields] = useState(
    {}
  );
  const [
    pbPartnershipContactDetailsFields,
    setPbPartnershipContactDetailsFields,
  ] = useState({});

  const beneficiaryFieldData = useSelector((state) => state.beneficiaryData);
  const { loading, error, bnData } = beneficiaryFieldData;

  const onPbPartnershipSectionFieldsChange = (event) => {
    setPbPartnershipSectionFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPbPartnershipAddressFieldsChange = (event) => {
    setPbPartnershipAddressFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onPbPartnershipContactDetailsFieldsChange = (event) => {
    setPbPartnershipContactDetailsFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  useEffect(() => {
    props.setpbPartnershipData({
      pbPartnershipSectionFields,
      pbPartnershipAddressFields,
      pbPartnershipContactDetailsFields,
    });
  }, [
    pbPartnershipSectionFields,
    pbPartnershipAddressFields,
    pbPartnershipContactDetailsFields,
  ]);
  return (
    <>
      <div className="flex">
        {bnData?.data &&
          bnData?.data?.pbPartnershipSectionFields?.map((item) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onPbPartnershipSectionFieldsChange}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                validationType={item.validationType}
              />
            );
          })}
        <div className="label-value-wrapper layout-2">
          <label className="field-label">
            {bnData?.data?.pbPartnershipTotalPercentage?.label}
          </label>
          <div>
            <input
              type={bnData?.data?.pbPartnershipTotalPercentage?.type}
              name={bnData?.data?.pbPartnershipTotalPercentage?.name}
              defaultValue={bnData?.data?.pbPartnershipTotalPercentage?.value}
              placeholder="Enter"
              onChange={(e) =>
                props.setPbPartnershipTotalPercentage(e.target.value)
              }
            />
          </div>
        </div>
      </div>
      <div className="line-separation"></div>
      <h3 id={bnData?.data && bnData?.data?.pbPartnershipAddressLabel?.id}>
        {bnData?.data && bnData?.data?.pbPartnershipAddressLabel?.label}
      </h3>
      <div className="flex">
        {bnData?.data &&
          bnData?.data?.pbPartnershipAddressFields.map((item) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onPbPartnershipAddressFieldsChange}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                dropdownType={item.dropdownType}
              />
            );
          })}
      </div>
      <div className="line-separation"></div>
      <h3
        id={bnData?.data && bnData?.data?.pbPartnershipContactDetailsLabel?.id}
      >
        {bnData?.data && bnData?.data?.pbPartnershipContactDetailsLabel?.label}
      </h3>
      <div className="flex">
        {bnData?.data &&
          bnData?.data?.pbPartnershipContactDetailsFields?.map((item) => {
            return (
              <FormField
                key={item.id}
                label={item.label}
                id={item.id}
                value={item.value}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                min={item.min}
                max={item.max}
                layoutType={item.layoutType}
                onChangeHandler={onPbPartnershipContactDetailsFieldsChange}
                selectOptions={item.selectOptions}
                regex={item?.regex}
                errorMessage={item?.errorMessage}
                dropdownType={item.dropdownType}
              />
            );
          })}
      </div>
      <div className="line-separation"></div>
      <PbParterDetails
        pbParterDetails={props.pbParterDetails}
        setPbParterDetails={props.setPbParterDetails}
        setPbParterPercentage={props.setPbParterPercentage}
        pbParterPercentage={props.pbParterPercentage}
      />
    </>
  );
};

export default PbPartnershipSection;
