import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { policyOwnerAction } from '../../../actions/policyOwnerAction';
import './../policyOwner/PolicyOwner.css';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import CommonLabelRadioButtons from '../../ui/commonLabelRadioButton';
import IndividualSectionAnnuity from './individualSection';
import TrustSectionAnnuity from './trustSection';
import LlcSectionAnnuity from './llcSection';
import CompanySectionAnnuity from './companySection';
import PartnershipSectionAnnuity from './partnershipSection';
import Loader from '../../loader';
import axios from '../../../axios/axios';
import ToastMessage from '../../ui/toastMessage';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import { policyOwnerAnnuityAction } from '../../../actions/policyOwnerAnnuityAction';

const PolicyOwnerAnnuity = () => {
  const hideFields = 'no';
  const [poQuestion, setPoQuestion] = useState({});

  const [llcData, setLlcData] = useState({});
  const [individualPhoneList, setIndividualPhoneList] = useState([]);
  const [individualEmailList, setIndividualEmailList] = useState([]);
  const [individualRelationship, setIndividualRelationship] = useState(null);
  const [individualTitle, setIndividualTitle] = useState('');
  const [individualFirstName, setIndividualFirstName] = useState('');
  const [individualMiddleName, setIndividualMiddleName] = useState('');
  const [individualLastName, setIndividualLastName] = useState('');
  const [individualSuffix, setIndividualSuffix] = useState('');
  const [individualDOB, setIndividualDOB] = useState('');
  const [individualSSN, setIndividualSSN] = useState('');
  const [individualBirthPlace, setIndividualBirthPlace] = useState('');
  const [individualGender, setIndividualGender] = useState(null);
  const [individualaddressSection, setIndividualaddressSection] = useState([]);
  const [individualDriverLicenseFields, setIndividualDriverLicenseFields] =
    useState({});
  const [noOptionValue, setOptionValue] = useState({});
  const [entityOptionValue, setEntityOptionValue] = useState({});
  const [trustAgreementFields, setTrustAgreementFields] = useState({});
  const [companyNameSection, setCompanyNameSection] = useState({});
  const [companyAuthorizedFields, setCompanyAuthorizedFields] = useState({});
  const [companyAddressFields, setCompanyAddressFields] = useState({});
  const [companyContactDetailsFields, setCompanyContactDetailsFields] =
    useState({});
  const [companyPhoneFields, setCompanyPhoneFields] = useState([]);
  const [partnershipSectionFields, setPartnershipSectionFields] = useState({});
  const [partnershipAddressFields, setPartnershipAddressFields] = useState({});
  const [partnershipContactDetailsFields, setPartnershipContactDetailsFields] =
    useState({});
  const [trustName, setTrustName] = useState({});
  const [partners, setPartners] = useState([]);
  const [trustee, setTrustee] = useState([]);
  const [beneficiary, setBeneficiary] = useState([]);
  const [trustType, setTrustType] = useState();
  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);
  const [trustAgreementYesValue, setTrustAgreementYesValue] = useState('');
  const dispatch = useDispatch();
  const fieldData = useSelector((state) => state.policyOwnerAnnuityData);
  const { loading, error, formData } = fieldData;

  useEffect(() => {
    dispatch(policyOwnerAnnuityAction());
  }, [dispatch]);

  const questionSectionData = formData?.data?.questionSection;
  const noOptions = formData?.data?.noQuestion.options;
  const entityOptions = formData?.data?.entityQuestion.options;

  useEffect(() => {
    const initialPoValue = formData?.data?.questionSection?.options?.find(
      (item) => item.defaultChecked
    );
    if (initialPoValue) {
      const key = initialPoValue['id'];
      const value = initialPoValue['value'];
      setPoQuestion({ [key]: value });
    }
    const initialNoValue = formData?.data?.noQuestion?.options.find(
      (item) => item.defaultChecked
    );
    if (initialNoValue) {
      const key = initialNoValue['id'];
      const value = initialNoValue['value'];
      setOptionValue({ [key]: value });
    }
    const initialEntityValue = formData?.data?.entityQuestion?.options?.find(
      (item) => item.defaultChecked
    );
    if (initialEntityValue) {
      const key = initialEntityValue['id'];
      const value = initialEntityValue['value'];
      setEntityOptionValue({ [key]: value });
    }
    const initialTrustAgreementValue =
      formData?.data?.trustAgreementSection?.options?.find(
        (item) => item.defaultChecked
      );
    if (initialTrustAgreementValue) {
      const key = initialTrustAgreementValue['id'];
      const value = initialTrustAgreementValue['value'];
      setTrustAgreementFields({ [key]: value });
    }

    setIndividualRelationship(formData?.data?.insuredRelationship?.value);
    setIndividualPhoneList(formData?.data?.individualAddPhone);
    setIndividualEmailList(formData?.data?.individualAddEmail);
    setIndividualaddressSection(formData?.data?.individualaddressSection);
    setPartners(formData?.data?.partnerInfo);
    setCompanyPhoneFields(formData?.data?.companyPhoneFields);
    if (formData?.data?.trusteeSection !== undefined) {
      setTrustee(formData?.data?.trusteeSection);
    }
    if (formData?.data?.beneficiaryFields !== undefined) {
      setBeneficiary(formData?.data?.beneficiaryFields);
    }
    setTrustAgreementYesValue(formData?.data?.trustAgreementYesOptions?.value);
  }, [formData?.data]);

  const poQuestionChangeHandler = (event) => {
    const initNoValue = formData?.data?.noQuestion?.options.find(
      (item) => item.defaultChecked
    );
    if (initNoValue) {
      const key = initNoValue['id'];
      const value = initNoValue['value'];
      setOptionValue({ [key]: value });
    }
    const initEntityValue = formData?.data?.entityQuestion?.options?.find(
      (item) => item.defaultChecked
    );
    if (initEntityValue) {
      const key = initEntityValue['id'];
      const value = initEntityValue['value'];
      setEntityOptionValue({ [key]: value });
    }
    setPoQuestion(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const noOptionsChangeHandler = (event) => {
    const initialEntityValue = formData?.data?.entityQuestion?.options?.find(
      (item) => item.defaultChecked
    );
    if (initialEntityValue) {
      const key = initialEntityValue['id'];
      const value = initialEntityValue['value'];
      setEntityOptionValue({ [key]: value });
    }
    setOptionValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  const entityChangeHandler = (event) => {
    const initialEntityValue = formData?.data?.entityQuestion?.options?.find(
      (item) => item.defaultChecked
    );
    if (initialEntityValue) {
      const key = initialEntityValue['id'];
      const value = initialEntityValue['value'];
      setEntityOptionValue({ [key]: value });
    }
    setEntityOptionValue(() => ({
      [event.target.id]: event.target.value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    questionSection: poQuestion,
    noQuestion: noOptionValue,
    insuredRelationship: {
      'poa-individual-relationshipToTheInsured': individualRelationship,
    },
    individualTitle: individualTitle,
    individualFirstName: individualFirstName,
    individualMiddleName: individualMiddleName,
    individualLastName: individualLastName,
    individualSuffix: individualSuffix,
    individualDOB: individualDOB,
    individualSSN: individualSSN,
    individualBirthPlace: individualBirthPlace,
    individualGender: individualGender,
    individualAddEmail: individualEmailList,
    individualAddPhone: individualPhoneList,
    individualaddressSection: individualaddressSection,
    individualDriverLicenseFields: individualDriverLicenseFields,
    entityQuestion: entityOptionValue,
    trustTypeOptions: trustType,
    companyNameSection,
    companyAuthorizedFields,
    companyAddressFields,
    companyContactDetailsFields,
    companyPhoneFields,
    partnershipSectionFields,
    partnerInfo: partners,
    partnershipAddressFields,
    partnershipContactDetailsFields,
    trusteeSection: trustee,
    beneficiaryFields: beneficiary,
    trustAgreementSection: trustAgreementFields,
    trustAgreementYesOptions: {
      'poa-trustAgreement-yes-text': trustAgreementYesValue,
    },
    southDakotaQuestionFields: llcData,
    trustName,
  };
  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s28',
      },
    });
  }, [payload]);
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s28`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
        dispatch(policyOwnerAction());
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  const onCloseToastMessage = () => {
    showToastMessage(false);
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/ppaapplication"
        nextPath="/form/purchaserownerquestionnaire"
        pageHeading={formData?.metadata?.label}
        caseIdValue={formData?.common_details?.case_id}
        onSaveFormDataHandler={onSaveFormDataHandler}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId={formData?.common_details?.section_id}
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
        productType={formData?.common_details?.product_type}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {formData?.data && (
            <FormFieldsWrapper>
              <LabelRadioButtons
                label={questionSectionData.label}
                options={questionSectionData.options}
                onChangeHandler={poQuestionChangeHandler}
              />

              <div
                className={
                  poQuestion['poa-poQuestion-no'] === hideFields
                    ? 'show-component'
                    : 'hide-component'
                }
              >
                <CommonLabelRadioButtons
                  options={noOptions}
                  onChangeHandler={noOptionsChangeHandler}
                />
                <div className="line-separation layout-1"></div>

                <div
                  className={
                    poQuestion['poa-poQuestion-no'] === hideFields &&
                    noOptionValue['poa-noOption-individual'] === 'individual'
                      ? 'show-component'
                      : 'hide-component'
                  }
                >
                  <IndividualSectionAnnuity
                    setIndividualEmailList={setIndividualEmailList}
                    individualEmailList={individualEmailList}
                    individualPhoneList={individualPhoneList}
                    setIndividualPhoneList={setIndividualPhoneList}
                    setIndividualTitle={setIndividualTitle}
                    setIndividualFirstName={setIndividualFirstName}
                    setIndividualMiddleName={setIndividualMiddleName}
                    setIndividualLastName={setIndividualLastName}
                    setIndividualSuffix={setIndividualSuffix}
                    setIndividualDOB={setIndividualDOB}
                    setIndividualSSN={setIndividualSSN}
                    setIndividualBirthPlace={setIndividualBirthPlace}
                    setIndividualGender={setIndividualGender}
                    setIndividualaddressSection={setIndividualaddressSection}
                    individualaddressSection={individualaddressSection}
                    setIndividualDriverLicenseFields={
                      setIndividualDriverLicenseFields
                    }
                    individualRelationship={individualRelationship}
                    setIndividualRelationship={setIndividualRelationship}
                  />
                </div>

                <div
                  className={
                    poQuestion['poa-poQuestion-no'] === hideFields &&
                    noOptionValue['poa-noOption-entity'] === 'entity'
                      ? 'show-component'
                      : 'hide-component'
                  }
                >
                  <CommonLabelRadioButtons
                    options={entityOptions}
                    onChangeHandler={entityChangeHandler}
                  />
                  <div className="line-separation layout-1"></div>

                  <div
                    className={
                      poQuestion['poa-poQuestion-no'] === hideFields &&
                      noOptionValue['poa-noOption-entity'] === 'entity' &&
                      entityOptionValue['poa-entityOption-trust'] === 'trust'
                        ? 'show-component'
                        : 'hide-component'
                    }
                  >
                    <TrustSectionAnnuity
                      trustAgreementFields={trustAgreementFields}
                      setTrustAgreementFields={setTrustAgreementFields}
                      trustee={trustee}
                      setTrustName={setTrustName}
                      setTrustee={setTrustee}
                      setTrustType={setTrustType}
                      setTrustAgreementYesValue={setTrustAgreementYesValue}
                      trustAgreementYesValue={trustAgreementYesValue}
                      beneficiary={beneficiary}
                      setBeneficiary={setBeneficiary}
                    />
                  </div>
                  <div
                    className={
                      poQuestion['poa-poQuestion-no'] === hideFields &&
                      noOptionValue['poa-noOption-entity'] === 'entity' &&
                      entityOptionValue['poa-entityOption-llc'] === 'llc'
                        ? 'show-component'
                        : 'hide-component'
                    }
                  >
                    <LlcSectionAnnuity setLlcData={setLlcData} />
                  </div>

                  <div
                    className={
                      poQuestion['poa-poQuestion-no'] === hideFields &&
                      noOptionValue['poa-noOption-entity'] === 'entity' &&
                      entityOptionValue['poa-entityOption-company'] ===
                        'company'
                        ? 'show-component'
                        : 'hide-component'
                    }
                  >
                    <CompanySectionAnnuity
                      setCompanyNameSection={setCompanyNameSection}
                      setCompanyAuthorizedFields={setCompanyAuthorizedFields}
                      setCompanyAddressFields={setCompanyAddressFields}
                      setCompanyContactDetailsFields={
                        setCompanyContactDetailsFields
                      }
                      setCompanyPhoneFields={setCompanyPhoneFields}
                      companyPhoneFields={companyPhoneFields}
                    />
                  </div>

                  <div
                    className={
                      poQuestion['poa-poQuestion-no'] === hideFields &&
                      noOptionValue['poa-noOption-entity'] === 'entity' &&
                      entityOptionValue['poa-entityOption-partnership'] ===
                        'partnership'
                        ? 'show-component'
                        : 'hide-component'
                    }
                  >
                    <PartnershipSectionAnnuity
                      setPartnershipSectionFields={setPartnershipSectionFields}
                      setPartnershipAddressFields={setPartnershipAddressFields}
                      setPartnershipContactDetailsFields={
                        setPartnershipContactDetailsFields
                      }
                      partners={partners}
                      setPartners={setPartners}
                    />
                  </div>
                </div>
              </div>
            </FormFieldsWrapper>
          )}
        </>
      )}
      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};

export default PolicyOwnerAnnuity;
