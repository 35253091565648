import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { purchaserOwnerAction } from '../../../actions/purchaserOwnerAction';
import { SET_SEND_DATA } from '../../../constants/sendDataConstants';
import { TOAST_MESSAGE_TIME } from '../../../constants/toastMessageConstants';
import CommonFormHeader from '../../commonFormHeader';
import Loader from '../../loader';
import FormField from '../../ui/formField';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import LabelRadioButtons from '../../ui/labelRadioButtons';
import LabelRadioWithFields from '../../ui/labelRadioWithFields';
import ToastMessage from '../../ui/toastMessage';

const PurchaserOwnerQuestionnaire = () => {
  const pageData = useSelector((state) => state.purchaserOwnerData);
  const { loading, error, formData } = pageData;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(purchaserOwnerAction());
  }, [dispatch]);

  const [toastMessage, showToastMessage] = useState(false);
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const [purchaserName, setPurchaserName] = useState({});
  const purchaserNameChange = (e) => {
    setPurchaserName((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [purchaserOwnerCitizenValue, setPurchaserOwnerCitizenValue] = useState(
    {}
  );
  const purchaserOwnerCitizenChange = (e) => {
    setPurchaserOwnerCitizenValue((prevState) => ({
      [e.target.name]: e.target.value,
    }));
  };

  const [outsideUSBusinessRadio, setOutsideUSBusinessRadio] = useState({});
  const outsideUSBusinessChange = (e) => {
    setOutsideUSBusinessRadio(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const [outsideUSBusinessDetails, setOutsideUSBusinessDetails] = useState({});
  const outsideUSBusinessDetailsChange = (e) => {
    setOutsideUSBusinessDetails((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const [purchaserOwnerBusinessRadio, setPurchaserOwnerBusinessRadio] =
    useState({});

  const [indSpouseNetExceedOneMRadio, setIndSpouseNetExceedOneMRadio] =
    useState({});
  const indSpouseNetExceedOneMChange = (e) => {
    setIndSpouseNetExceedOneMRadio((prevState) => ({
      [e.target.id]: e.target.value,
    }));
  };
  const [indSpouseNetAmountValue, setIndSpouseNetAmountValue] = useState({});
  const indSpouseNetAmountChange = (e) => {
    setIndSpouseNetAmountValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const [jointSpouseNetExceex3MRadio, seJjointSpouseNetExceex3MRadio] =
    useState({});
  const jointSpouseNetExceex3MChange = (e) => {
    seJjointSpouseNetExceex3MRadio(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const [jointSpouseNetAmountValue, setJointSpouseNetAmountValue] = useState(
    {}
  );
  const jointSpouseNetAmountChange = (e) => {
    setJointSpouseNetAmountValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const [individual5MInvestmentRadio, setIndividual5MInvestmentRadio] =
    useState({});
  const ind5MInvestmentAmtChange = (e) => {
    setIndividual5MInvestmentRadio(() => ({
      [e.target.id]: e.target.value,
    }));
  };
  const [individual5MInvestmentValue, setIndividual5MInvestmentValue] =
    useState({});
  const individual5MInvestmentAmtChange = (e) => {
    setIndividual5MInvestmentValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  const [indJointInvesQPValue, setIndJointInvesQPValue] = useState({});
  const indJointInvesQPChange = (e) => {
    setIndJointInvesQPValue(() => ({
      [e.target.id]: e.target.value,
    }));
  };

  useEffect(() => {
    const indSpouseNetExceedOneMCheck =
      formData?.data?.indSpouseNetExceedOneM?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (indSpouseNetExceedOneMCheck) {
      const key = indSpouseNetExceedOneMCheck['id'];
      const value = indSpouseNetExceedOneMCheck['value'];
      setIndSpouseNetExceedOneMRadio({ [key]: value });
    }

    const jointSpouseNetExceex3MCheck =
      formData?.data?.jointSpouseNetExceex3M?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (jointSpouseNetExceex3MCheck) {
      const key = jointSpouseNetExceex3MCheck['id'];
      const value = jointSpouseNetExceex3MCheck['value'];
      seJjointSpouseNetExceex3MRadio({ [key]: value });
    }

    const individual5MInvestmentCheck =
      formData?.data?.individual5MInvestment?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (individual5MInvestmentCheck) {
      const key = individual5MInvestmentCheck['id'];
      const value = individual5MInvestmentCheck['value'];
      setIndividual5MInvestmentRadio({ [key]: value });
    }

    const outsideUSBusinessRadioCheck =
      formData?.data?.outsideUSBusiness?.radioOptions?.find(
        (item) => item.defaultChecked
      );
    if (outsideUSBusinessRadioCheck) {
      const key = outsideUSBusinessRadioCheck['id'];
      const value = outsideUSBusinessRadioCheck['value'];
      setOutsideUSBusinessRadio({ [key]: value });
    }
  }, [formData?.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      showToastMessage(false);
    }, TOAST_MESSAGE_TIME);
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const payload = {
    purchaserName: purchaserName,
    purchaserOwnerCitizen: purchaserOwnerCitizenValue,
    outsideUSBusiness: {
      radioOptions: outsideUSBusinessRadio,
      provideDetails: outsideUSBusinessDetails,
    },
    purchaserOwnerBusinessDetail: {
      radioOptions: purchaserOwnerBusinessRadio,
    },
    indSpouseNetExceedOneM: indSpouseNetExceedOneMRadio,
    indSpouseNetAmount: indSpouseNetAmountValue,
    jointSpouseNetExceex3M: jointSpouseNetExceex3MRadio,
    jointSpouseNetAmount: jointSpouseNetAmountValue,
    individual5MInvestment: individual5MInvestmentRadio,
    individual5MInvestmentAmt: individual5MInvestmentValue,
    indJointInvesQP: indJointInvesQPValue,
  };

  useEffect(() => {
    dispatch({
      type: SET_SEND_DATA,
      payload: {
        payload: payload,
        sectionId: 'f1v1s24',
      },
    });
  }, [payload]);
  const onCloseToastMessage = (e) => {
    showToastMessage(false);
  };
  const onSaveFormDataHandler = () => {
    setShowDataSaveLoader(true);
    axios
      .post(
        `/rpiq-form-save/${localStorage.getItem('formId')}/f1v1s24`,
        payload
      )
      .then((response) => {
        showToastMessage(true);
        setShowDataSaveLoader(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
        setShowDataSaveLoader(false);
      });
  };
  return (
    <>
      <CommonFormHeader
        backPath="/form/policyownerannuity"
        nextPath="/form/dispositionofnetpremium"
        pageHeading={formData?.metadata?.label}
        onSaveFormDataHandler={onSaveFormDataHandler}
        caseIdValue={formData?.common_details?.case_id}
        isCompleted={formData?.metadata?.isCompleted}
        sectionId={formData?.common_details?.section_id}
        userName={formData?.common_details?.name}
        productSubType={formData?.common_details?.product_subtype}
        formId={formData?.common_details?.form_id}
        otherCasesInfo={formData?.common_details?.other_cases}
        productType={formData?.common_details?.product_type}
      />
      {loading ? (
        <Loader />
      ) : (
        <FormFieldsWrapper>
          <div className=" line-separation">
            {formData?.data?.purchaserName?.map((item, index) => {
              return (
                <FormField
                  key={index}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  value={item.value}
                  layoutType={item.layoutType}
                  name={item.name}
                  onChangeHandler={purchaserNameChange}
                  regex={item?.regex}
                  errorMessage={item?.errorMessage}
                  validationType={item.validationType}
                />
              );
            })}
          </div>
          <div className="line-separation">
            <LabelRadioButtons
              label={formData?.data?.purchaserOwnerCitizen?.label}
              options={formData?.data?.purchaserOwnerCitizen?.radioOptions}
              onChangeHandler={purchaserOwnerCitizenChange}
            />
          </div>

          <div className="line-separation">
            <div className={`label-radioButtons-wrapper `}>
              <p className="group-label">
                {formData?.data?.outsideUSBusiness?.label}
              </p>

              <div
                className="radio-buttons-wrapper"
                onChange={outsideUSBusinessChange}
              >
                {formData?.data?.outsideUSBusiness?.radioOptions?.map(
                  (item, index) => {
                    return (
                      <div className="radio-button" key={index}>
                        <input
                          type={item.type}
                          value={item.value}
                          name={item.name}
                          id={item.id}
                          className="radio"
                          defaultChecked={item.defaultChecked}
                        />
                        <label htmlFor={item.id} className="radio-label">
                          {item.label}
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            {outsideUSBusinessRadio['poq-businessOutsideUS-yes'] === 'yes' && (
              <FormField
                id={formData?.data?.outsideUSBusiness?.provideDetails?.id}
                label={formData?.data?.outsideUSBusiness?.provideDetails?.label}
                type={formData?.data?.outsideUSBusiness?.provideDetails?.type}
                value={formData?.data?.outsideUSBusiness?.provideDetails?.value}
                layoutType={
                  formData?.data?.outsideUSBusiness?.provideDetails?.layoutType
                }
                name={formData?.data?.outsideUSBusiness?.provideDetails?.name}
                onChangeHandler={outsideUSBusinessDetailsChange}
              />
            )}
          </div>

          <div>
            <h3>{formData?.data?.accreditedInvestorheading}</h3>
            {formData?.data?.purchaserOwnerBusinessDetail?.map(
              (item, index) => {
                return (
                  <div className="line-separation" id={index}>
                    <LabelRadioWithFields
                      id={item.id}
                      label={item.label}
                      options={item.radioOptions}
                      setRadioChangeValue={setPurchaserOwnerBusinessRadio}
                    />
                  </div>
                );
              }
            )}
          </div>

          <div className="line-separation">
            <LabelRadioButtons
              label={formData?.data?.indSpouseNetExceedOneM?.label}
              options={formData?.data?.indSpouseNetExceedOneM?.radioOptions}
              onChangeHandler={indSpouseNetExceedOneMChange}
            />
            {indSpouseNetExceedOneMRadio['poq-indSpouseNetExceedOneM-no'] ===
              'no' && (
              <FormField
                id={formData?.data?.indSpouseNetAmount?.id}
                label={formData?.data?.indSpouseNetAmount?.label}
                type={formData?.data?.indSpouseNetAmount?.type}
                value={formData?.data?.indSpouseNetAmount?.value}
                layoutType={formData?.data?.indSpouseNetAmount?.layoutType}
                name={formData?.data?.indSpouseNetAmount?.name}
                onChangeHandler={indSpouseNetAmountChange}
              />
            )}
          </div>
          <div className="line-separation">
            <LabelRadioButtons
              label={formData?.data?.jointSpouseNetExceex3M?.label}
              options={formData?.data?.jointSpouseNetExceex3M?.radioOptions}
              onChangeHandler={jointSpouseNetExceex3MChange}
            />
            {jointSpouseNetExceex3MRadio['poq-jointSpouseNetExceex3M-no'] ===
              'no' && (
              <FormField
                id={formData?.data?.jointSpouseNetAmount?.id}
                label={formData?.data?.jointSpouseNetAmount?.label}
                type={formData?.data?.jointSpouseNetAmount?.type}
                value={formData?.data?.jointSpouseNetAmount?.value}
                layoutType={formData?.data?.jointSpouseNetAmount?.layoutType}
                name={formData?.data?.jointSpouseNetAmount?.name}
                onChangeHandler={jointSpouseNetAmountChange}
              />
            )}
          </div>
          <h3>{formData?.data?.qualifiedPurchaserHeading}</h3>
          <div className="line-separation">
            <LabelRadioButtons
              label={formData?.data?.individual5MInvestment?.label}
              options={formData?.data?.individual5MInvestment?.radioOptions}
              onChangeHandler={ind5MInvestmentAmtChange}
            />
            {individual5MInvestmentRadio['poq-individual5MInvestment-no'] ===
              'no' && (
              <FormField
                id={formData?.data?.individual5MInvestmentAmt?.id}
                label={formData?.data?.individual5MInvestmentAmt?.label}
                type={formData?.data?.individual5MInvestmentAmt?.type}
                value={formData?.data?.individual5MInvestmentAmt?.value}
                layoutType={
                  formData?.data?.individual5MInvestmentAmt?.layoutType
                }
                name={formData?.data?.individual5MInvestmentAmt?.name}
                onChangeHandler={individual5MInvestmentAmtChange}
              />
            )}
          </div>
          <div className="line-separation">
            <LabelRadioButtons
              label={formData?.data?.indJointInvesQP?.label}
              options={formData?.data?.indJointInvesQP?.radioOptions}
              onChangeHandler={indJointInvesQPChange}
            />
          </div>
        </FormFieldsWrapper>
      )}

      {toastMessage === true && (
        <ToastMessage onCloseToastMessage={onCloseToastMessage} />
      )}
      {showDataSaveLoader && <Loader />}
    </>
  );
};
export default PurchaserOwnerQuestionnaire;
