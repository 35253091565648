// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  width: 50%;
  overflow-x: auto;
}

.react-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.react-table th,
.react-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.react-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

.react-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-height: 450px;
  overflow: auto;
}
.overlay-content-close {
  display: inline-block;
  margin-bottom: 15px;
  align-items: center;
}
.red-bold-warning-forms {
  color: red;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  padding-bottom: 2px;
  display: flex;
}

.overlay-redirect-list {
  margin: 24px auto;
  display: block;
  width: 230px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/carrierUploadForm/matchingFormList.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uCAAuC;EACvC,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".table-container {\n  width: 50%;\n  overflow-x: auto;\n}\n\n.react-table {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid #ddd;\n}\n\n.react-table th,\n.react-table td {\n  padding: 10px;\n  border: 1px solid #ddd;\n}\n\n.react-table th {\n  background-color: #f2f2f2;\n  font-weight: bold;\n  text-align: left;\n}\n\n.react-table tbody tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.overlay-content {\n  background: white;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  max-height: 450px;\n  overflow: auto;\n}\n.overlay-content-close {\n  display: inline-block;\n  margin-bottom: 15px;\n  align-items: center;\n}\n.red-bold-warning-forms {\n  color: red;\n  font-weight: bold;\n  font-size: 14px;\n  align-items: center;\n  padding-bottom: 2px;\n  display: flex;\n}\n\n.overlay-redirect-list {\n  margin: 24px auto;\n  display: block;\n  width: 230px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
