import './radioWithHeading.css';
const RadioWithHeading = (props) => {
  return (
    <>
      <p className="rwh-label">{props?.label && props?.label}</p>
      <div className="rwh-radio">
        {props?.options &&
          props?.options?.map((item, index) => {
            return (
              <div key={index}>
                <label
                  className={`field-label center-align ${
                    index % 2 ? 'space-between-radio' : ''
                  }`}
                >
                  <input
                    id={item.id}
                    type={item.type}
                    name={item.name}
                    value={item.value}
                    onChange={props.onRadioChange}
                    defaultChecked={item.defaultChecked}
                  />
                  <span>{item.label}</span>
                </label>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default RadioWithHeading;
