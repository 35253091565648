import FormField from '../../ui/formField';
import LabelRadioButtons from '../../ui/labelRadioButtons/index.js';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const MailingAddressSection = (props) => {
  const [showMailingFields, setShowMailingFields] = useState('yes');

  const piFormData = useSelector((state) => state.piData);

  const { loading, error, formData } = piFormData;
  const mailingPreferredRadioData = formData?.data?.mailingPreferredRadio;
  const mailingAddressChangeHandler = (event) => {
    setShowMailingFields(event.target.value.toLowerCase());

    props.setShowMailingAddressFields(() => ({
      [event.target.id]: event.target.value,
    }));
  };
  const onFieldChangeHandler = (event) => {
    props.setMailingAddressData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handelMailingPreferredRadio = (event) => {
    props.setMailingPreferredRadio(true);
    const updateAddressList = [...props.addAddressList];
    updateAddressList.map((item) => {
      item.preferredAddress = false;
    });
    props.setAddAddressList(updateAddressList);
  };

  const MailingAddressData = formData?.data?.mailingAddressLabel;

  const MailingAddressFields = formData?.data?.mailingAddressFields.map(
    (item, index) => {
      return (
        <>
          <FormField
            key={item.id}
            label={item.label}
            type={item.type}
            id={item.id}
            name={item.name}
            value={item.value}
            placeholder={item.placeholder}
            min={item.min}
            max={item.max}
            layoutType={item.layoutType}
            onChangeHandler={onFieldChangeHandler}
            selectOptions={item.selectOptions}
            dropdownType={item.dropdownType}
          />
          {index === 6 && (
            <div className="label-value-wrapper layout-3 make-primary-layout">
              <input
                type={mailingPreferredRadioData?.radioOptions?.type}
                id={mailingPreferredRadioData?.radioOptions?.id}
                name={mailingPreferredRadioData?.radioOptions?.name}
                value={true}
                onChange={handelMailingPreferredRadio}
                className="makePrimary-radio"
                checked={props.mailingPreferredRadio ? true : false}
              />
              <label
                htmlFor={mailingPreferredRadioData?.radioOptions?.id}
                className="makePrimary-label"
              >
                Mark as Preferred Address
              </label>
            </div>
          )}
        </>
      );
    }
  );

  return (
    <>
      {formData?.data && (
        <LabelRadioButtons
          label={MailingAddressData.label}
          options={MailingAddressData.radioOptions}
          id={MailingAddressData.id}
          onChangeHandler={mailingAddressChangeHandler}
        />
      )}
      {props.showMailingAddressFields['mailingAddress-no'] === 'no' && (
        <div className="flex">{MailingAddressFields}</div>
      )}
    </>
  );
};
export default MailingAddressSection;
