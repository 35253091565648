import React from 'react';
import { IoFilterSharp, IoCloseSharp, IoSearchSharp } from 'react-icons/io5';
import { FcClearFilters } from 'react-icons/fc';

const FormAdministrationFilters = ({
  filters,
  handleFilterChange,
  toggleFilters,
  clearFilters,
  clearSearch,
}) => {
  return (
    <div className="formAdministration--filters">
      <div className="searchInputWrapper">
        <input
          type="text"
          placeholder="Search by Form Name, Tags"
          value={filters.searchInput}
          onChange={(e) => handleFilterChange('searchInput', e.target.value)}
        />

        <span
          title={filters.searchInput ? 'Close Search' : 'Clear Search'}
          onClick={filters.searchInput ? clearSearch : null}
        >
          {filters.searchInput ? (
            <IoCloseSharp className="clearSearchIcon" size={20} />
          ) : (
            <IoSearchSharp className="clearSearchIcon" size={20} />
          )}
        </span>
      </div>

      <button
        className="filterButton"
        title="Clear All Filters"
        onClick={clearFilters}
        disabled={
          !(
            (filters.formName && filters.formName.length > 0) ||
            (filters.categoryName && filters.categoryName.length > 0) ||
            filters.productCategories.length > 0 ||
            filters.carriers.length > 0 ||
            filters.products.length > 0 ||
            filters.states.length > 0 ||
            filters.categoryTags.length > 0 ||
            filters.formTypes.length > 0
          )
        }
      >
        <FcClearFilters className="fcClearFilters" size={20} />
        <span className="buttonLabels"> Clear All Filters</span>
      </button>
    </div>
  );
};

export default FormAdministrationFilters;
