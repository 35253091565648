import './../Modal.css';

const CopyCaseDataModal = (props) => {
  return (
    <>
      <div className="modal-component">
        <div className="modal-overlay"></div>
        <div className="modal-wrapper">
          <h2 className="modal-heading">Are you Sure?</h2>
          <p className="modal-contents">
            This will copy the information from "{props.baseCaseId}" case id to
            "{props.targetCaseId}" once copied it can't be rolled back.
          </p>
          <div className="modal-buttons">
            <button
              onClick={props.onCancelhandler}
              className="button cancel-btn"
            >
              Cancel
            </button>
            <button
              onClick={props.onContinueHandler}
              className="button continue-btn"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CopyCaseDataModal;
