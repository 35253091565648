import { useEffect } from 'react';
import CommonFormHeader from '../../commonFormHeader';
import FormFieldsWrapper from '../../ui/formFieldsWrapper';
import './PolicyDetails.css';
import FormField from '../../ui/formField';
import Loader from '../../loader';
import { pageNavigationAction } from '../../../actions/pageNavigationAction';
import { useDispatch, useSelector } from 'react-redux';
import { policyDetailsAction } from '../../../actions/policyDetailsAction';

const PolicyDetails = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(policyDetailsAction());
  }, [dispatch]);

  const pdFormData = useSelector((state) => state.policyDetailsData);
  const { loading, error, pdData } = pdFormData;

  const addCommaSeparator = (value) => {
    if (value) {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      let lastValid = value;
      if (validNumber.test(value)) {
        lastValid = value;
      } else {
        value = lastValid;
      }
      let str = value.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const policyDetailsContents = pdData?.data?.map((item, index) => {
    return (
      <FormField
        key={item.id}
        label={item.label}
        type={item.type}
        id={item.id}
        name={item.name}
        value={
          item.name === 'pd-benefitAmount' || item.name === 'pd-premiumAmount'
            ? addCommaSeparator(item.value)
            : item.value
        }
        placeholder={item.placeholder}
        layoutType={item.layoutType}
        selectOptions={item.selectOptions}
        disabled
      />
    );
  });

  return (
    <>
      <CommonFormHeader
        hideSaveButton
        hideCompleteCheckBox
        backPath="/form/products&coverage"
        nextPath="/form/proposedinsured"
        pageHeading={pdData?.metadata?.label}
        caseIdValue={pdData?.common_details?.case_id}
        isCompleted={pdData?.metadata?.isCompleted}
        userName={pdData?.common_details?.name}
        sectionId="f1v1s2"
        productSubType={pdData?.common_details?.product_subtype}
        formId={pdData?.common_details?.form_id}
        otherCasesInfo={pdData?.common_details?.other_cases}
      />
      <FormFieldsWrapper>
        {loading ? (
          <Loader />
        ) : (
          <div className="policy-details-fields">{policyDetailsContents}</div>
        )}
      </FormFieldsWrapper>
    </>
  );
};

export default PolicyDetails;
