import React, { useState, useEffect, useRef } from 'react';
const CarrierUploadFormDropdown = ({
  selected,
  setSelected,
  options,
  labelKey,
  disabled,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  let customStyle = {};

  if (disabled) {
    customStyle = { pointerEvents: 'none', backgroundColor: '#f1f4f6' };
  }

  return (
    <div ref={dropdownRef} className="carrierUploadFormDropdown">
      <div
        className="carrierUploadFormDropdown--btn"
        onClick={() => {
          setIsActive(!isActive);
        }}
        style={customStyle}
      >
        {selected ? selected : 'Select'}
        <span
          className={`carrierUploadFormDropdown--btnIcon ${
            isActive ? 'carrierUploadFormDropdown--btnIcon__rotate' : ''
          }`}
        ></span>
      </div>
      {isActive && (
        <div className="carrierUploadFormDropdown--content">
          {options?.map((item, index) => {
            return (
              <div
                className={`carrierUploadFormDropdown--item ${
                  selected === item[labelKey]
                    ? 'carrierUploadFormDropdown--selected'
                    : ''
                }`}
                key={index}
                onClick={(e) => {
                  setSelected(item[labelKey]);
                  setIsActive(false);
                }}
              >
                {item[labelKey]}
              </div>
            );
          })}
        </div>
      )}
      {options?.length < 1 && <p></p>}
    </div>
  );
};

export default CarrierUploadFormDropdown;
