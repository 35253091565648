import './Checkboxes.css';
import radioCheckedImg from './../../../assets/images/radio-checked.svg';
import PropTypes from 'prop-types';

const Checkboxes = (props) => {
  const CheckboxField = (
    <div className="checkboxTabs">
      <input
        className="checkboxField"
        disabled={props.disabled}
        type={props.type}
        value={props.value}
        name={props.name}
        id={props.id}
        onChange={props.onChangeHandler}
        defaultChecked={props.defaultChecked}
        checked={props?.checked}
      />
      <label className="customCheckbox" htmlFor={props.id}>
        <img
          src={radioCheckedImg}
          alt="checked "
          className="customCheckbox__image"
        />
        <span className="customCheckbox__label">{props.label}</span>
      </label>
    </div>
  );
  return <>{CheckboxField}</>;
};
export default Checkboxes;

Checkboxes.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
