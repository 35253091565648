import './sortModal.css';
import closeIcon from '../../../../assets/images/close.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { listUsers } from '../../../../actions/caseListingAction';
import ascendingBlueIcon from '../../../../assets/images/ascendingBlueIcon.svg';
import descendingBlackIcon from '../../../../assets/images/descendingBlackIcon.svg';
import descendingBlueIcon from '../../../../assets/images/descendingBlueIcon.svg';
import ascendingBlackIcon from '../../../../assets/images/ascendingBlackIcon.svg';

const SortModal = (props) => {
  const [active, setActive] = useState('');
  const dispatch = useDispatch();

  const onUpArrowClick = (id) => {
    dispatch(
      listUsers(
        '',
        '',
        '',
        '',
        props.productType,
        props.caseType,
        `${id}_ascending`
      )
    );
    setActive(id + '_ascending');
    props.setShowModal(false);
  };
  const onDownArrowClick = (id) => {
    dispatch(
      listUsers(
        '',
        '',
        '',
        '',
        props.productType,
        props.caseType,
        `${id}_descending`
      )
    );
    setActive(id + '_descending');
    props.setShowModal(false);
  };

  return (
    <>
      <div className="sort-modal-component">
        <div className="sort-modal-overlay"></div>
        <div className="sort-modal-wrapper">
          <div className="sort-modal-heading-container">
            <p>Sort By</p>
            <img src={closeIcon} onClick={() => props.setShowModal(false)} />
          </div>
          <ul className="side-nav-sort  top-margin">
            {props?.sortData?.sortModalData &&
              props?.sortData?.sortModalData?.map((item) => {
                return (
                  <div key={item.id}>
                    <li
                      className={active === item.id ? 'active' : ''}
                      active={active === item.id}
                    >
                      <span
                        className={
                          (active === item.id + '_ascending' ||
                            active === item.id + '_descending') &&
                          'bold-sort-text'
                        }
                      >
                        {item.label}
                      </span>
                      <div>
                        <span
                          className="arrow-size"
                          onClick={() => onUpArrowClick(item.id)}
                        >
                          {active === item.id + '_ascending' ? (
                            <img src={ascendingBlueIcon} />
                          ) : (
                            <img src={ascendingBlackIcon} />
                          )}
                        </span>
                        <span
                          className="arrow-size"
                          onClick={() => onDownArrowClick(item.id)}
                        >
                          {active === item.id + '_descending' ? (
                            <img src={descendingBlueIcon} />
                          ) : (
                            <img src={descendingBlackIcon} />
                          )}
                        </span>
                      </div>
                    </li>
                  </div>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SortModal;
