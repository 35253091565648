import {
  FAMILY_HEALTH_HISTORY_DATA_REQUEST,
  FAMILY_HEALTH_HISTORY_DATA_SUCCESS,
  FAMILY_HEALTH_HISTORY_DATA_FAIL,
} from '../constants/familyHealthHistoryConstants';

export const familyHealthHistoryReducer = (
  state = { formData: [] },
  action
) => {
  switch (action.type) {
    case FAMILY_HEALTH_HISTORY_DATA_REQUEST:
      return { loading: true, formData: [] };
    case FAMILY_HEALTH_HISTORY_DATA_SUCCESS:
      return { loading: false, formData: action.payload };
    case FAMILY_HEALTH_HISTORY_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
