// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toastMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #14a37d;
  padding: 10px 10px 10px 10px;
  border-radius: 6px;
  position: fixed;
  right: 0;
  top: 200px;
  z-index: 11;
  box-shadow: 0px 3px 16px #0000001f;
  -ms-box-shadow: 0px 3px 16px #0000001f;
  -webkit-box-shadow: 0px 3px 16px #0000001f;
  -moz-box-shadow: 0px 3px 16px #0000001f;
}
.toastmessage-icon {
  margin-right: 5px;
}
.toastMessage-text {
  font-size: 13px;
  line-height: 1.5;
  color: #fff;
}
.toastMessage-close {
  cursor: pointer;
  height: 12px;
  font-size: 12px;
  color: #fff;
  margin-left: 8px;
}
.sync-toast-message {
  top: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/toastMessage/ToastMessage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,QAAQ;EACR,UAAU;EACV,WAAW;EACX,kCAAkC;EAClC,sCAAsC;EACtC,0CAA0C;EAC1C,uCAAuC;AACzC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ","sourcesContent":[".toastMessage {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #14a37d;\n  padding: 10px 10px 10px 10px;\n  border-radius: 6px;\n  position: fixed;\n  right: 0;\n  top: 200px;\n  z-index: 11;\n  box-shadow: 0px 3px 16px #0000001f;\n  -ms-box-shadow: 0px 3px 16px #0000001f;\n  -webkit-box-shadow: 0px 3px 16px #0000001f;\n  -moz-box-shadow: 0px 3px 16px #0000001f;\n}\n.toastmessage-icon {\n  margin-right: 5px;\n}\n.toastMessage-text {\n  font-size: 13px;\n  line-height: 1.5;\n  color: #fff;\n}\n.toastMessage-close {\n  cursor: pointer;\n  height: 12px;\n  font-size: 12px;\n  color: #fff;\n  margin-left: 8px;\n}\n.sync-toast-message {\n  top: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
