// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.toast {
  position: relative;
  min-width: 250px;
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  opacity: 0.9;
  display: flex;
}

.toast span {
  display: block;
}

.toast-success {
  background-color: #4caf50;
  display: flex;
}

.toast-error {
  background-color: #f44336;
}

.toast-warning {
  background-color: #ff9800;
}
.toast--iconSuccess {
  margin-right: 5px;
}
.toast--iconFail {
  height: 13px;
  width: 13px;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 9px;
  color: #fff;
  text-align: center;
  padding-top: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/notifications/Toast.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,WAAW;EACX,qBAAqB;EACrB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".toast-container {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 9999;\n}\n\n.toast {\n  position: relative;\n  min-width: 250px;\n  padding: 12px 16px;\n  margin-bottom: 8px;\n  border-radius: 4px;\n  font-size: 14px;\n  color: #fff;\n  opacity: 0.9;\n  display: flex;\n}\n\n.toast span {\n  display: block;\n}\n\n.toast-success {\n  background-color: #4caf50;\n  display: flex;\n}\n\n.toast-error {\n  background-color: #f44336;\n}\n\n.toast-warning {\n  background-color: #ff9800;\n}\n.toast--iconSuccess {\n  margin-right: 5px;\n}\n.toast--iconFail {\n  height: 13px;\n  width: 13px;\n  display: inline-block;\n  margin-right: 5px;\n  border: 1px solid #fff;\n  border-radius: 50%;\n  font-size: 9px;\n  color: #fff;\n  text-align: center;\n  padding-top: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
