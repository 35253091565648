import './ToastMessage.css';
import ToastMessageIcon from './../../../assets/images/icon-toastMessage.svg';
const SyncButtonToastMessage = (props) => {
  return (
    <div className="toastMessage sync-toast-message">
      <img
        className="toastmessage-icon"
        src={ToastMessageIcon}
        alt="Toast message Icon"
      />
      <span className="toastMessage-text">
        Web Form Data Updated successfully
      </span>
      <span
        className="toastMessage-close"
        onClick={props.onCloseSyncToastMessage}
      >
        X
      </span>
    </div>
  );
};

export default SyncButtonToastMessage;
