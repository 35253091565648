import { useState } from 'react';
import '../accordionSingleSection/AccordionSingleSection.css';
import deleteIcon from './../../../assets/images/delete-icon.svg';
import IconPlus from './../../../assets/images/icon-plus.svg';
import IconMinus from './../../../assets/images/icon-minus.svg';
const AccordionAddMoreSection = (props) => {
  const [openAccordion, setOpenAccordion] = useState(true);

  const onAccordionClick = (e) => {
    switch (e.target.dataset.accordion) {
      case 'true':
        setOpenAccordion(false);
        break;
      case 'false':
        setOpenAccordion(true);
        break;
      default: //Do Nothing
    }
  };
  return (
    <>
      <div className="accordion__wrapper">
        <div className={`accordion__header `}>
          <span
            className="accordionClickField"
            onClickCapture={onAccordionClick}
            data-accordion={openAccordion}
          ></span>
          <h3 className="accordion__accordionHeading">
            {props.accordionHeading}
          </h3>

          <div className="heading-container">
            {props.hideDelete && (
              <p className="accordion__sub_header">
                Please use Smart Office to delete this record
              </p>
            )}
            {props?.showDeleteIconOnFirstRecord && !props?.hideDeleteIcon ? (
              <span
                onClick={props.onDeleteClick}
                className={`icon-delete ${
                  props.hideDelete ? 'hide-component' : ''
                }`}
              >
                <img
                  src={deleteIcon}
                  alt="deleteIcon"
                  className="deleteIcon remarks-remove"
                />
              </span>
            ) : (
              <>
                {props.index && !props?.hideDeleteIcon ? (
                  <>
                    <span
                      onClick={props.onDeleteClick}
                      className={`icon-delete ${
                        props.hideDelete ? 'hide-component' : ''
                      }`}
                    >
                      <img
                        src={deleteIcon}
                        alt="deleteIcon"
                        className="deleteIcon remarks-remove"
                      />
                    </span>
                  </>
                ) : null}
              </>
            )}

            <img src={openAccordion ? IconMinus : IconPlus} alt="icon" />
          </div>
        </div>
        <div className={`accordion__content ${openAccordion ? 'open' : ''}`}>
          {props.children}
        </div>
      </div>
    </>
  );
};
export default AccordionAddMoreSection;
