import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  NAV_DATA_REQUEST,
  NAV_DATA_SUCCESS,
  NAV_DATA_FAIL,
} from '../constants/caseListingConstants';

export const caseListingReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const navDataReducer = (state = { nav: {} }, action) => {
  switch (action.type) {
    case NAV_DATA_REQUEST:
      return { loading: true, nav: {} };
    case NAV_DATA_SUCCESS:
      return { loading: false, nav: action.payload };
    case NAV_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
