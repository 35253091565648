// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purposeOfCoverage__radioTabsWrapper {
  justify-content: left;
}
.purposeOfCoverage__radioTabsWrapper input[type="radio"]:checked ~ span {
  color: #0177b5;
}
.purposeOfCoverage__radioTabs input[type="radio"] {
  margin-left: 0;
}
.purposeOfCoverage__radioTabs {
  margin-bottom: 10px;
  margin-right: 25px;
}
.purposeOfCoverage__radioTabs label {
  cursor: pointer;
  display: inline-block;
}
.purposeOfCoverage__textbox {
  margin: 5px 0 30px;
}
.purposeOfCoverage__textbox.label-value-wrapper .field-label {
  font-size: 14px;
}
.purposeOfCoverage__heading {
  font-weight: 600;
}
.poc_productsWrapper {
  display: flex;
  -webkit-display: flex;
  margin-bottom: 20px;
}
.poc_productsWrapper .customCheckbox__image {
  height: 12px;
  left: 6px;
  top: 10px;
}
.poc_productsWrapper .checkboxTabs .customCheckbox {
  padding: 8px 20px 8px 20px;
  margin-bottom: 0;
  display: inline-block;
}
.poc_productsWrapper .checkboxTabs {
  display: block;
  height: 50px;
  margin-bottom: 12px;
}
.poc_productsWrapper .customCheckbox__label {
  font-size: 12px;
  line-height: 1.3;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/purposeOfCoverage/PurposeOfCoverage.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,qBAAqB;AACvB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,SAAS;EACT,SAAS;AACX;AACA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".purposeOfCoverage__radioTabsWrapper {\n  justify-content: left;\n}\n.purposeOfCoverage__radioTabsWrapper input[type=\"radio\"]:checked ~ span {\n  color: #0177b5;\n}\n.purposeOfCoverage__radioTabs input[type=\"radio\"] {\n  margin-left: 0;\n}\n.purposeOfCoverage__radioTabs {\n  margin-bottom: 10px;\n  margin-right: 25px;\n}\n.purposeOfCoverage__radioTabs label {\n  cursor: pointer;\n  display: inline-block;\n}\n.purposeOfCoverage__textbox {\n  margin: 5px 0 30px;\n}\n.purposeOfCoverage__textbox.label-value-wrapper .field-label {\n  font-size: 14px;\n}\n.purposeOfCoverage__heading {\n  font-weight: 600;\n}\n.poc_productsWrapper {\n  display: flex;\n  -webkit-display: flex;\n  margin-bottom: 20px;\n}\n.poc_productsWrapper .customCheckbox__image {\n  height: 12px;\n  left: 6px;\n  top: 10px;\n}\n.poc_productsWrapper .checkboxTabs .customCheckbox {\n  padding: 8px 20px 8px 20px;\n  margin-bottom: 0;\n  display: inline-block;\n}\n.poc_productsWrapper .checkboxTabs {\n  display: block;\n  height: 50px;\n  margin-bottom: 12px;\n}\n.poc_productsWrapper .customCheckbox__label {\n  font-size: 12px;\n  line-height: 1.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
