import React, { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import IconDownload from '../../../assets/images/carrier-download.svg';
import axios from 'axios';
import { useToast } from '../../notifications/ToastContext';
import Loader from '../../loader';

const OverlayTable = ({ formData, onClose }) => {
  const { addToast } = useToast();
  const [showDataSaveLoader, setShowDataSaveLoader] = useState(false);

  const handleShowToast = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'success',
      message: message,
    };
    addToast(newToast);
  };

  const handleShowError = (message) => {
    const newToast = {
      id: Date.now(),
      type: 'error',
      message: message,
    };
    addToast(newToast);
  };
  const onDownloadClick = (uploadId) => {
    setShowDataSaveLoader(true);
    axios({
      url: 'carrier-upload/pdf-download',
      method: 'POST',
      data: { upload_id: uploadId },
      responseType: 'blob',
    })
      .then((response) => {
        setShowDataSaveLoader(false);

        let disposition = response.headers['content-disposition'];
        const filename = disposition.split('filename=')[1].replace(/["]+/g, '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        handleShowToast('File downloaded Successfully');
      })
      .catch(function (error) {
        setShowDataSaveLoader(false);

        handleShowError('Unexpected error!! Please contact administrator');
      });
  };
  return (
    <div className="overlay">
      <div className="overlay-content">
        <h1 style={{ float: 'left', fontSize: '16px' }}>
          Similar forms available in system
        </h1>
        <IoCloseSharp
          className="overlay-content-close"
          onClick={onClose}
          style={{ float: 'right' }}
          size={20}
        />
        <table className="react-table">
          <thead>
            <tr>
              <th>Carrier</th>
              <th>State</th>
              <th>Product Category</th>
              <th>Product Type</th>
              <th>Form Name</th>
              <th>Form Type</th>
              <th>Form Version</th>
              <th>Form Date</th>
              <th>Attached Form</th>
            </tr>
          </thead>
          <tbody>
            {formData?.map((form, index) => (
              <tr key={index}>
                <td>{form.carrier.join(', ')}</td>
                <td>{form.state_short_form.join(', ')}</td>

                <td>{form.product_category}</td>
                <td>{form.product.join(', ')}</td>
                <td>{form.form_name}</td>
                <td>{form.form_type}</td>
                <td>{form.form_version}</td>
                <td>{form.form_date}</td>
                <td>
                  <button
                    onClick={() => onDownloadClick(form.upload_id)}
                    className="formAdministration--Reupload-actionLink"
                  >
                    Download
                  </button>{' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Link
          className="button applicationUpload--btn overlay-redirect-list"
          to={'/formadministration'}
        >
          Go to Form Administration
        </Link>
      </div>
      {showDataSaveLoader && <Loader />}
    </div>
  );
};

export default OverlayTable;
