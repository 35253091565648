import { useSelector } from 'react-redux';
import Checkboxes from '../../../ui/formField/Checkboxes';

const QuestionSection = (props) => {
  const paFormData = useSelector((state) => state.paFieldData);
  const { loading, error, paData } = paFormData;

  const handleCheckBox = (event) => {
    let updatedList = [...props.checkboxValue];
    if (event.target.checked) {
      updatedList = [...props.checkboxValue, event.target.value];
    } else {
      updatedList.splice(props.checkboxValue.indexOf(event.target.value), 1);
    }
    props.setCheckBoxValue(updatedList);
  };

  return (
    <>
      {paData?.data?.professionalAdvisorsPerson?.label && (
        <p className="professionalAdvisors-heading">
          {paData?.data?.professionalAdvisorsPerson?.label}
        </p>
      )}
      {paData?.data?.professionalAdvisorsPerson?.option?.map(
        (option, index) => {
          return (
            <Checkboxes
              key={index}
              name={option.name}
              value={option.value}
              type={option.type}
              label={option.label}
              defaultChecked={option.defaultChecked}
              id={option.id}
              onChangeHandler={handleCheckBox}
            />
          );
        }
      )}
      {props.checkboxValue.includes('other') && (
        <div className="flex">
          <div
            className={`label-value-wrapper layout-3  ${
              !paData?.data?.otherValue?.value ? 'value--empty' : ''
            }`}
          >
            <label className="field-label" htmlFor="pa-other-text">
              Other
            </label>
            <div>
              <input
                type="text"
                id="pa-other-text"
                name="otherText"
                placeholder="Enter"
                onChange={(e) => {
                  props.setOtherValue(e.target.value);
                }}
                defaultValue={paData?.data?.otherValue?.value}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionSection;
