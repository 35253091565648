import {
  PA_DATA_REQUEST,
  PA_DATA_SUCCESS,
  PA_DATA_FAIL,
} from '../constants/professionalAdvisorsConstants';

export const professionalAdvisorsReducer = (state = { paData: [] }, action) => {
  switch (action.type) {
    case PA_DATA_REQUEST:
      return { loading: true, paData: [] };
    case PA_DATA_SUCCESS:
      return { loading: false, paData: action.payload };
    case PA_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
