import { useEffect, useState } from 'react';
import { listUsers } from '../../../../actions/caseListingAction';
import { useDispatch } from 'react-redux';
import Loader from '../../../loader';
import axios from '../../../../axios/axios';
import SideFilter from '../sideFilter';
import { useNavigate } from 'react-router';

const CaseListingSideNav = (props) => {
  const [fromDateMsg, setFromDateMsg] = useState('');
  const [toDateMsg, setToDateMsg] = useState('');
  const [btnLoader, setBtnLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dateSearch = () => {
    props.setStartValidation(true);

    if (props.fromDate.length > 9 && props.toDate.length > 9) {
      dispatch(
        listUsers(
          '',
          'side_search',
          props.fromDate,
          props.toDate,
          props.productType,
          props.caseType
        )
      );
    }
  };

  useEffect(() => {
    if (props.fromDate.length < 9) {
      setFromDateMsg('*this field is required');
    } else {
      setFromDateMsg('');
    }
    if (props.toDate.length < 9) {
      setToDateMsg('*this field is required');
    } else {
      setToDateMsg('');
    }
  }, [props.fromDate, props.toDate]);
  const onCaseListingBtnClick = () => {
    setBtnLoader(true);
    axios
      .post('/so-api/fetch-pending-cases')
      .then((response) => {
        setBtnLoader(false);
        dispatch(
          listUsers(
            '',
            'side_search',
            '',
            '',
            props.productType,
            props.caseType
          )
        );
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.detail === 'Not authenticated'
        ) {
          window.location.replace('/');
        }
        console.error('There was an error!', error);
      });
  };

  const onAppAvailClick = (pathname) => {
    navigate(pathname, { replace: true });
  };

  return (
    <>
      {btnLoader && <Loader showText />}
      <div className="case-listing-button-container">
        <button className="case-listing-button" onClick={onCaseListingBtnClick}>
          Update Data from Smart Office
        </button>
        <button
          className="case-listing-button applicationAvailable-btn"
          onClick={(e) => onAppAvailClick('/formadministration')}
        >
          Form Administration
        </button>
      </div>

      <div className="line-separation line-separation-width"></div>
      <SideFilter
        filterData={props?.sideNavData?.filterData}
        productType={props?.productType}
        caseType={props?.caseType}
      />
      <div className="line-separation line-separation-width"></div>
      <div className="label-value-wrapper date-width">
        <label className="field-label">From</label>
        <div>
          <input
            id="fromDate"
            type="date"
            name="from"
            min="1900-01-01"
            max={props.toDate || '3000-12-31'}
            value={props.fromDate}
            onChange={(e) => {
              props.setFromDate(e.target.value);
            }}
          />
          {props.startValidation && (
            <div className="errorMessage">{fromDateMsg}</div>
          )}
        </div>
      </div>
      <div className="label-value-wrapper date-width">
        <label className="field-label">To</label>
        <div>
          <input
            id="toDate"
            type="date"
            name="to"
            min={props.fromDate}
            max="3000-12-31"
            value={props.toDate}
            onChange={(e) => {
              props.setToDate(e.target.value);
            }}
          />
          {props.startValidation && (
            <div className="errorMessage">{toDateMsg}</div>
          )}
        </div>
      </div>
      <div className="listing-search-button-continer">
        <span className="list-search-button" onClick={dateSearch}>
          Search
        </span>
      </div>
    </>
  );
};

export default CaseListingSideNav;
